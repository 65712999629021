export default {
    methods: {
        formatBytes(bytes, decimals = 2) {
            if (!bytes) {
                return '';
            }
            if (bytes === 0) {
                return '0 Bytes';
            }
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        formatData(data) {
            if (!data && data !== 0) {
                return '';
            }
            return data.toFixed(2) + " %"
        }
    },
};
