<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                        </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="secondary" @handleClick="back()" class="margin-25-right">
                            <i class="glyphicons glyphicons-arrow-left"></i>{{ $t('AppConfigurationListForm.Voltar') }}
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="list-body">
                <!--Texto antes dos campos de título e descrição -->
                <div class="list-text">
                    <h1 v-if="!list_id">{{ $t('AppConfigurationListForm.Nova Lista de Configuração') }}</h1>
                    <h1 v-else>{{ $t('AppConfigurationListForm.Editar Lista de Configuração') }}</h1>
                </div>

                <!--Campos de título e descrição -->
                <div class="white-background">
                    <div class="list-text-input">
                        <h5> {{ $t('AppConfigurationListForm.Título da Lista') }}:</h5>
                        <app-input type="text" class="space-pattern" v-model="title"
                                   :placeholder="$t('AppConfigurationListForm.exemplo Configurações da Fazenda Norte')"></app-input>
                    </div>
                    <div class="list-text-input">
                        <h5>{{ $t('AppConfigurationListForm.Descrição da Lista') }}:</h5>
                        <app-input type="text" v-model="description"
                                   :placeholder="$t('AppConfigurationListForm.exemplo lista de atividades, paradas e parâmetros das máquinas colheitadeiras')"></app-input>
                    </div>
                </div>

                <!-- Tabela de motivos de parada -->
                <div class="white-background" style="max-height: 400px; overflow: auto">
                    <div v-if="stop_reasons_list">
                        <DataTable :value="stop_reasons_list" :rowHover="true"
                                   :selection.sync="stopReasonsSelected" dataKey="id" responsiveLayout="scroll"
                                   :loading="stopReasonsLoading" class="p-datatable-striped">
                            <template #header>
                                <h5>{{ $t('AppConfigurationListForm.Lista de Motivos de Parada') }}</h5>
                            </template>
                            <template #loading>
                                {{ $t('AppConfigurationListForm.Carregando motivos de parada, por favor aguarde') }}
                            </template>
                            <Column selectionMode="multiple" headerStyle="width: 3rem" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="code" :header="$t('AppConfigurationListForm.Código')"
                                    headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="description" :header="$t('AppConfigurationListForm.Descrição')"
                                    headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="type_description" :header="$t('AppConfigurationListForm.Tipo de Parada')"
                                    headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                        </DataTable>
                    </div>
                    <div v-else>
                        <div class="config-board">
                            <h3>
                                {{
                                    $t('AppConfigurationListForm.Não há registro de motivos de parada registrado para esta organização')
                                }}</h3>
                            <p>{{
                                    $t('AppConfigurationListForm.Clique no botão abaixo para redirecionar para página de adicionar motivos de parada')
                                }}</p>
                            <app-button type="secondary" @handleClick="openDialog('stop')">
                                <span class="pi pi-external-link mr-2"/>{{
                                    $t('AppConfigurationListForm.Ir para Motivos de Parada')
                                }}
                            </app-button>
                        </div>
                    </div>
                </div>

                <!-- Tabela de Atividades -->
                <div class="white-background" style="max-height: 400px; overflow: auto">
                    <div v-if="activities_list">
                        <DataTable :value="activities_list" :rowHover="true"
                                   :selection.sync="activitiesSelected" dataKey="id" responsiveLayout="scroll"
                                   :loading="activitiesLoading" class="p-datatable-striped">
                            <template #header>
                                <h5>{{ $t('AppConfigurationListForm.Lista de Atividades') }}</h5>
                            </template>
                            <template #loading>
                                {{ $t('AppConfigurationListForm.Carregando atividades, por favor aguarde') }}
                            </template>
                            <Column selectionMode="multiple" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="description" :header="$t('AppConfigurationListForm.Descrição')"
                                    headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column :header="$t('AppConfigurationListForm.Metas')" headerClass="headerClass"
                                    bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <li v-for="(item) in getActiveParams(slotProps.data.goals_list)">
                                        {{ item.description }}
                                    </li>
                                </template>
                            </Column>
                            <Column :header="$t('AppConfigurationListForm.Parâmetros')" headerClass="headerClass"
                                    bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <li v-for="(item) in getActiveParams(slotProps.data.parameters_list)">
                                        {{ item.description }}
                                    </li>
                                </template>
                            </Column>
                            <Column :header="$t('AppConfigurationListForm.Alarmes')" headerClass="headerClass"
                                    bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <li v-for="(item) in getActiveParams(slotProps.data.alerts_list)">
                                        {{ item.description }}
                                    </li>
                                </template>
                            </Column>
                            <Column header="" headerStyle="width: 50px" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <div v-show="buttonVisible(slotProps.data.id)">
                                        <Button icon="pi pi-cog"
                                                @click="openDialog('goals', slotProps.data)"/>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div v-else>
                        <div class="config-board">
                            <h3>{{
                                    $t('AppConfigurationListForm.Não há registro de atividades cadastradas para esta organização')
                                }}</h3>
                            <p>{{
                                    $t('AppConfigurationListForm.Clique no botão abaixo para redirecionar para página de adicionar atividades')
                                }}</p>
                            <app-button type="secondary" @handleClick="openDialog('activities')">
                                <span class="pi pi-external-link mr-2"/>Ir para Atividades
                            </app-button>
                        </div>
                    </div>
                </div>

                <div v-if="stop_reasons_list || activities_list" class="end-buttons">
                    <app-button style="margin:1rem" type="secondary" @handleClick="openDialog('clear')">
                        {{ $t('AppConfigurationListForm.Limpar')}}
                    </app-button>
                    <app-button style="margin:1rem" type="primary" @handleClick="checkResponse()">{{ $t('AppConfigurationListForm.Salvar') }}</app-button>
                </div>
            </div>
        </div>
        <!------------------------Dialogo para redirecionar para motivos de parada------------------------>
        <Dialog :header="$t('AppConfigurationListForm.Atenção')" :visible="stopReasonsDialog" :modal="true"
                @update:visible='closeDialog("stop")'>
            <div style="margin: 1rem 0rem">
                <h5>{{
                        $t('AppConfigurationListForm.Deseja sair da página atual para acessar os Motivos de Parada')
                    }}</h5>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationListForm.Cancelar')" icon="pi pi-times" class="p-button-text"
                        style="border-style: solid; border-width: 1px; border-color: #1d93f2"
                        @click='closeDialog("stop")'/>
                <Button :label="$t('AppConfigurationListForm.Confirmar')" icon="pi pi-check"
                        @click="redirectStopReasons()"/>
            </template>
        </Dialog>

        <!------------------------Dialogo para redirecionar para atividades------------------------>
        <Dialog :header="$t('AppConfigurationListForm.Atenção')" :visible="activitiesDialog" :modal="true"
                @update:visible='closeDialog("activities")'>
            <div style="margin: 1rem 0rem">
                <h5>{{ $t('AppConfigurationListForm.Deseja sair da página atual para acessar as Atividades') }}</h5>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationListForm.Cancelar')" icon="pi pi-times" class="p-button-text"
                        style="border-style: solid; border-width: 1px; border-color: #1d93f2"
                        @click='closeDialog("activities")'/>
                <Button :label="$t('AppConfigurationListForm.Confirmar')" icon="pi pi-check"
                        @click="redirectActivities()"/>
            </template>
        </Dialog>

        <!--------------------Dialogo para adicionar meta----------------------------------->
        <Dialog :header="$t('AppConfigurationListForm.Configurar atividade')" :visible.sync="goalsDialog" :modal="true"
                @update:visible='closeDialog("goals")' :style="{'width': is_col_12}">

            <div class="menu-dialog">
                <Button @click="active = 0" class="p-button-text" :class="{'button-dialog-active': active === 0}">
                    <i class="pi pi-fw pi-chart-line" :class="getIconClass(0)"/>
                    <label :class="getLabelClass(0)">Metas</label>
                </Button>

                <Button @click="active = 1" class="p-button-text mr-2" :class="{'button-dialog-active': active === 1}">
                    <i class="pi pi-fw pi-briefcase" :class="getIconClass(1)"/>
                    <label :class="getLabelClass(1)">{{ $t('AppConfigurationListForm.Parâmetros de Trabalho') }}</label>
                </Button>

                <Button @click="active = 2" class="p-button-text mr-2" :class="{'button-dialog-active': active === 2}">
                    <i class="pi pi-fw pi-bell" :class="getIconClass(2)"/>
                    <label :class="getLabelClass(2)">{{ $t('AppConfigurationListForm.Alarmes') }}</label>
                </Button>
            </div>

            <div style="height: 40vh">
                <div v-show="active === 0">
                    <DataTable :value="activityToReceiveGoal.goals_list" :rowHover="true"
                               dataKey="id" responsiveLayout="scroll"
                               class="p-datatable-striped">
                        <template #loading>
                            {{ $t('AppConfigurationListForm.Carregando motivos de parada, por favor aguarde') }}
                        </template>
                        <Column field="description" :header="$t('AppConfigurationListForm.Parâmetro')"
                                :headerStyle="{minWidth: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{minWidth: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div style="display: flex;justify-content: start;align-items: center">
                                    <input type="checkbox" v-model="slotProps.data.active" style="margin-right: 5px">
                                    {{ slotProps.data.description }}
                                </div>
                            </template>
                        </Column>
                        <Column field="value" :header="$t('AppConfigurationListForm.Valor')" headerClass="headerClass"
                                bodyClass="bodyClass">
                            <template #body="slotProps">
                                <div class="row" style="margin-right: 40px">
                                    <div class="col-11" style="display: flex;justify-content: end;align-items: center">
                                        <input type="number" v-model.number="slotProps.data.value" autofocus
                                               @input="onChangeGoal($event)"/>
                                    </div>
                                    <div class="col-1" style="margin-top: 5px">
                                        <span style="min-width: 30px !important;">{{ slotProps.data.suffix }}</span>
                                    </div>
                                </div>
                            </template>
                        </Column>

                    </DataTable>
                </div>

                <div v-show="active === 1">
                    <DataTable :value="activityToReceiveGoal.parameters_list" :rowHover="true"
                               dataKey="id" :style="{'width': table_width}" style="overflow: auto"
                               class="p-datatable-striped" responsiveLayout="scroll">
                        <template #loading>
                            {{ $t('AppConfigurationListForm.Carregando motivos de parada, por favor aguarde') }}
                        </template>

                        <Column field="description" :header="$t('AppConfigurationListForm.Parâmetro')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div v-if="!slotProps.data.parameterSpn" style="display: flex;">
                                    <input type="checkbox" v-model="slotProps.data.active" style="margin-right: 5px">
                                    {{ slotProps.data.description }}
                                </div>
                                <div v-else style="display: flex;">
                                    <input type="checkbox" v-model="slotProps.data.active" style="margin-right: 5px">
                                    <input v-model="slotProps.data.description" style="width: 100%;height: 25px"
                                           :placeholder="$t('AppConfigurationListForm.Descrição')"/>
                                    <input v-model="slotProps.data.spn" style="width: 100%;height: 25px;max-width: 50px"
                                           placeholder="SPN"/>
                                </div>
                            </template>
                        </Column>
                        <Column field="valueWork" :header="$t('AppConfigurationListForm.Trabalhando')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div class="row" style="font-size: 10px">
                                    <div class="p-0 col-6" style="display: flex;">
                                        <select v-model="slotProps.data.optionWork"
                                                style="font-size: 10px; height: 25px;max-width: 100%"
                                                @change="onChange($event)">
                                            <option disabled value="">{{$t('AppConfigurationListForm.Selecione')}}</option>
                                            <option v-for="option in slotProps.data.parameterOptions" :value="option">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="slotProps.data.optionWork.code !== 'BETWEEN'" class="col-4 p-0 pl-1"
                                         style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data.valueWork"
                                               style="width: 100%;height: 25px"
                                               v-show="slotProps.data.optionWork.valueEnable"/>
                                    </div>

                                    <div v-else class="col-5 p-0 pl-1 pr-1" style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data[slotProps.column.field]"
                                               style="width: 50%;height: 25px"/>
                                        -
                                        <input type="number" v-model.number="slotProps.data.valueSecondaryWork"
                                               style="width: 50%;height: 25px"/>
                                    </div>

                                </div>
                            </template>
                        </Column>
                        <Column field="valueManeuver" :header="$t('AppConfigurationListForm.Manobrando')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div class="row" style="font-size: 10px">
                                    <div class="p-0 col-6" style="display: flex;">
                                        <select v-model="slotProps.data.optionManeuver"
                                                style="font-size: 10px; height: 25px;max-width: 100%"
                                                @change="onChange($event, slotProps.data)">
                                            <option disabled value="">Selecione</option>
                                            <option v-for="option in slotProps.data.parameterOptions" :value="option">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="slotProps.data.optionManeuver.code !== 'BETWEEN'" class="col-3 p-0 pl-1"
                                         style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data.valueManeuver"
                                               style="width: 100%;height: 25px"
                                               v-show="slotProps.data.optionManeuver.valueEnable"/>
                                    </div>

                                    <div v-else class="col-5 p-0 pl-1 pr-1" style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data[slotProps.column.field]"
                                               style="width: 50%;height: 25px"/>
                                        -
                                        <input type="number" v-model.number="slotProps.data.valueSecondaryManeuver"
                                               style="width: 50%;height: 25px"/>
                                    </div>

                                </div>
                            </template>
                        </Column>
                        <Column field="valueTravel" :header="$t('AppConfigurationListForm.Deslocando')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div class="row" style="font-size: 10px">
                                    <div class="p-0 col-6" style="display: flex;">
                                        <select v-model="slotProps.data.optionTravel"
                                                style="font-size: 10px; height: 25px;max-width: 100%"
                                                @change="onChange($event, slotProps.data)">
                                            <option disabled value="">{{
                                                    $t('AppConfigurationListForm.Selecione')
                                                }}
                                            </option>
                                            <option v-for="option in slotProps.data.parameterOptions" :value="option">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="slotProps.data.optionTravel.code !== 'BETWEEN'" class="col-4 p-0 pl-1"
                                         style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data.valueTravel"
                                               style="width: 100%;height: 25px"
                                               v-show="slotProps.data.optionTravel.valueEnable"/>
                                    </div>

                                    <div v-else class="col-5 p-0 pl-1 pr-1" style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data[slotProps.column.field]"
                                               style="width: 50%;height: 25px"/>
                                        -
                                        <input type="number" v-model.number="slotProps.data.valueSecondaryTravel"
                                               style="width: 50%;height: 25px"/>
                                    </div>

                                </div>
                            </template>
                        </Column>
                        <Column field="valueIdle" :header="$t('AppConfigurationListForm.Ocioso')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div class="row" style="font-size: 10px">
                                    <div class="p-0 col-6" style="display: flex;">
                                        <select v-model="slotProps.data.optionIdle"
                                                style="font-size: 10px; height: 25px;max-width: 100%"
                                                @change="onChange($event, slotProps.data)">
                                            <option disabled value="">{{
                                                    $t('AppConfigurationListForm.Selecione')
                                                }}
                                            </option>
                                            <option v-for="option in slotProps.data.parameterOptions" :value="option">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div v-if="slotProps.data.optionIdle.code !== 'BETWEEN'" class="col-4 p-0 pl-1"
                                         style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data.valueIdle"
                                               style="width: 100%;height: 25px"
                                               v-show="slotProps.data.optionIdle.valueEnable"/>
                                    </div>

                                    <div v-else class="col-5 p-0 pl-1 pr-1" style="display: flex;">
                                        <input type="number" v-model.number="slotProps.data[slotProps.column.field]"
                                               style="width: 50%;height: 25px"/>
                                        -
                                        <input type="number" v-model.number="slotProps.data.valueSecondaryIdle"
                                               style="width: 50%;height: 25px"/>
                                    </div>

                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <div v-show="active === 2">
                    <DataTable :value="activityToReceiveGoal.alerts_list" :rowHover="true"
                               dataKey="id" responsiveLayout="scroll"
                               class="p-datatable-striped">
                        <template #loading>
                            {{ $t('AppConfigurationListForm.Carregando motivos de parada, por favor aguarde') }}
                        </template>

                        <Column field="description" :header="$t('AppConfigurationListForm.Parâmetro')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                            <template #body="slotProps">
                                <div style="display: flex;justify-content: start;align-items: center">
                                    <input type="checkbox" v-model="slotProps.data.active" style="margin-right: 5px">
                                    {{ slotProps.data.description }}
                                </div>
                            </template>
                        </Column>
                        <Column field="value" :header="$t('AppConfigurationListForm.Valor')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false"
                                :bodyStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}">
                            <template #body="slotProps">
                                <input v-if="slotProps.data.showEditValue"
                                       type="number" v-model.number="slotProps.data.value"
                                       style="width: 80%;height: 25px"
                                       :placeholder="getPlaceHolder(slotProps.data.suffix)"/>
                            </template>
                        </Column>
                        <Column field="time" :header="$t('AppConfigurationListForm.Tempo')"
                                :headerStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}"
                                :style="{overflow: 'hidden'}"
                                :sortable="false"
                                :bodyStyle="{width: '150px !important', overflow: 'hidden', textAlign: 'center'}">
                            <template #body="slotProps">
                                <input type="number" v-model.number="slotProps.data.time"
                                       style="width: 80%;height: 25px"
                                       :placeholder="$t('AppConfigurationListForm.segundos')"/>
                            </template>
                        </Column>
                    </DataTable>
                    <div
                        style="display: flex;justify-content: start;align-items: center;margin-top: 30px;padding-bottom: 40px">
                        SPN1:
                        <input v-model="activityToReceiveGoal.spnOne"
                               style="width: 80%;height: 25px;margin-right: 15px;"/>
                        SPN2:
                        <input v-model="activityToReceiveGoal.spnTwo"
                               style="width: 80%;height: 25px;margin-right: 15px;"/>
                        SPN3:
                        <input v-model="activityToReceiveGoal.spnThree" style="width: 80%;height: 25px"/>
                    </div>
                </div>
            </div>

            <template #footer>
                <Button :label="$t('AppConfigurationListForm.Cancelar')" icon="pi pi-times" class="p-button-text"
                        style="border-style: solid; border-width: 1px; border-color: #1d93f2"
                        @click='closeDialog("goals")'/>
                <Button @click='addGoal()' :label="$t('AppConfigurationListForm.Salvar')" icon="pi pi-save"/>
            </template>

        </Dialog>

        <!--------------------------------Dialogo para limpar campos-------------------------------->
        <Dialog :header="$t('AppConfigurationListForm.Limpar Campos')" :visible="clearFieldsDialog" :modal="true"
                @update:visible='closeDialog("clear")'>
            <div style="margin: 1rem 0rem">
                <h5>{{ $t('AppConfigurationListForm.limpar todos os campos do formulário') }} ?</h5>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationListForm.Cancelar')" icon="pi pi-times" class="p-button-text"
                        style="border-style: solid; border-width: 1px; border-color: #1d93f2"
                        @click='closeDialog("clear")'/>
                <Button :label="$t('AppConfigurationListForm.Limpar')" icon="pi"
                        @click="clearFields()"/>
            </template>
        </Dialog>

        <!--------------------------------Dialogo para salvar campos-------------------------------->
        <Dialog :header="$t('AppConfigurationListForm.Salvar Campos')" :visible="saveFieldsDialog" :modal="true"
                @update:visible='closeDialog("save")'>
            <div style="margin: 1rem 0rem">
                <h5>{{ $t('AppConfigurationListForm.Deseja salvar essa lista de configuração') }}</h5>
                <h6><b>{{
                        $t('AppConfigurationListForm.Atenção A atualização dessa lista para os dispositivos deverá ser feita manualmente na tela anterior')
                    }}</b></h6>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationListForm.Cancelar')" icon="pi pi-times" class="p-button-text"
                        style="border-style: solid; border-width: 1px; border-color: #1d93f2"
                        @click='closeDialog("save")'/>
                <Button :label="$t('AppConfigurationListForm.Salvar')" icon="pi pi-save" :disabled="disableSave"
                        @click="organizeRequest()"/>
            </template>
        </Dialog>

    </div>
</template>

<script>

import {alarmsTypes} from "./AlarmsTypes";
import {workParameters} from "./WorkParameters";
import {goalsTypes} from "./goalsTypes";
import {router} from '../../../router';
import AppInput from "../../common/AppInput";
import AppButton from "../../common/AppButton";
import {stopReasonsTypes} from "./stopReasonsList";

import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import InputNumber from 'primevue/inputnumber';

import ActivitiesService from "../../../services/ActivitiesService";
import StopReasonsService from "../../../services/StopReasonsService";
import ConfigurationListService from "../../../services/ConfigurationListService";

export default {
    mounted() {
        this.org_id = this.$route.query.org_id;
        this.list_id = this.$route.query.list_id;
        this.stopReasonsService = new StopReasonsService();
        this.configurationListService = new ConfigurationListService();
        this.activitiesService = new ActivitiesService();
        this.is_col_12 = this.isScreenMobile ? '85vw' : '50vw';
        this.table_width = this.isScreenMobile ? '200%' : '100%';
        this.getSavedData();
    },
    data() {
        return {
            is_col_12: '',
            table_width: '',
            //configuration list
            configurationListService: null,
            //services
            stopReasonsService: null,
            activitiesService: null,
            //stop reasons
            stopTypesList: stopReasonsTypes,
            stop_reasons_list: null,
            stopReasonsSelected: [],
            stopReasonsLoading: true,
            stopReasonsDialog: false,
            //activities
            activities_list: null,
            activitiesSelected: [],
            activitiesLoading: true,
            activitiesDialog: false,
            //goals
            activityToReceiveGoal: {
                goals_list: JSON.parse(JSON.stringify(goalsTypes)),
                parameters_list: JSON.parse(JSON.stringify(workParameters)),
                alerts_list: JSON.parse(JSON.stringify(alarmsTypes))
            },
            goalsDialog: false,
            //clear
            clearFieldsDialog: false,
            //save
            saveFieldsDialog: false,
            disableSave: false,
            //response
            list_id: null,
            org_id: null,
            active: 0,
            title: null,
            description: null,
            spn1: null,
            spn2: null,
            spn3: null
        }
    },
    methods: {
        getIconClass(index) {
            if (this.active === index) {
                return 'icon-dialog-active'
            }

            return 'icon-dialog'
        },
        getLabelClass(index) {
            if (this.active === index) {
                return 'label-dialog-active'
            }

            return 'label-dialog'
        },
        onChange(event) {
            //Só para refrescar essa tabela linda maravilhosa do primevue que não atualiza
            this.active = 0;
            this.active = 1;
        },
        onChangeGoal(event) {
            this.active = 1;
            this.active = 0;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getActiveParams(params) {
            return params.filter(obj => obj.active);
        },
        getPlaceHolder(suffix) {
            if (!suffix) {
                return "";
            }

            return suffix;
        },
        back() {
            router.push('/settings/configuration-list');
        },
        redirectStopReasons() {
            router.push('/settings/stop-reasons');
        },
        redirectActivities() {
            router.push('/settings/activities-list');
        },
        getStopReasons() {
            this.stopReasonsService.getStopReasonsByOrganization(this.org_id)
                .then((response) => {
                    if (response.length > 0) {
                        this.stop_reasons_list = response;
                        this.getStopTypes(this.stop_reasons_list);
                    }
                    this.getActivities();
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.stopReasonsLoading = false;
            })
        },
        getStopTypes(stop_reasons) {
            for (const element of stop_reasons) {
                let type = this.stopTypesList.find(stop => stop.type === element.type);
                if (!type) {
                    element.type_description = this.stopTypesList[0].description;
                } else {
                    element.type_description = type.description;
                }
            }
        },
        getActivities() {
            this.activitiesService.getActivitiesByOrganization(this.org_id)
                .then((response) => {
                    if (response.length > 0) {
                        this.activities_list = response;
                        this.activities_list.forEach(activity => {
                            activity['goals_list'] = JSON.parse(JSON.stringify(goalsTypes));
                            activity['parameters_list'] = JSON.parse(JSON.stringify(workParameters));
                            activity['alerts_list'] = JSON.parse(JSON.stringify(alarmsTypes));
                            activity['spnOne'] = null;
                            activity['spnTwo'] = null;
                            activity['spnThree'] = null;
                        })
                    }
                    if (this.list_id) {
                        this.getConfigurationList();
                    }
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.activitiesLoading = false;
            })
        },
        getSavedData() {
            this.getStopReasons();
        },
        getConfigurationList() {
            this.configurationListService.getConfigurationListData(this.list_id)
                .then((response) => {
                    this.editListConfiguration(response);
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                //
            })
        },
        editListConfiguration(response) {
            this.title = response.title;
            this.description = response.description;

            if (response.stop_reasons && response.stop_reasons.length > 0) {
                for (const element of response.stop_reasons) {
                    let stop_reason = this.stop_reasons_list.find(stop => stop.id === element.id);
                    this.stopReasonsSelected.push(stop_reason);
                }
            }

            if (response.activities && response.activities.length > 0) {
                let activitiesSelectedTemp = [];
                let activitiesListTemp = JSON.parse(JSON.stringify(this.activities_list));

                for (const elementActivity of response.activities) {
                    let activity = this.activities_list.find(act => act.id === elementActivity.id);
                    let activityIndex = this.activities_list.findIndex(act => act.id === elementActivity.id);

                    if (!activity) {
                        continue;
                    }

                    if (elementActivity.goals_list && elementActivity.goals_list.length > 0) {
                        for (const elementGoal of elementActivity.goals_list) {
                            let goal = activity.goals_list.find(goal => goal.type === elementGoal.type);
                            goal.active = true;
                            goal.value = elementGoal.value;

                            // activity[activity.goals_list.findIndex(goal => goal.type === elementGoal.type)] = goal;
                        }
                    }

                    if (elementActivity.parameters_list && elementActivity.parameters_list.length > 0) {
                        for (const elementParameter of elementActivity.parameters_list) {
                            let parameter = activity.parameters_list.find(goal => goal.type === elementParameter.type);
                            parameter = this.loadParameterValues(parameter, elementParameter)
                            // activity[activity.parameters_list.findIndex(goal => goal.type === elementParameter.type)] = parameter;
                        }
                    }

                    if (elementActivity.alerts_list && elementActivity.alerts_list.length > 0) {
                        for (const elementAlert of elementActivity.alerts_list) {
                            let alert = activity.alerts_list.find(goal => goal.type === elementAlert.type);
                            alert = this.loadAlertValues(alert, elementAlert);
                            if (elementAlert.spn_name && (elementAlert.type === "SPN_ONE_MAX" || elementAlert.type === "SPN_ONE_MIN")) {
                                activity.spnOne = elementAlert.spn_name;
                            }
                            if (elementAlert.spn_name && (elementAlert.type === "SPN_TWO_MAX" || elementAlert.type === "SPN_TWO_MIN")) {
                                activity.spnTwo = elementAlert.spn_name;
                            }
                            if (elementAlert.spn_name && (elementAlert.type === "SPN_THREE_MAX" || elementAlert.type === "SPN_THREE_MIN")) {
                                activity.spnThree = elementAlert.spn_name;
                            }
                        }
                    }
                    activitiesListTemp[activityIndex] = activity
                    activitiesSelectedTemp.push(activity);
                }

                this.activities_list = JSON.parse(JSON.stringify(activitiesListTemp));
                this.activitiesSelected = JSON.parse(JSON.stringify(activitiesSelectedTemp));
            }
        },
        loadAlertValues(element, elementValues) {
            element.value = elementValues.value;
            element.time = elementValues.time;
            element.active = true;

            return element;
        },
        loadParameterValues(element, elementValues) {
            element.type = elementValues.type;
            element.description = elementValues.name ? elementValues.name : element.description;
            element.valueWork = elementValues.value_work;
            element.valueSecondaryWork = elementValues.value_secondary_work;
            element.valueManeuver = elementValues.value_maneuver;
            element.valueSecondaryManeuver = elementValues.value_secondary_maneuver;
            element.valueTravel = elementValues.value_travel;
            element.valueSecondaryTravel = elementValues.value_secondary_travel;
            element.valueIdle = elementValues.value_idle;
            element.valueSecondaryIdle = elementValues.value_secondary_idle;
            element.optionWork = element.parameterOptions.find(obj => obj.code === elementValues.option_work);
            element.optionManeuver = element.parameterOptions.find(obj => obj.code === elementValues.option_maneuver);
            element.optionTravel = element.parameterOptions.find(obj => obj.code === elementValues.option_travel);
            element.optionIdle = element.parameterOptions.find(obj => obj.code === elementValues.option_idle);
            element.spn = elementValues.spn;
            element.active = true;

            return element;
        },
        buttonVisible(act_id) {
            let obj = this.activitiesSelected.find(obj => obj.id === act_id);
            return obj;
        },
        isNullOrEmptyString(val) {
            return (val === null || val === '' || val === undefined);
        },
        doesFieldRequireOptionValue(fieldCode) {
            if (fieldCode === 'GREATER_THAN') {
                return true;
            }

            if (fieldCode === 'LESS_THAN') {
                return true;
            }

            if (fieldCode === 'BETWEEN') {
                return true;
            }

            return false;
        },
        areFieldsValid() {
            let fieldsValid = true;
            this.activityToReceiveGoal.goals_list.forEach(goal => {
                if (goal.active && !goal.value) {
                    fieldsValid = false;
                }
            })

            this.activityToReceiveGoal.parameters_list.forEach((parameter) => {
                if (parameter.active && (parameter.type === "SPN_ONE" || parameter.type === "SPN_TWO" || parameter.type === "SPN_THREE") && !parameter.spn) {
                    fieldsValid = false;
                }

                if (parameter.active &&
                    ((this.doesFieldRequireOptionValue(parameter.optionWork.code) && this.isNullOrEmptyString(parameter.valueWork))
                        || (this.doesFieldRequireOptionValue(parameter.optionManeuver.code) && this.isNullOrEmptyString(parameter.valueManeuver))
                        || (this.doesFieldRequireOptionValue(parameter.optionTravel.code) && this.isNullOrEmptyString(parameter.valueTravel))
                        || (this.doesFieldRequireOptionValue(parameter.optionIdle.code) && this.isNullOrEmptyString(parameter.valueIdle)))) {
                    fieldsValid = false;
                }

                if (parameter.active
                    && ((parameter.optionWork.code === "BETWEEN" && this.isNullOrEmptyString(parameter.valueSecondaryWork))
                        || (parameter.optionManeuver.code === "BETWEEN" && this.isNullOrEmptyString(parameter.valueSecondaryManeuver))
                        || (parameter.optionTravel.code === "BETWEEN" && this.isNullOrEmptyString(parameter.valueSecondaryTravel))
                        || (parameter.optionIdle.code === "BETWEEN" && this.isNullOrEmptyString(parameter.valueSecondaryIdle)))) {
                    fieldsValid = false;
                }
            })

            this.activityToReceiveGoal.alerts_list.forEach(alert => {
                if (alert.active && !alert.time) {
                    fieldsValid = false;
                }
            })

            return fieldsValid;
        },

        addGoal() {
            if (!this.areFieldsValid()) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t$t('AppConfigurationListForm.Erro'),
                    detail: this.$t('AppConfigurationListForm.Preencha os campos corretamente'),
                    life: 5000
                });
                return;
            }

            let newList = JSON.parse(JSON.stringify(this.activities_list));
            let activityIndex = newList.findIndex(act => act.id === this.activityToReceiveGoal.id);
            newList[activityIndex] = this.activityToReceiveGoal
            this.activities_list = JSON.parse(JSON.stringify(newList));

            let activitiesSelectedTemp = JSON.parse(JSON.stringify(this.activitiesSelected));
            let activitySelectedIndex = activitiesSelectedTemp.findIndex(act => act.id === this.activityToReceiveGoal.id);
            activitiesSelectedTemp[activitySelectedIndex] = this.activityToReceiveGoal;
            this.activitiesSelected = activitiesSelectedTemp;

            this.closeDialog('goals');
        },
        clearFields() {
            this.title = '';
            this.description = '';
            this.stopReasonsSelected = [];
            this.activitiesSelected = [];
            for (let i = 0; i < this.activities_list.length; i++) {
                this.activities_list[i].goals_list = [];
            }

            this.closeDialog('clear');
        },

        checkResponse() {
            if (!this.title) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationListForm.Erro'),
                    detail: this.$t('AppConfigurationListForm.Preencha o campo de título'),
                    life: 5000
                });
                this.disableSave = false;
                return;
            } else if (this.stopReasonsSelected.length <= 0 && this.activitiesSelected.length <= 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationListForm.Erro'),
                    detail: this.$t('AppConfigurationListForm.Adicionar ao menos um motivo de parada ou uma atividade'),
                    life: 5000
                });
                this.disableSave = false;
                return;
            }
            this.openDialog('save');
        },
        organizeRequest() {
            this.disableSave = true;
            //loop de motivos de parada

            let request = {
                stop_reasons: [],
                activities: [],
                title: this.title,
                description: this.description,
            };

            for (const element of this.stopReasonsSelected) {
                request.stop_reasons.push({id: element.id});
            }

            this.activitiesSelected.forEach(activity => {

                let activityRequest = {
                    "id": activity.id,
                    "goals_list": [],
                    "parameters_list": [],
                    "alerts_list": [],
                };

                if (activity.goals_list && activity.goals_list.length > 0) {
                    for (const element of activity.goals_list) {
                        if (!element.active) {
                            continue;
                        }
                        activityRequest.goals_list.push({
                            "type": element.type,
                            "value": element.value
                        })
                    }
                }

                if (activity.parameters_list && activity.parameters_list.length > 0) {
                    for (const element of activity.parameters_list) {
                        if (!element.active) {
                            continue;
                        }
                        activityRequest.parameters_list.push(this.buildActivityParameterRequest(element))
                    }
                }

                if (activity.alerts_list && activity.alerts_list.length > 0) {
                    for (const element of activity.alerts_list) {
                        if (!element.active) {
                            continue;
                        }
                        activityRequest.alerts_list.push(this.buildActivityAlertRequest(element, activity))
                    }
                }

                request.activities.push(activityRequest)
            })


            this.sendRequest(this.org_id, request);
        },
        buildActivityAlertRequest(alert, activity) {
            if (alert.type === "SPN_ONE_MAX" || alert.type === "SPN_ONE_MIN") {
                return {
                    value: alert.value,
                    time: alert.time,
                    type: alert.type,
                    spn_name: activity.spnOne
                }
            }

            if (alert.type === "SPN_TWO_MAX" || alert.type === "SPN_TWO_MIN") {
                return {
                    value: alert.value,
                    time: alert.time,
                    type: alert.type,
                    spn_name: activity.spnTwo
                }
            }

            if (alert.type === "SPN_THREE_MAX" || alert.type === "SPN_THREE_MIN") {
                return {
                    value: alert.value,
                    time: alert.time,
                    type: alert.type,
                    spn_name: activity.spnThree
                }
            }

            return {
                value: alert.value,
                time: alert.time,
                type: alert.type
            }
        },
        buildActivityParameterRequest(parameter) {
            return {
                type: parameter.type,
                name: parameter.description,
                spn: parameter.spn,
                active: parameter.active,
                value_work: parameter.valueWork,
                value_secondary_work: parameter.valueSecondaryWork,
                value_maneuver: parameter.valueManeuver,
                value_secondary_maneuver: parameter.valueSecondaryManeuver,
                value_travel: parameter.valueTravel,
                value_secondary_travel: parameter.valueSecondaryTravel,
                value_idle: parameter.valueIdle,
                value_secondary_idle: parameter.valueSecondaryIdle,
                edit_enabled: parameter.editEnabled,
                option_work: parameter.optionWork.code,
                option_maneuver: parameter.optionManeuver.code,
                option_travel: parameter.optionTravel.code,
                option_idle: parameter.optionIdle.code
            }
        },
        sendRequest(orgId, request) {
            if (this.list_id) {
                this.configurationListService.editConfigurationList(this.list_id, request)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppConfigurationListForm.Sucesso'),
                            detail: this.$t('AppConfigurationListForm.Lista de configuração salva com sucesso'),
                            life: 5000
                        });
                        this.closeDialog('save');
                        this.disableSave = false;
                        this.back();
                    }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.status === 409) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppConfigurationListForm.Erro'),
                            detail: error.response.data.details,
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppConfigurationListForm.Erro'),
                            detail: this.$t('AppConfigurationListForm.Não foi possível salvar a lista de configuração'),
                            life: 5000
                        });
                    }
                    this.disableSave = false;
                })
            } else {
                this.configurationListService.saveConfigurationList(orgId, request)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppConfigurationListForm.Sucesso'),
                            detail: this.$t('AppConfigurationListForm.Lista de configuração salva com sucesso'),
                            life: 5000
                        });
                        this.closeDialog('save');
                        this.disableSave = false;
                        this.back();
                    }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.status === 409) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppConfigurationListForm.Erro'),
                            detail: error.response.data.details,
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppConfigurationListForm.Erro'),
                            detail: this.$t('AppConfigurationListForm.Não foi possível salvar a lista de configuração'),
                            life: 5000
                        });
                    }
                    this.disableSave = false;
                })
            }
        },
        openDialog(str, actv) {
            if (str === 'stop') {
                this.stopReasonsDialog = true;
            } else if (str === 'activities') {
                this.activitiesDialog = true;
            } else if (str === 'goals') {
                this.goalsDialog = true;
                this.activityToReceiveGoal = JSON.parse(JSON.stringify(actv));
            } else if (str === 'clear') {
                this.clearFieldsDialog = true;
            } else if (str === 'save') {
                this.saveFieldsDialog = true;
            }
        },
        closeDialog(str) {
            if (str === 'stop') {
                this.stopReasonsDialog = false;
            } else if (str === 'activities') {
                this.activitiesDialog = false;
            } else if (str === 'goals') {
                this.goalsDialog = false;
                this.activityToReceiveGoal = {
                    goals_list: JSON.parse(JSON.stringify(goalsTypes)),
                    parameters_list: JSON.parse(JSON.stringify(workParameters)),
                    alerts_list: JSON.parse(JSON.stringify(alarmsTypes))
                };
            } else if (str === 'clear') {
                this.clearFieldsDialog = false;
            } else if (str === 'save') {
                this.saveFieldsDialog = false;
            }
        }
    },
    computed: {
        activitySelectedParameters: function () {
            return this.activityToReceiveGoal.parameters_list;
        },
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    components: {
        router,
        stopReasonsTypes,
        goalsTypes,
        AppButton,
        AppInput,
        Dropdown,
        DataTable,
        Column,
        Dialog,
        Button,
        InputNumber,
        StopReasonsService,
        ActivitiesService,
        ConfigurationListService,
        Checkbox
    }
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

.white-background {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background: white;
    padding: 1rem 2rem;
    margin: 1rem 0rem;
    border-radius: 5px;
    width: 50%;
}

.list-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-text {
    margin: 2rem 0rem 1rem 0rem;
}

.list-text-input {
    margin: 1rem;
}

.dialog-goals {
    display: flex;
    justify-content: space-between;
    min-width: 525px;

}

.goal-line {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.goal-delete {
    color: red;
    margin-left: 1rem;
}

.goal-delete:hover {
    cursor: pointer;
}

.config-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem
}

.goal-delete:enabled:hover {
    background-color: #AD1111;
}

.btn-border {
    border-style: solid;
    border-width: 1px;
    border-color: #1d93f2;
}

.menu-dialog {
    width: 100%;
    border-bottom: solid 2px #cacaca;
    padding-top: 3px;
}

.label-dialog {
    color: #6C7589;
    font-weight: bold;
    position: relative;
    top: 4px;
}

.label-dialog-active {
    color: #0D89EC;
    font-weight: bold;
    position: relative;
    top: 4px;
}

.icon-dialog {
    margin: 0px 5px 0px 0px;
    color: #6C7589;
}

.icon-dialog-active {
    margin: 0px 5px 0px 0px;
    color: #0D89EC;
}

.button-dialog-active {
    position: relative;
    top: 1px;
    border-bottom: solid 2px #0D89EC !important;
}

@media(max-width: 1024px) {
    .white-background {
        width: 95%;
    }

    .data-table-width {
        width: 100vw;
    }

}
</style>
