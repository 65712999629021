<template>
    <div>
        <vue-highcharts :options="chart_options_activity" ref="child"/>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue';
import Highcharts from "highcharts";
import theme from "../../../assets/styles/charts/default";
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);


export default {
    props: {
        chart_data: {
            type: Object,
            required: false
        },
        chart_title: {
            type: String,
            required: false,
            default: null
        }
    },
    beforeCreate() {
        Highcharts.setOptions(theme);
    },
    created() {
        this.setupChart();
    },
    data() {
        return {
            chart_options_activity: {},
            seriesAttributes: [],
            seriesCategories: []
        }
    },
    methods: {
        setupChart() {
            let viewModel = this;
            this.chart_options_activity = {
                chart: {
                    zoomType: 'x',
                    spacingTop: 50,
                    height: 450,
                    style: {
                        fontFamily: 'robotoregular'
                    },
                    backgroundColor: 'white',
                    type: 'column'
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: viewModel.chart_title,
                },
                xAxis: {
                    type: "datetime",
                    crosshair: false,
                    categories: viewModel.seriesCategories
                },
                yAxis:  [{
                    labels: {
                        format: 'R${value}',
                        style: {
                            color: '#2ca02c'
                        }
                    },
                    title: {
                        text: this.$t('AppCostsColumnChart.Economia Potencial'),
                        style: {
                            color: '#2ca02c'
                        }
                    },
                    opposite: true
                }, {
                    gridLineWidth: 0,
                    title: {
                        text: this.$t('AppCostsColumnChart.Custos Totais'),
                        style: {
                            color: '#d62727'
                        }
                    },
                    labels: {
                        format: 'R${value}',
                        style: {
                            color: '#d62727'
                        }
                    }

                }],
                tooltip: {
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        stacking: false,
                        grouping: true
                    },
                    series: {}
                },
                series: viewModel.seriesAttributes
            };
        },
        setUpChartSeries() {
            this.seriesAttributes = [{
                name: this.$t('AppCostsColumnChart.Custos Totais'),
                type: 'column',
                yAxis: 1,
                data: this.chart_data.totalCosts,
                tooltip: {
                    valuePrefix: 'R$',
                    valueDecimals: 2
                },
                color: '#d62727'

            }, {
                name: this.$t('AppCostsColumnChart.Economia Potencial'),
                type: 'column',
                data: this.chart_data.potentialSavings,
                tooltip: {
                    valuePrefix: 'R$',
                    valueDecimals: 2
                },
                color: '#2ca02c'
            }]
            this.seriesCategories = this.chart_data.vehicles;
            this.setupChart();
        },
    },
    components: {VueHighcharts: Chart},
    watch: {
        chart_data: {
            deep: true,
            handler(val) {
                this.setUpChartSeries()
            }
        },
    }
}
</script>

<style scoped>


</style>
