var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{staticStyle:{"text-align":"center"},attrs:{"value":_vm.data,"removableSort":"","paginator":true,"rows":15,"rowHover":true,"sortOrder":-1,"dataKey":"id","filters":_vm.filters,"filterDisplay":"row","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":_vm.$t('AppWaterWithdrawalPointsTable.currentPageReportTemplate')},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('AppWaterWithdrawalPointsTable. Nenhum ponto de captação cadastrado'))+" ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('AppWaterWithdrawalPointsTable.Carregando pontos de captação'))+" ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.$t('AppWaterWithdrawalPointsTable.Pontos de captação'))+" ")])]},proxy:true}])},[_c('Column',{attrs:{"field":"location","header":_vm.$t('AppWaterWithdrawalPointsTable.Local de captação'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.location)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"state","header":_vm.$t('AppWaterWithdrawalPointsTable.Estado'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.state)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"city","header":_vm.$t('AppWaterWithdrawalPointsTable.Município'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.city)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"up","header":"UP","sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.up)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"point_id","header":_vm.$t('AppWaterWithdrawalPointsTable.ID ponto'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.point_id)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"flow","header":_vm.$t('AppWaterWithdrawalPointsTable.Vazão'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.flow)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"period","header":_vm.$t('AppWaterWithdrawalPointsTable.Período'),"sortable":true,"headerClass":"headerClass","bodyClass":"bodyClass","filterMatchMode":"contains","showFilterMenu":false,"showClearButton":false},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.period)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }