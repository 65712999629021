<template>
    <div class="row" style="height: 100%;">
        <div class="col-12">
            <div @click="remove" v-show="showStyle" class="button-remove" style="width: 17px; height: 17px; top: 5px;">
                <i class="pi pi-times" style="font-size: 10px; position: relative; bottom: 2px; color: black;"/>
            </div>
            <Dropdown v-model="card1" v-show="showStyle" style="width: 100%; height: 50%;" class="drop-side-cards"
                      :options="cardsOptions" optionLabel="name" @change="handleDropDownChange($event)"
                      :placeholder="placeholderSel" appendTo="tableDiv"/>

            <div class="card" v-show="showStyle" style="width: 100%; height: 50%;">
                {{ formatValue(card1Data) }}
            </div>

            <div v-show="!showStyle"
                 style="margin: 100px 30px 0px 30px; padding: 10px; border: 1px solid gray; border-radius: 7px;">
                <h2 style="font-size: 20px; text-align: center;">{{ card1.name }}</h2>

                <div style="width: 100%; text-align: center;">
                    <span style="font-size: 17px;">{{ formatValue(card1Data) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import {optionsSideCards} from './chartsOptions';
import retrieveData from '../../../mixins/ReportsMixin';
import i18n from '../../../i18n';
import idb from '../../../api/idb';

export default {
    mixins: [retrieveData],
    mounted() {
        this.cardsOptions = optionsSideCards()
        this.placeholderSel = i18n.t('AppAnalysisSideCards.Selecione')
    },
    data() {
        return {
            is_pdf: false,
            modelLocalStorage: 0,
            cardsData: null,
            placeholderSel: null,
            card1: {},
            showStyle: true,
            card1Data: 'N/D',
            cardsOptions: null,
            storedChosenCards: {},
            id: 0
        }
    },
    methods: {
        setModelLocalStorage(val) {
            this.modelLocalStorage = val;
        },
        isPdf(val) {
            this.is_pdf = val;
        },
        cleanStyle() {
            this.showStyle = !this.showStyle
        },
        remove() {
            this.$emit('remove', this.id);
        },
        correctHeight() {
            // console.log("AA");
        },
        fillCards() {
            this.card1Data = this.getCardData(this.card1.code);
        },
        getCardData(option) {
            if (!this.cardsData) {
                return 'N/D';
            }

            switch (option) {
                case 'AREA':
                    return this.calcArea();
                case 'PRODUTIVIDADE':
                    return this.calcProdutividade();
                case 'DISP_MECANICA':
                    return this.calcDispMecanica();
                case 'RENDIMENTO':
                    return this.calcRendimento();
                case 'CONSUMO':
                    return this.calcConsumo();
                case 'COMBUSTIVEL':
                    return this.calcCombustivel();
                case 'VELOCIDADE_MEDIA':
                    return this.calcVelocidadeMedia();
                case 'EFICIENCIA':
                    return this.calcEficiencia();
                case 'TEMPO':
                    return this.calcTempo();
                case 'TEMPO_TRABALHANDO':
                    return this.calcTempoTrabalhando();
                case 'TEMPO_MANOBRANDO':
                    return this.calcTempoManobrando();
                case 'TEMPO_DESLOCANDO':
                    return this.calcTempoDeslocando();
                case 'TEMPO_PARADO':
                    return this.calcTempoParado();
                case 'CONSUMO_TRABALHANDO':
                    return this.calcConsumoTrabalhando();
                case 'CONSUMO_MANOBRANDO':
                    return this.calcConsumoManobrando();
                case 'CONSUMO_DESLOCANDO':
                    return this.calcConsumoDeslocando();
                case 'CONSUMO_PARADO':
                    return this.calcConsumoParado();
                default:
                    return 'N/D';
            }
        },
        formatValue(val) {
            if (!val || !val.value || val.value === 'N/D') {
                return 'N/D';
            }
            if (!val.value) {
                return 'N/D';
            }

            if (val.isInteger) {
                return val.symbol ? val.value + ' ' + val.symbol : val.value;
            }

            if (!val.symbol) {
                return val.value.toFixed(2);
            }

            try {
                return val.value.toFixed(2) + ' ' + val.symbol;
            } catch (e) {
                return 'N/D';
            }
        },
        calcArea() {
            let total_area = 0;
            this.cardsData.forEach((data) => {
                if (typeof data.area === 'number') {
                    total_area += data.area;
                }
            });
            return {value: total_area, symbol: 'ha'};
        },
        calcTempoTotal() {
            let tempo_total = 0;

            this.cardsData.forEach((data) => {
                if (typeof data.time === 'number') {
                    tempo_total += data.time;
                }
            });
            return {value: tempo_total};
        },
        calcTempoEngineOn() {
            let tempo_total = 0;

            this.cardsData.forEach((data) => {
                if (typeof data.time === 'number' && data.mode < 5) {
                    tempo_total += data.time;
                }
            });
            return {value: tempo_total};
        },
        calcTempoTrabalhando() {
            let tempo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 1) {
                    return;
                }

                if (typeof data.time === 'number') {
                    tempo += data.time;
                }
            });
            return {value: tempo, symbol: 'h'}
        },
        calcTempoParado() {
            let tempo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 2) {
                    return;
                }

                if (typeof data.time === 'number') {
                    tempo += data.time;
                }
            });
            return {value: tempo, symbol: 'h'}
        },
        calcTempoManobrando() {
            let tempo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 3) {
                    return;
                }

                if (typeof data.time === 'number') {
                    tempo += data.time;
                }
            });
            return {value: tempo, symbol: 'h'}
        },
        calcTempoDeslocando() {
            let tempo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 4) {
                    return;
                }

                if (typeof data.time === 'number') {
                    tempo += data.time;
                }
            });
            return {value: tempo, symbol: 'h'}
        },


        calcProdutividade() {
            let prod = (this.calcArea().value / this.calcTempoTotal().value);
            return {value: prod, symbol: ' ha/h'};
        },
        calcDispMecanica() {
            let maintenanceTime = 0;
            let totalTime = 0;

            this.cardsData.forEach((data) => {
                if (typeof data.time === 'number') {
                    if (data.mode === 8) {
                        maintenanceTime += data.time;
                    }
                    totalTime += data.time
                }
            });

            let dispMecanica = ((totalTime - maintenanceTime) / totalTime) * 100;
            return {value: dispMecanica, symbol: ' %'};
        },

        calcConsumoTotal() {
            let total_fuel_consumption = 0;

            this.cardsData.forEach((data) => {
                if (typeof data.consumption === 'number') {
                    total_fuel_consumption += data.consumption;
                }
            });
            return {value: total_fuel_consumption};
        },

        calcRendimento() {
            let rendimento = 0;
            if (this.calcArea().value > 0) {
                rendimento = this.calcConsumoTotal().value / this.calcArea().value;
            }

            return {value: rendimento, symbol: 'L/ha'}
        },
        calcConsumo() {
            let consumo = this.calcConsumoTotal().value / this.calcTempoTotal().value;
            return {value: consumo, symbol: 'L/h'}
        },
        calcCombustivel() {
            return {value: this.calcConsumoTotal().value, symbol: 'Litros'}
        },
        calcVelocidadeMedia() {

            let total_distance = 0;

            this.cardsData.forEach((data) => {
                if (typeof data.distance === 'number') {
                    total_distance += data.distance;
                }
            });

            let velocidade_media = total_distance / this.calcTempoTotal().value;

            return {value: velocidade_media, symbol: 'km/h'}

        },
        calcEficiencia() {
            let eventsTotalTime = 0;
            let totalWorkTime = 0;

            this.cardsData.forEach((data) => {
                let workTime = 0;
                if (data.mode === 1 || data.mode === 3) {
                    workTime = data.time;
                }

                if (typeof data.time === 'number') {
                    eventsTotalTime += data.time;
                    totalWorkTime += workTime;
                }
            });

            let eficiencia = (totalWorkTime / ((eventsTotalTime) * (this.calcDispMecanica().value / 100))) * 100;
            return {value: eficiencia, symbol: ' %'};
        },
        calcTempo() {
            return {value: this.calcTempoEngineOn().value, symbol: 'h'}
        },
        calcConsumoTrabalhando() {
            let consumo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 1) {
                    return;
                }
                if (typeof data.consumption === 'number') {
                    consumo += data.consumption;
                }
            });
            return {value: consumo, symbol: 'L'}
        },
        calcConsumoManobrando() {
            let consumo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 3) {
                    return;
                }

                if (typeof data.consumption === 'number') {
                    consumo += data.consumption;
                }
            });
            return {value: consumo, symbol: 'L'}
        },
        calcConsumoDeslocando() {
            let consumo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 4) {
                    return;
                }

                if (typeof data.consumption === 'number') {
                    consumo += data.consumption;
                }
            });
            return {value: consumo, symbol: 'L'}
        },
        calcConsumoParado() {
            let consumo = 0;
            this.cardsData.forEach((data) => {
                if (data.mode !== 2) {
                    return;
                }

                if (typeof data.consumption === 'number') {
                    consumo += data.consumption;
                }
            });
            return {value: consumo, symbol: 'L'}
        },
        findCardByCode(cardName) {
            if (this.cardsOptions.find(x => x.code === this.storedChosenCards[cardName]) !== undefined) {
                return this.cardsOptions.find(x => x.code === this.storedChosenCards[cardName]);
            }
            return {};
        },
        handleDropDownChange(event) {
            let val = {
                name: event.value.name,
                code: event.value.code,
                sumInGroupMode: false,
                id: this.id
            }

            this.$emit('dropdownChanged', val);
        },
        dataWatch(filterEventsResults, chartDataFromIdb, filterTime, chartUpdater, chartDataToSideCards) {
            this.cardsData = chartDataToSideCards;
            this.fillCards();
        },
        setLocalStorage(count) {
            this.id = count;
        },
        setOption() {
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            temp[this.modelLocalStorage].value.forEach(element => {
                if (element.id === this.id) {
                    this.card1 = {name: element.name, code: element.code};
                }
            });
        },
        setNoValue() {
            this.card1Data = [];
        },
    },
    watch: {
        statistics_data: function (val) {
            this.cardsData = val;
            this.fillCards();
        },
        card1: function (val) {
            if (!val.code) {
                return;
            }
            this.card1Data = this.getCardData(val.code);
        }
    },
    components: {
        Dropdown
    }
}
</script>

<style scoped type="scss">

.card {
    padding: 10px;
    color: #4b4b4b;
    border-radius: 3px;
    font-size: 20px;
    background-color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.drop-side-cards {
    justify-content: center;
    align-items: center;
    text-align: center;
}

</style>
