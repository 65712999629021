import axios from '../axios/axios-auth'

export default class DevicesService {

    save(data) {
        return axios.post('/api/v1/devices', data).then(response => response.data)
    }

    getDeviceByVehicle(chassis) {
        return axios.get('/api/v1/devices/vehicle/' + chassis).then(response => response.data);
    }

    getDevicesByOrg(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/devices`).then(response => response.data);
    }
}
