<template>
    <div class="row" :class="{'col-12 w-100': is_col_12}">
        <Calendar id="datesArray" v-model="datesArray" ref="datesArray"
                  :showTime="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="getYearRange"
                  :maxDate="new Date()"
                  :appendTo="tableDiv"
                  :touchUI="is_col_12"
                  :disabledDates="disabled_dates"
                  @date-select="dateSelected"
                  dateFormat="dd/mm/yy"
                  selectionMode="range"
                  class="kt-margin-5-desktop auto-center" :class="{'w-100 pb-4': is_col_12}"
                  :style="{minWidth: '23ch'}"
                  :disabled="show_historical_data || !is_any_org_selected">
            <template #footer>
                <div v-if="!is_working_modes" style="display: flex;text-align: center;margin-top: 20px">
                    <div style="flex: 0 0 50%">
                        <div>{{ $t('AppFilterDateTimeControlBar.Hora Inicial') }}</div>
                        <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="start_time"/>
                    </div>
                    <div style="flex: 1">
                        <div>{{ $t('AppFilterDateTimeControlBar.Hora Final') }}</div>
                        <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="end_time"/>
                    </div>
                </div>
            </template>
        </Calendar>
        <app-button
            :disabled="show_historical_data"
            class="kt-margin-5-desktop auto-center"
            type="primary"
            @handleClick="validateDataPreRequest"
            :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loading_historical_data }">
            {{ $t('AppFilterDateTimeControlBar.Filtrar') }}
        </app-button>
    </div>
</template>

<script>

import AppDatePicker from 'vuejs-datepicker';
import AppButton from "./AppButton";
import moment from 'moment';
import Calendar from 'primevue/calendar';
import getYearRange from "../../mixins/DateMixin";
import i18n from '@/i18n'

export default {
    mixins: [getYearRange,],
    mounted() {
        this.tableDiv = this.isScreenMobile ? 'tableDiv' : ''
    },
    data() {
        return {
            theme: process.env.VUE_APP_THEME_ACTIVE,
            tableDiv: '',
            datesArray: [this.start_date_open_date, this.end_date_open_date],
            position: 'center',
            state: {
                highlighted: {
                    dates: []
                },
                includeDisabled: true,
                disabledDates: {
                    //desabilita as datas depois de hoje
                    from: new Date(),
                }
            },
            //Apenas nos importamos com as horas
            start_time: new Date(1910, 9, 1, 0, 0, 0, 0),
            end_time: new Date(1910, 9, 1, 23, 59, 59, 0),
            dateToShow: null,
        }
    },
    props: {
        //as datas em que os datepickers vão ser abertos
        start_date_open_date: {
            type: Date,
            default() {
                return moment().subtract(1, 'days').toDate()
            }
        },
        end_date_open_date: {
            type: Date,
            default() {
                return moment().subtract(1, 'days').toDate()
            }
        },
        //controla se aparece o loading no botão
        loading_historical_data: {
            type: Boolean,
            default: false
        },
        disabled_dates: {
            type: Array,
            required: true
        },
        btn_text: {
            type: String,
            default: i18n.t('AppFilterDateTimeControlBar.Filtrar')
        },
        show_historical_data: {
            type: Boolean,
            required: true
        },
        is_any_org_selected: {
            type: Boolean,
            required: true
        },
        is_col_12: {
            type: Boolean,
            default: false
        },
        is_working_modes: {
            type: Boolean,
            default: false
        },
    },
    components: {
        AppDatePicker, AppButton, Calendar

    },
    methods: {
        validateDataPreRequest() {

            if (!this.datesArray[0]) {
                this.displayToast(this.$t('AppFilterDateTimeControlBar.Selecione a data inicial e final'));
                return;
            } else if (!this.datesArray[1]) {
                this.displayToast(this.$t('AppFilterDateTimeControlBar.Selecione a data final'));
                return;
            }

            //valida se são datas válidas
            if (!moment(this.datesArray[0]).isValid() || !moment(this.datesArray[1]).isValid()) {
                this.displayToast(this.$t('AppFilterDateTimeControlBar.Datas inválidas'));
                return;
            }


            //Seta horas e minutos dos calendários de horas
            this.datesArray[0].setHours(this.start_time.getHours());
            this.datesArray[0].setMinutes(this.start_time.getMinutes());

            this.datesArray[1].setHours(this.end_time.getHours());
            this.datesArray[1].setMinutes(this.end_time.getMinutes());

            //valida novamente se são datas válidas
            if (!moment(this.datesArray[0]).isValid() || !moment(this.datesArray[1]).isValid()) {
                this.displayToast(this.$t('AppFilterDateTimeControlBar.Datas inválidas'));
                return;
            }

            //Valida se a data final é depois da data inicial
            if (moment(this.datesArray[1]).isBefore(this.datesArray[0])) {
                this.displayToast(this.$t('AppFilterDateTimeControlBar.Data inicial deve ser antes da data final'));
                return;
            }

            this.$emit('handleFilter', {start_date: this.datesArray[0], end_date: this.datesArray[1]})
        },
        displayToast(message) {
            this.$toast.add({
                severity: 'error',
                summary: message,
                life: 5000
            });
        },
        handleClick() {
            this.$emit('handleClick');
        },
        dateSelected() {
            //https://github.com/primefaces/primevue/blob/2.x/src/components/calendar/Calendar.vue
            //Alterando propriedade direto da implementação
            if (this.datesArray[1] && this.isScreenMobile) {
                this.$refs.datesArray.overlayVisible = false;
            }
            this.$emit('dateSelected');
        },
    },
    watch: {
        start_date_open_date: function (val) {
            while (this.datesArray.length > 0) {
                this.datesArray.pop();
            }
            this.datesArray.push(val);

            this.start_time = val;
        },
        end_date_open_date: function (val) {
            this.datesArray.push(val);

            this.end_time = val;
        },
    },
    computed: {
        getYearRange() {
            return '2018:' + new Date().getFullYear();
        },
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";


.auto-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.time-calendar {
    margin-bottom: 10px;
    outline: 5px solid white;
    outline-offset: -2px;
}

</style>
