<template>
    <Dialog :header="$t('AppAlertsAlertEdit.Recomendação')" :visible.sync="showDialog" :contentStyle="{'overflow': 'visible'}"
            @hide="handleClose" :style="{width: width_dialog}" :modal="true">
        <form class="kt-form" @submit.prevent>
            <div class="kt-portlet__body">
                <div class="form-group">
                    <label>Ordem</label>
                    <app-input class="form-control" type="text" placeholder=""
                               v-model="form.work_order"
                               :class="{'is-invalid' : $v.form.work_order.$invalid && $v.form.work_order.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.work_order.$invalid && $v.form.work_order.$dirty">
                            {{ $t('AppAlertsAlertEdit.Obrigatório informar a Ordem') }}
                    </span>
                </div>
                <div class="form-group">
                    <label>{{ $t('AppAlertsAlertEdit.Responsável') }}</label>
                    <app-input class="form-control" type="text" placeholder=""
                               v-model="form.assignee"
                               :class="{'is-invalid' : $v.form.assignee.$invalid && $v.form.assignee.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.assignee.$invalid && $v.form.assignee.$dirty">
                            {{ $t('AppAlertsAlertEdit.Obrigatório informar o Responsável') }}
                    </span>
                </div>
                <div class="form-group">
                    <label>Status</label>
                    <br>
                    <Dropdown v-model="form.status" :options="statuses" :placeholder="$t('AppAlertsAlertEdit.Selecione')" style="width: 100%"
                              class="p-column-filter" :showClear="true" optionLabel="name" optionValue="code">
                        <template #option="slotProps">
                            <span :class="'badge status-' + slotProps.option.code">{{slotProps.option.name}}</span>
                        </template>
                    </Dropdown>
                </div>
                <div class="form-group">
                    <label>{{ $t('AppAlertsAlertEdit.Observação') }}</label>
                    <br>
                    <Text-area id="textarea" v-model="form.observation" style="width: 100%" rows="4"/>
                </div>
            </div>
        </form>
        <template #footer>
            <Button @click="shareFault" class="p-button-success"
                    style="float: left;">
                <i class="pi pi-share-alt mr-2" style="padding-top: 5px;padding-bottom: 5px"></i>
                {{ $t('AppAlertsAlertEdit.Compartilhar') }}
            </Button>
            <app-button @handleClick="handleClose" type="secondary">
                <span class="pi pi-times"></span>
                {{ $t('AppAlertsAlertEdit.Fechar') }}
            </app-button>
            <app-button @handleClick="handleSalve" type="primary">
                <span class="pi pi-check"></span>
                {{ $t('AppAlertsAlertEdit.Salvar') }}
            </app-button>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressSpinner from 'primevue/progressspinner';
    import Button from 'primevue/button';
    import AppButton from "../../../common/AppButton";
    import AppInput from "../../../common/AppInput";
    import Dropdown from 'primevue/dropdown';
    import {STATUSES} from './AppOptions';
    import {email, maxLength, minLength, sameAs, required, numeric} from "vuelidate/lib/validators";
    import TextArea from 'primevue/textarea';
    import AlertsService from "../../../../services/AlertsService";
    import DevicesService from "../../../../services/DevicesService";
    import axios from "../../../../axios/axios-auth";
    import sendWhatsAppAlertDetailsMessage from "../../../../mixins/WhatsAppMixin"

    export default {
        mixins: [sendWhatsAppAlertDetailsMessage],
        data() {
            return {
                showDialog: false,
                statuses: STATUSES,
                form: {
                    id: null,
                    work_order: null,
                    assignee: null,
                    status: '',
                    observation: null
                },
                alertsService: null,
                devicesService: null,
                width_dialog: null
            }
        },
        mounted() {
            this.alertsService = new AlertsService();
            this.devicesService = new DevicesService();
            this.width_dialog = this.isScreenMobile ? '85vw' : '40vw';
        },
        methods: {
            handleClose() {
                this.$emit('handleClose');
            },
            handleSalve() {

                this.$v.form.$touch();

                if (!this.$v.form.$invalid) {
                    this.$emit('handleSalve', {
                        id: this.form.id,
                        daily_ids_and_counts: this.form.daily_ids_and_counts,
                        identification_number: this.form.identification_number,
                        assignee: this.form.assignee,
                        work_order: this.form.work_order,
                        status: this.form.status,
                        observation: this.form.observation
                    });
                }
            },
            shareFault() {

                this.$v.form.$touch();

                if (!this.$v.form.$invalid) {
                    let vm = this;
                    const promises = [];

                    this.devicesService.getDeviceByVehicle(vm.alertEditDetails.identification_number)
                            .then(result => {
                                if (result.mac_address) {
                                    promises.push(axios.post('/api/v1/realtime/last-position',
                                            [{
                                                chassis: vm.alertEditDetails.identification_number,
                                                device: result.mac_address
                                            }]));
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                promises.push(vm.alertsService.getDetails(vm.alertEditDetails.id));

                                let faultDetails = null;
                                let realTimeData = null;

                                Promise.all(promises)
                                        .then((results) => {
                                            if (results.length === 1) {
                                                if (results[0]) {
                                                    faultDetails = results[0];
                                                }
                                            } else if (results.length === 2) {
                                                if (results[0]) {
                                                    realTimeData = results[0];
                                                }
                                                if (results[1]) {
                                                    faultDetails = results[1];
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        })
                                        .finally(() => {
                                            vm.sendWhatsAppAlertDetailsMessage(vm.form, realTimeData, faultDetails, vm.alertEditDetails);
                                        })
                            })
                }
            },
        },
        props: {
            displayDialogEditAlert: {
                type: Boolean,
                default: false,
                required: true
            },
            alertEditDetails: {
                required: true
            }
        },
        watch: {
            displayDialogEditAlert: function (val) {
                this.showDialog = val;
            },
            alertEditDetails: function (val) {
                this.form = val;
            }
        },
        computed: {
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        },
        components: {
            Dialog, ProgressSpinner, Button, AppButton, AppInput, Dropdown, TextArea
        },
        validations: {
            form: {
                work_order: {required, minLength: minLength(1)},
                assignee: {required, minLength: minLength(1)},
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "src/components/views/alerts/style";
</style>
