import axios from '../axios/axios-auth'

export default class MissionService {

    getCurrentMission(vehicleId) {
        return axios.get(`api/v3/vehicles/${vehicleId}/currentMission`).then(response => response.data)
    }

    setCurrentMission(vehicleId, data) {
        return axios.post(`api/v3/vehicles/${vehicleId}/currentMission`, data).then(response => response.data)
    }
}
