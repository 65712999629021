<template>
    <Dialog header="Alertas" :visible.sync="display" :style="{width: '75vw'}"
            :maximizable="true" :modal="true" :contentStyle="{height: '490px'}">
        <p style="font-size: 12px;margin: 0">{{ $t('AppAlertsDialog.Exibindo no máximo os últimos 20 alertas') }}</p>
        <ProgressSpinner v-if="loading"
                         style="display: flex;justify-content: center;align-items: center;height: 4rem"/>
        <DataTable v-else :value="gridData" dataKey="id"
                   class="p-datatable-striped"
                   :paginator="true" :rows="5"
                   responsiveLayout="scroll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,20]"
                   :currentPageReportTemplate="$t('AppAlertsDialog.currentPageReportTemplate')"
                   :sortOrder="-1" sortField="date"
                   :filters="filters">
            <template #empty>
                <div style="text-align: center">
                    {{ $t('AppAlertsDialog.Nenhum alerta localizado') }}
                </div>
            </template>
            <template #loading>
                <div style="text-align: center">
                    {{ $t('AppAlertsDialog.Carregando alertas, por favor aguarde') }}
                </div>
            </template>
            <Column field="date" :header="$t('AppAlertsDialog.Horário')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{dateHourFormat(slotProps.data.date)}}
                </template>
            </Column>
            <Column field="city" :header="$t('AppAlertsDialog.Município')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.city}}
                </template>
            </Column>
            <Column field="state" :header="$t('AppAlertsDialog.Estado')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.state}}
                </template>
            </Column>
            <Column field="display_id" :header="$t('AppAlertsDialog.Identificação')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.display_id}}
                </template>
            </Column>
            <Column field="identification_number" :header="$t('AppAlertsDialog.Chassis')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{slotProps.data.identification_number}}
                </template>
            </Column>
            <Column field="hour_meter" :header="$t('AppAlertsDialog.Horímetro')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{roundNumber(slotProps.data.hour_meter)}}
                </template>
            </Column>
            <Column field="trade_name" :header="$t('AppAlertsDialog.Propriedade')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{getClientDisplayName(slotProps.data)}}
                </template>
            </Column>
            <Column field="code" :header="$t('AppAlertsDialog.Código')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{formatCode(slotProps.data.code, slotProps.data.severity)}}
                </template>
            </Column>
            <Column field="priority" :header="$t('AppAlertsDialog.Nível')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                <span :class="'badge priorities-' + slotProps.data.priority">
                    {{getPriority(slotProps.data.priority)}}
                </span>
                </template>
            </Column>
            <Column field="status" :header="$t('AppAlertsDialog.Status')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    <span :class="'badge status-' + slotProps.data.status">
                        {{getStatus(slotProps.data.status)}}
                    </span>
                </template>
            </Column>
            <Column field="active" :header="$t('AppAlertsDialog.Status')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                <span class="badge" :class="{active: slotProps.data.active, inactive: !slotProps.data.active}">
                    {{getActiveText(slotProps.data.active)}}
                </span>
                </template>
            </Column>
            <Column field="created_date" :header="$t('AppAlertsDialog.Horário de Chegada')" headerClass="headerClass"
                    bodyClass="bodyClass" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{dateHourFormat(slotProps.data.created_date)}}
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script>

    import ProgressSpinner from 'primevue/progressspinner';
    import Dialog from 'primevue/dialog';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';
    import maskCpfCnpj from "../../../../mixins/StringsMaskMixin";
    import dateHourFormat from "../../../../mixins/DateMixin";
    import filterValue from "../../../../mixins/FilterFunctionsDataTables";
    import orderVehiclesByDisplayIdentificationNaturalOrder from '../../../../mixins/ArrayMixin';
    import {STATUSES} from './AppOptions';
    import {PRIORITIES} from './AppOptions';


    export default {
        mixins: [maskCpfCnpj, dateHourFormat, filterValue, orderVehiclesByDisplayIdentificationNaturalOrder],

        data() {
            return {
                statuses: STATUSES,
                priorities: PRIORITIES,
                display: false,
                filters: {},
                loading: false
            }
        },
        props: {
            gridData: {
                type: Array,
            }
        },
        methods: {
            getActiveText(isActive) {
                return isActive ? 'ATIVO' : 'INATIVO'
            },
            changeVisibility() {
                this.display = !this.display;
            },
            showModalEditAndGetDetails(data) {

            },
            getClientDisplayName(val) {
                if (val.trade_name) {
                    return val.trade_name;
                }
                return val.name;
            },
            getStatus(status) {
                let result = this.statuses.find(({code}) => code === status);
                if (result) {
                    return result.name;
                }
            },
            formatCode(code, severity) {
                if (severity) {
                    return code + "." + severity;
                }
                return code;
            },
            getPriority(priority) {
                let result = this.priorities.find(({code}) => code === priority);
                if (result) {
                    return result.name;
                }
            },
            roundNumber(val, num) {

                if (!val) {
                    return null;
                }

                if (!num) {
                    num = 2;
                }
                return parseFloat(val).toFixed(num);
            },
            setLoading(val) {
                this.loading = val;
            }
        },
        components: {
            Dialog, DataTable, Column, Button, ProgressSpinner
        },
    }
</script>


<style scoped lang="scss">
    @import "src/assets/styles/primevue";
    @import "src/components/views/alerts/style";

    .active {
        background-color: #ffcdd2;
        color: #c63737;
    }

    .inactive {
        background-color: rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.8);
    }
</style>
