<template>
    <div>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="row center" :class="{ 'menu-dropdown': is_col_12 }">
                    <Dropdown v-model="dropDownTask" :options="tasks" optionLabel="content" :placeholder="$t('AppTasksAnalyze.Trabalho')"
                              style="margin-right: 10px"
                        class="auto-center" :class="{ 'dropdown-width-trabalho': is_col_12, 'width-250': !is_col_12 }"
                        dataKey="id" @change="taskChange">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                                {{ slotProps.value.name }} - {{ dateFormat(slotProps.value.start.valueOf() / 1000) }} a
                                {{ dateFormat(slotProps.value.end.valueOf() / 1000) }}
                            </div>
                            <span v-else>
                                {{ slotProps.placeholder }}
                            </span>
                        </template>
                        <template #option="slotProps" style="width: 500px">
                            <div style="padding-right: 20px" v-if="slotProps.option.name === 'ND'">
                                -
                            </div>
                            <div style="padding-right: 20px" v-else>
                                {{ slotProps.option.name }} - {{ dateFormat(slotProps.option.start.valueOf() / 1000) }}
                                a
                                {{ dateFormat(slotProps.option.end.valueOf() / 1000) }}
                            </div>
                        </template>
                    </Dropdown>
                    <div class="tasks-cascade" :class="{ 'cascade-width-type': is_col_12, 'width-250': !is_col_12 }">
                        <CascadeSelect v-model="dropDownTaskMapType" :options="mapTypes" optionGroupLabel="title"
                            :optionGroupChildren="['subtypes']" optionLabel="title"  :placeholder="$t('AppTasksAnalyze.Tipo de Mapa')"
                            class="auto-center" style="width: 100%;" @change="mapTypeChange">
                            <template #value="slotProps" style="width: 500px">
                                <div style="padding-right: 20px" v-if="slotProps.value && slotProps.value.title === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else-if="slotProps.value && slotProps.value.title === 'Desconhecido'">
                                    {{selected_task.name}}
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.value ? slotProps.value.title : "-" }}
                                </div>
                            </template>
                            <template #option="slotProps" style="width: 500px">
                                <div style="padding-right: 20px" v-if="slotProps.option.title === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.title === 'Desconhecido'">
                                    {{selected_task.name}}
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.title }}
                                </div>
                            </template>
                        </CascadeSelect>
                    </div>
<!--                    <div class="tasks-cascade" :class="{ 'dropdown-width-type': is_col_12, 'width-250': !is_col_12 }">-->
<!--                        <Calendar id="datesArray" v-model="dateSelected" ref="datesArray"-->
<!--                                  :autocomplete="'off'"-->
<!--                                  :showTime="false"-->
<!--                                  :monthNavigator="true"-->
<!--                                  :yearNavigator="true"-->
<!--                                  :yearRange="getYearRange"-->
<!--                                  :maxDate="new Date()"-->
<!--                                  :touchUI="is_col_12"-->
<!--                                  :disabledDates="disabledDates"-->
<!--                                  @date-select="dateSelectedEvent"-->
<!--                                  dateFormat="dd/mm/yy"-->
<!--                                  selectionMode="single"-->
<!--                                  class="kt-margin-5-desktop auto-center" :class="{'w-100 pb-4': is_col_12}"-->
<!--                                  :style="{minWidth: '23ch'}">-->
<!--                        </Calendar>-->
<!--                    </div>-->
                    <div :class="{ 'button-compare-back-12': is_col_12, 'button-compare-back': !is_col_12 }">
                        <app-button type="primary" @handleClick="compareDialog">
                            <i class="glyphicons glyphicons-map pb-1"></i> {{ $t('AppTasksAnalyze.Comparar') }}
                        </app-button>
                        <app-button type="secondary" :class="{ 'button-back-12': is_col_12, 'button-back': !is_col_12 }"
                            @handleClick="handleBack">
                            <i class="glyphicons glyphicons-arrow-left pb-1"></i> {{ $t('AppTasksAnalyze.Voltar') }}
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
        <div style="position: absolute; z-index: 5; width: 94%; margin: 0 auto;" v-show="cards && !isScreenMobile">
            <div class="row justify-content-center">
                <template v-for="card in cards">
                    <app-tasks-cards :title="card.title" :value="card.value" :symbol="card.symbol" />
                </template>
            </div>
        </div>
        <div style="position: absolute; z-index: 10; right: 20px" :style="{ 'top': top_px }">
            <div class="row justify-content-center">
                <app-google-maps-type-control :show_first_row="false" ref="googleMapsTypeControl"
                    @handleMapControlClick="handleMapControlClick">
                </app-google-maps-type-control>
            </div>
        </div>
        <div style="position: absolute; z-index: 10; left: 20px" :style="{ 'top': top_px }"
            v-show="works.length > 0 && !isMapTypeOverlap">
            <app-tasks-work-options :rate_steps="rateSteps" :works="works" :edit_enabled="true"
                                    :symbol="this.getOptionsSymbol()"
                @optionsChanged="optionsChanged" />
        </div>
        <app-google-maps-tasks
                        class="maps-tasks"
                        v-if="dropDownTaskMapType"
                        ref="googleMapsTasks"
                        :position_center="field_center"
                        :google_maps_tasks_id="'analyze-task-maps'"
                        :selected_field="selected_field"
                        :task="dropDownTask"
                        :rate_steps="rateSteps"
                        :opacity="opacity"
                        :stroke="stroke"
                        :works="works"
                        :is_map_type_overlap="isMapTypeOverlap"
                        :loading="loading"
                        @handleZoomChanged="handleZoomChanged"
                        @handleBoundsChanged="handleBoundsChanged"></app-google-maps-tasks>

        <Dialog :visible.sync="compareTasksDialog" :style="{ width: width_dialog_compare }" :header="$t('AppTasksAnalyze.Comparar')"
            :modal="true">
            <div>
                <div style="width: 100%;display: flex;justify-content:center;align-items:center;" class="mt-4">
                    <div style="width: 70%;">
                        <span class="p-input-icon-left" style="width: 100%;position: relative">
                            <span class="pi pi-search search-icon" />
                            <InputText type="text" v-model="taskSearch" :placeholder="$t('AppTasksAnalyze.Procurar Trabalho')" />
                        </span>
                    </div>
                </div>
                <perfect-scrollbar id="container" class="mt-4">
                    <div v-for="task in tasksToCompare" :id="task.name" @click="handleClickCompareTask(task)"
                        class="task-compare">
                        {{ task.name }} - {{ dateFormat(task.start.valueOf() / 1000) }} a
                        {{ dateFormat(task.end.valueOf() / 1000) }}
                    </div>
                </perfect-scrollbar>
            </div>
        </Dialog>
    </div>
</template>

<script>

import AppButton from "@/components/common/AppButton";
import FieldWorksService from "@/services/FieldWorksService";
import AppTasksCards from "@/components/views/agriculture/tasks/AppTasksCards";
import AppGoogleMapsTypeControl from "@/components/common/AppGoogleMapsTypeControl";
import AppGoogleMapsTasks from "@/components/views/agriculture/tasks/AppGoogleMapsTasks";
import AppTasksWorkOptions from "@/components/views/agriculture/tasks/AppTasksWorkOptions";

import * as jsts from 'jsts';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import CascadeSelect from "primevue/cascadeselect";

import dateFormat from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import { MAPS_DEFAULT_CONFIGS, MAPS_OVERLAY_OPEN_WEATHER_TYPES, MAPS_OVERLAY_WEATHER_TYPES } from "../../real-time/AppOptions";

import FieldsService from "../../../../services/FieldsService";
import getYearRange from "@/mixins/DateMixin";
import moment from "moment/moment";

export default {
    mixins: [dateFormat, getTimeFromSecondsDurationFormatMixin, getYearRange],
    mounted() {
        this.setupRangeDates();
        this.setTaskDates();
        this.user_configuration = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_LEGEND));
        this.dropDownTask = this.selected_task;
        this.tasksToCompare = this.tasks;
        this.fieldsService = new FieldsService();
        this.fieldWorksService = new FieldWorksService();
        this.loadMapTypes();
        this.width_dialog_compare = this.isScreenMobile ? '85vw' : '450px';
        this.top_px = this.isScreenMobile ? '110px' : '90px';
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            width_dialog_compare: null,
            top_px: '',
            is_col_12: false,
            selectedClient: null,
            customersList: [],
            loadingClients: false,
            cards: [],
            dropDownTask: null,
            dropDownTaskMapType: null,
            mapTypes: [],
            compareTasksDialog: false,
            taskSearch: null,
            tasksToCompare: [],
            rateSteps: [],
            opacity: 100,
            stroke: 0,
            fieldWorksService: null,
            works: [],
            isMapTypeOverlap: false,
            loading: false,
            activityType: '',
            taskType: '',
            user_configuration: null,

            mapBoundsAndZoom: {
                zoom: MAPS_DEFAULT_CONFIGS.ZOOM
            },
            fieldsService: null,
            dateSelected: null,
            disabledDates: [],
            initialDate: moment("2022-01-01", "YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            rangeDates: [],
            enabledDates: []
        }
    },
    methods: {
        setupRangeDates() {
            while (this.initialDate.isBefore(this.endDate, 'day')) {
                this.rangeDates.push(moment(this.initialDate).format('YYYY-MM-DD'));
                this.initialDate = this.initialDate.add(1, 'days');
            }
        },
        dateSelectedEvent() {
            this.getWorks();
        },
        handleMapControlClick(val) {
            switch (val) {
                case 'roadmap':
                    this.$refs.googleMapsTasks.setUpMapType('roadmap');
                    break;
                case 'hybrid':
                    this.$refs.googleMapsTasks.setUpMapType('hybrid');
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                    this.$refs.googleMapsTasks.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                    this.$refs.googleMapsTasks.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                    this.$refs.googleMapsTasks.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                    this.$refs.googleMapsTasks.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.REMOVE);
                    break;
                default:
            }
        },
        handleClickCompareTask(taskToCompare) {
            this.$emit('compareTasks', taskToCompare);
            this.compareTasksDialog = false;
        },
        taskChange(event) {
            this.$emit('taskChangeAnalyze', event.value);
            this.dropDownTaskMapType = this.mapTypes[0];
        },
        mapTypeChange(event) {
            this.isMapTypeOverlap = this.dropDownTaskMapType.value === 'OVERLAP';
            this.getWorks();
        },
        getWorksTimespan(works) {
            let totalTimespan = 0;
            works.forEach(work => {
                totalTimespan += work.timespan;
            })
            return this.getTimeFromSecondsDurationFormatMixin(totalTimespan);
        },
        getProportionalAverage(works) {
            let totalArea = 0;
            let totalValue = 0;
            works.forEach(work => {
                totalValue += work.value * work.area;
                totalArea += work.area;
            })

            return (totalValue / totalArea).toFixed(2);
        },
        getAverageWorkValueSymbol(workName) {
            if (workName === 'Colheita') {
                return 't/ha'
            }

            if (workName === 'Pulverização') {
                return 'l/ha'
            }

            return null
        },
        getTotalWorkValueSymbol(workName) {
            if (workName === 'Colheita') {
                return 't'
            }

            if (workName === 'Pulverização') {
                return 'l'
            }

            return null
        },
        getOptionsSymbol() {
            if (!this.dropDownTaskMapType) {
                return;
            }

            switch (this.dropDownTaskMapType.value) {
                case 'APPLICATION':
                    return this.getAverageWorkValueSymbol(this.selected_task.name)
                case 'HUMIDITY':
                    return '%'
                case 'SPEED':
                    return 'km/h'
                default:
                    return null
            }
        },
        loadCardsData(works, overlapArea, workedArea, producedArea) {
            if (!works || works.length === 0) {
                return;
            }

            this.cards = [{
                title: this.$t('AppTasksAnalyze.Área do Talhão'),
                value: this.selected_field.area,
                symbol: 'ha'
            }]

            if (!this.isMapTypeOverlap) {
                this.cards = this.cards.concat([
                    {
                        title: this.$t('AppTasksAnalyze.Duração'),
                        value: this.getWorksTimespan(works),
                        symbol: ''
                    },
                    {
                        title: this.$t('AppTasksAnalyze.Área Produzida'),
                        value: (producedArea * 1000000).toFixed(3),
                        symbol: 'ha'
                    },
                    {
                        title: this.$t('AppTasksAnalyze.Progresso'),
                        value: (producedArea * 1000000 / this.selected_field.area * 100).toFixed(3),
                        symbol: '%'
                    }
                ])
            }

            let workAverage = this.getProportionalAverage(works)
            switch (this.dropDownTaskMapType.value) {
                case 'APPLICATION':
                    this.cards = this.cards.concat([
                        {
                            title: this.selected_task.name + ' Média',
                            value: this.getProportionalAverage(works),
                            symbol: this.getAverageWorkValueSymbol(this.selected_task.name)
                        }
                    ]);

                    if ((workAverage * producedArea) > 0) {
                        this.cards = this.cards.concat([
                            {
                                title: 'Total',
                                value: (producedArea * 1000000 * workAverage).toFixed(2),
                                symbol: this.getTotalWorkValueSymbol(this.selected_task.name)
                            }
                        ]);
                    }
                    break;
                case 'HUMIDITY':
                    this.cards = this.cards.concat([
                        {
                            title: this.$t('AppTasksAnalyze.Umidade Média'),
                            value: this.getProportionalAverage(works),
                            symbol: '%'
                        }
                    ]);
                    break;
                case 'SPEED':
                    this.cards = this.cards.concat([
                        {
                            title: this.$t('AppTasksAnalyze.Velocidade Média'),
                            value: this.getProportionalAverage(works),
                            symbol: 'km/h'
                        }
                    ]);
                    break;
                case 'OVERLAP':
                    this.cards = this.cards.concat([
                        {
                            title: this.$t('AppTasksAnalyze.Área Percorrida'),
                            value: (workedArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksAnalyze.Área Produzida'),
                            value: (producedArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksAnalyze.Área Sobreposta'),
                            value: (overlapArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksAnalyze.Sobreposição'),
                            value: ((overlapArea / workedArea) * 100).toFixed(3),
                            symbol: '%'
                        },
                    ])
                    break;
                default:
                    this.cards = [];
                    break;
            }
        },
        compareDialog() {
            this.compareTasksDialog = true;
        },
        handleBack() {
            this.$emit('backToTasksList');
        },
        getPercentageInStep(works, upperLimit, lowerLimit) {
            let valuesInRange = 0
            let totalArea = 0;
            works.forEach(work => {
                if (work.value <= upperLimit && work.value > lowerLimit) {
                    valuesInRange += work.area;
                }
                totalArea += work.area;
            });

            return parseFloat((valuesInRange / totalArea * 100).toFixed(1));
        },
        getWorksWithoutZeroValue(works) {
            let tempWorks = [];
            works.forEach(work => {
                if (work.value !== 0) {
                    tempWorks.push(work)
                }
            })
            return tempWorks;
        },
        getSteps(works) {
            let steps = [];
            let stepsPercentage = [0.05, 0.20, 0.25, 0.20, 0.15, 0.10, 0.05];
            let worksSorted = works.sort((a, b) => b.value - a.value);
            worksSorted = this.getWorksWithoutZeroValue(worksSorted);

            if (worksSorted.length === 0) {
                return steps;
            }

            steps.push(worksSorted[0].value);

            let worksAddedToStep = 0;
            for (let i = 1; i < worksSorted.length; i++) {
                worksAddedToStep++;
                if (worksAddedToStep >= Math.floor(worksSorted.length * stepsPercentage[steps.length - 1]) &&
                    worksSorted[i].value !== steps[steps.length - 1]) {
                    worksAddedToStep = 0;
                    steps.push(worksSorted[i].value);
                }
            }

            return steps;
        },
        processStepRate(works, upperLimit, lowerLimit) {
            if (!works || works.length === 0) {
                return;
            }

            let steps = this.getSteps(works, upperLimit, lowerLimit)

            let rateStepsTemp = [];

            if (steps.length === 0) {
                rateStepsTemp.push({
                    value: steps[0], color: '#029235', percentage: 100, visible: true
                });
                this.rateSteps = rateStepsTemp;
                return;
            }

            rateStepsTemp.push({
                value: steps[0], color: '#029235', percentage: this.getPercentageInStep(works,
                    steps[0], steps[1]), visible: true
            });
            rateStepsTemp.push({
                value: steps[1], color: '#86d100', percentage: this.getPercentageInStep(works,
                    steps[1], steps[2]), visible: true
            });
            rateStepsTemp.push({
                value: steps[2], color: '#dcf502', percentage: this.getPercentageInStep(works,
                    steps[2], steps[3]), visible: true
            });
            rateStepsTemp.push({
                value: steps[3], color: '#fef801', percentage: this.getPercentageInStep(works,
                    steps[3], steps[4]), visible: true
            });
            rateStepsTemp.push({
                value: steps[4], color: '#ffb801', percentage: this.getPercentageInStep(works,
                    steps[4], steps[5]), visible: true
            });
            rateStepsTemp.push({
                value: steps[5], color: '#ff7e01', percentage: this.getPercentageInStep(works,
                    steps[5], steps[6]), visible: true
            });
            rateStepsTemp.push({
                value: steps[6], color: '#d40000', percentage: this.getPercentageInStep(works,
                    steps[6], 0), visible: true
            });

            this.rateSteps = rateStepsTemp;
        },
        userConfigurationStepRate(works, config) {
            let rateStepsTemp = [];
            let worksSorted = this.getWorksWithoutZeroValue(works);

            if (worksSorted.length === 0) {
                rateStepsTemp.push({
                    value: 0, color: '#029235', percentage: 100, visible: true
                });
                this.rateSteps = rateStepsTemp;
                this.opacity = config.opacity;
                this.stroke = config.stroke;
                return;
            }

            rateStepsTemp.push({
                value: config.values[0], color: config.colors[0], percentage: this.getPercentageInStep(works,
                    config.values[0], config.values[1]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[1], color: config.colors[1], percentage: this.getPercentageInStep(works,
                    config.values[1], config.values[2]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[2], color: config.colors[2], percentage: this.getPercentageInStep(works,
                    config.values[2], config.values[3]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[3], color: config.colors[3], percentage: this.getPercentageInStep(works,
                    config.values[3], config.values[4]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[4], color: config.colors[4], percentage: this.getPercentageInStep(works,
                    config.values[4], config.values[5]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[5], color: config.colors[5], percentage: this.getPercentageInStep(works,
                    config.values[5], config.values[6]), visible: true
            });
            rateStepsTemp.push({
                value: config.values[6], color: config.colors[6], percentage: this.getPercentageInStep(works,
                    config.values[6], 0), visible: true
            });

            this.opacity = config.opacity;
            this.stroke = config.stroke;
            this.rateSteps = rateStepsTemp;
        },
        optionsChanged(newStepRateOptions) {
            let new_configuration = { values: [], colors: [], opacity: newStepRateOptions.opacity, stroke: newStepRateOptions.stroke };
            newStepRateOptions.rate_steps.forEach(rateStep => {
                new_configuration.values.push(rateStep.value);
                new_configuration.colors.push(rateStep.color);
            })

            if (this.user_configuration) {
                if (!this.user_configuration[this.taskType]) {
                    this.user_configuration[this.taskType] = { [this.activityType]: new_configuration };
                }
                this.user_configuration[this.taskType][this.activityType] = new_configuration;
            } else {
                this.user_configuration = { [this.taskType]: { [this.activityType]: new_configuration } };
            }

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_LEGEND, JSON.stringify(this.user_configuration));

            this.rateSteps = newStepRateOptions.rate_steps;
            this.opacity = newStepRateOptions.opacity;
            this.stroke = newStepRateOptions.stroke;
        },
        getWorkRequestBySelectedMapType(fieldId, taskId) {
            let params = {
                startDate: this.selected_task.start.valueOf(),
                endDate: this.selected_task.end.valueOf()
            };
            switch (this.dropDownTaskMapType.value) {
                case 'APPLICATION':
                    params['binElementNumber'] = this.dropDownTaskMapType.bin_el;
                    params['culturalPractice'] = this.dropDownTaskMapType.cultural_practice;
                    return this.fieldWorksService.getApplicationRateWorks(fieldId, taskId, params);
                case 'HUMIDITY':
                    return this.fieldWorksService.getHumidityWorks(fieldId, taskId, params);
                case 'SPEED':
                    return this.fieldWorksService.getSpeedWorks(fieldId, taskId, params);
                case 'OVERLAP':
                    return this.fieldWorksService.getOverlap(fieldId, taskId, params);
                default:
                    return this.fieldWorksService.getSpeedWorks(fieldId, taskId, params);
            }
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        getWorks() {
            if (!this.dropDownTaskMapType) {
                return;
            }

            this.works = [];
            this.steps = [];
            this.cards = [];
            this.loading = true;

            this.getWorkRequestBySelectedMapType(this.selected_field.id, this.selected_task.id).then(response => {
                this.taskType = this.dropDownTask.name;
                this.activityType = this.dropDownTaskMapType.title;

                response.field_works.forEach((work) => {
                    if (!work || !work.value) {
                        return
                    }

                    if (this.selected_task.name === 'Colheita' && this.dropDownTaskMapType.value === 'APPLICATION') {
                        work.value = work.value * 10
                    }

                    if (this.selected_task.name === 'Pulverização' && this.dropDownTaskMapType.value === 'APPLICATION') {
                        work.value = work.value /100
                    }

                })

                this.works = response.field_works;

                if (this.user_configuration) {
                    if (Object.keys(this.user_configuration).find(task => task === this.taskType)) {
                        if (Object.keys(this.user_configuration[this.taskType]).find(activity => activity === this.activityType)) {
                            this.userConfigurationStepRate(this.works, this.user_configuration[this.taskType][this.activityType]);
                        } else {
                            this.processStepRate(response.field_works, Math.max(...response.field_works.map(o => o.value)),
                                Math.min(...response.field_works.map(o => o.value)));
                        }
                    } else {
                        this.processStepRate(response.field_works, Math.max(...response.field_works.map(o => o.value)),
                            Math.min(...response.field_works.map(o => o.value)));
                    }
                } else {
                    this.processStepRate(response.field_works, Math.max(...response.field_works.map(o => o.value)),
                        Math.min(...response.field_works.map(o => o.value)));
                }
                this.loadCardsData(response.field_works, response.overlap_area, response.worked_area, response.produced_area);
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.details) {
                    this.$toast.add({ severity: 'error', summary: error.response.data.details, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: this.$t('AppTasksAnalyze.Não foi possível analisar trabalho'), life: 3000 });
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        getMapDropDownMapType(mapTypes) {
            if (!mapTypes || mapTypes.length === 0) {
                return null;
            }

            if (mapTypes[0].value === 'APPLICATION') {
                return mapTypes[0].subtypes[0];
            }

            return mapTypes[0];
        },
        loadMapTypes() {
            this.fieldWorksService.getFieldWorkTypes(this.selected_field.id, this.selected_task.id).then(response => {
                let mapTypes = []
                let applicationTypes = []
                response.forEach(mapType => {
                    if (mapType.type === 'APPLICATION') {
                        applicationTypes.push({
                            title: mapType.cultural_practice_name, value: mapType.type,
                            bin_el: mapType.bin_element_number, cultural_practice: mapType.cultural_practice
                        })
                        return;
                    }

                    mapTypes.push({ title: mapType.name, value: mapType.type, bin_el: this.guidGenerator() })
                })
                if (applicationTypes.length > 0) {
                    mapTypes.push({ title: this.$t('AppTasksAnalyze.Trabalho'), value: "APPLICATION", subtypes: applicationTypes })
                }
                mapTypes.sort((a, b) => a.title.localeCompare(b.title));
                mapTypes.push({ title: this.$t('AppTasksAnalyze.Sobreposição'), value: "OVERLAP", bin_el: this.guidGenerator() })

                this.mapTypes = mapTypes;

                this.dropDownTaskMapType = this.getMapDropDownMapType(this.mapTypes);
                this.isMapTypeOverlap = this.dropDownTaskMapType.value === 'OVERLAP';
                this.getWorks();
            }).catch(error => {
                console.log(error); //TODO add toast
            })
        },
        guidGenerator() {
            let S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
        },
        handleZoomChanged(payload) {
            this.mapBoundsAndZoom = payload;

            if (this.mapBoundsAndZoom.zoom >= 15) {
                this.getFieldsDeck();
            } else {
                console.log("Fazer lógica para limpar talhões abaixo do zoom 15")
            }
        },
        handleBoundsChanged(payload) {
            this.mapBoundsAndZoom = payload;

            if (this.mapBoundsAndZoom.zoom >= 15) {
                this.getFieldsDeck();
            } else {
                console.log("Fazer lógica para limpar talhões abaixo do zoom 15")
            }
        },
        getFieldsDeck() {

            let params = {
                swLat: this.mapBoundsAndZoom.sw_lat,
                swLng: this.mapBoundsAndZoom.sw_lng,
                neLat: this.mapBoundsAndZoom.ne_lat,
                neLng: this.mapBoundsAndZoom.ne_lng,
            };

            this.fieldsService.getFieldsV3(this.selected_org.id, params)
                .then((response) => {
                    this.fieldsToMap(response);
                }).catch((error) => {
                    console.log(error)
                })
        },
        fieldsToMap(response) {
            this.$refs.googleMapsTasks.fieldsToMap(response.fields);
        },
        setTaskDates() {
            let temp_dates = []
            let enabledDatesTmp = []

            this.rangeDates.forEach((date) => {
                let dateToAdd = moment(date, "YYYY-MM-DD").startOf('day').toDate();
                if (dateToAdd >= moment(this.selected_task.start).toDate()
                    && dateToAdd < moment(this.selected_task.end).toDate() && this.field_work_dates.includes(dateToAdd.valueOf())) {
                    enabledDatesTmp.push(moment(date, "YYYY-MM-DD").startOf('day').toDate())
                    return;
                }
                temp_dates.push(moment(date, "YYYY-MM-DD").startOf('day').toDate());
            })
            this.disabledDates = temp_dates;

            if (enabledDatesTmp.length > 0) {
                this.dateSelected = enabledDatesTmp[0];
            }
        }
    },
    watch: {
        taskSearch: function (val) {
            let tempTasks = [];

            if (!val) {
                this.tasksToCompare = this.tasks;
                return;
            }

            this.tasks.forEach(task => {
                if (task.name.toLowerCase().includes(val.toLowerCase())) {
                    tempTasks.push(task);
                }
            })
            this.tasksToCompare = tempTasks;
        },
        selected_task: function (val) {
            this.dropDownTask = val;
            this.loadMapTypes();
            this.setTaskDates();
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        },
        getYearRange() {
            return '2018:' + new Date().getFullYear();
        },
    },
    props: {
        selected_task: {
            type: Object
        },
        tasks: {
            type: Array
        },
        field_center: {
            type: Object
        },
        selected_field: {
            type: Object
        },
        selected_org: {
            type: Object
        },
        field_work_dates: {
            type: Array
        }
    },
    components: {
        AppGoogleMapsTasks, Dropdown, AppButton, Dialog, Button, InputText, AppTasksCards,
        AppGoogleMapsTypeControl, AppTasksWorkOptions, CascadeSelect, Calendar
    }
}

</script>

<style>
.tasks-cascade>.p-cascadeselect .p-cascadeselect-label {
    height: calc(1.5em + 1.3rem + 2px) !important;
    padding: 0.8rem 0.8rem !important
}
</style>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.p-calendar {
    max-width: 14ch !important;
    min-width: 13ch !important;
}

.p-inputtext {
    border-radius: 5px;
    width: 100%;
}

.task-compare {
    border-bottom: 1px solid #e0e0e0;
    height: 50px;
    display: flex;
    align-items: center;
    color: #393939;
    padding-left: 10px;
}

.task-compare:hover {
    background-color: #addaff;
    cursor: pointer;
}

.sub-menu {
    display: flex;
    align-items: center;
    background-color: $head-porlet;
    height: 60px;
    padding: 0px 15px;
    position: relative;
}

.search-icon {
    position: absolute;
    top: 12px;
    left: 8px;
}

.ps {
    height: 250px;
}

.glyphicons:before {
    padding: 0;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-dropdown {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
}

.dropdown-width-trabalho {
    width: 48%
}

.dropdown-width-type {
    width: 48%;
    margin-left: 10px;
}

.calendar-width-type {
    width: 40%;
    margin-left: 5px;
}

.button-compare-back-12 {
    margin: 10px 0px;
}

.button-compare-back {
    position: absolute;
    right: 10px;
}

.button-back-12 {
    width: 100px;
    margin-left: 10px;
}

.button-back {
    margin-left: 10px;
}

.width-250 {
    width: 250px;
    margin-right: 5px;
}

.maps-tasks {
    height: calc(100% - 81px) !important;
}

@media (max-width: 1024px) {
    .maps-tasks {
        height: calc(100% - 106px) !important;
    }
}
</style>
