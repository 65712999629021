<template>
    <v-chart class="chart" :option="chartOption" autoresize theme="report-theme"/>
</template>

<script>
import {registerTheme, use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";

import reportTheme from '@/configurations/iotag_echarts_report_theme.json'

use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

registerTheme("report-theme", reportTheme);

export default {
    components: {
        VChart
    },
    beforeMount() {
        this.setupChart();
    },
    data() {
        return {
            chartOption: null,
            tooltipSuffix: 'm³',
            mode: null,
        }
    },
    computed: {
        monthSelection: function () {
            return this.$store.getters.getMonthType;
        }
    },
    props: {
        modeDisplay: {
            type: Object
        },
        fullData: {
            type: Object
        }
    },
    watch: {
        modeDisplay: {
            deep: true,
            handler(mode) {
                if (!mode || mode.name === 'Geral') {
                    this.mode = 'Geral'
                } else {
                    this.mode = mode.name
                }
                this.setupSeries();
            }
        },
        monthSelection: {
            deep: true,
            handler(val) {
                if (val !== null) {
                    this.mode = val.name;
                    this.setupSeries();
                }
            }
        }
    },
    methods: {
        setupChart() {
            this.chartOption = {
                fontFamily: "'Unica One', sans-serif",
                tooltip: {
                    trigger: 'item',
                    formatter: function (args) {
                        if (args.seriesName === "") {
                            return `${args.marker} ${args.name}: <b>${args.value} m³</b><br>`;
                        }
                        return `${args.name} <br> ${args.marker} ${args.seriesName}: <b>${args.value} m³</b><br>`;
                    }
                },
                legend: {
                    icon: 'circle'
                },
                series: [],
                xAxis: {
                    type: 'category',
                    data: this.fullData.months
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} m³'
                    },
                },
            }
        },
        setupSeries() {
            if (this.mode === 'Geral') {
                this.chartOption.series = this.fullData.mainSeries
            } else if (this.mode === 'Processos') {
                this.chartOption.series = this.fullData.processSeries
            } else {
                this.chartOption.series = this.fullData.stateSeries
            }
        }
    }
}
</script>

<style scoped>
.chart {
    height: 400px;
}
</style>
