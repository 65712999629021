<template>
    <select class="form-control kt-margin-5-desktop width-auto auto-center" @change="handleChange($event)"
            v-model="vehicle_selected" :disabled="disabled_select">
        <option value="SELECIONE" disabled selected="">{{ $t('AppVehiclesSelectVehicles.Selecione') }}</option>
        <option v-if="loading" value="">{{ $t('AppVehiclesSelectVehicles.Carregando') }}</option>
        <option v-if="vehiclesSorted.length === 0 && !loading" value="" disabled>{{ $t('AppVehiclesSelectVehicles.Nenhum veículo localizado') }}</option>
        <option v-for="vehicle in vehiclesSorted" v-bind:key="vehicle.id" :value="vehicle">
            <template v-if="data_type === 'VEHICLE'">
                {{ formatValue(vehicle.display_id) }}
            </template>
            <template v-else>
                {{ formatValue(vehicle.device) }}
            </template>

        </option>
    </select>
</template>

<script>

    import maskMacAddress from "../../../mixins/StringsMaskMixin"
    import orderVehiclesByNaturalOrder from "../../../mixins/ArrayMixin";

    export default {
        mixins: [maskMacAddress, orderVehiclesByNaturalOrder],
        data() {
            return {
                vehicle_selected: this.selected
            }
        },
        methods: {
            handleChange(event) {
                this.$emit('handleSelectChanges', this.vehicle_selected);
            },
            formatValue(value) {
                if (this.isMacAddress(value)) {
                    return this.maskMacAddress(value);
                } else {
                    return value;
                }
            },
            isMacAddress(value) {
                if (value.length !== 12) {
                    return false;
                }

                var regexp = /^([0-9A-F])/;
                return regexp.test(value)
            }
        },
        components: {},
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            selected: {
                type: Object
            },
            vehicles: {
                type: Array,
                required: true
            },
            disabled_select: {
                type: Boolean,
                default: false
            },
            data_type: {
                type: String,
                default: 'DEVICE'
            }
        },
        computed: {
            vehiclesSorted() {
                return this.orderVehiclesByNaturalOrder(this.vehicles);
            }
        },
        watch: {
            selected: {
                handler: function (val) {
                    if(val && val.identification_number){
                        this.vehicle_selected = val;
                    }else{
                        this.vehicle_selected = "SELECIONE";
                    }
                },
                deep: true
            }
        },
    }
</script>

<style scoped>

    .font-select-option {
        font-size: 15px;
    }

    .auto-center {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    select:disabled {
        opacity: 0.6;
    }

</style>
