const state = {
    totalValue: null,
    monthType: null,
    catchmentType: null,
    processType: null,
};

const getters = {
    getTotalValue: state => state.totalValue,
    getMonthType: state => state.monthType,
    getCatchmentType: state => state.catchmentType,
    getProcessType: state => state.processType,
};

const mutations = {
    setTotalValue: (state, value) => {
        state.totalValue = value;
    },
    setMonthType: (state, monthType) => {
        state.monthType = monthType;
    },
    setCatchmentType: (state, catchmentType) => {
        state.catchmentType = catchmentType;
    },
    setProcessType: (state, processType) => {
        state.processType = processType;
    },
};

const actions = {
    updateTotalValue: ({ commit }, value) => {
        commit('setTotalValue', value);
    },
    updateMonthType: ({ commit }, monthType) => {
        commit('setMonthType', monthType);
    },
    updateCatchmentType: ({ commit }, catchmentType) => {
        commit('setCatchmentType', catchmentType);
    },
    updateProcessType: ({ commit }, processType) => {
        commit('setProcessType', processType);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
