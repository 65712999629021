import i18n from "../../../../i18n";

export const STATUSES = [
    {name: i18n.t('AppOptions_dictionary.PENDENTE'), code: 'NEW'},
    {name: i18n.t('AppOptions_dictionary.EXECUTANDO'), code: 'IN_PROGRESS'},
    {name: i18n.t('AppOptions_dictionary.FINALIZADO'), code: 'CLOSED'},
];

export const STATUSES_FAILURES = [
    {name: i18n.t('AppOptions_dictionary.ATIVO'), code: 'ACTIVE'},
    {name: i18n.t('AppOptions_dictionary.INATIVO'), code: 'INACTIVE'},
];

export const PRIORITIES = [
    {name: i18n.t('AppOptions_dictionary.ALTO'), code: 'HIGH'},
    {name: i18n.t('AppOptions_dictionary.MÉDIO'), code: 'MEDIUM'},
    {name: i18n.t('AppOptions_dictionary.BAIXO'), code: 'LOW'},
    {name: i18n.t('AppOptions_dictionary.NULO'), code: 'UNKNOWN'},
];

export const CSV_FIELDS = ['date', 'city', 'state', 'display_id', 'identification_number', 'hour_meter', 'trade_name', 'code',
    'priority', 'count', 'response_time', 'execution_time', 'total_time', 'assignee', 'work_order', 'status', 'observation'];

export const CSV_LABELS = [
    i18n.t('AppOptions_dictionary.Horário'), i18n.t('AppOptions_dictionary.Município'), i18n.t('AppOptions_dictionary.Estado'), i18n.t('AppOptions_dictionary.Identificação'), i18n.t('AppOptions_dictionary.Chassis'), i18n.t('AppOptions_dictionary.Horímetro'), i18n.t('AppOptions_dictionary.Propriedade'),   i18n.t('AppOptions_dictionary.Código'), i18n.t('AppOptions_dictionary.Nível'), i18n.t('AppOptions_dictionary.Contagem'), i18n.t('AppOptions_dictionary.Reação'), i18n.t('AppOptions_dictionary.Execução'),i18n.t('AppOptions_dictionary.Total'), i18n.t('AppOptions_dictionary.Responsável'), i18n.t('AppOptions_dictionary.Ordem'), i18n.t('AppOptions_dictionary.Serviço'), i18n.t('AppOptions_dictionary.Observação')
];

export const GRID_COLUMNS = [
    {field: 'date', header: i18n.t('AppOptions_dictionary.Horário')},
    {field: 'city', header: i18n.t('AppOptions_dictionary.Município')},
    {field: 'state', header: i18n.t('AppOptions_dictionary.Estado')},
    {field: 'display_id', header: i18n.t('AppOptions_dictionary.Identificação')},
    {field: 'identification_number', header: i18n.t('AppOptions_dictionary.Chassis')},
    {field: 'hour_meter', header: i18n.t('AppOptions_dictionary.Horímetro')},
    {field: 'trade_name', header: i18n.t('AppOptions_dictionary.Propriedade')},
    {field: 'code', header: i18n.t('AppOptions_dictionary.Código')},
    {field: 'priority', header: i18n.t('AppOptions_dictionary.Nível')},
    {field: 'count', header: i18n.t('AppOptions_dictionary.Contagem')},
    {field: 'response_time', header: i18n.t('AppOptions_dictionary.Reação')},
    {field: 'execution_time', header: i18n.t('AppOptions_dictionary.Execução')},
    {field: 'total_time', header: i18n.t('AppOptions_dictionary.Total')},
    {field: 'assignee', header: i18n.t('AppOptions_dictionary.Responsável')},
    {field: 'work_order', header: i18n.t('AppOptions_dictionary.Ordem')},
    {field: 'status', header: i18n.t('AppOptions_dictionary.Serviço')},
];
