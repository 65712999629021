<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="row justify-content-between align-items-center header-buttons">
                    <div class="row justify-content-between align-items-center col-7 filters">
                        <dropdown v-model="selectedOrg" :options="organizations" optionLabel="name" class="col-2"
                                  placeholder="Organização" @change="onOrganizationSelected" :dataKey="'id'"></dropdown>
                        <MultiSelect v-model="selectedStates" :options="availableStates" :placeholder="$t('AppWaterWithdrawalDashboard.Estado')"
                                     :filter="true" :disabled="!selectedOrg" :selectionLimit="10" optionLabel="name"
                                     class="col-3" @change="setupUnityFilter">
                            <template #option="slotProps">
                                <span>{{ slotProps.option.name }}</span>
                            </template>
                        </MultiSelect>
                        <MultiSelect v-model="selectedUnities" :options="unities" :placeholder="$t('AppWaterWithdrawalDashboard.Unidade')"
                                     :disabled="!isAnyStateSelected" :selectionLimit="10" optionLabel="name"
                                     :filter="true" class="col-3" :dataKey="'id'">
                            <template #option="slotProps">
                                <span>{{ slotProps.option.name }}</span>
                            </template>
                        </MultiSelect>
                        <Calendar v-model="dateInterval" dateFormat="dd/mm/yy" style="margin-inline: -0.8rem;"
                                  selectionMode="range" :manualInput="false" class="col-3"></Calendar>
                        <app-button type="primary" @handleClick="getFilteredWaterWithdrawal" class="col-1">{{$t('AppWaterWithdrawalDashboard.Filtrar')}}
                        </app-button>
                    </div>
                    <div class="view-selection col-5">
                        <div class="col" style="display: flex;flex-direction:row-reverse;">
                            <Button class="m-0 auto-center btn-style" :style="styleButton4"
                                    @click="buttonPdfReportClicked()"
                                    v-tooltip.top="$t('AppWaterWithdrawalDashboard.Relatório em PDF')">
                                <i class="pi pi-file-pdf"></i>
                            </Button>
                            <Button class="m-0 auto-center btn-style" :style="styleButton3"
                                    @click="buttonSetClicked(3)"
                                    v-tooltip.top="$t('AppWaterWithdrawalDashboard.Pontos de captação')">
                                <i class="pi pi-map-marker"></i>
                            </Button>
                            <Button class="m-0 auto-center btn-style" :style="styleButton2"
                                    @click="buttonSetClicked(2)"
                                    v-tooltip.top="$t('AppWaterWithdrawalDashboard.Captações')">
                                <i class="glyphicons glyphicons-tint"></i>
                            </Button>
                            <Button class="m-0 auto-center btn-style" :style="styleButton1"
                                    @click="buttonSetClicked(1)"
                                    v-tooltip.top="$t('AppWaterWithdrawalDashboard.Dashboard')">
                                <i class="pi pi-chevron-circle-left"></i>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div v-if="!selectedOrg" class="row justify-content-center">
                    <div class="col-6 no-org-selected">
                        <span>{{ $t('AppWaterWithdrawalDashboard.Selecione uma organização para carregar os dados') }}</span>
                    </div>
                </div>
                <div v-else-if="withdrawals.length === 0" class="row justify-content-center">
                    <div class="col-6 no-org-selected">
                        <span>{{ $t('AppWaterWithdrawalDashboard.Dados insuficientes para geração do dashboard') }} </span>
                    </div>
                </div>
                <div v-else>
                    <div v-show="showScreen1" id="dashboard-report">
                        <app-water-withdrawal-charts :resume="resume" :byProcess="processes" :byMonth="months"
                                                     :byState="states"></app-water-withdrawal-charts>
                    </div>
                    <div v-show="showScreen2">
                        <app-water-withdrawal-log-table :data="states" :organization="selectedOrg"
                                                        :date_interval="dateInterval"></app-water-withdrawal-log-table>
                    </div>
                    <div v-show="showScreen3">
                        <app-water-withdrawal-points-table
                            :organization="selectedOrg"></app-water-withdrawal-points-table>
                    </div>
                </div>
            </div>
        </div>

        <app-water-withdrawal-report :generate_report="generateReport" :date_interval="dateInterval"
                                     :resume="resume" :byProcess="processes"
                                     :byMonth="months" :byState="states"/>
    </div>
</template>

<script>

import AppWaterWithdrawalCharts from "@/components/views/water_withdrawal/AppWaterWithdrawalCharts";
import AppWaterWithdrawalLogTable from "@/components/views/water_withdrawal/AppWaterWithdrawalLogTable";
import AppWaterWithdrawalPointsTable from "@/components/views/water_withdrawal/AppWaterWithdrawalPointsTable";
import AppWaterWithdrawalReport from "@/components/views/water_withdrawal/AppWaterWithdrawalReport";

import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import WaterWithdrawalService from "@/services/WaterWithdrawalService";
import AppButton from "@/components/common/AppButton";
import Dropdown from "primevue/dropdown";
import OrganizationsService from "@/services/OrganizationsService";
import {PROCESSES} from './AppOptions'

export default {
    components: {
        AppButton,
        MultiSelect,
        Calendar,
        Dropdown,
        AppWaterWithdrawalCharts,
        AppWaterWithdrawalLogTable,
        AppWaterWithdrawalPointsTable,
        AppWaterWithdrawalReport
    },
    beforeMount() {
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
        this.setupInitialDateInterval();
    },
    mounted() {
        this.waterWithdrawalService = new WaterWithdrawalService();
    },
    data() {
        return {
            waterWithdrawalService: null,
            organizationsService: null,

            availableStates: [],

            currentOrg: '',
            selectedOrg: null,
            selectedStates: [],
            selectedUnities: [],
            dateInterval: [],

            minDate: null,
            maxDate: null,

            showScreen1: true,
            showScreen2: false,
            showScreen3: false,

            styleButton1: "background-color: #474747",
            styleButton2: "background-color: inherit",
            styleButton3: "background-color: inherit",
            styleButton4: "background-color: inherit",

            withdrawals: [],
            organizations: [],
            states: [],
            unities: [],

            resume: {
                total: 0,
                regular: 0,
                deviation: 0,
                no_permission: 0
            },
            withdrawalProcesses: PROCESSES,
            processes: [],
            months: [],

            generateReport: false
        }
    },
    methods: {
        buttonSetClicked(val) {
            for (let i = 1; i < 4; i++) {
                if (i === val) {
                    this['styleButton' + i] = "background-color: #474747";
                    this['showScreen' + i] = true;
                } else {
                    this['styleButton' + i] = "background-color: inherit";
                    this['showScreen' + i] = false;
                }
            }
        },
        getOrganizations() {
            this.organizationsService.getAllV3("TRACK").then(data => {
                this.organizations = data
            }).catch(() => {
                this.$router.go(-1)
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWaterWithdrawalDashboard.Erro'),
                    detail: this.$t('AppWaterWithdrawalDashboard.Problemas ao carregar as organizações'),
                    life: 5000
                });
            })
        },
        setupInitialDateInterval() {
            this.maxDate = new Date()

            this.maxDate.setHours(23, 59, 59, 999)
            let month = this.maxDate.getMonth()
            let year = this.maxDate.getFullYear()

            let minMonth = month <= 2 ? (9 + month) : month - 2
            let minYear = month <= 2 ? year - 1 : year
            this.minDate = new Date(minYear, minMonth, 1, 0, 0, 0, 0)
            this.dateInterval = [this.minDate, this.maxDate]

            let tempDate = new Date()
            for (let i = minMonth; i <= minMonth + 2; i++) {
                let m = i
                if (m > 11) {
                    m -= 12
                }
                tempDate.setMonth(m)
                let monthName = tempDate.toLocaleString('pt-BR', {month: "short"}).substring(0, 3)
                this.months.push({
                    name: monthName,
                    volume: 0,
                    byProcess: [],
                    byState: []
                })
            }
        },
        onOrganizationSelected() {
            if (this.selectedOrg.name !== this.currentOrg) {
                this.currentOrg = this.selectedOrg.name
                this.clearAllData()
                this.setupOrgStateFilter()
                this.setupProcessObj()
                this.getWaterWithdrawals()
            }
        },
        setupProcessObj() {
            if (this.processes.length === 0) {
                this.withdrawalProcesses.forEach(process => {
                    this.processes.push({
                        type: process,
                        volume: 0,
                        regular: 0,
                        deviation: 0,
                        no_permission: 0,
                        protocol: 0
                    })
                })
            }
        },
        setupOrgStateFilter() {
            this.clearFilters()
            this.waterWithdrawalService.getStates(this.selectedOrg.id).then(data => {
                this.availableStates = data
                this.setupStatesObjList()
            })
            this.availableStates = this.sortByName(this.availableStates)
        },
        clearFilters() {
            this.clearData(this.selectedStates)
            this.clearData(this.availableStates)
            this.clearData(this.selectedUnities)
        },
        setupStatesObjList() {
            let list = this.isAnyStateSelected ? this.selectedStates : this.availableStates
            list.forEach(state => {
                this.states.push({
                    name: state.name,
                    volume: 0,
                    regular: 0,
                    deviation: 0,
                    no_permission: 0,
                    protocol: 0,
                    withdrawals: []
                })
            })
        },
        sortByName(list) {
            return list.sort((a, b) => (a.name > b.name) ? 1 : -1)
        },
        getWaterWithdrawals() {
            this.waterWithdrawalService.getAllWaterWithdrawals(this.selectedOrg.id, this.convertDateToEpoch()[0], this.convertDateToEpoch()[1]).then(data => {
                this.withdrawals = data
            }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWaterWithdrawalDashboard.Erro'),
                    detail: this.$t('AppWaterWithdrawalDashboard.Problemas ao carregar as informações'),
                    life: 5000
                });
            }).finally(() => {
                if (this.withdrawals.length === 0) {
                    return;
                }
                this.configureInfo(this.withdrawals)
            })
        },
        configureInfo(data) {
            this.configureResumeProcessState(data);
            let dataWithoutUnknown = data.filter(d => d.status !== "UNKNOWN");
            this.configureMonthCharts(dataWithoutUnknown);
        },
        setupUnityFilter() {
            this.clearData(this.unities)
            this.selectedStates.forEach(state => {
                state.ups.forEach(unity => {
                    this.unities.push(unity)
                })
            })
            this.unities = this.sortByName(this.unities)
            this.removeUnavailableUnities()
        },
        clearData(list) {
            if (list) {
                while (list.length > 0) {
                    list.pop()
                }
            }
        },
        removeUnavailableUnities() {
            let stillAvailable = []
            this.selectedUnities.forEach(selectedUnity => {
                this.unities.forEach(availableUnity => {
                    if (selectedUnity.id === availableUnity.id) {
                        stillAvailable.push(availableUnity)
                    }
                })
            })
            this.selectedUnities = stillAvailable
        },
        getFilteredWaterWithdrawal() {
            if (!this.selectedOrg) {
                this.$toast.add({
                    severity: 'info',
                    summary: this.$t('AppWaterWithdrawalDashboard.Nenhuma organização selecionada'),
                    detail: this.$t('AppWaterWithdrawalDashboard.Selecione uma organização para carregar o dashboard'),
                    life: 5000
                });
            } else {
                this.clearAllData()
                this.setupInterval()
                this.setupStatesObjList()
                if (!this.isAnyStateSelected) {
                    this.getWaterWithdrawals()
                } else if (this.selectedUnities.length === 0) {
                    this.getWithdrawalsFrom(this.selectedStates, this.waterWithdrawalService.getWaterWithdrawalsByState)
                } else {
                    this.getWithdrawalsFrom(this.selectedUnities, this.waterWithdrawalService.getWaterWithdrawalsByUnity)
                }
            }
        },
        getWithdrawalsFrom(list, filterBy) {
            let promises = []
            list.forEach(item => {
                promises.push(this.filter(item, filterBy))
            })

            Promise.all(promises).then(() => {
                this.configureInfo(this.withdrawals)
            })
        },
        filter(obj, filterBy) {
            return filterBy(this.selectedOrg.id, obj.id, this.convertDateToEpoch()[0], this.convertDateToEpoch()[1]).then(data => {
                data.forEach(item => {
                    this.withdrawals.push(item)
                })
            }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWaterWithdrawalDashboard.Erro'),
                    detail: this.$t('AppWaterWithdrawalDashboard.Problemas ao carregar as informações'),
                    life: 5000
                });
            })
        },
        configureResumeProcessState(data) {
            let vm = this
            data.forEach(withdrawal => {
                if (withdrawal.status !== "UNKNOWN") {
                    vm.resume.total += withdrawal.volume
                }

                for (let i = 0; i < vm.processes.length; i++) {
                    if (withdrawal.process === vm.processes[i].type.code) {
                        if (withdrawal.status !== "UNKNOWN") {
                            vm.processes[i].volume += withdrawal.volume
                        }
                        if (withdrawal.status === 'REGULAR') {
                            vm.resume.regular += withdrawal.volume
                            vm.processes[i].regular += withdrawal.volume
                        } else if (withdrawal.status === 'DEVIATION') {
                            vm.resume.regular += withdrawal.volume - withdrawal.volume_deviation
                            vm.processes[i].regular += withdrawal.volume - withdrawal.volume_deviation
                            vm.resume.deviation += withdrawal.volume_deviation
                            vm.processes[i].deviation += withdrawal.volume_deviation
                        } else if (withdrawal.status === 'NO_AUTHORIZATION') {
                            vm.resume.no_permission += withdrawal.volume
                            vm.processes[i].no_permission += withdrawal.volume
                        } else if (withdrawal.status === 'PROTOCOL') {
                            vm.processes[i].protocol += withdrawal.volume
                        }
                        break;
                    }
                }

                for (let i = 0; i < vm.states.length; i++) {
                    if (withdrawal.state === vm.states[i].name) {
                        vm.states[i].volume += withdrawal.volume
                        vm.states[i].withdrawals.push(withdrawal)
                        if (withdrawal.status === 'REGULAR') {
                            vm.states[i].regular += withdrawal.volume
                        } else if (withdrawal.status === 'DEVIATION') {
                            vm.states[i].regular += withdrawal.volume - withdrawal.volume_deviation
                            vm.states[i].deviation += withdrawal.volume_deviation
                        } else if (withdrawal.status === 'NO_AUTHORIZATION') {
                            vm.states[i].no_permission += withdrawal.volume
                        } else if (withdrawal.status === 'PROTOCOL') {
                            vm.states[i].protocol += withdrawal.volume
                        }

                        break;
                    }
                }
            })

            vm.resume.regular = this.formatValue(vm.resume.regular, 'porcentagem')
            vm.resume.deviation = this.formatValue(vm.resume.deviation, 'porcentagem')
            vm.resume.no_permission = this.formatValue(vm.resume.no_permission, 'porcentagem')
            vm.resume.total = this.formatValue(vm.resume.total, 'ponto-milhar')
        },
        formatValue(val, type) {
            if (type === 'porcentagem') {
                return (100 * val / this.resume.total).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
            return val.toLocaleString('pt-BR', {
                maximumFractionDigits: 2
            })
        },
        convertDateToEpoch() {
            let epoch = []
            this.dateInterval.forEach(date => {
                epoch.push(Math.floor(date.getTime() / 1000))
            })
            return epoch
        },
        configureMonthCharts(data) {
            let vm = this
            for (let i = 0; i < this.months.length; i++) {
                for (let j = 0; j < this.states.length; j++) {
                    this.months[i].byState.push({name: this.states[j].name, volume: 0})
                }

                for (let j = 0; j < this.processes.length; j++) {
                    this.months[i].byProcess.push({
                        name: this.processes[j].type.name,
                        code: this.processes[j].type.code,
                        type: this.processes[j].type,
                        volume: 0,
                        regular: 0,
                        deviation: 0,
                        protocol: 0,
                        no_permission: 0
                    })
                }
            }

            let withdrawalMonth
            data.forEach(withdrawal => {
                withdrawalMonth = new Date(withdrawal.timestamp * 1000).toLocaleString('pt-BR', {month: "short"}).substring(0, 3)
                for (let i = 0; i < vm.months.length; i++) {
                    if (withdrawalMonth === vm.months[i].name) {
                        vm.months[i].volume += withdrawal.volume

                        for (let j = 0; j < vm.months[i].byState.length; j++) {
                            if (withdrawal.state === vm.months[i].byState[j].name) {
                                vm.months[i].byState[j].volume += withdrawal.volume
                                break;
                            }
                        }

                        for (let j = 0; j < vm.months[i].byProcess.length; j++) {
                            if (withdrawal.process === vm.months[i].byProcess[j].type.code) {
                                vm.months[i].byProcess[j].volume += withdrawal.volume
                                if (withdrawal.status === 'REGULAR') {
                                    vm.months[i].byProcess[j].regular += withdrawal.volume
                                } else if (withdrawal.status === 'DEVIATION') {
                                    vm.months[i].byProcess[j].regular += withdrawal.volume - withdrawal.volume_deviation
                                    vm.months[i].byProcess[j].deviation += withdrawal.volume_deviation
                                } else if (withdrawal.status === 'NO_AUTHORIZATION') {
                                    vm.months[i].byProcess[j].no_permission += withdrawal.volume
                                } else if (withdrawal.status === 'PROTOCOL') {
                                    vm.months[i].byProcess[j].protocol += withdrawal.volume
                                }
                                break;
                            }
                        }

                        break;
                    }
                }
            })
        },
        clearAllData() {
            this.withdrawals = []

            this.resume.total = 0
            this.resume.regular = 0
            this.resume.deviation = 0
            this.resume.no_permission = 0

            this.processes.forEach(process => {
                process.volume = 0
                process.regular = 0
                process.deviation = 0
                process.protocol = 0
                process.no_permission = 0
            })

            this.clearData(this.states)
            this.months.forEach(month => {
                this.clearData(month.byState)
                this.clearData(month.byProcess)
            })
        },
        setupInterval() {
            this.clearData(this.months)
            this.dateInterval[0].setHours(0, 0, 0, 0)
            this.dateInterval[1].setHours(23, 59, 59, 999)
            let tempDate = new Date()
            for (let i = this.dateInterval[0].getMonth(); i <= this.dateInterval[1].getMonth(); i++) {
                tempDate.setMonth(i)
                let monthName = tempDate.toLocaleString('pt-BR', {month: "short"}).substring(0, 3)
                this.months.push({
                    name: monthName,
                    volume: 0,
                    byProcess: [],
                    byState: []
                })
            }
        },
        buttonPdfReportClicked() {
            this.generateReport = !this.generateReport;
        }
    },
    directives: {
        tooltip: Tooltip
    },
    computed: {
        isAnyStateSelected: function () {
            return this.selectedStates.length > 0;
        }
    }
}
</script>

<style scoped>
.no-org-selected {
    font-size: x-large;
    margin: 1.3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-buttons {
    width: 100%;
}

.filters {
    display: flex;
}
</style>
