export default {
    data: function () {
        return {
            toastError: 'error',
            toastSuccess: 'success',
            toastWarn: 'warn',
            toastInfo: 'info',
        }
    },
    methods: {
        displayToast(severity, summary = null, detail = null, life = 5000) {
            this.$toast.add({
                severity: severity,
                summary: summary === null ? this.getDefaultSummary(severity) : summary,
                detail: detail,
                life: life
            });
        },
        getDefaultSummary(severity) {
            switch (severity) {
                case this.toastError:
                    return 'Não foi possível realizar a operação';
                case this.toastSuccess:
                    return 'Operação realizada com sucesso';
                case this.toastWarn:
                    return 'Alerta';
                case this.toastInfo:
                    return 'Informação';
                default:
                    return '';
            }
        }
    }
}