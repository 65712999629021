<template>
    <div class="kt-portlet__body no-padding">
        <app-alert :type="'danger'" :icon="'glyphicons-exclamation-sign'" @handleClick="errors = []"
                   v-show="errors.length" class="kt-form">
            <b>{{ $t('AppProfileEditInformation.Por favor, verifique os erros informados') }}</b>
            <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </app-alert>
        <div class="kt-form">
            <form class="kt-form kt-form--label-right" v-on:submit.prevent>
                <div class="kt-portlet__body shadow-lg bg-white pl-5 pr-5" style="border-radius: 10px;">
                    <div class="form-group row">
                        <div class="title col-12 p-1">
                            {{ $t('AppProfileEditInformation.Informações Pessoais') }}
                            <img src="../../../../assets/icons/icone-user-edit.svg" alt=""
                                 class="mb-1 ml-1"
                                 width="20"
                                 height="20">
                        </div>
                    </div>
                    <div v-if="!loading" class="form-group row">
                        <label>{{ $t('AppProfileEditInformation.Nome') }}</label>
                        <app-input class="col-12" v-model="firstName"/>
                    </div>
                    <Skeleton v-else width="100%" height="3rem" class="mt-1"></Skeleton>
                    <div v-if="!loading" class="form-group row">
                        <label>{{ $t('AppProfileEditInformation.Sobrenome') }}</label>
                        <app-input class="col-12" v-model="lastName"/>
                    </div>
                    <Skeleton v-else width="100%" height="3rem" class="mt-3"></Skeleton>
                    <div class="form-group row">
                        <app-button v-if="!loading" class="mt-4 col-12 col-lg-3 ml-lg-auto btn-sucess" type="primary"
                                    @handleClick="validateForm" :disabled="saveDisabled">
                            <span class="pi pi-save mr-2"/>
                            {{ $t('AppProfileChangePassword.Salvar') }}
                        </app-button>
                        <Skeleton v-else width="30%" height="3rem"
                                  class="mt-4 col-12 col-lg-3 ml-lg-auto btn-sucess"></Skeleton>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
import AppButton from '../../../common/AppButton';
import AppInput from '../../../common/AppInput';
import AppAlert from '../../../common/AppAlert';
import Skeleton from 'primevue/skeleton';
import ProfileService from "@/services/ProfileService";

import {router} from '../../../../router';

export default {
    mounted() {
        this.profileService = new ProfileService();
        this.getProfileData();
    },
    data() {
        return {
            profileService: null,
            loading: true,
            firstName: null,
            lastName: null,
            errors: [],
            saveDisabled: true
        }
    },
    components: {
        AppButton, AppInput, AppAlert, Skeleton
    },
    methods: {
        getProfileData() {
            this.loading = true
            this.profileService.findProfileInfo().then((response) => {
                this.firstName = response.first_name;
                this.lastName = response.last_name;
                this.saveDisabled = false
            }).catch((error) => {
                console.log(error)
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppProfileEditInformation.Informações não alteradas'),
                    detail: error,
                    life: 3000
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        back() {
            router.go(-1);
        },
        validateForm() {
            this.errors = [];
            if (!this.firstName) {
                this.errors.push(this.$t('AppProfileEditInformation.Informe o primeiro nome'));
            }

            if (!this.lastName) {
                this.errors.push(this.$t('AppProfileEditInformation.Informe o sobrenome'));
            }

            if (this.errors.length === 0) {
                this.updateUserInfo();
            }
        },
        updateUserInfo() {
            let vm = this;
            this.loading = true;
            this.profileService.editProfileInfo({
                first_name: vm.firstName,
                last_name: vm.lastName
            }).then((response) => {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('AppProfileEditInformation.Informações atualizadas'),
                    detail: this.$t('AppProfileEditInformation.Suas informações foram atualizadas com sucesso'),
                    life: 3000
                });
                this.getProfileData();
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppProfileEditInformation.Informações não atualizadas'),
                            detail: this.$t('AppProfileEditInformation.Verfique seus dados'),
                            life: 3000
                        });
                    }
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppProfileEditInformation.Informações não atualizadas'),
                        detail: this.$t('AppProfileEditInformation.Não foi possível alterar suas informações'),
                        life: 3000
                    });
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/constants";

.form-group {
    margin-bottom: 1rem;
}

label {
    margin-bottom: 0;
}

img {
    filter: $aside-menu-item-inactive;
}

.title {
    border-bottom: 0.5px rgb(219, 219, 219) solid;
    padding-bottom: 15px;
    font-weight: bold;
    text-align: center;
    padding-top: 8px;
    font-size: 14px;
}

.kt-form {
    width: 80%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .kt-form {
        width: 95%;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .kt-form {
        width: 95%;
    }
}
</style>
