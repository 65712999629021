<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="organizations"
                                  optionLabel="display_name" :placeholder=" $t('AppWebhook.Organização')"
                                  class="auto-center" :dataKey="'id'"
                                  :filter="true"
                                  style="width: 250px"
                                  @input="saveChosenOptionsToLocalStorage">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> {{ $t('AppWebhook.Carregando, aguarde')}}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding" style="color: #787878;">
                <!-- Titulo -->
                <span class="title-webhook">
                    Webhook
                </span>

                <!-- Menu -->
                <div class="menu-options">
                    <div @click="menuOptionClick('tutorial')" :class="{'tutorial-option-active': isTutorialSelected, 'tutorial-option-inactive': !isTutorialSelected}">
                        {{ $t('AppWebhook.O QUE É')}}
                    </div>
                    <div @click="menuOptionClick('config')" :class="{'config-option-active': !isTutorialSelected, 'config-option-inactive': isTutorialSelected}">
                        {{ $t('AppWebhook.INSCRIÇÕES')}}
                    </div>
                </div>
                <div class="line-style"></div>

                <!-- Conteúdo -->
                <AppWebhookTutorial v-show="isTutorialSelected" class="container-content"/>
                <AppWebhookConfig v-show="!isTutorialSelected"
                    :grid_data="gridData"
                    :selected_org="selectedOrg"
                    :org_feature_flags="orgFeatureFlags"
                    @createData="createData"
                    @updateData="updateData"
                    class="container-content"/>
            </div>
        </div>
    </div>
</template>
<script>
import OrganizationsService from "@/services/OrganizationsService";
import WebhookService from "@/services/WebhookService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import Dropdown from 'primevue/dropdown';
import AppWebhookTutorial from './AppWebHookTutorial.vue';
import AppWebhookConfig from './AppWebHookConfig.vue';

export default {
    mixins: [orderCustomFieldByNaturalOrder],
    mounted() {
        this.organizationsService = new OrganizationsService();
        this.webhookService = new WebhookService();
        this.getOrganizations();

        this.isTutorialSelected = true
    },
    data() {
        return {
            organizations: [],
            organizationsService: null,
            webhookService: null,
            loadingOrgs: false,
            selectedOrg: null,
            storedChosenOptions: {},
            isTutorialSelected: false,
            gridData: [],
            orgFeatureFlags: []
        }
    },
    methods: {
        getOrganizations() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('WEBHOOK').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            })
            .catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppWebhook.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWebhook.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        menuOptionClick(type){
            if(type === 'tutorial'){
                this.isTutorialSelected = true
                document.querySelector(".line-style").style.right = '75px'
            }

            if(type === 'config'){
                this.isTutorialSelected = false
                document.querySelector(".line-style").style.right = '-75px'
            }
        },
        createData(val){
            this.webhookService.createConnectionWebHook(this.selectedOrg.id, val)
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppWebhook.Webhook criado com sucesso!'),
                            life: 3000
                        });
                    }).catch((error) => {
                        if (!error.response || error.response.status !== 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.details,
                                life: 5000
                            });
                            return;
                        }
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppWebhook.Você não tem acesso a esta funcionalidade'),
                            life: 5000
                        });
                }).finally(() => {
                    this.getData(this.selectedOrg)
                })
        },
        updateData(val, id){
            this.webhookService.editConnectionWebHook(this.selectedOrg.id, val, id)
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppWebhook.Webhook alterado com sucesso!'),
                            life: 3000
                        });
                    }).catch((error) => {
                        if (!error.response || error.response.status !== 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.details,
                                life: 5000
                            });
                            return;
                        }
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppWebhook.Você não tem acesso a esta funcionalidade'),
                            life: 5000
                        });
                }).finally(() => {
                    this.getData(this.selectedOrg)
                })
        },
        getOrganizationFeatureFlags(val){
            this.orgFeatureFlags =  [];
            this.organizationsService.getOrganizationFeatureFlags(val.id).then((results) => {
                    let orgFeatureFlags = []
                    results.forEach(result => {
                        if (result === 'WEBHOOK_REAL_TIME') {
                            orgFeatureFlags.push('REAL_TIME')
                        }
                        if (result === 'WEBHOOK_HISTORICAL_DATA') {
                            orgFeatureFlags.push('HISTORICAL_DATA')
                        }
                    })
                    this.orgFeatureFlags = orgFeatureFlags;
                    this.getData(val);
                })
                .catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppWebhook.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppWebhook.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                    console.log(error)
                })
        },
        getData(val){
            this.webhookService.getStatusAndUrl(val.id).then((results) => {
                this.gridData = results;
            })
            .catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppWebhook.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWebhook.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                console.log(error)
            })
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.getOrganizationFeatureFlags(val)
        }
    },
    components: {
        Dropdown, AppWebhookConfig, AppWebhookTutorial, WebhookService
    },

}
</script>

<style scoped>
.title-webhook{
    font-size: 30px;
    margin: 10px auto 0 auto;
    color: #525252;
}

.menu-options{
    margin: 0 auto;
    display: flex;
    cursor: pointer;
    font-size: 15px;
}

.tutorial-option-active{
    width: 150px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 15px;
    font-weight: bold;
    color: #3CBEB4;
}

.tutorial-option-inactive{
    width: 150px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 15px;
}

.config-option-active{
    padding-bottom: 10px;
    padding-top: 15px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    color: #3CBEB4;
}

.config-option-inactive{
    padding-bottom: 10px;
    padding-top: 15px;
    width: 150px;
    text-align: center;
}

.line-style{
    width: 150px;
    height: 4px;
    background-color: #3CBEB4;
    margin: 0 auto;
    position: relative;
    right: 75px;
    transition: right 0.5s;
}

.container-content{
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 1440px){
    .container-content{
        width: 90%;
        margin: 0 auto;
    }
}
</style>
