export const PRIVILEGES = {

    //Devices
    USER_ORGANIZATION_DEVICE_VIEW: "USER_ORGANIZATION_DEVICE_VIEW",
    USER_ORGANIZATION_DEVICE_CREATE: "USER_ORGANIZATION_DEVICE_CREATE",
    USER_ORGANIZATION_DEVICE_EDIT: "USER_ORGANIZATION_DEVICE_EDIT",
    USER_ORGANIZATION_DEVICE_DELETE: "USER_ORGANIZATION_DEVICE_DELETE",
    USER_ORGANIZATION_DEVICE_ALL: "USER_ORGANIZATION_DEVICE_ALL",

    //Máquinas
    USER_ORGANIZATION_VEHICLE_VIEW: "USER_ORGANIZATION_VEHICLE_VIEW",
    USER_ORGANIZATION_VEHICLE_CREATE: "USER_ORGANIZATION_VEHICLE_CREATE",
    USER_ORGANIZATION_VEHICLE_EDIT: "USER_ORGANIZATION_VEHICLE_EDIT",
    USER_ORGANIZATION_VEHICLE_DELETE: "USER_ORGANIZATION_VEHICLE_DELETE",
    USER_ORGANIZATION_VEHICLE_ALL: "USER_ORGANIZATION_VEHICLE_ALL",

    //Operadores
    USER_ORGANIZATION_OPERATOR_VIEW: "USER_ORGANIZATION_OPERATOR_VIEW",
    USER_ORGANIZATION_OPERATOR_CREATE: "USER_ORGANIZATION_OPERATOR_CREATE",
    USER_ORGANIZATION_OPERATOR_EDIT: "USER_ORGANIZATION_OPERATOR_EDIT",
    USER_ORGANIZATION_OPERATOR_DELETE: "USER_ORGANIZATION_OPERATOR_DELETE",
    USER_ORGANIZATION_OPERATOR_ALL: "USER_ORGANIZATION_OPERATOR_ALL",

    //Usuários
    USER_ORGANIZATION_USER_VIEW: "USER_ORGANIZATION_USER_VIEW",
    USER_ORGANIZATION_USER_CREATE: "USER_ORGANIZATION_USER_CREATE",
    USER_ORGANIZATION_USER_EDIT: "USER_ORGANIZATION_USER_EDIT",
    USER_ORGANIZATION_USER_DELETE: "USER_ORGANIZATION_USER_DELETE",
    USER_ORGANIZATION_USER_ALL: "USER_ORGANIZATION_USER_ALL",
};


//TODO remover
export const ORGANIZATION_PRIVILEGES = {
    ORGANIZATION_REGISTER_DEVICES: "ORGANIZATION_REGISTER_DEVICES",
    ORGANIZATION_CREATE: "ORGANIZATION_CREATE",
    ORGANIZATION_MANAGE_DEVICES: "ORGANIZATION_MANAGE_DEVICES",
    ORGANIZATION_FARM: "ORGANIZATION_FARM",
    ORGANIZATION_VEHICLES: "ORGANIZATION_VEHICLES",
    ORGANIZATION_END_CUSTOMER: "ORGANIZATION_END_CUSTOMER",
};
export const USER_ORGANIZATION_PRIVILEGES = {
    USER_ORGANIZATION_OWNER: "USER_ORGANIZATION_OWNER",
    USER_ORGANIZATION_MANAGE: "USER_ORGANIZATION_MANAGE",
    USER_ORGANIZATION_FINANCIAL: "USER_ORGANIZATION_FINANCIAL",
    USER_ORGANIZATION_MANAGE_USERS: "USER_ORGANIZATION_MANAGE_USERS",
    USER_ORGANIZATION_MANAGE_VEHICLES: "USER_ORGANIZATION_MANAGE_VEHICLES",
    USER_ORGANIZATION_MANAGE_DEVICES: "USER_ORGANIZATION_MANAGE_DEVICES",
    USER_ORGANIZATION_MANAGE_CLIENTS: "USER_ORGANIZATION_MANAGE_CLIENTS",
    USER_ORGANIZATION_MANAGE_FARMS: "USER_ORGANIZATION_MANAGE_FARMS",
    USER_ORGANIZATION_MANAGE_DRIVERS: "USER_ORGANIZATION_MANAGE_DRIVERS"
};



