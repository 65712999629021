<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div style="width: 100%; display: flex; justify-content: space-between; padding: 20px 0;">
                    <div>

                    </div>
                    <app-button type="secondary" @handleClick="backToAlerts">
                        <i class="glyphicons glyphicons-arrow-left"></i>
                        <span>{{ $t('AppAlertsServiceOrders.Voltar') }}</span>
                    </app-button>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading"
                        filterDisplay="row" :filters.sync="filters" :sortOrder="-1" sortField="created_date"
                        stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                        :currentPageReportTemplate="$t('AppAlertsServiceOrders.currentPageReportTemplate')"
                        class="p-datatable-striped">
                        <template #header>
                            <div style="text-align: right">
                                <Button icon="pi pi-refresh" :label="$t('AppAlertsServiceOrders.Atualizar Lista')" class="p-button-rounded p-button-info" @click="getListFailures()"
                                        style="background-color: #2196F3; border-color: #2196F3;"/>
                                <Button icon="pi pi-plus" :label="$t('AppAlertsServiceOrders.Novo Relatório')" class="p-button-rounded p-button-success" @click="openDialog"
                                        style="background-color: #3CBEB4; border-color: #3CBEB4; margin-left: 10px;"/>
                            </div>
                        </template>
                        <template #empty>
                            {{$t('AppAlertsServiceOrders.Sem dados para exibir')}}
                        </template>
                        <template #loading>
                            {{$t('AppAlertsServiceOrders.Carregando relatórios, por favor aguarde')}}

                        </template>
                        <Column field="created_date" :header="$t('AppAlertsServiceOrders.Solicitação')" :styles="{'min-width':'15rem'}" headerClass="headerClass"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.created_date) }}</span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
                            </template>
                        </Column>
                        <Column field="start_date" :header="$t('AppAlertsServiceOrders.Inicio')" :styles="{'min-width':'15rem'}" headerClass="headerClass"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.start_date) }}</span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
                            </template>
                        </Column>
                        <Column field="end_date" :header="$t('AppAlertsServiceOrders.Fim')" :styles="{'min-width':'15rem'}" headerClass="headerClass"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.end_date) }}</span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
                            </template>
                        </Column>
                        <Column field="status" :header="$t('AppAlertsServiceOrders.Status')" :styles="{'min-width':'9rem'}" headerClass="headerClass"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span class="badge"
                                    :class="{
                                        'status-CLOSED': slotProps.data.status === 'Processado',
                                        'status-IN_PROGRESS': slotProps.data.status === 'Processando',
                                        'status-NEW': slotProps.data.status === 'Erro'
                                    }" style="font-weight: bold;">
                                    {{ slotProps.data.status }}
                                </span>
                            </template>
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
                            </template>
                        </Column>
                        <Column :styles="{'max-width':'3rem'}" headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <div>
                                    <Button v-if="slotProps.data.status === 'Processado'" icon="pi pi-download" class="p-button-rounded m-1"
                                        @click="downloadCsv(slotProps.data)" />
                                </div>
                            </template>
                        </Column>
                        <Column :styles="{'max-width':'3rem'}" headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <div>
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger m-1" @click="deleteCSV(slotProps.data)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <Dialog header="Novo relatório" :visible.sync="reportDialog" :modal="true">
                    <div style="width: 600px;">
                        <div style="font-size: 15px; margin-bottom: 5px;">{{ $t('AppAlertsServiceOrders.Selecione as organizações') }}</div>

                        <MultiSelect v-model="selectedOrgs" :options="organizations" optionLabel="display_name"
                                     :placeholder="$t('AppAlertsServiceOrders.Organizações')" :dataKey="'id'" :filter="true"
                                     style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> {{ $t('AppAlertsServiceOrders.Carregando, aguarde') }}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </MultiSelect>

                        <div style="font-size: 15px; margin-bottom: 5px;margin-top: 15px">{{ $t('AppAlertsServiceOrders.Selecione o período') }}</div>

                        <div style="display: flex; border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd;">
                            <div style="border-right: 1px solid #dddddd; width: 60%; padding-top: 3px;">
                                <Calendar v-model="newReportDate" selectionMode="range" :inline="true" style="width: calc(100% - 3px);" class="time-calendar">
                                    <template #footer>
                                        <div style="display: flex;text-align: center; margin-top: 20px">
                                            <div style="flex: 0 0 50%">
                                                <div>{{ $t('AppAlertsServiceOrders.Hora Inicial') }}</div>
                                                <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="start_time"/>
                                            </div>
                                            <div style="flex: 1">
                                                <div>{{ $t('AppAlertsServiceOrders.Hora Final') }}</div>
                                                <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="end_time"/>
                                            </div>
                                        </div>
                                    </template>
                                </Calendar>
                            </div>

                            <div style="width: 40%; text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column;">
                                <div style="height: 50%; width: 100%; align-items: center; justify-content: center; display: flex; flex-direction: column;
                                        border-bottom: 1px solid #dddddd;">
                                    <div style="font-size: 16px; font-weight: bold;">{{ $t('AppAlertsServiceOrders.Inicio') }}</div>
                                    <div style="font-size: 16px;">{{ newReportDate && newReportDate[0] ? dateHourFormat(Date.parse(newReportDate[0]) / 1000) : '-' }}</div>
                                </div>
                                <div style="height: 50%; width: 100%; align-items: center; justify-content: center; display: flex; flex-direction: column;">
                                    <div style="font-size: 16px; font-weight: bold;">{{ $t('AppAlertsServiceOrders.Fim') }}</div>
                                    <div style="font-size: 16px;">{{ newReportDate && newReportDate[1] ? dateHourFormat(Date.parse(newReportDate[1]) / 1000) : '-' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <div style="position: relative; bottom: 4px;">
                            <Button :label="$t('AppAlertsServiceOrders.Cancelar')" icon="pi pi-times" @click="reportDialog = false" class="p-button-danger"/>
                            <Button :label="$t('AppAlertsServiceOrders.Confirmar')" icon="pi pi-check" @click="sendNewReport" />
                        </div>
                    </template>
                </Dialog>
                <Dialog :visible.sync="deleteReport" :header="$t('AppAlertsServiceOrders.Deletar')" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                        <span>{{ $t('AppAlertsServiceOrders.Tem certeza que deseja deletar o relatório') }}</span>
                    </div>
                    <template #footer>
                        <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteReport = false"/>
                        <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="confirmDelete"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AlertsService from "../../../../services/AlertsService";
import MultiSelect from 'primevue/multiselect';
import OrganizationsService from "@/services/OrganizationsService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import DataTable from 'primevue/datatable';
import {router} from '../../../../router';
import Column from 'primevue/column';
import AppButton from "@/components/common/AppButton";
import InputText from "primevue/inputtext/InputText";
import Button from "primevue/button/Button";
import dateHourFormat from "@/mixins/DateMixin";
import Dialog from "primevue/dialog/Dialog";
import Calendar from "primevue/calendar/Calendar";
import {FilterMatchMode} from 'primevue/api';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';

export default {
    mixins: [orderCustomFieldByNaturalOrder, dateHourFormat],
    created() {
        this.organizationsService = new OrganizationsService();
        this.alertsService = new AlertsService();
    },
    mounted: function () {
        this.getOrganizations();
        this.getListFailures();
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.dateHourFormat(value).includes(filter);
        });
    },
    data() {
        return {
            filters: {
                'created_date': { value: null, matchMode: DATE_FILTER },
                'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'start_date': { value: null, matchMode: DATE_FILTER },
                'end_date': { value: null, matchMode: DATE_FILTER },
                'created_by': { value: null, matchMode: DATE_FILTER }
            },
            alertsService: null,
            loading: false,
            gridData: [],
            organizationsService: null,
            loadingOrgs: false,
            selectedOrgs: [],
            organizations: [],
            storedChosenOptions: {},
            reportDialog: false,
            newReportDate: null,
            start_time: new Date(1970, 0, 0, 0, 0, 0, 0),
            end_time: new Date(1970, 0, 0, 23, 59, 59, 0),
            deleteReport: false,
            itemSelectedToDelete: null
        }
    },
    methods: {
        backToAlerts() {
            router.push({name: 'alerts-machine'});
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('VEHICLES').then((results) => {
                this.populateOrgsList(results);
            }).catch((error) => {
                vm.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsServiceOrders.Operação falhou'),
                    detail: this.$t('AppAlertsServiceOrders.Não foi possível buscar organizações'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        downloadCsv(val){
            this.alertsService.downloadCsv(val.id)
                .then(response => {
                    this.loadCSV(response, val.file_name)
                }).catch((error) => {
                    console.log(error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsServiceOrders.Erro'),
                        detail: this.$t('AppAlertsServiceOrders.Não foi possível fazer o download, tente novamente mais tarde'),
                        life: 5000
                    });
            });
        },
        loadCSV(csvData, fileName) {
            let blob = new Blob([csvData], {type: 'text/csv'});
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        deleteCSV(val){
            this.itemSelectedToDelete = val
            this.deleteReport = true
        },
        confirmDelete(){
            this.deleteReport = false
            this.alertsService.deleteCsv(this.itemSelectedToDelete.id)
                .then(response => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsServiceOrders.Sucesso'),
                        detail: this.$t('AppAlertsServiceOrders.Relatório deletado com sucesso'),
                        life: 5000
                    });
                    this.getListFailures()
                }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsServiceOrders.Erro'),
                        detail: this.$t('AppAlertsServiceOrders.Não foi possível deletar o relatório'),
                        life: 5000
                    });
                });
        },
        getListFailures(){
            this.loading = true
            this.alertsService.getListFailures()
                .then(data => {
                    this.gridData = data;
                    this.gridData.forEach(element => {
                        element.status = element.status === 'PROCESSED' ? 'Processado' : element.status === 'PROCESSING' ? 'Processando' : 'Erro'
                    });
                })
                .catch(error => {
                    this.gridData = []
                    if (error.response) {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppAlertsServiceOrders.Você não tem acesso a essa funcionalidade'),
                                life: 5000
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppAlertsServiceOrders.Não foi possivel completar a operação, tente novamente'),
                                life: 5000
                            });
                        }
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsServiceOrders.Não foi possivel completar a operação, tente novamente'),
                            life: 5000
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                }
            );
        },
        openDialog(){
            this.newReportDate = null
            this.start_time = new Date(1970, 0, 0, 0, 0, 0, 0)
            this.end_time = new Date(1970, 0, 0, 23, 59, 59, 0)
            this.reportDialog = true
        },
        updateReportDate(val) {
            if (!val) {
                return
            }

            if (val[0]) {
                val[0].setHours(this.start_time.getHours());
                val[0].setMinutes(this.start_time.getMinutes());
            }

            if (val[1]) {
                val[1].setHours(this.end_time.getHours());
                val[1].setMinutes(this.end_time.getMinutes());
                val[1].setSeconds(59);
            }
        },
        sendNewReport(){

            if(this.selectedOrgs.length === 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsServiceOrders.Selecione ao menos uma organização'),
                    life: 5000
                });
                return
            }

            if(!this.newReportDate || !this.newReportDate[0] || !this.newReportDate[1]){
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsServiceOrders.Selecione o período!'),
                    life: 5000
                });
                return
            }

            if(this.newReportDate[0] > this.newReportDate[1]){
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsServiceOrders.Data final deve ser maior que a inicial!'),
                    life: 5000
                });
                return
            }

            let params = {
                start: Date.parse(this.newReportDate[0]) / 1000,
                end: Date.parse(this.newReportDate[1]) / 1000,
                orgs_ids: this.selectedOrgs.map(obj => obj.id)
            }

            this.alertsService.newReport(params)
                .then(data => {
                    this.getListFailures();
                    this.reportDialog = false
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },
    watch: {
        start_time(val) {
            if (this.newReportDate && this.newReportDate[0]) {
                this.newReportDate[0].setHours(val.getHours());
                this.newReportDate[0].setMinutes(val.getMinutes());
                this.newReportDate = [...this.newReportDate]
            }
        },
        end_time(val) {
            if (this.newReportDate && this.newReportDate[1]) {
                this.newReportDate[1].setHours(val.getHours());
                this.newReportDate[1].setMinutes(val.getMinutes());
                this.newReportDate[1].setSeconds(59);
                this.newReportDate = [...this.newReportDate]
            }
        },
        newReportDate(val) {
            this.updateReportDate(val);
        }
    },
    components: {
        MultiSelect,
        DataTable,
        Column,
        InputText,
        AppButton,
        Button,
        Dialog,
        Calendar
    },
    computed: {
        ...mapGetters([
            'getCurrentOrganization'
        ])
    },

}
</script>

<style scoped>

.time-calendar {
    margin-bottom: 5px;
    outline: 5px solid white;
    outline-offset: -2px;
}

.btn-status{
    border-radius: 17px;
    padding: 7px 12px;
    text-align: center;
    align-items: center;
    color: white;
}

.status-processed {
    background-color: rgb(22, 163, 74);
}

.status-processing {
    background-color: rgb(13, 137, 236);
}

.status-exception {
    background-color: rgb(192, 41, 41)
}

</style>

<i18n>
{
"pt-BR": {
"vehicles": "Veículos"
},
"en-US": {
"vehicles": "Vehicles"
}
}
</i18n>
