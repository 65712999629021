<template>
    <div id="app">
        <Toast position="top-right"/>
        <router-view/>
        <div v-if="loading"
             style="position:absolute; width: 100%; height:100%; top:0; left:0; z-index:10000; background-color:#f2f2f2">
            <div class="showbox">
                <div class="loader">
                    <svg class="circular" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                                stroke-miterlimit="10"/>
                    </svg>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="openModal" :style="{width: 'auto'}" :modal="true"
                style="text-align: center" position="top" @hide="dialogHidden">
            <ProgressSpinner v-if="loadingInvites"
                             style="display: flex;justify-content: center;align-items: center;height: 4rem"/>
            <div v-else-if="inviteDialogData">
                <i class="pi pi-envelope" style="font-size: 96px"></i>
                <div class="mt-4">
                    <b> {{inviteDialogData.invited_by}} lhe convidou para ser membro de {{inviteDialogData.org_name}}</b>
                </div>
                <br>
                <div><em>Gostaria de aceitar o convite?</em></div>
            </div>
            <template #footer>
                <div v-if="!loadingInvites && inviteDialogData" class="row">
                    <div @click="handleDenyInvite" class="col-4 deny-invite"
                         style="display:flex; align-items: center;justify-content: start">
                        <em><u>Não</u></em>
                    </div>
                    <div class="col-4 offset-4">
                        <app-button type="primary" @handleClick="handleAcceptInvite">Sim</app-button>
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>
<script>
import Toast from 'primevue/toast';
import {router} from "./router";
import auth from './services/auth'
import Dialog from 'primevue/dialog';
import UserOrgInvitesService from "@/services/UserOrgInvitesService";
import AppButton from "@/components/common/AppButton";
import ProgressSpinner from 'primevue/progressspinner';
import UsersService from "@/services/UsersService";
import {mapGetters} from "vuex";

export default {
    created() {
        document.title = process.env.VUE_APP_THEME_PAGE_TITLE;
        this.setLanguage()
        // Basic setup of MSAL helper with client id, or give up
        if (process.env.VUE_APP_CLIENT_ID) {
            auth.configure(process.env.VUE_APP_CLIENT_ID)

            // Restore any cached or saved local user
            this.user = auth.user()

        } else {
            this.error = 'VUE_APP_CLIENT_ID is not set, the app will not function! 😥'
        }
    },
    data: () => ({
        loading: true,
        openModal: false,
        invitesTimeout: null,
        userOrgInvitesService: null,
        currentInvitesPage: 0,
        loadingInvites: false,
        inviteDialogData: null,
        usersService: null
    }),
    mounted() {
        this.usersService = new UsersService();
        this.userOrgInvitesService = new UserOrgInvitesService();
        setTimeout(() => {
            this.loading = false
        }, 2000)
        this.invitesTimeout = setInterval(this.showInvitesIfLoggedIn, 2000)

        this.$store.dispatch('tryAutoLogin');
        if (!this.isAuthenticated && router.currentRoute.name !== 'forgot-password' &&
            router.currentRoute.name !== 'change-password' && router.currentRoute.name !== 'invite'
            && router.currentRoute.name !== 'login'  && router.currentRoute.name !== 'signup') {
            this.$store.dispatch('logout');
            router.push({name: 'login'});
        }
    },
    methods: {
        setLanguage() {
            let lang = localStorage.getItem(process.env.VUE_APP_I18N_LOCALE);

            if (!lang) {
                lang = 'pt'
            }

            this.$i18n.locale = lang;
        },
        dialogHidden() {
            this.usersService.getOrgFeatureFlags().then((response) => {
                this.$store.dispatch('storeOrganizationFeatureFlags', response);
            }).catch((error) => {
                console.log(error)
            })
        },
        handleAcceptInvite() {
            this.loadingInvites = true;
            this.userOrgInvitesService.acceptInvite(this.inviteDialogData.id)
                .then((response) => {
                    if (this.inviteDialogData.total_pages - 1 > this.inviteDialogData.current_page) {
                        this.getInvites();
                        return;
                    }

                    this.openModal = false;
                }).catch((error) => {
                console.log(error);
                this.openModal = false;
            })
        },
        handleDenyInvite() {
            this.userOrgInvitesService.denyInvite(this.inviteDialogData.id)
                .then((response) => {
                    if (this.inviteDialogData.total_pages - 1 > this.inviteDialogData.current_page) {
                        this.getInvites();
                        return;
                    }

                    this.openModal = false;
                }).catch((error) => {
                console.log(error);
                this.openModal = false;
            })
        },
        showInvitesIfLoggedIn() {
            if (!this.isLoggedIn()) {
                return;
            }

            this.getInvites();
            clearInterval(this.invitesTimeout)
        },
        getInvites() {
            this.userOrgInvitesService.findInvitesByPage({'page': this.currentInvitesPage})
                .then((response) => {
                    if (response.total_pages > 0) {
                        this.openModal = true;
                        this.inviteDialogData = response;
                    } else {
                        this.openModal = false;
                    }
                }).catch((error) => {
                console.log(error);
                this.openModal = false;
            }).finally(() => {
                this.loadingInvites = false;
            })
        },
        isLoggedIn() {
            const data = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
            if (!data) {
                return false;
            }

            if (!data.access_token || !data.refresh_token) {
                return false;
            }

            if (Math.floor(Date.now() / 1000) >= data.expiration) {
                return false;
            }

            return true;
        },
    },
    components: {AppButton, Toast, Dialog, ProgressSpinner},
    computed: {
        ...mapGetters([
            'isAuthenticated'
        ]),
    },
}
</script>

<style lang="scss">

$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

$width: 100px;

.loader {
    position: relative;
    margin: 0 auto;
    width: $width;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: $red;
    }
    40% {
        stroke: $blue;
    }
    66% {
        stroke: $green;
    }
    80%,
    90% {
        stroke: $yellow;
    }
}

.showbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.deny-invite {
    font-size: 14px;
    color: inherit;
}

.deny-invite:hover {
    font-weight: bold;
    cursor: pointer;
}
</style>
