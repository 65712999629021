<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <Dropdown v-model="selectedOrg" :options="orgsList"
                          optionLabel="display_name" :placeholder="$t('AppOperationsCenterConnectionCallback.Organização')"
                          class="auto-center" :dataKey="'id'"
                          :filter="true"
                          style="width: 250px">
                    <template #option="slotProps" style="width: 500px">
                        <div v-if="loadingOrgs"> Carregando, aguarde</div>
                        <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                            -
                        </div>
                        <div style="padding-right: 20px" v-else>
                            {{ slotProps.option.display_name }}
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="kt-portlet__body no-padding">
                <div v-show="!selectedOrg" class="orgs-list">
                    <h3>
                        {{
                            $t('AppOperationsCenterConnectionCallback.Selecione uma organização para gerenciar conexão com o Operations Center TM')
                        }}
                    </h3>
                </div>
                <div v-show="loadingJdOrgs">
                    <ProgressSpinner style="display: flex; justify-content:center"/>
                </div>
                <div v-show="!loadingJdOrgs && selectedOrg" class="orgs-list">
                    <div class="row connection-info-card" style="border: 0px solid gray;border-radius: 5px;">
                        <div class="col-sm-12 col-md-8 user-info-card">
                            <div class="row" style="width: 100%">
                                <div class="col">
                                    {{ $t('AppOperationsCenterConnectionCallback.Sessão atual') }}:
                                    <br><b>{{ subFromToken }}</b>
                                    <div class="mt-1">
                                        {{ $t('AppOperationsCenterConnectionCallback.Data de Criação') }}:
                                        <br><b>{{ authTime ? dateHourFormat(authTime) : 'N/D' }}</b>
                                    </div>
                                </div>
                                <div class="col">
                                    <app-button type="secondary" style=" position: absolute; right: 0px;top: 5px"
                                                @handleClick="endSessionDialog = true">{{ $t('AppOperationsCenterConnectionCallback.Encerrar sessão')}}
                                    </app-button>
                                </div>
                            </div>

                            <div style="width: 100%" class="oc-info mt-3">
                                <p style="margin-bottom: 0px">{{
                                        $t('AppOperationsCenterConnectionCallback.Esta sessão do Operations Center™ deve ter permissão para compartilhamento de dados com empresas de software conectadas')
                                    }}🛈</p>

                                <span v-if="!isScreenMobile" class="tooltip-content">
                                    <img :src="helpGifTask" alt="help-drag-crop" width="450" height="425"/>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4"
                             style="position:relative; display: flex; align-items: center;min-height: 100%;padding: 0;">
                            <div class="card">
                                <div style="float: left; font-size: 1rem; color: grey">
                                    {{ $t('AppOperationsCenterConnectionCallback.Organização IoTag') }}
                                </div>
                                <div class="mt-2" v-if="selectedOrg">{{ selectedOrg.name }}</div>
                                <i class="pi pi-users" style="font-size: 2rem; margin-top: 1.5rem"></i>
                            </div>

                        </div>
                    </div>

                    <div class="mt-2">
                        <img :src="showLinkIcon ? linkIcon : unlinkIcon" alt="link-unlink-org" height="50" width="50">
                    </div>

                    <div v-show="linkedOrg && !loadingJdOrgs && selectedOrg" class="row connection-info-card mt-2">
                        <div class="col-sm-12 col-md-8 user-info-card" style="width: 100%">
                            <div class="row" style="width: 100%">
                                <div class="col">
                                    {{ $t('AppOperationsCenterConnectionCallback.Sessão que realizou atrelamento') }}:
                                    <br><b>{{ subFromTokenLinkedOrg }}</b>
                                    <div class="mt-3">
                                        {{ $t('AppOperationsCenterConnectionCallback.Data de Atrelamento') }}: <br><b
                                        v-if="linkedOrg">{{ linkedOrg.created_date ? dateHourFormat(linkedOrg.created_date) : 'N/D' }}</b>
                                    </div>
                                </div>
                                <div class="col" @mouseover="showLinkIcon = false" @mouseout="showLinkIcon = true">
                                    <app-button type="secondary" style=" position: absolute; right: 0px;top: 5px"
                                                @handleClick="unlinkButtonClicked()">{{
                                            $t('AppOperationsCenterConnectionCallback.Desatrelar Organização')
                                        }}
                                    </app-button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4"
                             style="position:relative; display: flex; align-items: center;min-height: 100%;padding: 0">
                            <div class="card">
                                <div style="float: left; font-size: 1rem; color: grey">
                                    {{ $t('AppOperationsCenterConnectionCallback.Organização Operations Center') }}
                                </div>
                                <div class="mt-2" v-if="linkedOrg">{{ linkedOrg.name }}</div>
                                <i class="pi pi-users" style="font-size: 2rem; margin-top: 1.5rem"></i>
                            </div>

                        </div>
                    </div>

                    <div v-show="!linkedOrg && !loadingJdOrgs && selectedOrg" class="activities-list-panel mt-2"
                         style="position:relative;">
                        <DataTable :value="organizationsFromJohnDeere" :lazy="true" :paginator="true" :rows="10"
                                   ref="dt" dataKey="id"
                                   :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                   class="p-datatable-striped" :globalFilterFields="['id','name']"
                                   responsiveLayout="scroll">
                            <template #header>
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filterValue" :placeholder="$t('AppOperationsCenterConnectionCallback.Buscar por nome')"
                                               @keyup.enter.native="onEnterFilter"/>
                                </span>
                            </template>
                            <Column field="id" header="id" ref="id" headerClass="headerClass"
                                    bodyClass="bodyClass" :sortable="false"/>

                            <Column field="name" :header="$t('AppOperationsCenterConnectionCallback.Nome')" ref="name"
                                    headerClass="headerClass"
                                    bodyClass="bodyClass" :sortable="false"/>

                            <Column field="member" :header="$t('AppOperationsCenterConnectionCallback.É membro?')"
                                    ref="member" headerClass="headerClass"
                                    bodyClass="bodyClass" :sortable="false">
                                <template #body="slotProps">
                                    <span :class="'badge ' + (slotProps.data.member ? 'member' : 'not-member')">
                                        {{ slotProps.data.member ? $t('AppOperationsCenterConnectionCallback.membro') : $t('AppOperationsCenterConnectionCallback.não membro') }}
                                    </span>
                                </template>
                            </Column>

                            <Column :headerStyle="{maxWidth: '4rem', padding: '0px'}"
                                    bodyClass="bodyClass"
                                    :bodyStyle="{maxWidth: '4rem', padding: '0px'}">
                                <template #body="slotProps">
                                    <Button v-if="!isOrgConnected(slotProps.data)" @click="connectOrg(slotProps.data)"
                                            class="link-button">
                                        {{ $t('AppOperationsCenterConnectionCallback.Habilitar Conexão') }}
                                    </Button>
                                    <Button v-else @click="openSaveDialog(slotProps.data)"
                                            class="p-button-success link-button"
                                            @mouseover="showLinkIcon = true" @mouseout="showLinkIcon = false">
                                        {{ $t('AppOperationsCenterConnectionCallback.Atrelar Organização') }}
                                    </Button>
                                </template>
                            </Column>

                        </DataTable>


                    </div>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="saveDialog" :style="{width: '450px', fontSize: '14px'}" :header="$t('AppOperationsCenterConnectionCallback.Conectar')" :modal="true">
            <div v-if="linkedOrg" style="margin-bottom: 1rem; font-weight: bold">{{ getConnectionOrgsNames() }}</div>

            <div>{{ displayConnectionsDetails() }}</div>

            <template #footer>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Cancelar')" icon="pi pi-times"
                        class="p-button-text" @click="saveDialog = false"/>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Salvar')" icon="pi pi-check"
                        class="p-button-text" @click="saveConnections"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="deleteLinkDialog" :style="{width: '450px', fontSize: '14px'}" :header="$t('AppOperationsCenterConnectionCallback.Desatrelar')"
                :modal="true">

            <div v-if="selectedOrg && linkedOrg">{{ $t('AppOperationsCenterConnectionCallback.Você está destrelando')}}  {{ selectedOrg.name }} ({{ $t('AppOperationsCenterConnectionCallback.organização') }} IoTag) {{$t('AppOperationsCenterConnectionCallback.de') }} {{ linkedOrg.name }} ({{$t('AppOperationsCenterConnectionCallback.organização') }} Operations Center™)
            </div>

            <template #footer>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Cancelar')" icon="pi pi-times"
                        class="p-button-text" @click="deleteLinkDialog = false"/>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Salvar')" icon="pi pi-check"
                        class="p-button-text" @click="deleteLink"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="endSessionDialog" :style="{width: '450px', fontSize: '14px'}" header="ATENÇÃO!"
                :modal="true">

            <div>
                {{ $t('AppOperationsCenterConnectionCallback.Você está encerrando sua sessão do Operations Center')}}
            </div>

            <template #footer>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Cancelar')" icon="pi pi-times" class="p-button-text" @click="endSessionDialog = false"/>
                <Button :label="$t('AppOperationsCenterConnectionCallback.Encerrar Sessão')" icon="pi pi-check" class="p-button-text" @click="endSession"/>
            </template>
        </Dialog>
    </div>
</template>

<script>

import JohnDeereService from "@/services/JohnDeereService";
import OrganizationsService from "@/services/OrganizationsService";
import OperationsCenterService from "@/services/OperationsCenterService";

import {router} from "@/router";
import jwt_decode from "jwt-decode";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Column from 'primevue/column';
import Dropdown from "primevue/dropdown";
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import AppButton from "@/components/common/AppButton";
import {mapGetters} from "vuex";
import dateHourFormat from "@/mixins/DateMixin";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";

export default {
    mixins: [orderCustomFieldByNaturalOrder, dateHourFormat],
    components: {AppButton, Dropdown, RadioButton, Dialog, Button, ProgressSpinner, Column, DataTable, InputText},
    mounted() {
        this.johnDeereService = new JohnDeereService();
        this.organizationsService = new OrganizationsService();
        this.operationsCenterService = new OperationsCenterService();
        this.postGetToken();
        this.loadLocalStorageFilter();
    },
    data() {
        return {
            operationsCenterService: null,
            johnDeereService: null,
            authenticationInfo: null,
            organizationsService: null,
            orgsList: [],
            selectedOrg: null,
            loadingOrgs: false,
            loadingJdOrgs: false,
            linkedOrg: null,
            organizationsFromJohnDeere: [],
            saveDialog: false,
            storedChosenOptions: {},
            filters: {
                'id': {value: '', matchMode: 'contains'},
                'name': {value: '', matchMode: 'contains'}
            },
            loading: false,
            totalRecords: 0,
            filterValue: null,
            startOffset: 0,
            orgToLink: null,
            deleteLinkDialog: false,
            subFromToken: 'N/D',
            helpGifTask: require("@/assets/images/operations-center/operations-center-connection.gif"),
            linkIcon: require("@/assets/images/operations-center/link_icon.png"),
            unlinkIcon: require("@/assets/images/operations-center/unlink_icon.png"),
            showLinkIcon: false,
            endSessionDialog: false,
            authTime: null,
            subFromTokenLinkedOrg: null
        }
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        isScreenMobile: function () {
            return window.screen.width < 1024;
        }
    },
    watch: {
        linkedOrg: {
            deep: true,
            handler(val) {
                this.showLinkIcon = (val != null);
            }
        },
        selectedOrg: {
            deep: true,
            handler(val) {
                this.getConnectedOrganization(this.selectedOrg.id);
                this.saveChosenOptionsToLocalStorage()
            }
        }
    },
    methods: {
        loadLocalStorageFilter() {
            this.filterValue = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_OC_DATATABLE_FILTER);
        },
        onPage(event) {
            this.startOffset = event.page * 10
            this.getJohnDeereOrganizations();
        },
        onEnterFilter() {
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_OC_DATATABLE_FILTER, this.filterValue);
            this.getJohnDeereOrganizations()
        },
        unlinkButtonClicked() {
            this.deleteLinkDialog = true;
        },
        connectOrg(org) {
            let selectOrgLink = false;
            org.links.forEach(link => {
                if (link.uri.includes("/select-organizations")) {
                    selectOrgLink = link.uri;
                }
            })
            window.location.replace(selectOrgLink + "?redirect_uri=" + encodeURI(window.location.href))
        },
        isOrgConnected(org) {
            return org.is_connection_allowed;
        },
        postGetToken() {
            let code = this.$route.query.code;

            let payload = {
                'redirect_uri': encodeURI(window.location.href).split('?')[0],
                'code': code,
                'user_id': this.user.user_id
            }

            this.johnDeereService.requestNewToken(payload).then((response) => {
                this.authenticationInfo = response;
                try {
                    this.subFromToken = jwt_decode(response.access_token).sub ? jwt_decode(response.access_token).sub : 'N/D';
                    this.authTime = jwt_decode(response.access_token).auth_time;
                } catch (err) {
                    console.log(err)
                }

                this.getIotagOrgs();
            }).catch((error) => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Operação falhou'),
                    detail: this.$t('AppOperationsCenterConnectionCallback.O serviço do Operations Center está indisponível'),
                    life: 5000
                });
                router.push({name: 'operations-center-connection'});
            })
        },
        getIotagOrgs() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('OPERATIONS_CENTER_CONNECTION').then((results) => {

                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    console.log(error)
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "display_name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];
        },
        getJohnDeereOrganizations() {
            let params = {
                'access_token': this.authenticationInfo.access_token,
                'org_name': this.filterValue,
                'page_start': this.startOffset
            }

            this.loadingJdOrgs = true;
            this.johnDeereService.getOrganizations(this.selectedOrg.id, params).then((response) => {
                this.totalRecords = response.total
                this.organizationsFromJohnDeere = response.values;
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                router.push({name: 'settings'});
            }).finally(() => {
                this.loadingJdOrgs = false;
            })
        },
        openSaveDialog(orgToLink) {
            this.orgToLink = orgToLink;
            this.saveDialog = true;
        },
        saveConnections() {
            let postData = {
                'user_id': this.user.user_id,
                'jd_organization_id': this.orgToLink ? this.orgToLink.id : null
            }

            this.saveDialog = false

            this.johnDeereService.setOrgsLink(this.selectedOrg.id, postData).then((response) => {
                this.$toast.add({
                    severity: 'success',
                    summary:this.$t('AppOperationsCenterConnectionCallback.Operação bem-sucedida'),
                    detail: this.$t('AppOperationsCenterConnectionCallback.Organizações conectadas'),
                    life: 5000
                });
                this.getConnectedOrganization(this.selectedOrg.id);
            }).catch((error) => {
                if (!error.response) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }

                if (error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: error.response.data.details,
                        life: 5000
                    });
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            })
        },
        getConnectedOrganization(orgId) {
            this.linkedOrg = null;
            this.totalRecords = 0;
            this.organizationsFromJohnDeere = [];

            let params = {
                'access_token': this.authenticationInfo.access_token,
            }

            this.loadingJdOrgs = true;
            this.johnDeereService.getConnectedOrganization(orgId, params).then((response) => {
                this.linkedOrg = response;
                this.subFromTokenLinkedOrg = jwt_decode(response.auth_token).sub ? jwt_decode(response.auth_token).sub : 'N/D';
                this.loadingJdOrgs = false;
            }).catch((error) => {
                if (!error.response) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    this.loadingJdOrgs = false;
                    return;
                }

                if (error.response.status === 404) {
                    this.getJohnDeereOrganizations();
                    return
                }

                if (error.response.status === 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                }
                this.loadingJdOrgs = false;
            })
        },
        getConnectionOrgsNames() {
            if (!this.selectedOrg || !this.orgToLink) {
                return;
            }

            return this.selectedOrg.name + " <=> " + this.orgToLink.name;
        },
        displayConnectionsDetails() {
            return this.$t("AppOperationsCenterConnectionCallback.Você está criando uma nova conexão");
        },
        saveChosenOptionsToLocalStorage() {
            if (!this.storedChosenOptions) {
                this.storedChosenOptions = {}
            }

            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        deleteLink() {
            this.johnDeereService.unlinkOrgs(this.selectedOrg.id).then((response) => {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Operação bem-sucedida'),
                    detail: this.$t('AppOperationsCenterConnectionCallback.Organizações desatreladas'),
                    life: 5000
                });

                this.getConnectedOrganization(this.selectedOrg.id);
            }).catch((error) => {
                if (!error.response) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }

                if (error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: error.response.data.details,
                        life: 5000
                    });
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnectionCallback.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.deleteLinkDialog = false
            })
        },
        endSession() {
            let params = {
                'access_token': this.authenticationInfo.access_token,
            }

            this.johnDeereService.endSession(params).then((response) => {
                this.$toast.add({
                    severity: 'success',
                    summary:this.$t('AppOperationsCenterConnectionCallback.Operação bem-sucedida'),
                    detail: 'Sessão finalizada',
                    life: 5000
                });

                let theTop = ((screen.height / 2) - (500 / 2)) / 2;
                let theLeft = (screen.width / 2) - (500 / 2);
                let features = 'height=700,width=700,top=' + theTop + ',left=' + theLeft + ',toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1';

                window.open('https://persistent-header.deere.com/logout?target=https://map.deere.com', '_blank', features)
                router.push({name: 'settings'});
            }).catch((error) => {
                if (!error.response) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCenterConnectionCallback.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: error.response.data.details,
                    life: 5000
                });
            })
        }
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

#horizontal-line {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

#horizontal-span {
    background: inherit;
    padding: 0 10px;
}

.card {
    padding-top: 10px;
    padding-bottom: 20px;
    color: #4b4b4b;
    border-radius: 0 5px 5px 0;
    font-size: 20px;
    background-color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    border: 2px solid gray;
    border-left: 0 !important;
}

.badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.not-member {
    background-color: #ffcdd2 !important;
    color: #c63737 !important;
}

.member {
    background-color: #c8e6c9 !important;
    color: #256029 !important;
}

.link-button {
    border: 1px solid gray;
    border-radius: 0.25rem;
    margin-right: 1rem;
    margin: 1px;
}

.link-button:hover {
    cursor: pointer;
}

.container {
    margin: 0 auto;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

select,
input[type="checkbox"] {
    margin-top: 5px;
}

.orgs-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

@media (min-width: 1024px) {
    .activities-list-panel {
        max-width: 55%;
        width: 55%;
        display: flex;
        flex-direction: column;
        background-color: white;
        color: black;
        //padding: 1rem;
    }

    .connection-info-card {
        max-width: 55%;
        width: 55%
    }

}

@media (max-width: 1024px) {
    .activities-list-panel {
        width: 80%;
        display: flex;
        flex-direction: column;
        background-color: white;
        color: black;
        //padding: 1rem;
    }

    .connection-info-card {
        width: 100%;
        max-width: 100% !important;
    }

    .card {
        padding-top: 10px;
        padding-bottom: 20px;
        color: #4b4b4b;
        border-radius: 5px;
        font-size: 20px;
        background-color: #fff;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        min-width: 25rem;
        border: 1px solid gray !important;

    }

}

.connection-info-card {
    min-height: 11rem !important;
}

.oc-info {
    position: relative;
    display: inline-block;
    cursor: help;
}

.oc-info .tooltip-content {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 30%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.oc-info .tooltip-content::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.oc-info:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.user-info-card {
    position: relative;
    padding-bottom: 0rem;
    border: 1px solid gray;
    padding: 0.5rem;
    border-radius: 5px 0 0 5px;
    color: black;
    background-color: white;
}

</style>
