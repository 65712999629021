<template>
    <div class="work-options-wrapper"
         :style="{width: editing ? '270px' : '180px', height: editing ? '470px' : '310px'}">
        <div style="margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center">
            <div>
                {{ $t('AppTasksWorkOptions.LEGENDA') }} {{getSymbol()}}
            </div>
            <Button :icon="editButtonIcon" class="p-button-rounded p-button-text p-button-plain p-button-sm"
                    style="color: white" @click="editClicked" v-if="edit_enabled"/>
        </div>
        <div v-show="!loading">
            <div v-for="(step, index) in rateStepsEdit" style="height: 35px;">
                <div style="display: flex;min-height: 100%">
                    <div style="min-width: 60px;text-align: right" v-if="!editing">
                        {{ displayStepValue(step.value) }}
                    </div>
                    <input v-else v-model="step.value" style="max-width: 60px;margin-top: 4px" type="number" step="0.01">
                    <div class="work-color" :class="{workColorDisabled: !step.visible}"
                         :style="{borderColor: getBorderColor(step), borderTopWidth: '2px', borderBottomWidth: '0px'}"
                         @mouseover="setCurrentStep(index)" v-on:click="colorPickerClicked(index)">
                        <ColorPicker v-model="step.color" :disabled="!editing" @input="change"/>
                    </div>
                    <div style="min-width: 80px;margin-left: 5px;min-height: 100%;margin-top: 5px; position: relative;
                        overflow: hidden">
                        <div id="slide"
                             :style="{maxWidth: step.percentage + '%', backgroundColor: 'gray', minHeight: '100%',
                        position: 'relative', overflow: 'visible', borderRadius: '1px'}">
                            &nbsp;
                        </div>
                        <span style="position: absolute; bottom: 0; left: 0;">
                                {{ step.percentage }}%
                            </span>
                    </div>
                </div>
            </div>
            <div v-if="editing" class="mt-3">
                <label class="mt-4">{{$t('AppTasksWorkOptions.Opacidade')}}: {{ opacity }}%</label>
                <Slider v-model="opacity" style="border: 1px solid gray;" :disabled="!editing"/>

                <label class="mt-4">{{$t('AppTasksWorkOptions.Contorno')}}: {{ stroke }}px</label>
                <Slider v-model="stroke" style="border: 1px solid gray;" :disabled="!editing" :max="5"/>
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from 'primevue/colorpicker';
import Slider from 'primevue/slider';
import AppButton from "@/components/common/AppButton";
import Button from 'primevue/button';

export default {
    mounted() {
        this.rateStepsEdit = JSON.parse(JSON.stringify(this.rate_steps));
    },
    data() {
        return {
            rateStepsEdit: [],
            opacity: 100,
            stroke: 0,
            editing: false,
            loading: false,
            currentStepIndex: 0
        }
    },
    props: {
        rate_steps: {
            type: Array
        },
        works: {
            type: Array
        },
        edit_enabled: {
            type: Boolean
        },
        symbol: {
            type: String
        }
    },
    methods: {
        getSymbol() {
            if (!this.symbol) {
                return ''
            }

            return '(' + this.symbol + ')';
        },
        colorPickerClicked(index) {
            if (this.editing) {
                return;
            }

            this.rateStepsEdit[index].visible = !this.rateStepsEdit[index].visible
            this.$emit('optionsChanged', {
                rate_steps: JSON.parse(JSON.stringify(this.rateStepsEdit)),
                opacity: this.opacity, stroke: this.stroke
            });
        },
        displayStepValue(value) {
            if (!value) {
                return '';
            }
            return value.toFixed(2);
        },
        getPercentageInStep(upperLimit, lowerLimit) {
            let valuesInRange = 0
            let totalArea = 0;
            this.works.forEach(work => {
                if (work.value <= upperLimit && work.value > lowerLimit) {
                    valuesInRange += work.area;
                }
                totalArea += work.area;
            });

            return parseFloat((valuesInRange / totalArea * 100).toFixed(1));
        },
        setCurrentStep(stepIndex) {
            this.currentStepIndex = stepIndex;
        },
        change(event) {
            this.rateStepsEdit[this.currentStepIndex].color = "#" + event
        },
        getBorderColor(step) {
            return step.color;
        },
        editClicked() {
            this.rateStepsEdit.forEach(rateStep => {
                rateStep.value = parseFloat(rateStep.value)
                rateStep.visible = true;
            })

            if (this.rateStepsEdit.length === 1) {
                this.editing = !this.editing;
                if (!this.editing) {
                    this.$emit('optionsChanged', {
                        rate_steps: JSON.parse(JSON.stringify(this.rateStepsEdit)),
                        opacity: this.opacity, stroke: this.stroke
                    });
                }
                return
            }

            for (let i = 0; i < this.rateStepsEdit.length; i++) {
                if (i === 0) {
                    this.rateStepsEdit[i].percentage = this.getPercentageInStep(Number.MAX_VALUE,
                        this.rateStepsEdit[i + 1].value)
                    continue;
                }

                if (i === this.rateStepsEdit.length - 1) {
                    this.rateStepsEdit[i].percentage = this.getPercentageInStep(this.rateStepsEdit[i].value, 0)
                    continue;
                }

                this.rateStepsEdit[i].percentage = this.getPercentageInStep(this.rateStepsEdit[i].value,
                    this.rateStepsEdit[i + 1].value)
            }

            this.editing = !this.editing;

            if (!this.editing) {
                this.$emit('optionsChanged', {
                    rate_steps: JSON.parse(JSON.stringify(this.rateStepsEdit)),
                    opacity: this.opacity, stroke: this.stroke
                });
            }
        },
    },
    computed: {
        editButtonIcon: function () {
            return this.editing ? 'pi pi-check' : 'pi pi-pencil'
        }
    },
    watch: {
        rate_steps: async function (val) {
            this.rateStepsEdit = JSON.parse(JSON.stringify(val));
        }
    },
    components: {
        ColorPicker, Slider, AppButton, Button
    }
}
</script>

<style lang="scss">

.work-color > .p-colorpicker > .p-inputtext {
    border: none !important;
    border-radius: 1px !important;
    height: 100% !important;
}

.work-color > .p-colorpicker > .p-disabled {
    opacity: 1 !important;
}

.work-color > .p-colorpicker {
    height: 100% !important;
    width: 100% !important;
    padding-left: 3px;
    margin-top: -1px;
}

</style>

<style scoped>

.work-color:hover {
    cursor: pointer;
}

.workColorDisabled {
    filter: brightness(30%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input {
    max-height: 20px;
    color: white;
    background: rgba(41, 50, 60, .8);
}

.work-color {
    min-height: 80%;
    max-width: 20px;
    margin-top: 5px;
    margin-left: 3px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    overflow: visible;
}

.work-options-wrapper {
    background: rgba(41, 50, 60, .8);
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px;
    color: white;
    overflow: hidden;
    position: relative;
    transition: width 1s, height 1s;
}

#slide {
    left: -100px;
    -webkit-animation: slide 2s forwards;
    -webkit-animation-delay: 0.2s;
    animation: slide 2s forwards;
    animation-delay: 0.2s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

</style>
