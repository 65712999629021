import i18n from "../i18n";


export const BR = () => {
    return {
        //https://www.primefaces.org/primevue/showcase-v2/#/locale
        firstDayOfWeek: 0,
        dayNames: [
            i18n.t('Calendar.Domingo'),
            i18n.t('Calendar.Segunda'),
            i18n.t('Calendar.Terça'),
            i18n.t('Calendar.Quarta'),
            i18n.t('Calendar.Quinta'),
            i18n.t('Calendar.Sexta'),
            i18n.t('Calendar.Sábado')
        ],
        dayNamesShort: [
            i18n.t('Calendar.Dom'),
            i18n.t('Calendar.Seg'),
            i18n.t('Calendar.Ter'),
            i18n.t('Calendar.Qua'),
            i18n.t('Calendar.Qui'),
            i18n.t('Calendar.Sex'),
            i18n.t('Calendar.Sáb')
        ],
        dayNamesMin: [
            i18n.t('Calendar.Dom'),
            i18n.t('Calendar.Seg'),
            i18n.t('Calendar.Ter'),
            i18n.t('Calendar.Qua'),
            i18n.t('Calendar.Qui'),
            i18n.t('Calendar.Sex'),
            i18n.t('Calendar.Sáb')
        ],
        monthNames: [
            i18n.t('Calendar.Janeiro'),
            i18n.t('Calendar.Fevereiro'),
            i18n.t('Calendar.Março'),
            i18n.t('Calendar.Abril'),
            i18n.t('Calendar.Maio'),
            i18n.t('Calendar.Junho'),
            i18n.t('Calendar.Julho'),
            i18n.t('Calendar.Agosto'),
            i18n.t('Calendar.Setembro'),
            i18n.t('Calendar.Outubro'),
            i18n.t('Calendar.Novembro'),
            i18n.t('Calendar.Dezembro')
        ],
        monthNamesShort: [
            i18n.t('Calendar.Jan'),
            i18n.t('Calendar.Fev'),
            i18n.t('Calendar.Mar'),
            i18n.t('Calendar.Abr'),
            i18n.t('Calendar.Mai'),
            i18n.t('Calendar.Jun'),
            i18n.t('Calendar.Jul'),
            i18n.t('Calendar.Ago'),
            i18n.t('Calendar.Set'),
            i18n.t('Calendar.Out'),
            i18n.t('Calendar.Nov'),
            i18n.t('Calendar.Dez')
        ],
        today: i18n.t('Calendar.Hoje'),
        clear: i18n.t('Calendar.Limpar'),
        dateFormat: 'mm/dd/yy',
        weekHeader: 'Wk'
    };
};
