<template>
    <vue-highcharts :options="chart_options_activity" ref="child" class="chart"/>
</template>

<script>
    import {Chart} from 'highcharts-vue';
    import Highcharts from "highcharts";
    import theme from "../../../assets/styles/charts/default";
    import i18n from "@/i18n";
    import HighchartsNoData from 'highcharts/modules/no-data-to-display';
    HighchartsNoData(Highcharts);

    export default {
        props: {
            chart_data: {
                type: Array,
                required: true
            },
            series_color: {
                type: String,
                default: '#008eff'
            },
            series_symbol: {
                type: String
            },
            reflow_chart: {
                type: Boolean
            },
            chart_title: {
                type: String,
                default: null
            },
            is_analysis: {
                type: Boolean,
                default: false
            },
            is_pdf: {
                type: Boolean,
                default: false
            }
        },
        beforeCreate() {
            Highcharts.setOptions(theme);
        },
        created() {
            this.setupChart();
        },
        data() {
            return {
                chart_options_activity: {},
                chartDataProxy: [],
                height: 0
            }
        },
        methods: {
            setupChart() {
                let viewModel = this;
                this.chart_options_activity = {
                    lang: {
                        viewFullscreen: i18n.t('AppLineChart.Tela Cheia'),
                        downloadPNG: i18n.t('AppLineChart.Download PNG'),
                        printChart: i18n.t('AppLineChart.Imprimir'),
                        exitFullscreen: i18n.t('AppLineChart.Sair da Tela Cheia')
                    },
                    exporting: {
                        shared: true,
                        useHTML: true,
                        filename: 'gráfico_historico',
                        buttons: {
                            contextButton: {
                                menuItems: ['viewFullscreen', 'downloadPNG', 'printChart'],
                                theme:{ fill: "white", stroke: "none", padding: 5 },
                                symbolFill: "#666666",
                                symbolStroke: "#666666",
                            },
                        }
                    },
                    chart: {
                        spacingTop: this.is_pdf ? 10 : this.is_analysis ? 70 : 10,
                        height: this.is_analysis ? 450 : 300,
                        zoomType: 'x',
                        type: "line",
                    },
                    boost: {
                        useGPUTranslations: true
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: this.chart_title,
                    },
                    tooltip: {
                        outside: true,
                        useHTML: true,
                        formatter: function () {
                            return '<b>' + this.y + ' ' + viewModel.series_symbol + '</b><br/>'
                                + Highcharts.dateFormat('%d/%m/%y %H:%M', this.x);
                        }
                    },
                    series: viewModel.chart_data,
                    yAxis: {
                        useHTML: true,
                        title: {
                            text: null
                        },
                        labels: {},
                    },

                    xAxis: {
                        // labels: {
                        //     formatter: function () {
                        //         return Highcharts.dateFormat('%y/%e %H:%M', this.value);
                        //     },
                        // },
                        type: 'datetime',
                    },
                };
            },
            redimensionar(val){
                this.height = val;
                this.chart_options_activity.chart.height = val;
                let chart = this.$refs.child.chart;
                chart.reflow();
            }
        },
        components: {VueHighcharts: Chart},
        watch: {
            chart_data: {
                deep: true,
                handler() {
                    this.setupChart();
                    if(this.is_analysis){
                        this.redimensionar(this.height);
                    }
                    var chart = this.$refs.child.chart;
                    chart.reflow();
                }
            },
            reflow_chart: function (val) {
                //https://api.highcharts.com/class-reference/Highcharts.Chart#reflow
                var chart = this.$refs.child.chart;
                chart.reflow();
            }
        }
    }
</script>

<style>
    .highcharts-tooltip-container {
        z-index: 9999 !important;
    }

</style>
