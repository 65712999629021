<template>
    <v-chart :option="chartOption" autoresize/>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {PieChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    components: {
        VChart
    },
    beforeMount() {
        this.setupChart();
    },
    mounted() {
        this.setupSeries()
    },
    data() {
        return {
            chartOption: null,
        };
    },
    props: {
        data: {
            type: Array
        },
        tooltipSuffix: {
            type: String,
            default: 'm³'
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setupChart() {
            this.chartOption = {
                fontFamily: "'Unica One', sans-serif",
                tooltip: {
                    trigger: "item",
                    formatter: function (args) {
                        return `${args.marker} ${args.name}: <b>${args.value} m³</b> | ${args.percent}%<br>`;
                    }
                },
                series: [],
            }
        },
        setupSeries() {
            let vm = this;
            this.chartOption.series = [
                {
                    type: "pie",
                    label: {
                        show: this.showLabel,
                        position: 'inner',
                        color: '#fff',
                        formatter: function (params) {
                            if (params.value > (vm.$store.getters.getTotalValue * 0.2)) {
                                return '{name|' + params.name + '}\n{time|' + params.value + 'm³}';
                            }
                            return '';
                        },
                        rich: {
                            time: {
                                fontSize: 10,
                                color: '#fff'
                            }
                        },
                    },
                    radius: '80%',
                    center: ['50%', '45%'],
                    data: this.data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },

                }
            ]
        },
    },
}
</script>

<style scoped>
</style>
