<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                    <div class="kt-portlet__head-label">
                        <!--                        <h3 class="kt-portlet__head-title">Editar Operador</h3>-->
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <app-button type="secondary" @handleClick="back" class="size ">
                            <i class="glyphicons glyphicons-arrow-left"></i> {{ $t('AppOperatorsEdit.Voltar') }}
                        </app-button>
                    </div>
                </div>
                <div class="kt-form shadow-lg pl-3 pr-3 bg-white mt-3 mt-md-5">
                    <app-operators-form @handleSave="handleUpdate" :edit="edit"/>
                </div>
            </div>
        </div>
</template>
<script>
    import {router} from "../../../router";


    import AppButton from '../../common/AppButton'
    import AppOperatorsForm from "./AppOperatorsForm";
    import OperatorsService from "../../../services/OperatorsService";

    export default {
        mounted() {
            this.id = this.$route.params.id;

            this.operatorService = new OperatorsService();
            this.getDetails();
        },
        data() {
            return {
                edit: null,
                operatorService: null,
            }
        },
        methods: {
            back() {
                router.go(-1)
            },
            handleUpdate(data) {
                this.operatorService.updateV3(this.id, data)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppOperatorsEdit.Atualização realizada com sucesso'),
                            life: 5000
                        });
                        router.go(-1);
                    }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.details) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppOperatorsEdit.Não foi possivel completar a operação'),
                            life: 5000
                        });
                    }
                })
            },
            getDetails() {
                this.operatorService.getDetailsEditV3(this.id)
                    .then((response) => {
                        this.edit = response;
                    }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppOperatorsEdit.Não foi possivel completar a operação'),
                            life: 5000
                        });
                    }
                    router.push({name: 'operators'})
                })
            }
        },
        components: {
            AppButton, AppOperatorsForm
        }
    }
</script>

<style scoped>
.kt-form{
        width: 35%;
        margin: 0 auto;
    }

    @media (max-width: 758px){
        .kt-form{
            width: 95%;
        }
    }

    @media (max-width: 1024px) and (min-width: 758px){
        .kt-form{
            width: 70%;
        }
    }
</style>
