import axios from '../axios/axios-auth'

export default class TrackService {

    findByTilesByVIDAndDate(body) {
        return axios.post('/api/v1/vehicles/tiles', body).then(response => response.data)
    }

    findByTilesByVIDAndDateV2(body) {
        return axios.post('/api/v2/vehicles/tiles', body).then(response => response.data)
    }

    findByTilesByVIDAndDateV3(body) {
        return axios.post('/api/v3/vehicles/tiles', body).then(response => response.data)
    }

    findAppRateTiles(body) {
        return axios.post('/api/v2/vehicles/tiles/app-rate', body).then(response => response.data)
    }

    findSpeedTiles(body) {
        return axios.post('/api/v2/vehicles/tiles/speed', body).then(response => response.data)
    }

    findHumidityTiles(body) {
        return axios.post('/api/v2/vehicles/tiles/humidity', body).then(response => response.data)
    }
}
