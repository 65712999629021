import i18n from "../../../i18n";

export const alarmsTypes = [
    {
        type: "RPM_MAX",
        description: i18n.t('alarmsTypes.RPM máximo permitido'),
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "RPM_WORK_MIN",
        description:  i18n.t('alarmsTypes.RPM mínimo permitido em trabalho'),
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_WORK_MIN",
        description:  i18n.t('alarmsTypes.Velocidade mínima permitida em trabalho'),
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_WORK_MAX",
        description:  i18n.t('alarmsTypes.Velocidade máxima permitida em trabalho'),
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_TRAVEL_MAX",
        description:  i18n.t('alarmsTypes.Velocidade máxima permitida em deslocamento'),
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "IDLE_TIME",
        description:  i18n.t('alarmsTypes.Tempo máximo permitido ocioso'),
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: false
    },
    {
        type: "SPN_ONE_MAX",
        description:  i18n.t('alarmsTypes.Valor máximo permitido para o SPN1'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_ONE_MIN",
        description:  i18n.t('alarmsTypes.Valor mínimo permitido para o SPN1'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_TWO_MAX",
        description:  i18n.t('alarmsTypes.Valor máximo permitido para o SPN2'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_TWO_MIN",
        description:  i18n.t('alarmsTypes.Valor mínimo permitido para o SPN2'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_THREE_MAX",
        description:  i18n.t('alarmsTypes.Valor máximo permitido para o SPN3'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_THREE_MIN",
        description:  i18n.t('alarmsTypes.Valor mínimo permitido para o SPN3'),
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
]
