const DB_NAME = 'charts_data_db';
const DB_VERSION = 1;
let DB;

export default {

    async getDb() {
        return new Promise((resolve, reject) => {

            if (DB) {
                return resolve(DB);
            }
            console.log('OPENING DB', DB_NAME);
            let request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };

            request.onsuccess = e => {
                DB = e.target.result;
                resolve(DB);
            };

            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                db.createObjectStore("charts_data", {autoIncrement: false});
            };
        });
    },
    async delete(chassis) {
        console.log(chassis)

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['charts_data'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('charts_data');
            store.delete(chassis);
        });
    },
    async deleteAll() {
        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['charts_data'], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('charts_data');
            store.clear();
        });
    },
    async getData() {

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['charts_data'], 'readonly');
            trans.oncomplete = () => {
                resolve(charts_data);
            };

            let store = trans.objectStore('charts_data');
            let charts_data = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    charts_data.push(cursor.value);
                    cursor.continue();
                }
            };
        });
    },

    async findByChassis(chassis) {

        let db = await this.getDb();

        return new Promise(resolve => {

            let trans = db.transaction(['charts_data'], 'readonly');
            trans.oncomplete = () => {
                resolve(charts_data);
            };

            let store = trans.objectStore('charts_data');
            store.get(chassis);


            let charts_data = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    if (cursor.key === chassis) {
                        charts_data = cursor.value;
                    }
                    cursor.continue();
                }
            };
        });
    },

    /**
     * Não usar,
     * Para eventos usar saveEvents
     * para falhas usar saveFaults
     * **/
    async save(dataToBeSaved, chassis) {
        console.log("Salvando")

        let db = await this.getDb();

        const promise = await new Promise( resolve => {
            (async () => {
                let trans = db.transaction(['charts_data'], 'readwrite');
                trans.oncomplete = () => {
                    resolve();
                };
                dataToBeSaved.chassis = chassis;
                dataToBeSaved.time = Date.now();

                let store = trans.objectStore('charts_data');

                try {
                    await store.put(dataToBeSaved, chassis);
                }
                catch (e) {
                    console.log(e)
                }
            })();
        });

        return promise;
    },

    async saveEvents(events, chassis) {
        let data = await this.findByChassis(chassis);

        let newData = {
            faults: data.faults,
            events: events
        };

        await this.save(newData, chassis);
    },

    async saveFaults(faults, chassis) {
        let data = await this.findByChassis(chassis);

        let newData = {
            faults: faults,
            events: data.events
        };

        await this.save(newData, chassis);
    }
}