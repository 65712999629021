import axios from '../axios/axios-auth'

export default class OperatorsService {


    getAllV3(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/operators`).then(response => response.data)
    }

    getDetailsEditV3(id) {
        return axios.get('/api/v3/operators/' + id).then(response => response.data)
    }

    updateV3(id, data) {
        return axios.patch('/api/v3/operators/' + id, data).then(response => response.data)
    }
}
