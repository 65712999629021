<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                    </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="secondary" @handleClick="back" class="margin-25-right">
                            <i class="glyphicons glyphicons-arrow-left"></i> {{ $t('AppChecklistsNew.Voltar') }}
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__body">
                <div class="form-group-parent">

                    <!------------TITULO----------------->
                    <div class="text-center">
                        <h1 v-if="checklist_id == null">{{ $t('AppChecklistsNew.Novo Checklist') }}</h1>
                        <h1 v-else>{{ $t('AppChecklistsNew.Editar Checklist') }}</h1>
                    </div>
                    <div class="white-background">
                        <div v-if="checklist_id == null">
                            <app-checklist-title class="input-title" @titleUpdate="titleUpdate"
                                                 @descriptionUpdate="descriptionUpdate"/>
                        </div>
                        <div v-else>
                            <app-checklist-title :checklist_title="checklistData.title"
                                                 :checklist_description="checklistData.description" class="input-title"
                                                 @titleUpdate="titleUpdate" @descriptionUpdate="descriptionUpdate"/>
                        </div>
                    </div>

                    <!-------------PERGUNTAS----------------->
                    <app-checklist-fields :body_list="checklistData.checklist_body_list"
                                          @checklistUpdate="checklistUpdate"/>

                    <div class="footer-buttons">
                        <app-button type="primary" @handleClick="preview">{{
                                $t('AppChecklistsNew.PRÉ - VISUALIZAÇÃO')
                            }}
                        </app-button>

                        <app-button :class="spinnerClass" :disabled="saveDisabled" type="primary"
                                    @handleClick="checkSaveOption">{{ $t('AppChecklistsNew.SALVAR') }}
                        </app-button>
                    </div>

                    <Dialog :header="checklistData.title" :visible="showPreview" @update:visible="preview"
                            :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '80vw' }"
                            :modal="true">
                        <div class="dialog-description">
                            <p>{{ checklistData.description }}</p>
                        </div>
                        <app-checklist-preview :checklist="checklistData.checklist_body_list"></app-checklist-preview>
                        <template #footer>
                            <Button :label="$t('AppChecklistsNew.Fechar')" icon="pi pi-times" @click="preview"/>
                        </template>
                    </Dialog>

                    <Dialog :header="$t('AppChecklistsNew.Atenção')" :visible="showUpdateWarning"
                            @update:visible="closeWarningDialog"
                            :modal="true">
                        <div>
                            <p>{{ $t('AppChecklistsNew.Ao editar um checklist') }}</p>

                        </div>
                        <template #footer>
                            <Button :label="$t('AppChecklistsNew.Cancelar')" icon="pi pi-times" class="p-button-text"
                                    :disabled="disableUpdateWarningButton"
                                    @click='closeWarningDialog()'/>
                            <Button :disabled="disableUpdateWarningButton" :label="$t('AppChecklistsNew.Continuar')"
                                    icon="pi pi-check"
                                    @click="updateChecklist()"/>
                        </template>
                    </Dialog>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {router} from '../../../router';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import AppHeader from "../../layout/AppHeader";
import AppButton from "../../common/AppButton";
import AppChecklistTitle from "./components/AppChecklistTitle";
import AppChecklistFields from "./components/AppChecklistFields";
import AppChecklistPreview from "./components/AppChecklistPreview";
import ChecklistService from "../../../services/ChecklistService";
import {requiredIf, required, minLength} from 'vuelidate/lib/validators';


export default {
    beforeMount() {
        this.checklistService = new ChecklistService();

        this.org_id = this.$route.query.org_id;
        this.checklist_id = this.$route.query.checklist_id;
        this.org_checklist_id = this.$route.query.org_checklist_id;

        if (this.checklist_id != null) {
            this.getChecklistData(this.checklist_id);
        } else if (this.org_checklist_id != null) {
            this.getChecklistData(this.org_checklist_id);
        }
    },
    data() {
        return {
            org_id: null,
            checklist_id: null,
            org_checklist_id: null,
            checklistService: null,
            checklistData: {
                title: '',
                description: '',
                is_global: true,
                org_id: null,
                checklist_body_list: []
            },
            saveButton: false,
            showPreview: false,
            saveDisabled: false,
            showUpdateWarning: false,
            disableUpdateWarningButton: false,
        }
    },
    computed: {
        spinnerClass: function () {
            return this.saveDisabled ?
                'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark' : ''
        }
    },
    methods: {
        back() {
            router.go(-1);
        },
        preview() {
            if (this.checklistValidation(this.checklistData)) {
                this.showPreview = !this.showPreview;
            }
        },
        closePreview(val) {
            this.showPreview = val;
        },
        getChecklistData(id) {
            this.checklistService.getChecklistData(id)
                .then((response) => {
                    if (this.checklist_id == null) {
                        this.checklistData.checklist_body_list = this.organizeResponse(response);
                    } else {
                        this.checklistData = this.organizeResponse(response);
                    }
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklistsNew.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistsNew.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            })
        },
        organizeResponse(response) {
            let body_list = JSON.parse(JSON.stringify(response.checklist_body_list));
            let temp_body_list = []
            for (let i = 1; i < body_list.length; i++) {
                let index1 = i.toString();
                let item1 = body_list.find(obj => obj.identification === index1);
                if (item1 != null) {
                    temp_body_list.push(item1);
                    for (let j = 1; j < body_list.length; j++) {
                        let index2 = i + '.' + j;
                        let item2 = body_list.find(obj => obj.identification === index2);
                        if (item2 != null) {
                            temp_body_list.push(item2);
                            for (let k = 1; k < body_list.length; k++) {
                                let index3 = i + '.' + j + '.' + k;
                                let item3 = body_list.find(obj => obj.identification === index3);
                                if (item3 != null) {
                                    temp_body_list.push(item3);
                                }
                            }
                        }

                    }
                }
            }
            if (this.checklist_id == null) {
                return temp_body_list
            } else {
                let responseFinal = JSON.parse(JSON.stringify(response));
                responseFinal.checklist_body_list = temp_body_list;
                return responseFinal;
            }
        },
        titleUpdate(newTitle) {
            this.checklistData.title = newTitle;
        },
        descriptionUpdate(newDescription) {
            this.checklistData.description = newDescription;
        },
        orgUpdate(newOrg) {
            this.checklistData.org_id = newOrg;
        },
        globalUpdate(newGlobal) {
            this.checklistData.is_global = newGlobal;
        },
        checklistUpdate(newChecklist) {
            this.checklistData.checklist_body_list = newChecklist;
        },
        checklistValidation(list) {
            if (!list.checklist_body_list || list.checklist_body_list.length === 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistsNew.Checklist não pode ser vazio'),
                    life: 7000
                })
                return false;
            }

            for (let i = 0; i < list.checklist_body_list.length; i++) { //Validação dos nº de caracteres e limites da pergunta numérica
                if (list.checklist_body_list[i].option_list) {
                    if (list.checklist_body_list[i].type === 'TEXT' && list.checklist_body_list[i].option_list[0].option_text < 0) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppChecklistsNew.Por favor, selecione 0 ou um número positivo na resposta aberta'),
                            life: 7000
                        })
                        return false;
                    } else if (list.checklist_body_list[i].type === 'NUMBER_TYPING') {
                        if (list.checklist_body_list[i].option_list[0].option_text > list.checklist_body_list[i].option_list[1].option_text) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppChecklistsNew.Por favor, selecionar um limite mínimo menor que o limite máximo na pergunta numérica'),
                                life: 7000
                            })
                            return false;
                        } else if (list.checklist_body_list[i].option_list[0].option_text < 0 || list.checklist_body_list[i].option_list[1].option_text < 0) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppChecklistsNew.Por favor, selecione números positivos nos limites da resposta numérica'),
                                life: 7000
                            })
                            return false;
                        } else if (list.checklist_body_list[i].option_list[0].option_text == null && list.checklist_body_list[i].option_list[1].option_text != null
                            || list.checklist_body_list[i].option_list[0].option_text != null && list.checklist_body_list[i].option_list[1].option_text == null) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppChecklistsNew.Por favor, preencha todos os limites para a pergunta numérica ou deixe ambos sem número para remover limitação'),
                                life: 5000
                            })
                            return false
                        }
                    }
                }
            }

            if (list.title == null || list.title === '') { // Verificação do título do checklist
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistsNew.Por favor, preencha o título da checklist'),
                    life: 5000
                })
                return false
            }

            this.$v.$touch();
            if (this.$v.$invalid) { //Verificação dos texto das perguntas do checklist
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistsNew.Por favor, preencha todos os textos de perguntas do checklist'),
                    life: 5000
                })
                return false
            }

            for (let i = 0; i < list.checklist_body_list.length; i++) { //Verificação do texto das resposta do checklist
                if (list.checklist_body_list[i].type === 'CHECKBOX_EXCLUSIVE') {
                    for (let j = 0; j < list.checklist_body_list[i].option_list.length; j++) {
                        if (list.checklist_body_list[i].option_list[j].option_text == null || list.checklist_body_list[i].option_list[j].option_text === '') {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppChecklistsNew.Por favor, preencha todas as respostas das perguntas do tipo Múltipla Escolha'),
                                life: 5000
                            })
                            return false
                        }
                    }
                }
            }

            return true;
        },
        checkSaveOption() {
            if (this.checklist_id != null) {
                this.showUpdateWarning = true;
            } else {
                this.saveChecklist();
            }
        },
        closeWarningDialog() {
            this.showUpdateWarning = false;
        },
        saveChecklist() {
            this.saveDisabled = true;
            if (this.checklistValidation(this.checklistData)) {
                this.checklistService.saveChecklist(this.org_id, this.checklistData)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppChecklistsNew.Checklist cadastrado com sucesso'),
                            life: 5000
                        });
                        this.back();
                    }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppChecklistsNew.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklistsNew.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                }).finally(() => {
                    this.saveDisabled = false;
                })
            } else {
                this.saveDisabled = false;
            }
        },
        updateChecklist() {
            this.saveDisabled = true;
            this.disableUpdateWarningButton = true;
            if (this.checklistValidation(this.checklistData)) {
                this.checklistService.saveEditedChecklist(this.checklist_id, this.checklistData)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Checklist cadastrado com sucesso',
                            life: 5000
                        });
                        this.back();
                    }).catch((error) => {
                    console.log(error);
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppChecklistsNew.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklistsNew.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                }).finally(() => {
                    this.saveDisabled = false;
                    this.disableUpdateWarningButton = false;
                })
            } else {
                this.saveDisabled = false;
            }
        },
    },
    validations: {
        checklistData: {
            checklist_body_list: {
                $each: {
                    text: {
                        required: requiredIf(prop => prop.type !== 'TITLE'),
                        minLength: minLength(1)
                    }
                }
            }
        }
    },
    components: {
        AppHeader,
        Dialog,
        Button,
        AppButton,
        AppChecklistTitle,
        AppChecklistFields,
        AppChecklistPreview,
        ChecklistService
    }
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";

.form-group-parent {
    padding: 0rem 20rem;
}

.form-group-parent h3 {
    margin-bottom: 2rem;
}

.white-background .input-title {
    padding-top: 1.5rem;
}

.white-background {
    background: white;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 5px;
    margin: 0rem;
}

.small-preview {
    width: 40vw;
    margin: auto auto 2rem auto;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.footer-buttons button {
    margin-right: 0.5rem;
    background-color: #0f9af0;
}

.footer-buttons button:hover {
    background-color: #0d89ec;
}

.preview-edit {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 50rem;
    overflow: auto;
}

@media(max-width: 1024px) {
    .form-group-parent {
        padding: 0rem 0rem;
    }

    .text-center h1 {
        font-size: 27px;
    }
}
</style>
