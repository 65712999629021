<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <Dropdown v-model="selectedOrg" :options="orgsList"
                          optionLabel="display_name" :placeholder="$t('AppFields.Organização')"
                          class="auto-center" :dataKey="'id'"
                          :filter="true"
                          style="width: 250px">
                    <template #option="slotProps" style="width: 500px">
                        <div v-if="loadingClients"> {{$t('AppFields.Carregando, aguarde')}}</div>
                        <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                            -
                        </div>
                        <div style="padding-right: 20px" v-else>
                            {{slotProps.option.display_name}}
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="row no-gutters wrapper">
                    <div v-if="is_col_12" class="col-12" style="height: 100%;">
                        <div v-show="!editField" class="col-12" style="height: 100%">
                            <app-fields-manager :selected_client="selectedOrg"
                                                :selected_field="selectedField"
                                                :loading="loading"
                                                :client_with_too_many_fields="clientWithTooManyFields"
                                                :fields_list="fieldsToInitialize"
                                                @getFields="getFields"
                                                @createNewFieldClicked="createNewFieldClicked"
                                                @changeSelectedField="changeSelectedField"/>
                        </div>
                        <app-fields-edit v-show="editField" class="col-12" ref="appFieldsEdit" :draw_field="drawField"
                                        :field_to_edit="fieldToEdit"
                                        @saveField="saveField"
                                        @discardShape="discardShape"
                                        @closeFieldEdit="closeFieldEdit"
                                        @drawFieldClicked="drawFieldClicked"
                                        @colorPolygon="colorPolygon"/>
                    </div>


                    <div :class="{'col-12': is_col_12, 'col-9': !is_col_12}">
                        <splitpanes class="default-theme splitpanes-height"
                                    :dbl-click-splitter="false">
                            <pane min-size="1">
                                <div class="row no-gutters wraper">
                                    <div class="col-12">
                                        <app-google-maps-fields
                                            style="position: relative; width: 100%; height: 100%"
                                            class="google-maps"
                                            ref="googleMapsFields"
                                            :selected_field="selectedField"
                                            :positionCenter="center"
                                            :show_drawing_tools="showDrawingTools"
                                            :fields_to_initialize="fieldsToInitialize"
                                            :draw_field="drawField"
                                            :edit_field="editField"
                                            :field_to_edit="fieldToEdit"
                                            :draw_mode="drawMode"
                                            :color_polygon="colorPolygonOption"
                                            @newFieldDrawn="newFieldDrawn"
                                            @changeSelectedField="changeSelectedField"/>
                                    </div>
                                </div>
                            </pane>
                            <pane v-if="selectedField && !editField" min-size="1" :size="size_fields_details">
                                <app-fields-details :selected_field="selectedField"
                                                    @editSelectedField="editSelectedField"
                                                    @deleteSelectedField="deleteSelectedField"
                                                    @closeFieldInfo="closeFieldInfo"
                                                    @colorPolygon="colorPolygon"/>
                            </pane>

                        </splitpanes>
                    </div>


                    <div v-if="!is_col_12" class="col-3" style="height: 100%">
                        <div v-show="!editField" class="col-12" style="height: 100%">
                            <app-fields-manager :selected_client="selectedOrg"
                                                :selected_field="selectedField"
                                                :loading="loading"
                                                :client_with_too_many_fields="clientWithTooManyFields"
                                                :fields_list="fieldsToInitialize"
                                                @getFields="getFields"
                                                @createNewFieldClicked="createNewFieldClicked"
                                                @changeSelectedField="changeSelectedField"/>
                        </div>

                        <app-fields-edit v-show="editField" class="col-12" style="height: 100%" ref="appFieldsEdit" :draw_field="drawField"
                                        :field_to_edit="fieldToEdit"
                                        @saveField="saveField"
                                        @discardShape="discardShape"
                                        @closeFieldEdit="closeFieldEdit"
                                        @drawFieldClicked="drawFieldClicked"
                                        @colorPolygon="colorPolygon"/>
                    </div>





                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as jsts from 'jsts';

import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import AppButton from "../../common/AppButton";

import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';

import AppGoogleMapsFields from "./AppGoogleMapsFields";
import AppFieldsManager from "./AppFieldsManager";
import AppFieldsDetails from "@/components/views/fields/AppFieldsDetails";
import AppFieldsEdit from "@/components/views/fields/AppFieldsEdit";

import OrganizationsService from "../../../services/OrganizationsService";
import FieldsService from "@/services/FieldsService";
import gmapsInit from "@/utils/gmaps";
import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";


export default {
        mixins: [jstsWithHolesToGoogleMaps],
        async mounted() {
            this.google_maps_reference = await gmapsInit();
            this.organizationsService = new OrganizationsService();
            this.getOrganizations();
            this.fieldsService = new FieldsService();
            this.is_col_12 = this.isScreenMobile;
            this.size_fields_details = this.isScreenMobile ? '40' : '30';
        },


        data() {
            return {
                google_maps_reference: null,
                is_col_12: false,
                size_fields_details: '',
                loadingClients: true,
                orgsList: [],

                showFieldsManager: true,
                center: {lat: -25.407688, lng: -49.253990},
                fieldsToInitialize: [],

                selectedOrg: null,
                mapsBounds: {},
                organizationsService: null,

                showDrawingTools: false,
                selectedField: null,

                clientWithTooManyFields: false,
                loading: false,
                fieldsService: null,

                editField: false,
                drawField: false,

                fieldToEdit: null,

                drawMode: 'polygon',
                colorPolygonOption: null
            }
        },
        watch: {
            selectedOrg: function (val) {
                this.handleChangeOrg();
            },
        },
        methods: {
            colorPolygon(val){
                this.colorPolygonOption = val;
            },
            editSelectedField() {
                this.editField = true;
                this.drawField = false;

                this.$refs.appFieldsEdit.updateDrawnShape(this.selectedField.shape);
                this.fieldToEdit = this.selectedField;
            },
            deleteSelectedField() {
                let fieldToDelete = this.selectedField;
                this.selectedField = null;
                this.loading = true;

                this.fieldsService.deleteFieldV3(fieldToDelete.id).then((response) => {
                    this.$toast.add({severity: 'success', summary: this.$t('AppFields.Local deletado com sucesso'), life: 3000});
                }).catch((error) => {
                    console.log(error)
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppFields.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppFields.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                }).finally(() => {
                    this.getFields();
                })
            },
            saveField(fieldToSave) {
                let fieldToEdit = this.fieldToEdit;
                this.clearFieldEditData();
                this.loading = true;

                if (fieldToEdit) {
                    this.fieldsService.updateFieldV3(fieldToEdit.id, fieldToSave).then((response) => {
                        this.$toast.add({severity: 'success', summary: this.$t('AppFields.Local atualizado com sucesso'), life: 3000});
                    }).catch((error) => {
                        if (!error.response || error.response.status !== 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppFields.Não foi possivel completar a operação'),
                                life: 5000
                            });
                            return;
                        }
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppFields.Você não tem acesso a esta funcionalidade'),
                            life: 5000
                        });
                    }).finally(() => {
                        this.closeFieldEdit();
                    })
                } else {
                    this.fieldsService.createNewFieldV3(this.selectedOrg.id, fieldToSave).then((response) => {
                        this.$toast.add({severity: 'success', summary: 'Talhão criado com sucesso', life: 3000});
                    }).catch((error) => {
                        if (!error.response || error.response.status !== 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppFields.Não foi possivel completar a operação'),
                                life: 5000
                            });
                            return;
                        }
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppFields.Você não tem acesso a esta funcionalidade'),
                            life: 5000
                        });
                    }).finally(() => {
                        this.closeFieldEdit();
                    })
                }
            },
            discardShape() {
                this.$refs.googleMapsFields.discardCurrentShape(true);
            },
            clearFieldEditData() {
                this.drawField = false;
                this.editField = false;
                this.selectedField = null;
                this.fieldToEdit = null;
            },
            closeFieldEdit() {
                this.clearFieldEditData();
                this.discardShape();
                this.getFields();
            },
            newFieldDrawn(value) {
                this.$refs.appFieldsEdit.updateDrawnShape(value);
                this.drawField = false;
            },
            drawFieldClicked(val) {
                this.drawField = true;
                this.drawMode = val.mode;
            },
            createNewFieldClicked() {
                this.editField = true;
                this.selectedField = null;
                this.getFields();
            },
            handleChangeOrg() {
                this.fieldsToInitialize = [];
                this.selectedField = null;
                this.getFields();
            },
            getFields() {
                let vm = this;
                vm.loading = true;
                vm.clientWithTooManyFields = false;

                if (!this.selectedOrg) {
                    return
                }

                const promises = [];

                promises.push(this.fieldsService.getFieldsV3(this.selectedOrg.id));

                Promise.all(promises)
                    .then(function (response) {
                        vm.processFieldsResponse(response[0]);
                    }).catch(function (error) {
                    vm.clientWithTooManyFields = true;
                    if (error.response && error.response.data.details === 'INVALID_DATA') {
                        vm.clientWithTooManyFields = true;
                        vm.$toast.add({
                            severity: 'error', summary: this.$t('AppFields.Falha'),
                            detail:this.$t('AppFields.Organização com muitos talhões para exibir'), life: 3000
                        });
                        return;
                    }
                    if (!error.response || error.response.status !== 403) {
                        vm.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppFields.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    vm.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppFields.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
            }).finally(() => {
                vm.loading = false;
            })
            },
            calculateArea(field) {
                return (this.google_maps_reference.maps.geometry.spherical.computeArea(
                    this.jstsWithoutHolesToGoogleMaps(this.buildPolygonFromCoordinates(field.coordinates),
                        this.google_maps_reference.maps)[0]) / 10000).toFixed(3);
            },
            buildPolygonFromCoordinates(coordinates) {
                let reader = new jsts.io.WKTReader();
                return reader.read(coordinates);
            },
            processFieldsResponse(response) {
                if (!response.fields || response.fields.length === 0) {
                    return;
                }

                response.fields.forEach(field => {
                    field.area = this.calculateArea(field);
                    field.color = field.color === undefined ? '0335ad' : field.color;
                })
                let sortedFields = response.fields.sort((a, b) => a.name.localeCompare(b.name))
                this.initializeFields(sortedFields)
            },
            changeSelectedField(val) {
                this.selectedField = val;
            },
            setShowDrawingTools(val) {
                this.showDrawingTools = val;
            },
            initializeFields(val) {
                this.fieldsToInitialize = val;
            },
            getOrganizations() {
                this.organizationsService.getAllV3('FIELDS')
                    .then((response) => {
                        this.getOrgsList(response);
                        this.selectSavedOrg();
                    }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.loadingClients = false;
                })
            },
            getOrgsList(orgs) {
                this.orgsList = orgs.sort(function (a, b) {
                    return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
                });

                //se so tem um já seleciona
                if (this.orgsList.length === 1) {
                    this.selectedOrg = this.orgsList[0];
                }
            },
            selectSavedOrg() {
                //se so tem um já seleciona
                if (this.orgsList.length === 1) {
                    this.selectedOrg = this.orgsList[0];
                    return;
                }

                let storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));
                if (storedChosenOptions && storedChosenOptions.selectedOrg && this.orgsList.find(obj => {return obj.id === storedChosenOptions.selectedOrg.id})) {
                    this.selectedOrg = storedChosenOptions.selectedOrg;
                }
            },
            closeFieldInfo() {
                this.selectedField = null;
                this.getFields();
            }
        },
        computed: {
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        },
        components: {
            Splitpanes, Pane, AppGoogleMapsFields, AppFieldsManager, Dropdown, AppButton,
            Button, AppFieldsDetails, InputSwitch, InputText, AppFieldsEdit
        }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.auto-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.wraper .google-maps {
    height: calc(100vh - 81px) !important;
}

.kt-portlet {
    background-color: #fff !important;
}

.splitpanes.default-theme .splitpanes__pane {
    background-color: #fff;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
