<template>
    <div>
        <DataTable v-if="cardsDataGrouped.length > 0" :value="cardsDataGrouped"
                   rowGroupMode="subheader" groupRowsBy="groupHeader.name"
                   :loading="cards_data_loading" class="p-datatable-striped p-datatable-sm">
            <Column field="groupHeader.name" header="GroupHeader"></Column>
            <Column field="name" :header="$t('AppRealTimeReportCards.Estatística')">
                <template #body="slotProps">
                    <div style="padding-left: 20px">
                        {{ slotProps.data.name }}
                    </div>
                </template>
            </Column>
            <Column field="value" :header="$t('AppRealTimeReportCards.Valor')">
            </Column>
            <template #groupheader="slotProps">
                <span style="font-weight: bold">{{ slotProps.data.groupHeader.name }}</span>
            </template>
        </DataTable>
        <div v-else class="show-no-data">
            {{$t('AppRealTimeReportCards.Sem dados no período')}}
        </div>
    </div>
</template>

<script>
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import dateHourFormat from '@/mixins/DateMixin'

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    mixins: [getTimeFromSecondsDurationFormatMixin, dateHourFormat],
    data() {
        return {
            cardsDataGrouped: {},
        }
    },
    props: {
        cards_data: {
            type: Object,
            required: true
        },
        cards_data_loading: {
            type: Boolean,
            required: true
        },
        first_part: {
            type: Boolean,
            required: true
        },
        vehicle_amount: {
            type: Number
        },
        selected_dates: {
            type: Object
        }
    },
    components: {DataTable, Column},
    watch: {
        cards_data: function (val) {
            let grouped = [];

            if (this.first_part) {

                //Total
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Área'), value: this.formatValue(val._1035, 'ha'), groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Combustível'), value: this.formatValue(val._1009, 'L'), groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Tempo'), value: this.formatValue(val._1000, 'h'), groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Distância'), value: this.formatValue(val._1005, 'km'), groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Rendimento'),
                    value: this.formatValue(val._1009 / val._1035, 'L/ha'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Consumo') + '(L/h)' ,
                    value: this.formatValue(val._1009 / val._1000, 'L/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name:this.$t('AppRealTimeReportCards.Consumo') + '(km/L)' ,
                    value: this.formatValue(val._1005 / val._1009),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Velocidade') ,
                    value: this.formatValue(val._1005 / val._1000, 'km/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Disponibilidade Mecanica') ,
                    value: this.formatValue(((val.total_time - val.maintenance_stop_time) / val.total_time) * 100, '%'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Eficiência') ,
                    value: this.formatValue((val._1001) / (val._1000) * 100, '%'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Produtividade') ,
                    value: this.formatValue(val._1035 / val._1000, 'ha/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Total')},
                });

                //Trabalhando
                grouped.push({
                    name: 'Área', value: this.formatValue(val._1035, 'ha'), groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Combustível'), value: this.formatValue(val._1010, 'L'), groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Tempo'), value: this.formatValue(val._1001, 'h'), groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Distância'), value: this.formatValue(val._1006, 'km'), groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Rendimento'),
                    value: this.formatValue(val._1010 / val._1035, 'L/ha'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Consumo') + '(L/h)' ,
                    value: this.formatValue(val._1010 / val._1001, 'L/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Velocidade') ,
                    value: this.formatValue(val._1006 / val._1001, 'km/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Produtividade') ,
                    value: this.formatValue(val._1035 / val._1001, 'ha/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Trabalhando') },
                });

                //Ocioso
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Combustível'), value: this.formatValue(val._1011, 'L'), groupHeader: {name: this.$t('AppRealTimeReportCards.Ocioso') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Tempo'), value: this.formatValue(val._1002, 'h'), groupHeader: {name: this.$t('AppRealTimeReportCards.Ocioso') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Consumo') ,
                    value: this.formatValue(val._1011 / val._1002, 'L/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Ocioso') },
                });
            } else {
                //Manobrando
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Combustível'), value: this.formatValue(val._1012, 'L'), groupHeader: {name: this.$t('AppRealTimeReportCards.Manobrando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Tempo'), value: this.formatValue(val._1003, 'h'), groupHeader: {name: this.$t('AppRealTimeReportCards.Manobrando') },
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Consumo') ,
                    value: this.formatValue(val._1012 / val._1003, 'L/h'),
                    groupHeader: {name: this.$t('AppRealTimeReportCards.Manobrando') },
                });

                //Deslocando
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Combustível'), value: this.formatValue(val._1013, 'L'), groupHeader: {name: this.$t('AppRealTimeReportCards.Deslocando')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Tempo'), value: this.formatValue(val._1004, 'h'), groupHeader: {name: this.$t('AppRealTimeReportCards.Deslocando')},
                });
                grouped.push({
                    name: this.$t('AppRealTimeReportCards.Consumo') ,
                    value: this.formatValue(val._1013 / val._1004, 'L/h'),
                    groupHeader: {name: 'Deslocando'},
                });
            }

            this.cardsDataGrouped = grouped;
        }
    },
    methods: {
        formatValue(value, symbol) {
            if (!value || value === 'N/D') {
                return 'N/D';
            }
            if (!value || !isFinite(value)) {
                return 'N/D';
            }


            if (value.isInteger) {
                return symbol ? value + ' ' + symbol : value;
            }

            if (!symbol) {
                return value.toFixed(2);
            }

            try {
                return value.toFixed(2) + ' ' + symbol;
            } catch (e) {
                return 'N/D';
            }
        },
    },
}
</script>

<style scoped>

.show-no-data {
    background-color: #323234;
    height: 100%;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
