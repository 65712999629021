import axios from '../axios/axios-auth'

export default class WebhookService {

    getStatusAndUrl(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/subscriptions`).then(response => response.data)
    }

    createConnectionWebHook(orgId, data) {
        return axios.post(`/api/v3/organizations/${orgId}/subscriptions`, data).then(response => response.data)
    }

    editConnectionWebHook(orgId, data, id) {
        return axios.patch(`/api/v3/subscriptions/${id}`, data).then(response => response.data)
    }
}
