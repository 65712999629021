import axios from '../axios/axios-auth'

export default class ProfileService {

    findProfileInfo() {
        return axios.get(`/api/v3/profile`).then(response => response.data)
    }

    editProfileInfo(data) {
        return axios.patch(`/api/v3/profile`, data).then(response => response.data)
    }

}
