<template>
    <div class="row justify-content-center">
        <template v-for="card in cards">
            <app-vehicles-track-cards :title="card.title" :value="card.value" :tooltip="card.tooltip">
            </app-vehicles-track-cards>
        </template>
    </div>
</template>

<script>

    import AppVehiclesTrackCards from "./AppVehiclesTrackCards";

    export default {
        data() {
            return {
                cardsData: {},
            }
        },
        methods: {},
        props: {
            cards: {
                type: Array,
                default() {
                    return []
                }
            }
        },

        components: {
            AppVehiclesTrackCards,
        }
    }
</script>

<style scoped>

</style>
