<template>
    <button @click="handleClick" class="btn" :class="[btn_type,btn_size,{'btn-pill': pill }]"
            :disabled="disabled">
        <slot/>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                required: true,
                default: 'btn-primary'
            },
            size: {
                type: String,
            },
            pill: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            btn_type: function () {
                return 'btn-' + this.type
            },
            btn_size: function () {
                return 'btn-' + this.size
            }
        },
        methods: {
            handleClick() {
                this.$emit('handleClick');
            }
        }
    }
</script>

<style scoped type="scss">


</style>
