<template>
    <select class="form-control width-auto-100 form-control-sm" @change="handleChange($event)" v-model="chart_selected">
        <option class="font-select-option" v-for="type in charts_types_data" v-bind:value="type" v-bind:key="type.key">{{type.name}}</option>
    </select>
</template>

<script>
    export default {
        data() {
            return {
                chart_selected: this.selected
            }
        },
        methods: {
            handleChange(event) {
                this.$emit('handleSelectChanges', this.chart_selected);
            }
        },
        components: {},
        props: {
            selected: {
                type: Object
            },
            charts_types_data: {
                type: Array,
                required: true
            }
        },
        watch: {
            selected: {
                handler: function (val) {
                    this.chart_selected = val;
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

    .font-select-option {
        font-size: 15px;
    }

</style>
