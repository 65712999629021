export default {
    methods: {
        removeSpecialCharacters(val) {
            if (val) {
                return val.replace(/[\W_]+/g, '')
            }
        },
        isMacAddress(value) {
            if (value.length !== 12) {
                return false;
            }

            var regexp = /^([0-9A-F])/;
            return regexp.test(value)
        }
    },
};
