<template>
    <div id="logo" v-tooltip.bottom="tooltip">
        {{title}} <br> <b>{{value}}</b> <br>
    </div>
</template>

<script>
    import Tooltip from "primevue/tooltip";

    export default {
        props: {
            title: {
                required: true
            },
            value: {
                required: true
            },
            tooltip: {
                default: ''
            }
        },
        methods: {},
        components: {
            Tooltip
        },
        directives: {
            tooltip: Tooltip
        },
    }
</script>

<style scoped>
    #logo {
        color: #4b4b4b;
        min-width: 100px;
        border-radius: 3px;
        position: relative;
        padding: 10px;
        font-size: 15px;
        background-color: rgba(242, 242, 242, .8);
        left: 0.6%;
        margin-left: 10px;
        margin-top: 10px;
        text-align: center;
    }
</style>
