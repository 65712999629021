<template>
    <div class="input-group">
        <input class="form-control" :placeholder="placeholder" :type="type" v-bind:value="value" :disabled="disabled"
               v-on:input="$emit('input', $event.target.value)" :class="{'is-invalid' : is_invalid}">
        <div class="input-group-append">
            <button class="btn btn-primary" type="button" @click="show_password = !show_password">
                <i style="color: #fff" class="glyphicons glyphicons-eye-open" v-if="!show_password"/>
                <i style="color: #fff" class="glyphicons glyphicons-eye-close" v-else/>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                show_password: false,
                type: 'password'
            }
        },
        props: {
            value: {
                type: [Number, String]
            },
            placeholder: String,
            is_invalid: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            show_password: function (val) {
                if (this.show_password) {
                    this.type = 'text'
                } else {
                    this.type = 'password'
                }
            }
        }

    }
</script>

<style scoped>

</style>
