<template>
    <div>
        <vue-highcharts v-show="timelineChartSelected" :options="chart_options_activity" ref="child"/>
        <vue-highcharts v-show="!timelineChartSelected" :options="pareto_chart_options_activity" ref="secondChild"/>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue';
import Highcharts from "highcharts";
import theme from "../../../assets/styles/charts/default";
import leastSquares from '../../../utils/leastSquares';
import exportHighCharts from 'highcharts/modules/exporting';
import i18n from "@/i18n";

exportHighCharts(Highcharts)

import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

import pareto from 'highcharts/modules/pareto';

pareto(Highcharts)

export default {
    props: {
        chart_data: {
            type: Array,
            required: true
        },
        reflow_chart: {
            type: Boolean
        },
        chart_name: {
            type: String,
            required: true
        },
        chart_code: {
            type: String,
            required: true
        },
        display_group_options: {
            type: Boolean,
            default: true
        },
        is_analysis: {
            type: Boolean,
            default: false
        },
        is_pdf: {
            type: Boolean,
            default: false
        }
    },
    beforeCreate() {
        console.log(theme)
        Highcharts.setOptions(theme);
    },
    created() {
        this.setupChart();
        this.setupParetoChart();
    },
    data() {
        return {
            chart_options_activity: {
                plotOptions: {
                    column: {
                        stacking: false
                    },
                },
            },
            pareto_chart_options_activity: {},
            seriesAttributes: [],
            faultCountSorted: [],
            faultCodeSorted: [],
            timelineChartSelected: false,
            showCreditsToParetoChart: false,
            height: 0
        }
    },
    methods: {
        getChartOptions() {
            if (this.timelineChartSelected) {
                return this.chart_options_activity;
            }
            return this.pareto_chart_options_activity;
        },
        changeStaking(val) {
            this.chart_options_activity.plotOptions.column.stacking = val;

            //As duas primeiras séries são as séries de tendências, que devem ser exibidas ou não.
            if (this.seriesAttributes.length > 1 && this.timelineChartSelected) {
                this.seriesAttributes[0].visible = !val;
                this.seriesAttributes[1].visible = val;
            }
        },
        setupChart() {
            let viewModel = this;
            this.chart_options_activity = {
                lang: {
                    viewFullscreen: i18n.t('AppColumnChart.Tela Cheia'),
                    downloadPNG: i18n.t('AppColumnChart.Download PNG'),
                    printChart: i18n.t('AppColumnChart.Imprimir'),
                    exitFullscreen: i18n.t('AppColumnChart.Sair da Tela Cheia')
                },
                exporting: {
                    shared: true,
                    useHTML: true,
                    filename: 'gráfico_tendencia',
                    menuItemDefinitions: {
                        groupByHour: {
                            onclick: function () {
                                viewModel.$emit('setChartIntervalAndCalculateSeries',
                                    {chartInterval: 'hour', chartName: viewModel.chart_name});
                            },
                            text: i18n.t('AppColumnChart.Agrupar por Hora')
                        },
                        groupByDay: {
                            onclick: function () {
                                viewModel.$emit('setChartIntervalAndCalculateSeries',
                                    {chartInterval: 'day', chartName: viewModel.chart_name});
                            },
                            text: i18n.t('AppColumnChart.Agrupar por Dia')
                        },
                        groupByMonth: {
                            onclick: function () {
                                viewModel.$emit('setChartIntervalAndCalculateSeries',
                                    {chartInterval: 'month', chartName: viewModel.chart_name});
                            },
                            text: i18n.t('AppColumnChart.Agrupar por Mês')
                        },
                    },
                    buttons: {
                        contextButton: {
                            menuItems: viewModel.display_group_options ?
                                ['groupByHour', 'groupByDay', 'groupByMonth', 'separator', 'viewFullscreen',
                                    'downloadPNG', 'printChart'] : ['viewFullscreen', 'downloadPNG', 'printChart'],
                            theme: {fill: "white", stroke: "none", padding: 5},
                            symbolFill: "#666666",
                            symbolStroke: "#666666",
                        }
                    }
                },
                chart: {
                    zoomType: 'x',
                    spacingTop: this.is_pdf ? 10 : 80,
                    height: 450,
                    style: {
                        fontFamily: 'robotoregular'
                    },
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null,
                },
                xAxis: {
                    type: "datetime",
                    crosshair: false,
                    categories: null
                },
                yAxis: {
                    title: {
                        text: null
                    },
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    column: {
                        stacking: viewModel.chart_options_activity.plotOptions.column.stacking,
                        grouping: true
                    },
                    series: {}
                },
                series: viewModel.seriesAttributes
            };
        },
        setupParetoChart() {
            let viewModel = this;
            viewModel.pareto_chart_options_activity = {
                lang: {
                    viewFullscreen: i18n.t('AppColumnChart.Tela Cheia'),
                    downloadPNG: i18n.t('AppColumnChart.Download PNG'),
                    printChart: i18n.t('AppColumnChart.Imprimir'),
                    exitFullscreen: i18n.t('AppColumnChart.Sair da Tela Cheia')
                },
                exporting: {
                    shared: true,
                    useHTML: true,
                    filename: 'gráfico_tendencia',
                    menuItemDefinitions: {},
                    buttons: {
                        contextButton: {
                            menuItems: ['viewFullscreen', 'downloadPNG', 'printChart'],
                            theme: {fill: "white", stroke: "none", padding: 5},
                            symbolFill: "#666666",
                            symbolStroke: "#666666",
                        }
                    }
                },
                chart: {
                    renderTo: 'container',
                    type: 'column',
                    zoomType: 'x',
                    spacingTop: 50,
                    height: 450,
                    style: {
                        fontFamily: 'robotoregular'
                    },
                },
                tooltip: {
                    shared: true,
                },
                credits: {
                    enabled: viewModel.showCreditsToParetoChart,
                    text: i18n.t('AppColumnChart.Exibindo os 30 alertas que mais ocorreram no período'),
                    href: false,
                },
                title: {
                    text: null,
                },
                xAxis: {
                    categories: viewModel.faultCodeSorted,
                    crosshair: true
                },
                yAxis: [{
                    title: {
                        text: null
                    }
                }, {
                    title: {
                        text: ''
                    },
                    minPadding: 0,
                    maxPadding: 0,
                    max: 100,
                    min: 0,
                    opposite: true,
                    labels: {
                        format: "{value}%"
                    }
                }],
                series: this.seriesAttributes,
                plotOptions: {
                    column: {
                        stacking: viewModel.chart_options_activity.plotOptions.column.stacking,
                        grouping: true
                    },
                    series: {}
                },
            }
        },
        setupParetoChartSeries() {
            let viewModel = this;
            viewModel.faultCountSorted = [];
            viewModel.faultCodeSorted = [];
            viewModel.showCreditsToParetoChart = false;
            const MAX_NUMBER_OF_FAULTS_TO_DISPLAY = 29;

            let chartToSort = JSON.parse(JSON.stringify(this.chart_data));
            let sortedChartDataByCount = chartToSort.sort((a, b) => b.count - a.count);

            sortedChartDataByCount.forEach((fault, index) => {
                if (index > MAX_NUMBER_OF_FAULTS_TO_DISPLAY) {
                    viewModel.showCreditsToParetoChart = true;
                    return;
                }
                viewModel.faultCountSorted.push(fault.count)
                viewModel.faultCodeSorted.push(fault.code)
            })

            viewModel.seriesAttributes = [{
                type: 'pareto',
                name: 'Pareto',
                yAxis: 1,
                zIndex: 2,
                baseSeries: 1,
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: '%'
                },
                visible: true,
                data: []
            }, {
                name: i18n.t('AppColumnChart.Alertas'),
                type: 'column',
                zIndex: 1,
                data: viewModel.faultCountSorted,
                visible: true,
            }]

            this.setupParetoChart();
        },
        setUpChartSeries() {
            if (this.chart_data.length > 0) {

                let sortedChartData = [];

                this.chart_data.forEach(singleChartdata => {
                    singleChartdata.data.forEach(data => {
                        sortedChartData.push({
                            x: data.x,
                            y: data.y
                        })
                    })
                })
                sortedChartData = sortedChartData.sort((a, b) => a.x - b.x);


                let dateToKeepTrack = sortedChartData[0].x;
                let objectToPush = {
                    x: 0,
                    y: sortedChartData[0].y
                }
                let numberOfItemsAdded = 1;

                let xIndex = 0;
                var X = []
                var Y_media = []
                var Y_soma = []
                var arrayOfDates = []

                sortedChartData.forEach((sortedData, index) => {
                    if (sortedData.x !== dateToKeepTrack) {
                        X.push(xIndex);
                        Y_media.push(objectToPush.y / numberOfItemsAdded);
                        Y_soma.push(objectToPush.y);
                        arrayOfDates.push(dateToKeepTrack)
                        xIndex++;

                        objectToPush = {
                            x: sortedData.x,
                            y: sortedData.y
                        }

                        numberOfItemsAdded = 1;

                    } else if (index !== 0) {
                        objectToPush.y += sortedData.y
                        numberOfItemsAdded++;
                    }
                    dateToKeepTrack = sortedData.x;
                })

                X.push(xIndex);
                Y_media.push(objectToPush.y / numberOfItemsAdded);
                Y_soma.push(objectToPush.y);
                arrayOfDates.push(dateToKeepTrack);

                //Fazendo regressão linear
                var ret_media = {};
                var ret_soma = {};
                var f1 = leastSquares(X, Y_media, ret_media);
                var f2 = leastSquares(X, Y_soma, ret_soma);


                let linear_regression_media = {
                    type: 'line',
                    data: [],
                    name: i18n.t('AppColumnChart.Tendência'),
                    tooltip: {
                        valueDecimals: 2,
                    },
                    visible: !this.chart_options_activity.plotOptions.column.stacking,
                    showInLegend: false,
                    zIndex: 1,
                }
                let linear_regression_soma = {
                    type: 'line',
                    data: [],
                    name: i18n.t('AppColumnChart.Tendência'),
                    tooltip: {
                        valueDecimals: 2,
                    },
                    visible: this.chart_options_activity.plotOptions.column.stacking,
                    showInLegend: false,
                    zIndex: 1,
                }

                let index = 0;


                arrayOfDates.forEach(date => {
                    linear_regression_media.data.push({
                        x: date,
                        y: ret_media.b + (ret_media.m * index)
                    })
                    linear_regression_soma.data.push({
                        x: date,
                        y: ret_soma.b + (ret_soma.m * index)
                    })
                    index++;
                })
                let linearFirstAndLastData = [];
                linearFirstAndLastData.push(linear_regression_media.data[0]);
                linearFirstAndLastData.push(linear_regression_media.data[linear_regression_media.data.length - 1]);
                linear_regression_media.data = linearFirstAndLastData;

                linearFirstAndLastData = [];
                linearFirstAndLastData.push(linear_regression_soma.data[0]);
                linearFirstAndLastData.push(linear_regression_soma.data[linear_regression_soma.data.length - 1]);
                linear_regression_soma.data = linearFirstAndLastData;

                this.seriesAttributes = [];

                this.seriesAttributes.push(linear_regression_media);
                this.seriesAttributes.push(linear_regression_soma);

                this.chart_data.forEach(singleChartdata => {
                    singleChartdata.type = 'column'
                    this.seriesAttributes.push(singleChartdata)
                })
            } else {
                this.seriesAttributes = [];
            }
            this.setupChart();
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async reflowChart(ref) {
            //https://api.highcharts.com/class-reference/Highcharts.Chart#reflow
            await this.sleep(1);

            let chart = this.$refs[ref].chart;
            chart.reflow();
        },
        redimensionar(val) {
            this.height = val;
            this.chart_options_activity.chart.height = val;
            let chart = this.$refs.child.chart;
            chart.reflow();
        }
    },
    components: {VueHighcharts: Chart},
    watch: {
        chart_data: {
            deep: true,
            handler() {
                if (this.chart_code !== 'ALERTAS_PARETO') {
                    this.timelineChartSelected = true;
                    this.setUpChartSeries();
                    if (this.is_analysis) {
                        this.redimensionar(this.height);
                    }
                    this.reflowChart('child');
                } else {
                    this.timelineChartSelected = false;
                    this.setupParetoChartSeries();
                    if (this.is_analysis) {
                        this.redimensionar(this.height);
                    }
                    this.reflowChart('secondChild');
                }
            }
        },
        reflow_chart: function (val) {
            this.reflowChart('child');
            this.reflowChart('secondChild');
        },
    }
}
</script>

<style scoped>


</style>
