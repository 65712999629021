<template>
    <div class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
        <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1">
                <div class="kt-error-v1__container">
                    <h1 class="kt-error-v1__number">404</h1>
                    <p class="kt-error-v1__desc">
                        {{ $t('not_found_message') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            this.$i18n.locale = this.$store.getters.user.language;
            return {
                locale: this.$store.getters.user.language
            }
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            }
        },
    }
</script>

<style scoped>

    .kt-error-v1 {
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }

    .kt-error-v1 .kt-error-v1__container .kt-error-v1__number {
        font-size: 150px;
        margin-left: 80px;
        margin-top: 9rem;
        font-weight: 700;
        color: #595d6e;
    }

    .kt-error-v1 .kt-error-v1__container .kt-error-v1__desc {
        font-size: 1.5rem;
        margin-left: 80px;
        color: #74788d;
    }

    @media (max-width: 768px) {
        .kt-error-v1 .kt-error-v1__container .kt-error-v1__number {
            margin: 120px 0 0 3rem;
            font-size: 8rem;
        }

        .kt-error-v1 .kt-error-v1__container .kt-error-v1__desc {
            margin-left: 3rem;
            padding-right: 0.5rem;
        }
    }


</style>

<i18n>
    {
        "pt-BR": {
            "not_found_message": "Ops! Tivemos um problema aqui"
        },
        "en-US": {
            "not_found_message": "OOPS! Something went wrong here"
        }
    }
</i18n>
