import axios from '../axios/axios-auth'

export default class AnomaliasOrderService {

    createOrder(orgId, order) {
        return axios.post(`/api/v3/organizations/${orgId}/anomalias/orders`, order).then(response => response.data)
    }

    getOrdersByOrganization(orgId, start, end) {
        return axios.get(`/api/v3/organizations/${orgId}/anomalias/orders/date/${start}/${end}`).then(response => response.data)
    }

    deleteOrder(orderId) {
        return axios.delete(`/api/v3/anomalias/orders/${orderId}`).then(response => response.data)
    }

    updateOrder(orderId, order) {
        return axios.patch(`/api/v3/anomalias/orders/${orderId}`, order).then(response => response.data)
    }

    closeOrder(orderId, data) {
        return axios.patch(`/api/v3/anomalias/orders/${orderId}/close`, data).then(response => response.data)
    }

    getFieldOrders(fieldId) {
        return axios.get(`/api/v3/fields/${fieldId}/anomalias/orders`).then(response => response.data)
    }
}
