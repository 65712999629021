import i18n from "../../../i18n";
export const ActivityCodes = [
    {id: 0, value: "UNKNOWN", description: i18n.t('ActivityCodes.Desconhecido')},
    {id: 1, value: "FERTILIZING", description: i18n.t('ActivityCodes.Fertilização')},
    {id: 2, value: "SOWING_AND_PLANTING", description: i18n.t('ActivityCodes.Semeadura e Plantio')},
    {id: 3, value: "CROP_PROTECTION", description: i18n.t('ActivityCodes.Proteção de Culturas')},
    {id: 4, value: "TILLAGE", description: i18n.t('ActivityCodes.Preparo de Solo')},
    {id: 5, value: "BALING", description: i18n.t('ActivityCodes.Enfardamento')},
    {id: 6, value: "MOWING", description: i18n.t('ActivityCodes.Corte')},
    {id: 7, value: "WRAPPING", description: i18n.t('ActivityCodes.Empacotar')},
    {id: 8, value: "HARVESTING", description: i18n.t('ActivityCodes.Colheita')},
    {id: 9, value: "FORAGE_HARVESTING", description: i18n.t('ActivityCodes.Colheita de Forragem')},
    {id: 10, value: "TRANSPORT", description: i18n.t('ActivityCodes.Transporte')},
    {id: 11, value: "SWATHING", description: i18n.t('ActivityCodes.Enleiramento')},
    {id: 12, value: "MANURE_APPLICATION", description: i18n.t('ActivityCodes.Aplicação de Esterco')},
    {id: 13, value: "SELF_LOADING_WAGON", description: i18n.t('ActivityCodes.Vagão Autocarregável')},
    {id: 14, value: "TEDDING", description: i18n.t('ActivityCodes.Espalhamento de Forragem')},
    {id: 15, value: "MEASURING", description: i18n.t('ActivityCodes.Medição')},
    {id: 16, value: "IRRIGATION", description: i18n.t('ActivityCodes.Irrigação')},
    {id: 17, value: "MIXING", description: i18n.t('ActivityCodes.Mistura')},
    {id: 18, value: "MULCHING", description: i18n.t('ActivityCodes.Cobertura Morta do Solo')},
]
