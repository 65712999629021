<template>
    <div class="card-chart">
        <div class="header">
            <span style="font-size: large; color: #000;">{{ $t('AppWaterWithdrawalPieCard.Captação por') }} {{ title }}</span>
        </div>
        <app-water-withdrawal-pie-chart :data="volume" :showLabel="showLabel"></app-water-withdrawal-pie-chart>
    </div>
</template>

<script>
import AppWaterWithdrawalPieChart from "@/components/views/water_withdrawal/AppWaterWithdrawalPieChart";

export default {
    components: {AppWaterWithdrawalPieChart},
    data() {
        return {
            volume: []
        }
    },
    props: {
        info: {
            type: Array
        },
        title: {
            type: String
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        info: {
            deep: true,
            handler(val) {
                this.cleanData()
                val.forEach(item => {
                    if (item.type) {
                        this.volume.push({name: item.type.name, value: item.volume})
                    } else {
                        this.volume.push({name: item.name, value: item.volume})
                    }
                })
            }
        }
    },
    methods: {
        cleanData() {
            while (this.volume.length > 0) {
                this.volume.pop()
            }
        }
    }
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: center;
}

.card-chart {
    margin: 1.3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 35rem;
    padding-top: 2rem;
}
</style>
