<template>
    <form class="kt-form" @submit.prevent>
        <div class="form-group">
            <span class="p-float-label checklist-title">
                <InputText class="form-control" type="text" v-model="title"
                           :class="{'is-invalid' : $v.title.$invalid && $v.title.$dirty}"/>
                <label>{{ $t('AppChecklistTitle.Título do Checklist') }}</label>
            </span>
            <span class="form-text error" v-if="$v.title.$invalid && $v.title.$dirty">
                {{ $t('AppChecklistTitle.Título é obrigatório') }}
            </span>

            <span class="p-float-label checklist-description">
                <InputText class="form-control" type="text" v-model="description" style="margin-top: 2rem"/>
                <label>{{ $t('AppChecklistTitle.Descrição') }}</label>
            </span>
        </div>
    </form>
</template>

<script>
import InputText from "primevue/inputtext";
import {required} from "vuelidate/lib/validators";

export default {
    data() {
        return {
            title: '',
            description: '',
            checkTitle: true,
        }
    },
    watch: {
        checklist_title: {
            deep: true,
            handler(val) {
                if (this.checkTitle && val != '') {
                    this.title = this.checklist_title;
                    this.checkTitle = false;
                }
            }
        },
        checklist_description: {
            deep: true,
            handler(val) {
                this.description = this.checklist_description;
            }
        },
        title: function (val) {
            this.$v.title.$touch();
            if (this.$v.title.$invalid) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistTitle.Por favor preencha o título do checklist'),
                    life: 5000
                });
            }
            this.$emit('titleUpdate', val);
        },
        description: function (val) {
            this.$emit('descriptionUpdate', val);
        }
    },
    props: {
        checklist_title: {
            type: String
        },
        checklist_description: {
            type: String
        }
    },
    validations: {
        title: {required}
    },
    components: {
        InputText
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";


</style>
