import axios from '../axios/axios-auth'

export default class OrganizationsService {

    /**
     * V3
     **/

    getAllV3(feature) {
        return axios.get(`/api/v3/organizations?feature=${feature}`).then(response => response.data)
    }

    getOrganizationFeatureFlags(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/featureFlags`).then(response => response.data)
    }

}
