<template>
    <div id="logo" v-if="shouldShowCard(value)">
        {{ title }} <br> <b>{{ value }} {{ symbol }}</b> <br>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true
        },
        value: {
            required: true
        },
        symbol: {
            required: false
        },
    },
    methods: {
        shouldShowCard(val) {
            return isFinite(parseFloat(val)) && !isNaN(parseFloat(val));
        },
    }
}
</script>

<style scoped>
#logo {
    color: #4b4b4b;
    min-width: 100px;
    border-radius: 3px;
    position: relative;
    padding: 10px;
    font-size: 15px;
    background-color: rgba(242, 242, 242, .8);
    left: 0.6%;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
}
</style>
