<template>
    <div class="container card-style" @click="click">
        <div class="row">
            <div class="col-5 pl-4">
                <img v-if='imgUrl' :src="imgUrl" @error="defaultImage" alt="vehicle image"/>
                <img v-else :src="defaultImgUrl" @error="defaultImage" alt="vehicle image"/>
            </div>
            <div class="col-7" style="text-align: left;">
                <div class="row">
                    <b style="font-size: 15px;" class="col-8">{{vehicle.display_id}}</b>

                    <div class="col-4">
                        <b style="float: right" class="badge counter">{{vehicle.count}}</b>
                    </div>

                </div>
                <b style="font-size: 14px;">{{vehicle.frente_corte}}</b>

                <p><span :class="'badge priorities-' + vehicle.priority">{{getPriority(vehicle.priority)}}</span></p>
                <p style="position: relative; bottom: 10px"><span :class="'badge status-' + vehicle.status">{{getStatus(vehicle.status)}}</span></p>
                <p></p>
            </div>
        </div>
    </div>
</template>

<script>

import {STATUSES} from './AppOptions';
import {PRIORITIES} from './AppOptions';

export default {
    mounted() {
        if (!this.vehicle) {
            return;
        }

        this.getVehicleImg();
    },
    data() {
        return {
            imgUrl: null,
            defaultImgUrl: 'https://static.iotag.com.br/vehicles/vehicle.png',
            statuses: STATUSES,
            priorities: PRIORITIES,
        }
    },

    props: {
        vehicle: {
            type: Object,
            required: true
        },
    },
    methods: {
        defaultImage(event) {
            event.target.src = 'https://static.iotag.com.br/vehicles/vehicle.png';
        },
        click() {
            this.$emit('handleClickCard', this.vehicle.identification_number)
        },
        getPriority(priority) {
            let result = this.priorities.find(({code}) => code === priority);
            if (result) {
                return result.name;
            }
        },
        getStatus(status) {
            let result = this.statuses.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        getVehicleImg() {
            if (this.vehicle.model) {
                let model = this.vehicle.model.replace(/ /g, "_").toUpperCase();
                this.imgUrl = 'https://static.iotag.com.br/vehicles/' + model + '.png';
            }
        }
    }
}
</script>

<style scoped lang="scss">

@import "src/components/views/alerts/style";

.container {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    border-color: red;
}

.card-style {
    cursor: pointer;
    border: 2px solid #dbdbdb;
}

img {
    max-width: 100px;
    max-height: 100px;
}


</style>
