<template>
    <div class="alert fade show" :class="class_type">
        <div class="alert-icon">
            <i class="glyphicons" :class="icon"></i></div>
        <div class="alert-text">
            <slot></slot>
        </div>
        <div class="alert-close">
            <button type="button" class="close" @click="handleClick">
                <span aria-hidden="true"><i class="glyphicons glyphicons-remove"></i></span>
            </button>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'primary'
            },
            icon: {
                type: String
            }
        },
        computed: {
            class_type: function () {
                return 'alert-' + this.type;
            }
        },
        methods: {
            handleClick() {
                this.$emit('handleClick');
            }
        }
    }
</script>

<style scoped>

    .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }

    .alert-heading {
        color: inherit;
    }

    .alert-link {
        font-weight: 700;
    }

    .alert-dismissible {
        padding-right: 4rem;
    }

    .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 1.25rem;
        color: inherit;
    }

    .alert-primary {
        color: #2e3673;
        background-color: #dee1f8;
        border-color: #d0d4f5;
    }

    .alert-primary hr {
        border-top-color: #bbc1f1;
    }

    .alert-primary .alert-link {
        color: #1f254f;
    }

    .alert-secondary {
        color: #75757c;
        background-color: #f9f9fc;
        border-color: #f7f7fb;
    }

    .alert-secondary hr {
        border-top-color: #e6e6f3;
    }

    .alert-secondary .alert-link {
        color: #5c5c62;
    }

    .alert-success {
        color: #0f695f;
        background-color: #d2f4f1;
        border-color: #c0f0eb;
    }

    .alert-success hr {
        border-top-color: #abebe4;
    }

    .alert-success .alert-link {
        color: #093c37;
    }

    .alert-info {
        color: #2c3e7a;
        background-color: #dde4fb;
        border-color: #cfd9f9;
    }

    .alert-info hr {
        border-top-color: #b8c7f6;
    }

    .alert-info .alert-link {
        color: #1e2b55;
    }

    .alert-warning {
        color: #856012;
        background-color: #fff1d3;
        border-color: #ffebc1;
    }

    .alert-warning hr {
        border-top-color: #ffe3a8;
    }

    .alert-warning .alert-link {
        color: #58400c;
    }

    .alert-danger {
        color: #841e3f;
        background-color: #ffd7e4;
        border-color: #fec8da;
    }

    .alert-danger hr {
        border-top-color: #feafc9;
    }

    .alert-danger .alert-link {
        color: #5a152b;
    }

    .alert-light {
        color: #818182;
        background-color: #fefefe;
        border-color: #fdfdfe;
    }

    .alert-light hr {
        border-top-color: #ececf6;
    }

    .alert-light .alert-link {
        color: #686868;
    }

    .alert-dark {
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
    }

    .alert-dark hr {
        border-top-color: #b9bbbe;
    }

    .alert-dark .alert-link {
        color: #040505;
    }


    .alert {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        padding: 1rem 2rem;
        margin: 0 0 20px 0;
        border-radius: 4px;
    }

    .alert .alert-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 1.25rem 0 0;
    }

    .alert .alert-icon i {
        font-size: 2.4rem;
    }

    .alert .alert-icon.alert-icon-top {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
    }

    .alert .alert-text {
        -ms-flex-item-align: center;
        align-self: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .alert .alert-close {
        padding: 0 0 0 1rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .alert .alert-close .close {
        text-shadow: none !important;
    }

    .alert .alert-close i {
        opacity: 0.8;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .alert .alert-close i:hover {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 1;
    }

    .alert.alert-elevate {
        -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
        box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
    }

    .alert.alert-brand {
        background: #2c77f4;
        border: 1px solid #2c77f4;
        color: #ffffff;
    }

    .alert.alert-brand .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-brand .alert-text {
        color: #ffffff;
    }

    .alert.alert-brand .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-brand .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-brand {
        border: 1px solid #2c77f4;
        color: #2c77f4;
    }

    .alert.alert-outline-brand .alert-icon i {
        color: #2c77f4;
    }

    .alert.alert-outline-brand .alert-text {
        color: #2c77f4;
    }

    .alert.alert-outline-brand .alert-close i {
        color: #2c77f4;
    }

    .alert.alert-solid-brand {
        background: rgba(44, 119, 244, 0.1);
        border-color: transparent;
        color: #2c77f4;
    }

    .alert.alert-solid-brand .alert-icon i {
        color: #2c77f4;
    }

    .alert.alert-solid-brand .alert-text {
        color: #2c77f4;
    }

    .alert.alert-solid-brand .alert-close i {
        color: #2c77f4;
    }

    .alert.alert-light {
        background: #ffffff;
        border: 1px solid #ffffff;
        color: #282a3c;
    }

    .alert.alert-light .alert-icon i {
        color: #282a3c;
    }

    .alert.alert-light .alert-text {
        color: #282a3c;
    }

    .alert.alert-light .alert-text hr {
        border-top-color: #282a3c;
        opacity: 0.4;
    }

    .alert.alert-light .alert-close i {
        color: #282a3c;
    }

    .alert.alert-outline-light {
        border: 1px solid #ffffff;
        color: #ffffff;
    }

    .alert.alert-outline-light .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-outline-light .alert-text {
        color: #ffffff;
    }

    .alert.alert-outline-light .alert-close i {
        color: #ffffff;
    }

    .alert.alert-solid-light {
        background: rgba(255, 255, 255, 0.1);
        border-color: transparent;
        color: #ffffff;
    }

    .alert.alert-solid-light .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-solid-light .alert-text {
        color: #ffffff;
    }

    .alert.alert-solid-light .alert-close i {
        color: #ffffff;
    }

    .alert.alert-dark {
        background: #282a3c;
        border: 1px solid #282a3c;
        color: #ffffff;
    }

    .alert.alert-dark .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-dark .alert-text {
        color: #ffffff;
    }

    .alert.alert-dark .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-dark .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-dark {
        border: 1px solid #282a3c;
        color: #282a3c;
    }

    .alert.alert-outline-dark .alert-icon i {
        color: #282a3c;
    }

    .alert.alert-outline-dark .alert-text {
        color: #282a3c;
    }

    .alert.alert-outline-dark .alert-close i {
        color: #282a3c;
    }

    .alert.alert-solid-dark {
        background: rgba(40, 42, 60, 0.1);
        border-color: transparent;
        color: #282a3c;
    }

    .alert.alert-solid-dark .alert-icon i {
        color: #282a3c;
    }

    .alert.alert-solid-dark .alert-text {
        color: #282a3c;
    }

    .alert.alert-solid-dark .alert-close i {
        color: #282a3c;
    }

    .alert.alert-primary {
        background: #5867dd;
        border: 1px solid #5867dd;
        color: #ffffff;
    }

    .alert.alert-primary .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-primary .alert-text {
        color: #ffffff;
    }

    .alert.alert-primary .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-primary .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-primary {
        border: 1px solid #5867dd;
        color: #5867dd;
    }

    .alert.alert-outline-primary .alert-icon i {
        color: #5867dd;
    }

    .alert.alert-outline-primary .alert-text {
        color: #5867dd;
    }

    .alert.alert-outline-primary .alert-close i {
        color: #5867dd;
    }

    .alert.alert-solid-primary {
        background: rgba(88, 103, 221, 0.1);
        border-color: transparent;
        color: #5867dd;
    }

    .alert.alert-solid-primary .alert-icon i {
        color: #5867dd;
    }

    .alert.alert-solid-primary .alert-text {
        color: #5867dd;
    }

    .alert.alert-solid-primary .alert-close i {
        color: #5867dd;
    }

    .alert.alert-success {
        background: #1dc9b7;
        border: 1px solid #1dc9b7;
        color: #ffffff;
    }

    .alert.alert-success .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-success .alert-text {
        color: #ffffff;
    }

    .alert.alert-success .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-success .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-success {
        border: 1px solid #1dc9b7;
        color: #1dc9b7;
    }

    .alert.alert-outline-success .alert-icon i {
        color: #1dc9b7;
    }

    .alert.alert-outline-success .alert-text {
        color: #1dc9b7;
    }

    .alert.alert-outline-success .alert-close i {
        color: #1dc9b7;
    }

    .alert.alert-solid-success {
        background: rgba(29, 201, 183, 0.1);
        border-color: transparent;
        color: #1dc9b7;
    }

    .alert.alert-solid-success .alert-icon i {
        color: #1dc9b7;
    }

    .alert.alert-solid-success .alert-text {
        color: #1dc9b7;
    }

    .alert.alert-solid-success .alert-close i {
        color: #1dc9b7;
    }

    .alert.alert-info {
        background: #5578eb;
        border: 1px solid #5578eb;
        color: #ffffff;
    }

    .alert.alert-info .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-info .alert-text {
        color: #ffffff;
    }

    .alert.alert-info .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-info .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-info {
        border: 1px solid #5578eb;
        color: #5578eb;
    }

    .alert.alert-outline-info .alert-icon i {
        color: #5578eb;
    }

    .alert.alert-outline-info .alert-text {
        color: #5578eb;
    }

    .alert.alert-outline-info .alert-close i {
        color: #5578eb;
    }

    .alert.alert-solid-info {
        background: rgba(85, 120, 235, 0.1);
        border-color: transparent;
        color: #5578eb;
    }

    .alert.alert-solid-info .alert-icon i {
        color: #5578eb;
    }

    .alert.alert-solid-info .alert-text {
        color: #5578eb;
    }

    .alert.alert-solid-info .alert-close i {
        color: #5578eb;
    }

    .alert.alert-warning {
        background: #ffb822;
        border: 1px solid #ffb822;
        color: #111111;
    }

    .alert.alert-warning .alert-icon i {
        color: #111111;
    }

    .alert.alert-warning .alert-text {
        color: #111111;
    }

    .alert.alert-warning .alert-text hr {
        border-top-color: #111111;
        opacity: 0.4;
    }

    .alert.alert-warning .alert-close i {
        color: #111111;
    }

    .alert.alert-outline-warning {
        border: 1px solid #ffb822;
        color: #ffb822;
    }

    .alert.alert-outline-warning .alert-icon i {
        color: #ffb822;
    }

    .alert.alert-outline-warning .alert-text {
        color: #ffb822;
    }

    .alert.alert-outline-warning .alert-close i {
        color: #ffb822;
    }

    .alert.alert-solid-warning {
        background: rgba(255, 184, 34, 0.1);
        border-color: transparent;
        color: #ffb822;
    }

    .alert.alert-solid-warning .alert-icon i {
        color: #ffb822;
    }

    .alert.alert-solid-warning .alert-text {
        color: #ffb822;
    }

    .alert.alert-solid-warning .alert-close i {
        color: #ffb822;
    }

    .alert.alert-danger {
        background: #fd397a;
        border: 1px solid #fd397a;
        color: #ffffff;
    }

    .alert.alert-danger .alert-icon i {
        color: #ffffff;
    }

    .alert.alert-danger .alert-text {
        color: #ffffff;
    }

    .alert.alert-danger .alert-text hr {
        border-top-color: #ffffff;
        opacity: 0.4;
    }

    .alert.alert-danger .alert-close i {
        color: #ffffff;
    }

    .alert.alert-outline-danger {
        border: 1px solid #fd397a;
        color: #fd397a;
    }

    .alert.alert-outline-danger .alert-icon i {
        color: #fd397a;
    }

    .alert.alert-outline-danger .alert-text {
        color: #fd397a;
    }

    .alert.alert-outline-danger .alert-close i {
        color: #fd397a;
    }

    .alert.alert-solid-danger {
        background: rgba(253, 57, 122, 0.1);
        border-color: transparent;
        color: #fd397a;
    }

    .alert.alert-solid-danger .alert-icon i {
        color: #fd397a;
    }

    .alert.alert-solid-danger .alert-text {
        color: #fd397a;
    }

    .alert.alert-solid-danger .alert-close i {
        color: #fd397a;
    }

    .alert.alert-secondary .alert-icon i {
        color: #74788d;
    }

    .alert.alert-secondary .alert-text {
        color: #595d6e;
    }

    .alert.alert--marginless {
        margin: 0;
    }

    .alert.alert-bold .alert-text {
        font-weight: 500;
    }


    .alert[data-notify] {
        min-width: 300px;
    }

    .alert[data-notify] .close {
        right: 10px !important;
        padding: 0.25rem 0 0 2rem;
        font-family: "LineAwesome";
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        font-weight: 300;
    }

    .alert[data-notify] .close:before {
        content: "";
    }

    .alert[data-notify] .close:before {
        font-size: 1.1rem;
        color: #fff;
        opacity: 0.7;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .alert[data-notify] .close:hover:before {
        opacity: 1;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .alert[data-notify] {
            max-width: 70%;
        }
    }

    @media (max-width: 768px) {
        .alert[data-notify] {
            max-width: 90%;
        }
    }

    .alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center] {
        width: 30%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        .alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center] {
            width: 70%;
        }
    }

    @media (max-width: 768px) {
        .alert[data-notify][data-notify-position=top-center], .alert[data-notify][data-notify-position=bottom-center] {
            width: 90%;
        }
    }

    .alert[data-notify] .icon {
        position: absolute;
    }

    .alert[data-notify] [class^="la-"],
    .alert[data-notify] [class*=" la-"] {
        font-size: 1.8rem;
    }

    .alert[data-notify] [class^="fa-"],
    .alert[data-notify] [class*=" fa-"] {
        font-size: 1.6rem;
    }

    .alert[data-notify] [class^="la-"],
    .alert[data-notify] [class*=" la-"] {
        margin-top: -0.1rem;
    }

    .alert[data-notify] [class^="fa-"],
    .alert[data-notify] [class*=" fa-"] {
        margin-top: -0.1rem;
    }

    .alert[data-notify] [data-notify=title] {
        display: block;
        font-weight: 500;
    }

    .alert[data-notify] .icon ~ [data-notify=title] {
        padding-left: 2.85rem;
    }

    .alert[data-notify] .icon ~ [data-notify=message] {
        display: inline-block;
        padding-left: 2.85rem;
    }

    .alert[data-notify] [data-notify=title]:not(:empty) ~ [data-notify=message] {
        margin-top: 0.2rem;
    }

    .alert[data-notify] .progress {
        margin-top: 0.5rem;
        line-height: 0.5rem;
        height: 0.5rem;
    }
</style>
