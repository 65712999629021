<template>
    <div id="google-maps-realtime"></div>
</template>

<script>
import {MarkerClusterer} from "@googlemaps/markerclusterer";

import moment from 'moment';
import gmapsInit from '../../../utils/gmaps';
import getMarkerState from '../../../mixins/VehicleMarkerMixin';
import getRotatedIcon from '../../../mixins/VehicleMarkerMixin';
import getVehicleStatusCode from '../../../mixins/VehicleMarkerMixin';
import maskMacAddress from "../../../mixins/StringsMaskMixin";
import {mapGetters} from 'vuex';
import RealtimeService from "../../../services/RealtimeService";

import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import {PolygonLayer, PathLayer} from '@deck.gl/layers';

import CoordMapType from "../../../classes/CoordMapType";

import {MAPS_OVERLAY_WEATHER_TYPES} from './AppOptions';
import {MAPS_DEFAULT_CONFIGS} from './AppOptions';

import * as jsts from 'jsts';
import i18n from "@/i18n";
import dateHourFormat from "@/mixins/DateMixin";
import {PRIORITIES} from "@/components/views/alerts/machine/AppOptions";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";
import MeasureTool from '@/utils/measuretool-googlemaps-v3.js';


export default {
    mixins: [getMarkerState, maskMacAddress, getVehicleStatusCode, dateHourFormat, getRotatedIcon, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        let mapType = 'hybrid';

        if (this.isUserDemo) {
            mapType = 'satellite'
        }

        let vm = this;
        try {
            const google = await gmapsInit();
            vm.google_maps_reference = google;
            vm.maxZoomService = new google.maps.MaxZoomService();
            let mapElement = document.getElementById('google-maps-realtime');
            this.map = new google.maps.Map(mapElement, {
                // https://developers.google.com/maps/documentation/javascript/tutorial#MapOptions
                minZoom: 4, maxZoom: 20,
                zoom: MAPS_DEFAULT_CONFIGS.ZOOM,
                center: vm.positionCenter,
                pan: true,
                mapTypeId: mapType,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true
            });

            const renderer = {
                render: function ({count, position}, stats) {
                    return new google.maps.Marker({
                        position: position,
                        icon: {
                            url: require('@/assets/images/vehicles/white.png'),
                            labelOrigin: new google.maps.Point(27, 27)
                        },
                        label: {
                            text: String(count),
                            color: "black",
                            fontSize: "12px",
                            fontWeight: 'bold'
                        },
                    });
                },
            };

            vm.marker_clusterer = new MarkerClusterer({
                map: vm.map, markers: vm.markers_cluster, renderer: renderer
            });

            this.map.addListener('click', event => {

                let infoWindowField = new google.maps.InfoWindow();

                if (!this.overlay || !event.pixel) {
                    return;
                }

                const deckPicked = this.overlay._deck.pickObject({
                    x: event.pixel.x,
                    y: event.pixel.y,
                    radius: 4
                });

                if (!deckPicked) {
                    infoWindowField.close();
                    return;
                }

                if (deckPicked.layer && deckPicked.layer.id === "connectivityPaths") {
                    infoWindowField.close();
                    return;
                }


                if (vm.getInfoWindowByIndex(deckPicked.index) >= 0) {
                    return;
                }
                infoWindowField.setContent("<b>" + deckPicked.object.name + "</b> <br/>" + deckPicked.object.area.toFixed(2) + " ha" +
                    "<br><a href='/agriculture/tasks?org_id=" + this.selected_organization_id + "&field_id=" + deckPicked.object.id + "'" +
                    "style='text-decoration: underline !important;'>Ir para análise</a>");
                infoWindowField.setPosition({lng: event.latLng.lng(), lat: event.latLng.lat()});
                infoWindowField.open(vm.map);
                infoWindowField.index = deckPicked.index;

                vm.fieldInfoWindows.push(infoWindowField);
                vm.commentedInfoWindow(vm.show_panel);

                this.google_maps_reference.maps.event.addListener(infoWindowField, 'closeclick', function () {
                    vm.handleCloseClickInfoWindow(this);
                });

            });

            mapElement.addEventListener('wheel', vm.wheelEvent, true);

            // setTimeout(function () {
            vm.map.addListener('zoom_changed', () => vm.handleZoomChanged());
            vm.map.addListener('dragend', () => vm.handleBoundsChanged());
            vm.handleBoundsChanged();
            // }, 2000);

            let measureTool = new MeasureTool(vm.map, {
                showSegmentLength: true,
                unit: MeasureTool.UnitTypeId.METRIC, // or just use 'imperial',
                language: 'pt'
            });

        } catch (error) {
            console.error(error);
        }

        this.realTimeService = new RealtimeService();

        setTimeout(function () {
            vm.handleBoundsChanged();
        }, 1000)

    },
    data() {
        return {
            fieldInfoWindows: [],
            operationalAlertInfoWindow: null,
            machineAlertInfoWindow: null,
            overlay: null,

            realTimeService: null,

            marker_clusterer: '',
            //mapa com os markers, key é o id da maquina
            markersMap: new Map(),

            //mapa com as infoWindows, key é o id da maquina
            infoWindowsMap: new Map(),

            //mapa com as info adicionais mostradas no infowindow, key é o id da maquina
            infoWindowAdditionalDataMap: new Map(),

            //mapa com última estrutura de localização, direção e caminho de cada máquina
            vehiclesDirectionMap: new Map(),

            map: null,
            google_maps_reference: {},

            fieldsData: [],
            layers: [],
            INDEX_OVERLAY_TILES: 10,
            INDEX_OVERLAY_APPLICATION_TILES: 9,
            INDEX_OVERLAY_VELOCITY_TILES: 8,
            INDEX_OVERLAY_HUMIDITY_TILES: 7,
            INDEX_OVERLAY_WEATHER: 1,
            INDEX_OVERLAY_TILES_FIELDS: 2,

            markerLocation: null,
            maxZoomService: null,
            polygon: null,
            priorities: PRIORITIES,
            machineAlertMarkers: [],
            operationalAlertMarkers: [],
            markerInitData: null,
            markerEndData: null,
            gapsInMap: []
        }
    },
    props: {
        /**
         * Formato esperado:
         * [{"zoom": 0,"tiles": []},{"zoom": 1,"tiles": []}]
         **/
        tilesData: {
            type: Array
        },
        realtime_data: {
            type: Array
        },
        //posição no mapa, ex: {lat: -14.916356, lng: -53.516697}
        positionCenter: {
            required: true,
        },
        map_type: {
            type: String
        },
        selected_vehicles: {
            type: Array
        },
        selected_statuses: {
            type: Array
        },
        //lista de maquinas que devem ter markers
        vehicles_list: {
            type: Array
        },
        organization_identification: {
            type: String
        },
        weather_overlay: {
            type: String
        },
        marker_historical: {
            type: Object
        },
        marker_location: {
            type: Object
        },
        selected_organization_id: {
            type: String,
        },
        application_tiles_data: {
            type: Array,
        },
        application_on: {
            type: Boolean,
        },
        speed_tiles_data: {
            type: Array,
        },
        speed_on: {
            type: Boolean,
        },
        humidity_tiles_data: {
            type: Array,
        },
        humidity_on: {
            type: Boolean,
        },
        track_on: {
            type: Boolean,
        },
        operational_alerts: {
            type: Array,
        },
        report_dates: {
            type: Object,
        },
        show_panel: {
            type: Boolean,
        },
        display_id_vehicles: {
            type: Array,
        },
        marker_init_data: {
            type: Object,
        },
        marker_end_data: {
            type: Object,
        },
        gaps: {
            type: Array,
        },
        noTrackOn: {
            type: Boolean,
        },
        historical_charts_data: {
            type: Array,
        },
        connectivity_events: {
            type: Array,
        }
    },

    methods: {
        closeAndRemoveFromMapInfoWindow(infoWindow) {
            if (!infoWindow) {
                return;
            }

            infoWindow.setAnchor(null);
            infoWindow.setMap(null);
            infoWindow.close();
        },
        createOperationalAlertInfoWindowString(alert) {
            let infoString = "<b>" + i18n.t('AppGoogleMapsRealTime.Alerta Operacional') + "</b> <br/>";
            infoString += i18n.t('AppGoogleMapsRealTime.Código') + ": <b>" + alert.code + "</b> <br/>";
            infoString += i18n.t('AppGoogleMapsRealTime.Descrição') + ": <b>" + alert.description + "</b> <br/>";
            infoString += alert.priority ? i18n.t('AppGoogleMapsRealTime.Nível') + ": <span style='font-weight: bold' class='badge priorities-" + alert.priority + "'>" + this.getAlertsPriority(alert.priority) + "</span> <br/>" : "";

            infoString += alert.fleet ? i18n.t('AppGoogleMapsRealTime.Máquina') + ": <b>" + alert.fleet + "</b> <br/>" : "";
            infoString += alert.user_identification ? i18n.t('AppGoogleMapsRealTime.Operador') + ": <b>" + alert.user_identification + "</b> <br/>" : "";
            infoString += alert.value ? i18n.t('AppGoogleMapsRealTime.Valor') + ": <b>" + alert.value + " " + (alert.unit ? alert.unit : "") + "</b> <br/>" : "";
            infoString += alert.date ? i18n.t('AppGoogleMapsRealTime.Data') + ": <b>" + this.dateHourFormat(alert.date) + "</b> <br/>" : "";

            infoString += this.report_dates ? "<a href='/alerts/operational?vehicle_id=" +
                alert.vehicle_id + "&start=" + this.report_dates.start_date.valueOf() + "&end=" +
                this.report_dates.end_date.valueOf() + "' style='text-decoration: underline !important;'>ver mais</a>" : ""

            return infoString;
        },
        createMachineAlertInfoWindowString(alert) {
            let infoString = "<b>" + i18n.t('AppGoogleMapsRealTime.Alerta de Máquina') + "</b> <br/>";
            infoString += "<b>" + i18n.t('AppGoogleMapsRealTime.Código') + ": <b>" + alert.code + "</b> <br/>";
            infoString += alert.priority ? i18n.t('AppGoogleMapsRealTime.Nível') + ": <span style='font-weight: bold' class='badge priorities-" + alert.priority + "'>" + this.getAlertsPriority(alert.priority) + "</span> <br/>" : "";

            infoString += alert.identification ? i18n.t('AppGoogleMapsRealTime.Máquina') + ": <b>" + alert.identification + "</b> <br/>" : "";
            infoString += alert.date ? i18n.t('AppGoogleMapsRealTime.Data') + ": <b>" + this.dateHourFormat(alert.date) + "</b> <br/>" : "";

            infoString += this.report_dates ? "<a href='/alerts/machine?vehicle_id=" + alert.vehicle_id + "&start=" + this.report_dates.start_date.valueOf() + "&end=" + this.report_dates.end_date.valueOf() + "' style='text-decoration: underline !important;'>ver mais</a>" : ""

            return infoString;
        },
        getAlertsPriority(priority) {
            let result = this.priorities.find(({code}) => code === priority);
            if (result) {
                return result.name;
            }
        },
        wheelEvent(event) {
            let mapZoomBeforeUpdate = this.map.getZoom();

            if (!this.tilesData || this.tilesData.length === 0) {
                return;
            }

            this.maxZoomService.getMaxZoomAtLatLng(this.map.center, (result) => {
                if (result.status !== "OK") {
                    return;
                }

                if (event.deltaY < 0 && mapZoomBeforeUpdate >= result.zoom && this.map_type !== 'roadmap') {
                    this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.ROADMAP);
                    if (mapZoomBeforeUpdate === this.map.getZoom()) {
                        this.map.setZoom(mapZoomBeforeUpdate + 1)
                    }
                    return;
                }

                if (event.deltaY > 0 && this.map.getZoom() <= result.zoom && this.map_type === 'hybrid') {
                    this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.HYBRID);
                }

            });

        },
        handleZoomChanged() {
            let payload = this.buildPayloadBounds();

            if (payload) {
                this.$emit('handleZoomChanged', payload)
            }
        },
        handleBoundsChanged() {
            let payload = this.buildPayloadBounds();

            if (payload) {
                this.$emit('handleBoundsChanged', payload)
            }
        },
        buildPayloadBounds() {

            //TODO validação
            if (!this.map || !this.map.getBounds()) {
                return null;
            }
            return {
                zoom: this.map.getZoom(),
                sw_lat: parseFloat(this.map.getBounds().getSouthWest().lat().toFixed(6)),
                sw_lng: parseFloat(this.map.getBounds().getSouthWest().lng().toFixed(6)),
                ne_lat: parseFloat(this.map.getBounds().getNorthEast().lat().toFixed(6)),
                ne_lng: parseFloat(this.map.getBounds().getNorthEast().lng().toFixed(6))
            }
        },


        setupWeatherOverlay() {

            let vm = this;

            const imageMapType = new vm.google_maps_reference.maps.ImageMapType({
                name: 'openweathermap',
                getTileUrl: function (coord, zoom) {
                    return "https://tile.openweathermap.org/map/" + vm.weather_overlay + "/" + zoom + "/" + coord.x + "/" + coord.y + ".png?appid=" + process.env.VUE_APP_OPEN_WEATHER_MAP_KEY
                },
                tileSize: new vm.google_maps_reference.maps.Size(256, 256),
            });
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_WEATHER, imageMapType);
        },

        setupTilesFieldsOverlay(orgId) {

            let vm = this;

            const imageMapType = new vm.google_maps_reference.maps.ImageMapType({
                name: 'tiles-fields',
                getTileUrl: function (coord, zoom) {
                    return "https://tiles-fields.iotag.com.br/" + orgId + "/" + zoom + "/" + coord.x + "/" + coord.y + "/"
                        + zoom + "_" + coord.y + ".png";
                },
                tileSize: new vm.google_maps_reference.maps.Size(256, 256),
            });
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_TILES_FIELDS, imageMapType);
        },
        createInfoWindowContentFromVehicleForPanel(id) {
            let vehicle = this.vehicles_list.find((obj) => {
                return obj.id === id
            })
            if (!vehicle) {
                return "N/D";
            }

            let content = '<div>'

            if (this.isValueValid(vehicle.identification)) {
                content += vehicle.identification;
            }

            content += '</div>';

            return content;
        },
        createInfoWindowContentFromVehicle(id) {

            let vehicle = this.vehicles_list.find((obj) => {
                return obj.id === id
            })
            if (!vehicle) {
                return "N/D";
            }

            let content = '<div style="min-width: 200px">';

            let model;
            let deviceMac;
            let hourMeter;
            let chassis;
            let identification;
            let lastContact


            if (this.isValueValid(vehicle.identification)) {
                identification = "<b>" + i18n.t('AppGoogleMapsRealTime.Identificação') + ": </b>" + vehicle.identification + '<br>';
            }

            if (this.isValueValid(vehicle.identification_number)) {
                chassis = "<b>" + i18n.t('AppGoogleMapsRealTime.Chassis') + ": </b>" + vehicle.identification_number + '<br>';
            }

            if (this.isValueValid(vehicle.last_contact)) {
                lastContact = "<b>" + i18n.t('AppGoogleMapsRealTime.Último Contato') + ": </b>" + moment(vehicle.last_contact * 1000).format('DD/MM/YY HH:mm:ss') + '<br>';
            }

            if (this.isValueValid(vehicle.model)) {
                model = "<b>" + i18n.t('AppGoogleMapsRealTime.Marca Modelo') + ": </b>" + vehicle.manufacturer + " - " + vehicle.model + '<br>';
            }

            if (this.isValueValid(vehicle.mac_address)) {
                deviceMac = "<b>" + i18n.t('AppGoogleMapsRealTime.Serial') + ": </b>" + vehicle.mac_address + '<br>';
            }

            if (this.isValueValid(vehicle.hour_meter) && vehicle.hour_meter > 0) {
                hourMeter = "<b>" + i18n.t('AppGoogleMapsRealTime.Horímetro') + ": </b>" + vehicle.hour_meter.toFixed(1) + ' h<br>';
            }

            if (identification) {
                content += identification;
            }
            if (model) {
                content += model;
            }
            if (lastContact) {
                content += lastContact;
            }
            if (hourMeter) {
                content += hourMeter;
            }
            if (chassis) {
                content += chassis;
            }
            if (deviceMac) {
                content += deviceMac;
            }

            content += '</div>';

            return content;
        },
        showInfoWindow(id) {

            let vm = this;

            if (!this.markersMap.has(id)) {
                return;
            }

            this.$emit('handleClickMarker', id);

            let infoWindow = null;

            if (!this.infoWindowsMap.has(id)) {
                if (this.show_panel) {
                    infoWindow = new vm.google_maps_reference.maps.InfoWindow({
                        disableAutoPan: true,
                        pixelOffset: new vm.google_maps_reference.maps.Size(0, 8)
                    });
                } else {
                    infoWindow = new vm.google_maps_reference.maps.InfoWindow({
                        disableAutoPan: true
                    });
                }

                if (this.show_panel) {
                    infoWindow.setContent(this.createInfoWindowContentFromVehicleForPanel(id));
                } else {
                    infoWindow.setContent(this.createInfoWindowContentFromVehicle(id));
                }

                this.google_maps_reference.maps.event.addListener(infoWindow, 'closeclick', function () {
                    vm.closeInfoWindow(id);
                });

                this.infoWindowsMap.set(id, infoWindow);
            } else {
                infoWindow = this.infoWindowsMap.get(id);
            }

            if (infoWindow) {
                infoWindow.open(this.map, this.markersMap.get(id));
            }

            this.getAdditionalInfo(id);
        },

        handleClick(val) {
            this.$emit('handleClickMarker', val);
        },

        closeInfoWindow(vehicleId) {
            //não precisa fechar a info windows somente desmarcar a maquina que o evento de desmarcar
            // volta aqui e desmarca
            this.$emit('handleClickCloseInfoWindow', vehicleId);
        },
        makeLatLong(lat, lng) {
            return new this.google_maps_reference.maps.LatLng(lat, lng)
        },
        buildInfoWindowShowPanel(infoWindow, data) {
            let content = '<div>';
            let vehicleIdentification = this.display_id_vehicles.find(vehicle => vehicle.id === data.id);

            if (vehicleIdentification) {
                content += vehicleIdentification.display_id;
            }

            content += '</div>';

            infoWindow.setContent(content);
        },
        buildInfoWindow(infoWindow, data) {

            let content = '<div style="min-width: 200px">';
            let lastContact;
            if (data._005) {
                lastContact = "<b>" + i18n.t('AppGoogleMapsRealTime.Último Contato') + ": </b>" + moment(data._005 * 1000).format('DD/MM/YY HH:mm:ss') + '<br>';
            }

            let chasis;

            if (this.isValueValid(data._035)) {
                chasis = "<b>" +  i18n.t('AppGoogleMapsRealTime.Chassis') +  ": </b>" + data._035 + '<br>';
            }

            let info = this.getAdditionalInfo(data.id);

            let vehicleIdentification;
            let model;
            let deviceMac;
            let driver;
            let activity;
            let connection;
            let hourMeter;

            if (info && lastContact) {
                if (this.isValueValid(info.vehicle_identification)) {
                    vehicleIdentification = "<b>" + i18n.t('AppGoogleMapsRealTime.Identificação') + ": </b>" + info.vehicle_identification + '<br>';
                }

                if (this.isValueValid(info.vehicle_model)) {
                    model = "<b>" + i18n.t('AppGoogleMapsRealTime.Marca Modelo') + ": </b>" + info.vehicle_model + '<br>';
                }

                if (this.isValueValid(info.device_mac)) {
                    deviceMac = "<b>" + i18n.t('AppGoogleMapsRealTime.Serial') + ": </b>" + info.device_mac + '<br>';
                }

                if (this.isValueValid(info.driver) && info.driver !== "\"Não Identificado\"") {
                    driver = "<b>" + i18n.t('AppGoogleMapsRealTime.Operador') + ": </b>" + info.driver + '<br>';
                }

                if (this.isValueValid(info.activity) && info.activity !== "\"Não Identificado\"") {
                    activity = "<b>" + i18n.t('AppGoogleMapsRealTime.Atividade') + ": </b>" + info.activity + '<br>';
                }

                if (this.isValueValid(info.hour_meter) && info.hour_meter > 0) {
                    hourMeter = "<b>" + i18n.t('AppGoogleMapsRealTime.Horímetro') + ": </b>" + info.hour_meter.toFixed(1) + ' h<br>';
                }


            } else {
                infoWindow.setContent(this.createInfoWindowContentFromVehicle(data.id));
                return
            }

            if (this.isValueValid(data.connection)) {
                connection = "<b>Meio de comunicação: </b>" + data.connection + '<br>';
            }

            if (vehicleIdentification) {
                content += vehicleIdentification;
            }
            if (model) {
                content += model;
            }
            if (lastContact) {
                content += lastContact;
            }
            if (hourMeter) {
                content += hourMeter;
            }
            if (chasis) {
                content += chasis;
            }
            if (deviceMac) {
                content += deviceMac;
            }
            if (activity) {
                content += activity;
            }
            if (connection) {
                content += connection;
            }
            if (driver) {
                content += driver;
            }


            content += '</div>';

            infoWindow.setContent(content);
        },

        getAdditionalInfo(vehicleId) {
            let info = this.infoWindowAdditionalDataMap.get(vehicleId);

            if (!info) {
                this.requestAdditionalInfo(vehicleId);
                return;
            }
            return info;
        },
        requestAdditionalInfo(vehicleId) {
            let vehicleDeviceMac = this.getVehicleDeviceLinked(vehicleId);

            if (!vehicleDeviceMac) {
                return;
            }

            this.realTimeService.requestAdditionalData(vehicleId, vehicleDeviceMac)
                .then((response) => {
                    this.infoWindowAdditionalDataMap.set(vehicleId, response);
                }).catch((error) => {
                console.log(error)
            })
        },
        getVehicleDeviceLinked(vehicleId) {
            let vehicleObj = this.vehicles_list.find(obj => {
                return obj.id === vehicleId
            });

            if (!vehicleObj || !vehicleObj.mac_address) {
                return null;
            }

            return vehicleObj.mac_address;
        },
        isValueValid(val) {
            if (typeof val === 'undefined') {
                return false;
            }

            if (val == null) {
                return false;
            }

            if (val.length === 0) {
                return false;
            }

            return true;
        },
        removeMarkerAndInfoWindow(vehicleId) {
            let markerToRemove = this.markersMap.get(vehicleId);
            this.marker_clusterer.removeMarker(markerToRemove);

            this.markersMap.delete(vehicleId);
            this.removeInfoWindow(vehicleId);
        },
        removeInfoWindow(vehicleId) {
            let infoWindow = this.infoWindowsMap.get(vehicleId);

            if (infoWindow) {
                infoWindow.setMap(null);
                infoWindow.setAnchor(null);
                infoWindow.close();
            }
            this.infoWindowsMap.delete(vehicleId);
        },
        removeAllMarkers() {
            for (const key of this.markersMap.keys()) {
                this.markersMap.get(key).setMap(null)
                this.markersMap.delete(key);
                this.removeInfoWindow(key);
            }

            this.marker_clusterer.clearMarkers();
        },
        isMarkerVisible(lat, lng) {
            if (!this.map) {
                return false;
            }

            if (lat < this.map.getBounds().getSouthWest().lat()) {
                return false;
            }

            if (lat > this.map.getBounds().getNorthEast().lat()) {
                return false;
            }

            if (lng < this.map.getBounds().getSouthWest().lng()) {
                return false;
            }

            if (lng > this.map.getBounds().getNorthEast().lng()) {
                return false;
            }

            return true;
        },
        handleDataRealTime(data) {

            if (this.show_panel) {
                let newdata = [];
                data.forEach(element => {
                    this.selected_vehicles.forEach(select => {
                        if (element.id === select.id) {
                            newdata.push(element);
                        }
                    });
                });
                data = newdata;
            }

            let vehicles_updated = []
            data.forEach(vehicleData => {

                if (!vehicleData || !vehicleData._009 || !vehicleData._010) {
                    return;
                }

                if (!this.isStatusSelected(vehicleData)) {
                    this.removeMarkerAndInfoWindow(vehicleData.id);
                    return;
                }

                // Pega o tipo da máquina
                let type;
                this.vehicles_list.forEach(element => {
                    element.type = element.type === undefined ? "Indefinido" : element.type;
                    if (element.id === vehicleData.id) {
                        type = element.type
                    }
                });


                // Não tem o marker dessa maquina
                if (!this.markersMap.has(vehicleData.id)) {
                    this.initVehiclesDirectionMap(vehicleData.id, this.makeLatLong(vehicleData._009, vehicleData._010), vehicleData);
                    this.addFirstVehicleMarker(vehicleData, type);
                    return;
                }

                // Atualiza status do marcador se necessário
                if (this.shouldUpdateVehicleMarkerByState(vehicleData.id, vehicleData)) {
                    this.setVehicleIcon(this.updateVehicleState(vehicleData.id, vehicleData), vehicleData, type);
                }

                // Atualiza posição e angulação do marcador se necessário
                if (this.shouldUpdateVehicleMarkerByDistance(vehicleData.id, vehicleData)) {
                    this.setVehicleIcon(this.updateDirectionsMap(vehicleData.id, vehicleData), vehicleData, type);
                }

                if (this.selected_vehicles) {
                    if (this.selected_vehicles.find(v => v.id === vehicleData.id)) {
                        this.classListInfoWindow(this.show_panel);
                        this.updateInfoWindow(vehicleData);
                        vehicles_updated.push(vehicleData.id);
                    }
                }
            })
        },
        // Para iniciar o handleDataRealTime a primeira vez ou quando for adicionado um novo dinamicamente
        initVehiclesDirectionMap(id, coordinates, vehicleData) {
            this.vehiclesDirectionMap.set(id, {
                firstDirCoordinates: coordinates,
                lastDirCoordinates: null,
                lastPosition: coordinates,
                iconAngle: 0,
                state: this.getMarkerState(vehicleData)
            })
        },
        addFirstVehicleMarker(vehicleData, type) {
            let icon = {
                url: this.getRotatedIcon(vehicleData, 0, type),
                origin: new this.google_maps_reference.maps.Point(0, 0)
            };

            let marker = new this.google_maps_reference.maps.Marker({
                position: this.makeLatLong(vehicleData._009, vehicleData._010),
                icon: icon,
                title: vehicleData._035,
                id: vehicleData.id
            });
            marker.addListener("click", () => {
                this.$emit('handleClickMarker', vehicleData.id);
                this.showInfoWindow(vehicleData.id)
            });

            // A clusterização é feita de acordo com o status do painel de visualização
            if (this.show_panel) {
                marker.setMap(this.map);
            } else {
                this.marker_clusterer.addMarker(marker);
            }
            this.markersMap.set(vehicleData.id, marker)

            // Se o painel de visualização estiver ligado, todos os infoWindow são colocados na tela
            if (this.show_panel) {
                this.handlePanelUpdates(vehicleData);
            } else {
                this.updateVehiclesIdsInVisibleCluster();
            }
        },
        handlePanelUpdates(vehicleData) {
            if (this.selected_vehicles && this.selected_vehicles.find(v => v.id === vehicleData.id)) {
                this.showInfoWindow(vehicleData.id);
                this.classListInfoWindow(this.show_panel);
                this.updateInfoWindow(vehicleData);
                this.$emit('updateVehiclesIdsInVisibleCluster', []);
            }
        },
        // Em caso de alteração de status do marcador
        shouldUpdateVehicleMarkerByState(id, vehicleData) {
            if (this.vehiclesDirectionMap.get(id).state !== this.getMarkerState(vehicleData)) {
                return true;
            }
        },
        updateVehicleState(id, vehicleData) {
            let vehicleDirection = this.vehiclesDirectionMap.get(id);
            vehicleDirection.state = this.getMarkerState(vehicleData);
            this.vehiclesDirectionMap.set(id, vehicleDirection);
            return vehicleDirection;
        },
        // Para setar na tela o status e posição do marker
        setVehicleIcon(vehicleDirectionsObj, data, type) {
            let icon = {
                url: this.getRotatedIcon(data, vehicleDirectionsObj.iconAngle, type),
                origin: new this.google_maps_reference.maps.Point(0, 0)
            };
            this.markersMap.get(data.id).setIcon(icon);
            this.markersMap.get(data.id).setPosition(vehicleDirectionsObj.lastPosition);
        },
        // Para verificar e calcular a angulação correta do marcador
        shouldUpdateVehicleMarkerByDistance(id, vehicleData) {
            return this.calculateCoordinatesDistance(this.vehiclesDirectionMap.get(id).lastPosition,
                this.makeLatLong(vehicleData._009, vehicleData._010)) > 0;
        },
        updateDirectionsMap(id, vehicleData) {
            let vehicleDirection = this.vehiclesDirectionMap.get(id);
            let newCoordinates = this.makeLatLong(vehicleData._009, vehicleData._010);

            vehicleDirection.iconAngle = this.lineAngle360(vehicleDirection.lastPosition, newCoordinates);

            vehicleDirection.lastPosition = newCoordinates;
            vehicleDirection.state = this.getMarkerState(vehicleData);

            this.vehiclesDirectionMap.set(id, vehicleDirection);
            return vehicleDirection;
        },
        lineAngle360(firstCoordinates, lastCoordinates) {
            let deltaX = lastCoordinates.lat() - firstCoordinates.lat();
            let deltaY = lastCoordinates.lng() - firstCoordinates.lng();

            let angleRadians = Math.atan2(deltaX, deltaY) * 180 / Math.PI;

            return (angleRadians + 360) % 360;
        },
        calculateCoordinatesDistance(firstCoordinate, secondCoordinate) {
            return this.google_maps_reference.maps.geometry.spherical.computeDistanceBetween(firstCoordinate,
                secondCoordinate);
        },
        updateVehiclesIdsInVisibleCluster() {
            let vehiclesIdsInVisibleCluster = [];
            if (!this.marker_clusterer || !this.marker_clusterer.clusters ||
                this.marker_clusterer.clusters.length === 0) {
                this.$emit('updateVehiclesIdsInVisibleCluster', vehiclesIdsInVisibleCluster)
                return;
            }

            this.marker_clusterer.clusters.forEach(cluster => {
                if (!cluster.markers[0]) {
                    return;
                }

                if (!this.isMarkerVisible(cluster.markers[0].position.lat(), cluster.markers[0].position.lng())) {
                    return;
                }

                vehiclesIdsInVisibleCluster.push(cluster.markers[Math.floor(Math.random() * cluster.markers.length)].id)
            })

            this.$emit('updateVehiclesIdsInVisibleCluster', vehiclesIdsInVisibleCluster)
        },

        /**
         * se n tem status selecionado retorna true
         * caso contrario retorna true conforme o status selecionado e de cada maquina
         */
        isStatusSelected(vehicleData) {

            //se n tem nenhum selecionado mostra tudos
            if (!this.selected_statuses || this.selected_statuses.length === 0) {
                return true;
            }

            //caso contrario mostra so o que tem no select de status
            let status = this.getVehicleStatusCode(vehicleData);
            return this.selected_statuses.some(e => e.code === status);
        },
        updateInfoWindow(data) {

            let infoWindow = this.infoWindowsMap.get(data.id);
            if (infoWindow) {
                if (this.show_panel) {
                    this.buildInfoWindowShowPanel(infoWindow, data);
                } else {
                    this.buildInfoWindow(infoWindow, data);
                }
            } else {
                this.showInfoWindow(data.id);
            }
        },
        removeInfoWindowVehiclesNotMoreSelected(vehiclesSelected) {
            //remove as infoWindow dos veiculos que foram desmarcados usando o select

            //n tem nenhum selecionado esconde todos
            if (vehiclesSelected.length === 0) {
                for (const key of this.infoWindowsMap.keys()) {
                    this.removeInfoWindow(key);
                }
                return;
            }

            //caso contrario remove so o que não está selecionado
            for (const key of this.infoWindowsMap.keys()) {

                let vehicleId = vehiclesSelected.find(v => v.id === key);

                if (!vehicleId) {
                    this.removeInfoWindow(key);
                }
            }
        },
        addInfoWindowVehiclesSelected(vehiclesSelected) {
            //adiciona as infowindows das maquinas que foram selecionadas usando o select
            vehiclesSelected.forEach((vehicle) => {
                if (!this.infoWindowsMap.has(vehicle.id)) {
                    this.showInfoWindow(vehicle.id);
                }

                //Contruindo infowindow padrão, pq realtime não é mais chamado constantemente para quem nao tem mac
                if (!vehicle.mac) {
                    let infoWindow = this.infoWindowsMap.get(vehicle.id);
                    this.buildDefaultInfoWindow(infoWindow, vehicle.id)
                }
            });
        },
        buildDefaultInfoWindow(infoWindow, vehicle_id) {
            if (!infoWindow || this.show_panel) {
                return;
            }

            infoWindow.setContent(this.createInfoWindowContentFromVehicle(vehicle_id));
        },
        hexToRgb(hex) {
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;

            return [r, g, b];
        },
        newData(data) {
            this.layers = [];

            this.layers.push(
                new PolygonLayer({
                    id: 'fields-layer',
                    data: data,
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    lineWidthMinPixels: 2,
                    getPolygon: d => d.coordinates,
                    getFillColor: d => this.hexToRgb(d.color),
                    getLineColor: d => this.hexToRgb(d.color),
                    getLineWidth: 0.1,
                    opacity: 0.4
                })
            );

            this.overlay.setProps({layers: Object.values(this.layers)});
        },
        buildGeomFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        fieldsToMap(fields) {

            let data = [];

            fields.forEach(field => {

                if (field.type === 'UNPRODUCTIVE') {
                    return
                }

                let polygon = this.buildGeomFromCoordinates(field.coordinates)

                let newField = {
                    area: (this.google_maps_reference.maps.geometry.spherical.computeArea(
                        this.jstsWithoutHolesToGoogleMaps(polygon,
                            this.google_maps_reference.maps)[0]) / 10000),
                    name: field.name,
                    id: field.id,
                    color: field.color === undefined ? '0335ad' : field.color
                };
                let eachPolygonTemp = []

                polygon._shell.getCoordinates().forEach(coord => {
                    eachPolygonTemp.push([parseFloat(coord.x), parseFloat(coord.y)]);
                })

                newField['coordinates'] = eachPolygonTemp;
                data.push(newField);
            });


            if (!this.overlay) {
                this.overlay = new DeckOverlay({});
                this.overlay.setMap(this.map);
            }

            this.newData(data)
        },
        removeFieldsFromRealTime() {

            if (this.overlay) {
                this.layers = [];
                this.overlay.setProps({layers: Object.values(this.layers)});
            }

            this.fieldInfoWindows.forEach(infoWindow => {
                infoWindow.setAnchor(null);
                infoWindow.setMap(null);
                infoWindow.close();
            });
            this.fieldInfoWindows = [];
        },
        operationalAlertsToMap(alerts) {
            let vm = this;
            alerts.forEach(alert => {
                const OPERATIONAL_MARKER_ICON = require(`@/assets/images/maps-type-control/operational-alert-marker-${alert.priority}.png`);
                let alertPoint = this.buildGeomFromCoordinates(alert.geom)
                let marker = new vm.google_maps_reference.maps.Marker({
                    position: new vm.google_maps_reference.maps.LatLng(parseFloat(alertPoint.getCoordinates()[0].y),
                        parseFloat(alertPoint.getCoordinates()[0].x)),
                    map: vm.map,
                    icon: OPERATIONAL_MARKER_ICON
                });


                marker.addListener("click", () => {
                    vm.operationalAlertInfoWindow = new vm.google_maps_reference.maps.InfoWindow({
                        content: vm.createOperationalAlertInfoWindowString(alert),
                    });

                    vm.operationalAlertInfoWindow.open({
                        anchor: marker,
                        map: vm.map,
                        shouldFocus: false,
                    });
                });

                vm.operationalAlertMarkers.push(marker);
            });
        },
        removeOperationalAlertsFromRealTime() {
            this.operationalAlertMarkers.forEach(marker => {
                marker.setMap(null);
                marker = null
            })

            this.operationalAlertMarkers = [];
        },
        machineAlertsToMap(alerts) {
            let vm = this;
            const MACHINE_ALERT_ICON = require('@/assets/images/maps-type-control/machine-alert.png');
            alerts.forEach(alert => {
                let alertPoint = this.buildGeomFromCoordinates(alert.geom)
                let marker = new vm.google_maps_reference.maps.Marker({
                    position: new vm.google_maps_reference.maps.LatLng(parseFloat(alertPoint.getCoordinates()[0].y),
                        parseFloat(alertPoint.getCoordinates()[0].x)),
                    map: vm.map,
                    icon: MACHINE_ALERT_ICON
                });


                marker.addListener("click", () => {
                    vm.machineAlertInfoWindow = new vm.google_maps_reference.maps.InfoWindow({
                        content: vm.createMachineAlertInfoWindowString(alert),
                    });

                    vm.machineAlertInfoWindow.open({
                        anchor: marker,
                        map: vm.map,
                        shouldFocus: false,
                    });
                });

                vm.machineAlertMarkers.push(marker);
            });
        },
        removeMachineAlertsFromRealTime() {
            this.machineAlertMarkers.forEach(marker => {
                marker.setMap(null);
                marker = null
            })

            this.machineAlertMarkers = [];
        },
        getInfoWindowByIndex(index) {

            if (!this.fieldInfoWindows || this.fieldInfoWindows.length === 0) {
                return -1;
            }

            for (let i = 0; i < this.fieldInfoWindows.length; i++) {
                if (this.fieldInfoWindows[i].index === index) {
                    return i;
                }
            }
            return -1;
        },
        handleCloseClickInfoWindow(InfoWindow) {

            let index = this.getInfoWindowByIndex(InfoWindow.index);

            if (index >= 0) {
                this.fieldInfoWindows.splice(index, 1)
            }
        },

        handleTilesData(data) {

            let vm = this;

            //limpa os tiles que estão na tela, se houver
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_TILES, null);

            this.map.setOptions({minZoom: null});
            this.map.setOptions({maxZoom: null});

            if (data.length > 0) {
                //se veio novos tiles, envia para o google maps desenhar quando necessário

                this.map.overlayMapTypes.setAt(
                    vm.INDEX_OVERLAY_TILES,
                    new CoordMapType(new vm.google_maps_reference.maps.Size(256, 256), vm.tilesData)
                );
            }
        },
        configureMapOptionsByTilesData(data) {
            if (data.length > 0) {

                //procura no array os zoom max e min que tem tiles
                //e trava os zooms do mapa neles
                let minZoom = this.findMinZoomWithTiles(data);
                let maxZoom = this.findMaxZoomWithTiles(data);


                if (minZoom && minZoom < 30) {
                    this.map.setOptions({minZoom: minZoom});
                }

                if (maxZoom && maxZoom > -1) {
                    this.map.setOptions({maxZoom: maxZoom});
                }
            }
        },
        handleApplicationTilesData(data, indexOverlay) {
            let vm = this;

            //limpa os tiles que estão na tela, se houver
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_APPLICATION_TILES, null);
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_VELOCITY_TILES, null);
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_HUMIDITY_TILES, null);

            if (data.length > 0) {
                //se veio novos tiles, envia para o google maps desenhar quando necessário

                this.map.overlayMapTypes.setAt(
                    indexOverlay,
                    new CoordMapType(new vm.google_maps_reference.maps.Size(256, 256), data),
                );
            }
        },

        /**
         * Recebe um tile(z,x,y) e calcula a lat lng
         * retorna no padrão do google maps {lat: lat, lng: lng}
         * */
        tileToLatLng(tile) {
            if (!tile) {
                return;
            }

            let lng = (tile.x / Math.pow(2, tile.z) * 360 - 180);

            var n = Math.PI - 2 * Math.PI * tile.y / Math.pow(2, tile.z);
            let lat = (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));

            return {lat: lat, lng: lng};
        },

        /**
         * Rebebe um nome de arquivo    D8A01D55C576/21/05/02/14/5989/9243/2040.png,
         * e retorna um objeto {x:5989, :y:9243, z:18}
         * */
        getXYZFromFileName(filename) {
            if (!filename) {
                return;
            }

            let fileSplit = filename.split("/");

            return {
                x: parseInt(fileSplit[5]) + 1,
                y: parseInt(fileSplit[6]) + 1,
                z: fileSplit[4]
            }
        },
        /**
         * Tenta achar o zoom que tem dados começando no 14 e diminuindo
         * para dar zoom e centralizar
         * */
        findZoomWithData(tilesData) {
            let zoom = -1;

            for (const tileByVehicle of tilesData) {
                for (let i = 14; i >= 4; i--) {
                    if (tileByVehicle.zooms[i].tiles.length > 0) {
                        if (zoom < i) {
                            zoom = i;
                            break;
                        }
                    }
                }
            }

            return zoom;
        },

        findMinZoomWithTiles(tilesData) {
            let min = 30;

            for (const tileByVehicle of tilesData) {
                for (let i = 0; i <= 21; i++) {
                    if (tileByVehicle.zooms[i].tiles.length > 0) {
                        if (min > i) {
                            min = i;
                            break;
                        }
                    }
                }
            }
            return min;
        },
        findMaxZoomWithTiles(tilesData) {

            let max = -1;

            for (const tileByVehicle of tilesData) {
                for (let i = 21; i >= 0; i--) {
                    if (tileByVehicle.zooms[i].tiles.length > 0) {
                        if (max < i) {
                            max = i;
                            break;
                        }
                    }
                }
            }
            return max;
        },
        removeFieldsTiles() {

            if (!this.map.overlayMapTypes) {
                return;
            }

            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_TILES_FIELDS, null);
        },
        centerMap(selectedVehicles) {

            if (selectedVehicles.length === 0) {
                return;
            }

            let markerToCenterMap = this.markersMap.get(selectedVehicles[selectedVehicles.length - 1].id);

            if (markerToCenterMap) {
                this.map.setCenter(markerToCenterMap.position)
                if (this.map.getZoom() < 16) {
                    this.map.setZoom(16);
                }
            }
        },
        setMapCenterAndZoomForTiles(data) {
            if (data && data.length > 0) {
                this.map.setZoom(14);
                let XYZFromTile = this.getXYZFromFileName(data[0].zooms[14].tiles[0]);

                if (XYZFromTile) {
                    this.map.panTo(this.tileToLatLng(XYZFromTile));
                }
            }
        },
        clearAllMarkers() {
            for (let [id, marker] of this.markersMap) {
                marker.setMap(null);
            }
            this.markersMap.clear();
        },
        commentedInfoWindow(val) {
            this.fieldInfoWindows.forEach(infoWindow => {
                let content = infoWindow.content;
                if (val) {
                    content = content.includes("<!--") ? content : content.replace("<br/>", "<!--<br/>").replace("</a>", "</a>-->");
                } else {
                    content = content.replace("<!--", "").replace("-->", "");
                }
                infoWindow.setContent(content);
            });
        },
        classListInfoWindow(val) {
            var elementStyle = document.querySelector('.gm-style');
            if (val) {
                elementStyle.classList.add('painel-ativo');
            } else if (elementStyle.classList.contains('painel-ativo')) {
                elementStyle.classList.remove('painel-ativo');
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        removeConnectivityTracks() {
            if (!this.overlay) {
                return;
            }

            let newLayers = []
            this.layers.forEach((layer) => {
                if (layer.id === 'connectivityPaths') {
                    return;
                }
                newLayers.push(layer);
            })
            this.layers = newLayers

            this.overlay.setProps({layers: Object.values(this.layers)});
        }
    },
    computed: {
        markers_cluster: function () {
            return Array.from(this.markersMap.values());
        },
        ...mapGetters([
            'isUserDemo'
        ])
    },
    watch: {
        show_panel: function (val) {
            if (val) {
                this.commentedInfoWindow(val);
                this.removeAllMarkers();
                this.centerMap(this.selected_vehicles);
            } else {
                this.classListInfoWindow(val);
                this.commentedInfoWindow(val);
                this.clearAllMarkers();
            }
        },
        weather_overlay: {
            handler: function (val) {
                this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_WEATHER, null);
                if (val !== MAPS_OVERLAY_WEATHER_TYPES.REMOVE) {
                    this.setupWeatherOverlay();
                }
            },
            deep: true
        },
        tilesData: {
            handler: function (val) {
                this.handleTilesData(val);
                this.configureMapOptionsByTilesData(val);
                this.setMapCenterAndZoomForTiles(val)
            },
            deep: true
        },
        application_on: function (val) {
            if (!val) {
                this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_APPLICATION_TILES, null);
                return;
            }

            this.handleApplicationTilesData(this.application_tiles_data, this.INDEX_OVERLAY_APPLICATION_TILES);
        },
        speed_on: function (val) {
            if (!val) {
                this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_VELOCITY_TILES, null);
                return;
            }

            this.handleApplicationTilesData(this.speed_tiles_data, this.INDEX_OVERLAY_VELOCITY_TILES);
        },
        humidity_on: function (val) {
            if (!val) {
                this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_HUMIDITY_TILES, null);
                return;
            }

            this.handleApplicationTilesData(this.humidity_tiles_data, this.INDEX_OVERLAY_HUMIDITY_TILES);
        },
        track_on: function (val) {
            if (!val) {
                this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_TILES, null);
                return;
            }

            this.handleTilesData(this.tilesData);
            this.configureMapOptionsByTilesData(this.tilesData);
        },
        realtime_data: {
            handler: function (data) {
                this.handleDataRealTime(data);
                this.updateVehiclesIdsInVisibleCluster();
            },
            deep: true
        },
        map_type: function (val) {
            if (this.isUserDemo || !this.map) {
                return;
            }

            if (val === 'roadmap') {
                this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.ROADMAP);
            } else {
                this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.HYBRID);
                if (this.map.getZoom() > 18) {
                    this.map.setZoom(18);
                }
            }
        },
        positionCenter: {
            handler: function (val) {
                if (val) {
                    this.map.setCenter({lat: val.lat, lng: val.lng,});
                    if (this.map.getZoom() < 10) {
                        this.map.setZoom(16)
                    }
                }
            },
            deep: true
        },
        selected_vehicles: {
            handler: function (val) {
                this.removeInfoWindowVehiclesNotMoreSelected(val);
                this.addInfoWindowVehiclesSelected(val);
                if (!this.show_panel) {
                    this.centerMap(val);
                } else {
                    this.removeAllMarkers();
                }
            },
            deep: true
        },
        vehicles_list: {
            handler: function (val) {
                //so mostra os markers das maquinas que estão aparecendo no filtro
                for (const key of this.markersMap.keys()) {

                    let vehicleId = val.find(v => v.id === key);

                    if (!vehicleId) {
                        this.removeMarkerAndInfoWindow(key);
                    }
                }
            }
        },
        marker_historical: {
            handler: function (val) {
                let vm = this;

                if (!val) {
                    vm.marker.setMap(null);
                    vm.marker = null
                } else {
                    if (!this.marker) {
                        vm.marker = new vm.google_maps_reference.maps.Marker({
                            position: val,
                            map: vm.map
                        });
                    } else {
                        vm.marker.setPosition(val);
                        vm.map.panTo(val);
                    }
                }
            },
            deep: true
        },
        marker_location: {
            handler: function (val) {
                let vm = this;

                if (!val) {
                    if (vm.markerLocation) {
                        vm.markerLocation.setMap(null);
                        vm.markerLocation = null;
                    }
                } else {
                    if (vm.markerLocation) {
                        vm.markerLocation.setMap(null);
                    }
                    vm.markerLocation = new vm.google_maps_reference.maps.Marker({
                        position: val,
                        map: vm.map,
                        icon: './images/user-location.png'
                    });
                }
            },
            deep: true

        },
        marker_init_data: {
            handler: function (val) {

                if (this.markerInitData) {
                    this.markerInitData.setMap(null);
                }

                if (!val) {
                    return;
                }

                if (val.lat === 0 || val.lng === 0) {
                    return;
                }

                this.markerInitData = new this.google_maps_reference.maps.Marker({
                    position: new this.google_maps_reference.maps.LatLng(val.lat, val.lng),
                    icon: {
                        url: require('@/assets/images/vehicles/play.png'),
                        labelOrigin: new this.google_maps_reference.maps.Point(27, 27)
                    },
                });

                this.markerInitData.setMap(this.map);
            },
            deep: true
        },
        marker_end_data: {
            handler: function (val) {

                if (this.markerEndData) {
                    this.markerEndData.setMap(null);
                }

                if (!val) {
                    return;
                }

                if (val.lat === 0 || val.lng === 0) {
                    return;
                }

                this.markerEndData = new this.google_maps_reference.maps.Marker({
                    position: new this.google_maps_reference.maps.LatLng(val.lat, val.lng),
                    icon: {
                        url: require('@/assets/images/vehicles/pause.png'),
                        labelOrigin: this.google_maps_reference.maps.Point(32, 32),
                    },
                });

                this.markerEndData.setMap(this.map);
                this.map.panTo(val)
            },
            deep: true
        },
        gaps: {
            handler: function (val) {
                if (!this.gaps || this.gaps.length === 0) {
                    this.gapsInMap.forEach(gap => {
                        gap.setMap(null)
                    })

                    this.gapsInMap = [];
                    return
                }

                const lineSymbol = {
                    path: "M 0,-1 0,1",
                    strokeOpacity: 1,
                    scale: 3,
                };

                this.gaps.forEach(gap => {

                    let polyline = this.buildGeomFromCoordinates(gap);
                    let gapPath = [];

                    polyline._points._coordinates.forEach(coord => {
                        gapPath.push({lat: coord.y, lng: coord.x})
                    })

                    const gapPolyline = new this.google_maps_reference.maps.Polyline({
                        path: gapPath,
                        geodesic: true,
                        strokeColor: "#FFFFFF",
                        strokeOpacity: 0,
                        strokeWeight: 1,
                        icons: [
                            {
                                icon: lineSymbol,
                                offset: "0",
                                repeat: "20px",
                            },
                        ],
                    });

                    if (this.noTrackOn) {
                        gapPolyline.setMap(this.map);
                    }
                    this.gapsInMap.push(gapPolyline)

                })
            },
            deep: true
        },
        noTrackOn: {
            handler: function (val) {
                if (!val) {
                    this.gapsInMap.forEach(gap => {
                        gap.setMap(null)
                    })
                    return
                }

                this.gapsInMap.forEach(gap => {
                    gap.setMap(this.map)
                })
            },
            deep: true
        },
        connectivity_events: {
            handler: async function (val) {

                if (!val || val.length === 0) {
                    return;
                }
                const processEvents = (val) => {
                    const connections = [];

                    val.forEach((event, index) => {
                        const fields = event.data.split('|');

                        if (event.type === 'EVENT_TYPE_MODEM_CONNECT') {
                            const [carrier, technology, lat, lng] = fields;
                            const parsedEvent = {
                                type: 'EVENT_TYPE_MODEM_CONNECT',
                                carrier,
                                technology,
                                coordinates: [parseFloat(lng), parseFloat(lat)]
                            };

                            // Check for a valid connection (27 followed by 28)
                            if (val[index + 1] && val[index + 1].type === 'EVENT_TYPE_MODEM_DISCONNECT') {
                                const nextFields = val[index + 1].data.split('|');
                                const [bytesDownloaded, bytesUploaded, ts, latNext, lngNext] = nextFields;

                                connections.push({
                                    from: {coordinates: parsedEvent.coordinates},
                                    to: {coordinates: [parseFloat(lngNext), parseFloat(latNext)]},
                                    from_ts: event.ts,
                                    to_ts: val[index + 1].ts,
                                    path: [],
                                    color: "#000000".replace(/0/g, function () {
                                        return (~~(Math.random() * 16)).toString(16);
                                    }),
                                    tooltip: this.dateHourFormat(event.ts) + "\na\n" +
                                        this.dateHourFormat(val[index + 1].ts) + "\n\ndw:" + bytesDownloaded + "\n\nup:" + bytesUploaded
                                        + "\n\nCarrier:" + carrier + "\n\nTech:" + technology
                                });
                            }
                        }
                    });

                    return connections;
                };


                const connections = processEvents(val);

                if (connections.length > 0) {
                    this.historical_charts_data[0].data.forEach((data) => {
                        connections.forEach((connection) => {
                            if ((data.ts / 1000) < connection.from_ts) {
                                return
                            }

                            if ((data.ts / 1000) > connection.to_ts) {
                                return
                            }

                            if (data.lng === 0 || data.lat === 0) {
                                return;
                            }

                            connection.path.push([data.lng, data.lat])
                        })
                    })
                }

                this.layers.push(
                    new PathLayer({
                        id: 'connectivityPaths',
                        data: connections,
                        getColor: d => {
                            const hex = d.color;
                            // convert to RGB
                            return hex.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16));
                        },
                        getPath: d => d.path,
                        getWidth: 3,
                        widthMinPixels: 3,
                        autoHighlight: true,
                        capRounded: true,
                        parameters: {
                            depthMask: false
                        },
                        pickable: true,
                    })
                );

                if (!this.overlay) {
                    this.overlay = new DeckOverlay({
                        getTooltip: ({object}) => object && object.tooltip ? object.tooltip : null
                    });
                    this.overlay.setMap(this.map);
                }
                this.overlay.setProps({layers: Object.values(this.layers)});
            },
            deep: true
        },

    },
}
</script>


<style lang="scss">
@import "src/components/views/alerts/style";

#google-maps-realtime {
    height: 100%;
}

.gm-style.painel-ativo .gm-style-iw {
    max-height: 35px !important;
}

.gm-style.painel-ativo .gm-style-iw button {
    display: none !important;
}

.gm-style.painel-ativo .gm-style-iw-c {
    opacity: 0.7;
}

.gm-style.painel-ativo .gm-style-iw-tc::after {
    display: none !important;
}

.gm-style.painel-ativo .gm-style-iw-d {
    color: black;
    font-weight: bold;
    position: relative;
    left: 2px;
    top: 3px;
    // bottom: 2px;
}

.gm-style > div:nth-child(2) > div:first-child > div:first-child {
    z-index: 102 !important;
}

.hide-labels {
    display: none;
    color: transparent;
}

.gm-style-iw-chr {
    zoom: 28%;
}

.gm-style-iw-chr button span {
    width: 60px !important;
    height: 60px !important;
    position: relative;
    right: 30px;
}

.labels {
    color: #646c9a;
    background-color: white;
    font-family: Roboto, Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border: 1px solid #646c9a;
    border-radius: 5px;
    box-sizing: border-box;
    white-space: nowrap;
    transform: translateX(-50%) translateY(-337%);
}
</style>
