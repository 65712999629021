export default {

    methods: {
        orderVehiclesByNaturalOrder(values) {

            try {
                return values.sort((a, b) => a.display_id.localeCompare(b.display_id));
            } catch (e) {
                console.log(e);
                return values;
            }
        },
        orderVehiclesByDisplayIdentificationNaturalOrder(values) {
            try {
                return values.sort((a, b) => a.display_identification.localeCompare(b.display_identification));
            } catch (e) {
                return values;
            }
        },
        orderClientsByDisplayNameNaturalOrder(values) {
            try {
                return values.sort((a, b) => a.name.localeCompare(b.name));
            } catch (e) {
                console.log(e)
                return values;
            }
        },
        orderOrgByNaturalOrder(values) {
            try {
                return values.sort((a, b) => a.cpf_cnpj.localeCompare(b.cpf_cnpj));
            } catch (e) {
                console.log(e);
                return values;
            }
        },
        orderCustomFieldByNaturalOrder(values, field) {
            try {
                return values.sort((a, b) => a[field].localeCompare(b[field]));
            } catch (e) {
                console.log(e);
                return values;
            }
        }
    }
}
