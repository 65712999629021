import i18n from '../../../i18n';


export const STATUS = [
    {name: i18n.t('AppOptions_water.Regular'), code: 'REGULAR'},
    {name: i18n.t('AppOptions_water.Desvio'), code: 'DEVIATION'},
    {name: i18n.t('AppOptions_water.Protocolizado'), code: 'PROTOCOL'},
    {name: i18n.t('AppOptions_water.Não autorizado'), code: 'NO_AUTHORIZATION'},
    {name: i18n.t('AppOptions_water.Desconhecido'), code: 'UNKNOWN'},
];

export const PROCESSES = [
    {name: i18n.t('AppOptions_water.Colheita'), code: 'HARVEST'},
    {name: i18n.t('AppOptions_water.DTI'), code: 'DTI'},
    {name: i18n.t('AppOptions_water.Inteligência Patrimonial'), code: 'PI'},
    {name: i18n.t('AppOptions_water.Logística'), code: 'LOGISTICS'},
    {name: i18n.t('AppOptions_water.Silvicultura'), code: 'FORESTRY'},
    {name: i18n.t('AppOptions_water.Viveiro'), code: 'PLANT_NURSERY'},
    {name: i18n.t('AppOptions_water.Estrada'), code: 'ROAD'},
    {name: i18n.t('AppOptions_water.Desconhecido'), code: 'UNKNOWN'},
];

export const ACTIVITIES = [
    {name: i18n.t('AppOptions_water.Aplicação de defensivos'), code: 'PESTICIDE'},
    {name: i18n.t('AppOptions_water.Combate a incêndio'), code: 'FIRE_FIGHTING'},
    {name: i18n.t('AppOptions_water.Lavagem de máquinas'), code: 'MACHINE_WASHING'},
    {name: i18n.t('AppOptions_water.Plantio, replantio e irrigação'), code: 'PLANTING'},
    {name: i18n.t('AppOptions_water.Produção de mudas'), code: 'SEEDLING_PRODUCTION'},
    {name: i18n.t('AppOptions_water.Terraplanagem'), code: 'LANDSCAPING'},
    {name: i18n.t('AppOptions_water.Umectação de estradas'), code: 'ROAD_WETTING'},
    {name: i18n.t('AppOptions_water.Construção e manutenção de estradas'), code: 'ROAD_CONSTRUCTION'},
    {name: i18n.t('AppOptions_water.Desconhecido'), code: 'UNKNOWN'},

];
