<template>
    <div>
        <v-chart class="chart" ref="chartInstance" :option="chartOption" :autoresize="true" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components';
import { VisualMapComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import getTimeFromSecondsDurationFormatMixinShowZero from '../../../mixins/DateMixin';

import VChart from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
    VisualMapComponent
]);

export default {
    mixins: [getTimeFromSecondsDurationFormatMixinShowZero],
    components: {
        VChart
    },
    props: {
        data_to_pie: {
            type: Array,
            default() {
                return []
            }
        },
        time_or_consumption: {
            type: String
        }
    },
    data() {
        return {
            isMouseOver: false
        }
    },
    mounted() {
        let chart = this.$refs.chartInstance.$el;
        if (chart) {
            chart.addEventListener('mouseover', this.handleMouseOver);
            chart.addEventListener('mouseout', this.handleMouseOut);
        }

        this.setupLegendClickEvent();
    },
    computed: {
        chartOption() {
            return {
                title: {
                    top: '13',
                    left: 'center',
                    text: this.time_or_consumption,
                    textStyle: {
                        fontSize: '14',
                        color: '#696969'
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (args) => {
                        return `${args.marker} ${args.name} <b>${this.getTheCorrectFormat(args.value)}</b><br>`;
                    }
                },
                series: [{
                    type: 'pie',
                    radius: '70%',
                    top: '30',
                    labelLine: {
                        show: this.isMouseOver,
                        length: 7,
                        lineStyle: {
                            width: 1
                        }
                    },
                    label: {
                        show: this.isMouseOver,
                        formatter: function(params) {
                            let styleKey = params.color === '#FFFF33' ? 'yellowStyle' : 'defaultStyle';
                            let percent = params.percent.toFixed(1).toString().replace(".", ",");
                            return `{${styleKey}|${percent}%}`;
                        },
                        rich: {
                            yellowStyle: {
                                color: '#000',
                                backgroundColor: '#FFFF33',
                                opacity: 0.8,
                                padding: [5, 5],
                                borderRadius: 4
                            },
                            defaultStyle: {
                                color: '#fff',
                                backgroundColor: 'auto',
                                opacity: 0.8,
                                padding: [5, 5],
                                borderRadius: 4
                            }
                        }
                    },
                    data: this.data_to_pie
                }]
            };
        }
    },
    watch: {
        data_to_pie: function(val) {
            this.setupSeries(val)
        }
    },
    methods: {
        setupLegendClickEvent() {
            let chart = this.$refs.chartInstance.chart;
            chart.on('mouseover', (params) => {
                chart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: params.seriesIndex,
                    dataIndex: params.dataIndex
                });
            });
        },
        handleMouseOver() {
            this.isMouseOver = true
        },
        handleMouseOut() {
            this.isMouseOver = false
        },
        setupSeries(chartData) {
            let newChartData = [{
                type: 'pie',
                radius: '70%',
                top: '30',
                labelLine: {
                    show: this.isMouseOver,
                    length: 7,
                    lineStyle: {
                        width: 1
                    }
                },
                label: {
                    show: this.isMouseOver,
                    formatter: function(params) {
                        let styleKey = params.color === '#FFFF33' ? 'yellowStyle' : 'defaultStyle';
                        let percent = params.percent.toFixed(1).toString().replace(".", ",");
                        return `{${styleKey}|${percent}%}`;
                    },
                    rich: {
                        yellowStyle: {
                            color: '#000',
                            backgroundColor: '#FFFF33',
                            opacity: 0.8,
                            padding: [5, 5],
                            borderRadius: 4
                        },
                        defaultStyle: {
                            color: '#fff',
                            backgroundColor: 'auto',
                            opacity: 0.8,
                            padding: [5, 5],
                            borderRadius: 4
                        }
                    }
                },
                data: chartData,
            }]

            this.$set(this.chartOption, 'series', newChartData);
        },
        getTheCorrectFormat(val){
            if(this.time_or_consumption === 'Atividade'){
                return this.getTimeFromSecondsDurationFormatMixinShowZero(val)
            }
            return this.formatTooltipConsumption(val)
        },
        formatTooltipConsumption(val) {
            val = val.toFixed(2);
            val = Number(val).toLocaleString('pt-BR');
            return `${val} L`;
        }
    },
    beforeDestroy() {
        let chart = this.$refs.chartInstance.$el;
        if (chart) {
            chart.removeEventListener('mouseover', this.handleMouseOver);
            chart.removeEventListener('mouseout', this.handleMouseOut);
        }
    }
};
</script>