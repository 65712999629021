<template>
    <div>
      <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="!show_canva"
        :paginate-elements-by-height="1100"
        filename="Análises-Dashboard"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf">
        <section slot="pdf-content">
            <div style="position: relative; right: 10px;">
                <div class="brand-background" >
                    <img alt="Logo" style="height: 80px;width: 150px" :src="headerImage"/>
                </div>
                <div class="center default-padding" style="font-size: 23px; margin-top: 10px;">
                    {{ title }}
                </div>
                <div id="components" style="display: flex; width: 100%; flex-wrap: wrap;"></div>
            </div>
        </section>
     </VueHtml2pdf>
    </div>
 </template>

<script>
import Vue from 'vue';
import i18n from "@/i18n";
import VueHtml2pdf from 'vue-html2pdf';
import AppAnalysisSideCards from "./AppAnalysisSideCards";
import AppAnalysisPieCharts from "./AppAnalysisPieCharts";
import AppAnalysisLineCharts from "./AppAnalysisLineCharts";
import AppAnalysisColumnCharts from "./AppAnalysisColumnCharts";


export default {
    components: {
        VueHtml2pdf, Vue, AppAnalysisColumnCharts, AppAnalysisLineCharts, AppAnalysisPieCharts, AppAnalysisSideCards
    },
    data() {
        return {
            headerImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_HEADER + ".png"),
            count: 0,
            title: ""
        }
    },
    props: {
        generate_report: {
            type: Boolean
        },
        filter_events_results: {
            type: Array
        },
        chart_data_from_idb: {
            type: Array
        },
        filter_time: {
            type: Object
        },
        chart_updater: {
            type: Boolean
        },
        chart_data_to_side_cards: {
            type: Array
        },
        content_grid_stack: {
            type: Array
        },
        selected_model: {
            type: Object
        }
    },
    methods: {
        createComponent(element, i) {
            var novaDiv = document.createElement('div');
            novaDiv.style.width = "400px"
            novaDiv.style.height = "300px"
            novaDiv.style.padding = "0px 10px 0px 10px"
            if(i === 1 || i === 2){
                novaDiv.style.margin = "20px 0px 10px 0px"
            } else if(i >= 7){
                novaDiv.style.margin = "64px 0px 10px 0px"
            } else{
                novaDiv.style.margin = "10px 0px 10px 0px"
            }
            document.getElementById("components").appendChild(novaDiv);

            let Component;

            if (element.type === "Info") {
                Component = Vue.extend(AppAnalysisSideCards);
            } else if (element.type === "Pizza") {
                Component = Vue.extend(AppAnalysisPieCharts);
            } else if (element.type === "Gráfico") {
                Component = Vue.extend(AppAnalysisLineCharts);
            } else if (element.type === "Tubo") {
                Component = Vue.extend(AppAnalysisColumnCharts);
            }

            const componentVue = new Component({
                ref: `componentePdf${element.id}`
            });

            componentVue.$mount();

            novaDiv.appendChild(componentVue.$el);

            this.$refs[`componentePdf${element.id}`] = componentVue;

            componentVue.dataWatch(this.filter_events_results, this.chart_data_from_idb, this.filter_time, this.chart_updater, this.chart_data_to_side_cards);

            componentVue.setModelLocalStorage(this.selected_model.code)

            componentVue.isPdf(true);

            componentVue.cleanStyle();

            componentVue.setLocalStorage(element.id);

            componentVue.correctHeight(300);

            this.$refs[`componentePdf${element.id}`].setOption();
        }
    },
    watch: {
        generate_report: function () {
            this.title = this.selected_model.name
            var componentsDiv = document.getElementById("components");
            componentsDiv.innerHTML = '';
            let i = 0;
            this.$emit('changeGenerate', true);
            this.content_grid_stack.forEach(element => {
                if(element.code === null){
                    return;
                } else{
                    i++
                    this.createComponent(element, i)
                }
            });

            if(i === 0){
                this.$toast.add({
                    severity: 'warn',
                    summary: i18n.t('AppAnalysisReport.Sem dados'),
                    detail: i18n.t('AppAnalysisReport.Insira um gráfico e selecione uma opção para apresentar valores'),
                    life: 5000
                });
                this.$emit('changeGenerate', false);
                return;
            }

            setTimeout(() => {
                this.$refs.html2Pdf.generatePdf();
                this.$emit('changeGenerate', false);
            }, 2000);
        }
    },
    computed: {
        show_canva() {
            return window.screen.width <= 1024;
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.default-padding {
    padding: 10px 50px 5px 40px;
}

.brand-background {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 102%;
    background-color: $aside-menu-item-active;
}
</style>
