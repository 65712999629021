<template>
    <transition name="container-transitions" mode="out-in">
        <div v-if="show">
            <AppTranslator style="position: fixed; right: 20px; top: 15px" />
            <div class="background kt-grid kt-grid--ver kt-grid--root kt-page">
                <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div class="kt-login__container">
                                <div class="kt-login__logo">
                                    <a href="#">
                                        <img :src="loginImage" class="img-fluid" style="max-height: 182px">
                                    </a>
                                </div>
                                <div class="kt-login__head">
                                    <h3 class="kt-login__title">{{ $t('AppForgotPassword.Esqueceu a senha?') }}</h3>
                                </div>
                                <form class="kt-form" v-on:submit.prevent>
                                    <app-input class="form-control" type="text" :placeholder="$t('AppForgotPassword.Email')" v-model="email"
                                    ></app-input>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <app-button type="primary" @handleClick="recoverPassword" class="size "
                                                        :disabled="loading"
                                                        style="float: right">

                                                <span v-show="!loading">{{ $t('AppForgotPassword.Enviar') }}</span>
                                                <span v-show="loading" >{{ $t('AppForgotPassword.Enviando') }}</span>

                                            </app-button>&nbsp;&nbsp;
                                            <app-button type="secondary" @handleClick="goToLogin" class="size "
                                                        style="float: left">{{ $t('AppForgotPassword.Voltar') }}
                                            </app-button>
                                        </div>
                                    </div>
                                </form>
                                <div class="kt-login__account">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import AppButton from '../../../components/common/AppButton';
    import AppInput from '../../common/AppRegisterInput'
    import {router} from '../../../router';

    import axios from 'axios';
    import AppTranslator from "@/components/common/AppTranslator.vue";

    export default {
        data() {
            return {
                show: false,
                loginImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE + ".png"),
                locale: navigator.language || navigator.userLanguage,
                email: '',
                loading: false,
            }
        },
        mounted() {
            this.fade();
        },
        bedoreDestroy() {
            this.fade();
        },
        components: {
            AppTranslator,
            AppButton,
            AppInput
        },
        methods: {
            fade() {
                this.show = !this.show;
            },
            recoverPassword() {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!this.email || !re.test(this.email)) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppForgotPassword.Informe um e-mail válido'),
                        life: 5000
                    });
                } else {
                    this.sendRequest();
                }
            },
            sendRequest() {
                this.loading = true;
                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/v3/forgot-password', {
                    username: this.email,
                    brand_id: process.env.VUE_APP_BRAND_ID
                }).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppForgotPassword.Solicitação realizada com sucesso'),
                        life: 5000
                    });
                }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppForgotPassword.Não foi possível atender a solicitação'),
                        life: 5000
                    });
                }).finally(() => {
                    this.loading = false
                });
            },
            goToLogin() {
                router.push({name: 'login'})
            },
        },
    }
</script>

<style scoped>
    @import "../../../assets/styles/login-4.css";

    .container-transitions-enter-active {
        transition-duration: 2.1s !important;
        transition-property: opacity !important;
        transition-timing-function: ease !important;
    }

    .container-transitions-enter, .container-transitions-leave-active {
        opacity: 0 !important;
    }

    .size {
        height: 50px;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

</style>
<i18n>
    {
    "pt-BR": {
    "recover": "Recuperar conta",
    "back": "Voltar",
    "forgotten_password": "Esqueceu a senha ?",
    "forgotten_password_msg": "Informe o seu email para resetar a sua senha: ",
    "error_email": "Digite um e-mail válido",
    "error_request": "Ocorreu um erro inesperado, atualize a pagina e tente novamente!",
    "success": "E-mail enviado com sucesso",
    "email_not_found": "E-mail não encontrado"
    },
    "en-US": {
    "recover": "Recover Account",
    "back": "Back",
    "forgotten_password": "Forgotten Password ?",
    "forgotten_password_msg": "Enter your email to reset your password:",
    "error_email": "Invalid e-mail",
    "error_request": "An unexpected error has occurred, please refresh the page and try again!",
    "success": "E-mail sent",
    "email_not_found": "E-mail not found"
    }
    }
</i18n>
