import axios from 'axios'
import store from '../store/store'
import {router} from '../router';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import auth from '../services/auth';
import jwt_decode from "jwt-decode";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_ADDRESS,
});

instance.interceptors.request.use(config => {
    if (store.getters.user.access_token === null || store.getters.user.access_token === 'undefined') {
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
        }
    }
    config.headers['Authorization'] = 'Bearer ' + store.getters.user.access_token;
    config.headers['Content-Type'] = 'application/json';
    return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async failedRequest => {

    if (store.getters.isSSOLogin) {
        console.log("Refresh MS")
        // tem que atualizar com a MS
        try {
            await auth.acquireToken().then(function (result) {
                store.dispatch('storeAccessTokenAction', result);

                let dataLocalStorage = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));

                let decodedJWT = jwt_decode(result.idToken);

                let expiresIn = decodedJWT.exp -  decodedJWT.nbf;

                dataLocalStorage.access_token = result.idToken;
                dataLocalStorage.refresh_token = new Date();
                dataLocalStorage.expires_in = expiresIn;
                dataLocalStorage.expiration = decodedJWT.exp;

                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE, JSON.stringify(dataLocalStorage));

                failedRequest.response.config.headers['Authorization'] = 'Bearer ' + store.getters.user.access_token;

                return Promise.resolve();
            });
        } catch (error) {
            console.log(error);
        }

    } else {
        console.log("Refresh interno")

    return axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/v1/refresh-token',
        {
            refresh_token: store.getters.user.refresh_token
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getters.user.access_token
            },
        }).then((tokenRefreshResponse) => {
        console.log(tokenRefreshResponse);

        tokenRefreshResponse.data.expiration = Math.floor(Date.now() / 1000) + tokenRefreshResponse.data.expires_in;
        store.commit('storeUser', tokenRefreshResponse.data);
        localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE, JSON.stringify(tokenRefreshResponse.data));

        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + store.getters.user.access_token;
        return Promise.resolve();
    }).catch(error => {
        console.log(error)
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
        }
    });
    }
};


createAuthRefreshInterceptor(instance, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});

export default instance
