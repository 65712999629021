import axios from '../axios/axios-auth'

export default class UsersService {

    getUsers(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/users`).then(response => response.data)
    }

    removeUser(userId) {
        return axios.delete(`/api/v3/users/${userId}`).then(response => response.data)
    }

    createUser(orgId, data) {
        return axios.post(`/api/v3/organizations/${orgId}/invite`, data).then(response => response.data)
    }

    updateUser(userId, data) {
        return axios.patch(`/api/v3/users/${userId}`, data).then(response => response.data)
    }

    sendFCMToken(data){
        return axios.post('/api/v1/users/saveFCMToken', data).then(response => response.data)
    }

    getOrgFeatureFlags() {
        return axios.get('/api/v3/users/featureFlags').then(response => response.data)
    }

    impersonate(userId) {
        return axios.get('/auth/v1/impersonation/userId/' + userId).then(response => response.data)
    }

}
