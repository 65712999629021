import Papa from 'papaparse';

export default {

    methods: {

        /**
         * fields: array de string com as key do json que serão inclusas, ou null para adicionar todas
         * header: array de string que será o header do csv
         * data: array de objetos que será convertido para csv
         * fileName: nome do arquivo de saida, sem extensão. Se null o nome será export.csv
         * **/

        exportJsonToCsv(fields, header, data, fileName) {

            var csv = Papa.unparse({
                    //filtra s colunas que devem aparecer
                    "fields": fields,
                    "data": data,
                }, {
                    header: false,
                    delimiter: ';'
                }
            );

            let headers = Papa.unparse({"fields": header, "data": []}, {header: true, delimiter: ';'});

            let BOM_utf8 = "\ufeff";
            let payload = BOM_utf8 + headers + csv;

            var exportedFileName = fileName + '.csv' || 'export.csv';

            var blob = new Blob([payload], {type: 'text/csv;charset=utf-8;'});
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFileName);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
    }
}