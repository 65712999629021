import {mapGetters} from "vuex";

export default {

    methods: {
        hasFeatureEnabled: function (feature) {

            if (this.getOrganizationFeatureFlags.length === 0) {
                return false;
            }

            if (!feature) {
                return false;
            }

            return this.getOrganizationFeatureFlags.includes(feature);
        },
    },
    computed: {
        ...mapGetters([
            'getOrganizationFeatureFlags',
        ])
    },
}
