import i18n from "../../../i18n";
export const PERMISSIONS = () => [
    {name: i18n.t('AppOptions_user.Sem acesso'), code: 'NOT_AUTHORIZED'},
    {name: i18n.t('AppOptions_user.Visualizar'), code: 'VIEW'},
    {name: i18n.t('AppOptions_user.Editar'), code: 'EDIT'},
];

export const PERMISSIONS_DESCRIPTIONS = () =>  [
    {description:  i18n.t('AppOptions_user.Acesso à talhões, estradas, etc'), code: 'LOCATIONS'},
    {description:  i18n.t('AppOptions_user.Acesso às máquinas, dispositivos, etc'), code: 'EQUIPMENT'},
    {description:  i18n.t('AppOptions_user.Acesso aos membros da organização, configurações do aplicativo, etc'), code: 'MANAGEMENT'},
]
