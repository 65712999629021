<template>
    <div>
        <splitpanes class="default-theme" :dbl-click-splitter="false" :horizontal="is_col_12">
            <pane :size="50">
                <div style="position: relative; height: 100%">
                    <div class="kt-portlet__head no-border-radius">
                        <div class="row center" :class="{'menu-dropdown': is_col_12}">
                            <Dropdown v-model="dropDownTaskOne" :options="tasks"
                                      optionLabel="name" :placeholder="$t('AppTasksCompare.Trabalho')"
                                      style="margin-right: 10px"
                                      :disabled="true"
                                      class="auto-center" dataKey="id"
                                      :class="{'dropdown-width-trabalho': is_col_12, 'width-250': !is_col_12}">
                                <template #value="slotProps">
                                    <div v-if="slotProps.value">
                                        {{ slotProps.value.name }} -
                                        {{ dateFormat(slotProps.value.start.valueOf() / 1000) }} a
                                        {{ dateFormat(slotProps.value.end.valueOf() / 1000) }}
                                    </div>
                                    <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                                </template>
                                <template #option="slotProps" style="width: 500px">
                                    <div style="padding-right: 20px" v-if="slotProps.option.name === 'ND'">
                                        -
                                    </div>
                                    <div style="padding-right: 20px" v-else>
                                        {{ slotProps.option.name }} -
                                        {{ dateFormat(slotProps.option.start.valueOf() / 1000) }} a
                                        {{ dateFormat(slotProps.option.end.valueOf() / 1000) }}
                                    </div>
                                </template>
                            </Dropdown>
                            <div class="tasks-cascade" :class="{'dropdown-width-type': is_col_12, 'width-250': !is_col_12}">
                                <CascadeSelect v-model="dropDownTaskMapTypeOne" :options="mapTypesOne"
                                               optionGroupLabel="title"
                                               :optionGroupChildren="['subtypes']"
                                               optionLabel="title" :placeholder="$t('AppTasksCompare.Tipo de Mapa')"
                                               class="auto-center" style="width: 100%"
                                               @change="mapTypeChangeOne">
                                    <template #value="slotProps" style="width: 500px">
                                        <div style="padding-right: 20px" v-if="slotProps.value && slotProps.value.title === 'ND'">
                                            -
                                        </div>
                                        <div style="padding-right: 20px" v-else-if="slotProps.value && slotProps.value.title === 'Desconhecido'">
                                            {{selected_task.name}}
                                        </div>
                                        <div style="padding-right: 20px" v-else>
                                            {{ slotProps.value ? slotProps.value.title : "-" }}
                                        </div>
                                    </template>
                                    <template #option="slotProps" style="width: 500px">
                                        <div style="padding-right: 20px" v-if="slotProps.option.title === 'ND'">
                                            -
                                        </div>
                                        <div style="padding-right: 20px" v-else-if="slotProps.option.title === 'Desconhecido'">
                                            {{selected_task.name}}
                                        </div>
                                        <div style="padding-right: 20px" v-else>
                                            {{ slotProps.option.title }}
                                        </div>
                                    </template>
                                </CascadeSelect>
                            </div>
<!--                            <div class="tasks-cascade" :class="{ 'dropdown-width-type': is_col_12, 'width-250': !is_col_12 }">-->
<!--                                <Calendar id="datesArray" v-model="dateSelectedOne" ref="datesArray"-->
<!--                                          :autocomplete="'off'"-->
<!--                                          :showTime="false"-->
<!--                                          :monthNavigator="true"-->
<!--                                          :yearNavigator="true"-->
<!--                                          :yearRange="getYearRange"-->
<!--                                          :maxDate="new Date()"-->
<!--                                          :touchUI="is_col_12"-->
<!--                                          :disabledDates="disabledDatesOne"-->
<!--                                          @date-select="dateSelectedEventOne"-->
<!--                                          dateFormat="dd/mm/yy"-->
<!--                                          selectionMode="single"-->
<!--                                          class="kt-margin-5-desktop auto-center" :class="{'w-100 pb-4': is_col_12}"-->
<!--                                          :style="{minWidth: '23ch'}">-->
<!--                                </Calendar>-->
<!--                            </div>-->

                        </div>
                    </div>

                    <div class="statistics-card" v-show="worksOne.length > 0">
                        <div v-for="card in cardsOne">
                            <span v-if="shouldShowCard(card.value)">
                                <b>{{ card.title }}:</b> {{ card.value }}{{ card.symbol }}<br/>
                            </span>
                        </div>
                    </div>
                    <div style="position: absolute; z-index: 10; right: 20px" :style="{'top': top_px}">
                        <div class="row justify-content-center">
                            <app-google-maps-type-control
                                :show_first_row="false"
                                ref="googleMapsTypeControl"
                                @handleMapControlClick="handleMapControlClickOne">
                            </app-google-maps-type-control>
                        </div>
                    </div>
                    <div class="top-subtitle" style="position: absolute; z-index: 10; left: 20px"
                         v-show="worksOne.length > 0 && !isMapTypeOverlapOne">
                        <app-tasks-work-options :rate_steps="rateStepsOne" :works="worksOne" :edit_enabled="false"
                                                :symbol="this.getOptionsSymbol(this.dropDownTaskMapTypeOne, this.selected_task)"
                                                @optionsChanged="optionsOneChanged"/>
                    </div>
                    <app-google-maps-tasks class="maps-tasks"
                                           ref="googleMapsTasksOne"
                                           :position_center="field_center"
                                           :selected_field="selected_field"
                                           :google_maps_tasks_id="'compare-tasks-1'"
                                           :task="dropDownTaskOne"
                                           :rate_steps="rateStepsOne"
                                           :opacity="100"
                                           :stroke="0"
                                           :works="worksOne"
                                           :is_map_type_overlap="isMapTypeOverlapOne"
                                           :loading="loadingOne"></app-google-maps-tasks>


                </div>
            </pane>
            <pane :size="50">
                <div style="position: relative; height: 100%">
                    <div class="kt-portlet__head no-border-radius">
                        <div class="row center" :class="{'menu-dropdown': is_col_12}">
                            <Dropdown v-model="dropDownTaskTwo" :options="tasks"
                                      optionLabel="name" :placeholder="$t('AppTasksCompare.Trabalho')"
                                      style="margin-right: 10px"
                                      :disabled="true"
                                      class="auto-center" dataKey="id"
                                      :class="{'dropdown-width-trabalho': is_col_12, 'width-250': !is_col_12}">
                                <template #value="slotProps">
                                    <div v-if="slotProps.value">
                                        {{ slotProps.value.name }} -
                                        {{ dateFormat(slotProps.value.start.valueOf() / 1000) }} a
                                        {{ dateFormat(slotProps.value.end.valueOf() / 1000) }}
                                    </div>
                                    <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                                </template>
                                <template #option="slotProps" style="width: 500px">
                                    <div style="padding-right: 20px" v-if="slotProps.option.name === 'ND'">
                                        -
                                    </div>
                                    <div style="padding-right: 20px" v-else>
                                        {{ slotProps.option.name }} -
                                        {{ dateFormat(slotProps.option.start.valueOf() / 1000) }} a
                                        {{ dateFormat(slotProps.option.end.valueOf() / 1000) }}
                                    </div>
                                </template>
                            </Dropdown>
                            <div class="tasks-cascade" :class="{'dropdown-width-type': is_col_12, 'width-250': !is_col_12}">
                                <CascadeSelect v-model="dropDownTaskMapTypeTwo" :options="mapTypesTwo"
                                               optionGroupLabel="title"
                                               :optionGroupChildren="['subtypes']"
                                               optionLabel="title" :placeholder="$t('AppTasksCompare.Tipo de Mapa')"
                                               class="auto-center" style="width: 100%"
                                               @change="mapTypeChangeTwo">
                                    <template #value="slotProps" style="width: 500px">
                                        <div style="padding-right: 20px" v-if="slotProps.value && slotProps.value.title === 'ND'">
                                            -
                                        </div>
                                        <div style="padding-right: 20px" v-else-if="slotProps.value && slotProps.value.title === 'Desconhecido'">
                                            {{task_to_compare.name}}
                                        </div>
                                        <div style="padding-right: 20px" v-else>
                                            {{ slotProps.value ? slotProps.value.title : "-" }}
                                        </div>
                                    </template>
                                    <template #option="slotProps" style="width: 500px">
                                        <div style="padding-right: 20px" v-if="slotProps.option.title === 'ND'">
                                            -
                                        </div>
                                        <div style="padding-right: 20px" v-else-if="slotProps.option.title === 'Desconhecido'">
                                            {{task_to_compare.name}}
                                        </div>
                                        <div style="padding-right: 20px" v-else>
                                            {{ slotProps.option.title }}
                                        </div>
                                    </template>
                                </CascadeSelect>
                            </div>
<!--                            <div class="tasks-cascade" :class="{ 'dropdown-width-type': is_col_12, 'width-250': !is_col_12 }">-->
<!--                                <Calendar id="datesArray" v-model="dateSelectedTwo" ref="datesArray"-->
<!--                                          :autocomplete="'off'"-->
<!--                                          :showTime="false"-->
<!--                                          :monthNavigator="true"-->
<!--                                          :yearNavigator="true"-->
<!--                                          :yearRange="getYearRange"-->
<!--                                          :maxDate="new Date()"-->
<!--                                          :touchUI="is_col_12"-->
<!--                                          :disabledDates="disabledDatesTwo"-->
<!--                                          @date-select="dateSelectedEventTwo"-->
<!--                                          dateFormat="dd/mm/yy"-->
<!--                                          selectionMode="single"-->
<!--                                          class="kt-margin-5-desktop auto-center" :class="{'w-100 pb-4': is_col_12}"-->
<!--                                          :style="{minWidth: '23ch'}">-->
<!--                                </Calendar>-->
<!--                            </div>-->
                        </div>

                    </div>

                    <div class="statistics-card" v-show="worksTwo.length > 0">
                        <div v-for="card in cardsTwo">
                            <span v-if="shouldShowCard(card.value)">
                                <b>{{ card.title }}:</b> {{ card.value }}{{ card.symbol }}<br/>
                            </span>
                        </div>
                    </div>
                    <div style="position: absolute; z-index: 10; right: 20px" :style="{'top': top_px}">
                        <div class="row justify-content-center">
                            <app-google-maps-type-control
                                :show_first_row="false"
                                ref="googleMapsTypeControl"
                                @handleMapControlClick="handleMapControlClickTwo">
                            </app-google-maps-type-control>
                        </div>
                    </div>
                    <div class="top-subtitle" style="position: absolute; z-index: 10; left: 20px"
                         v-show="worksTwo.length > 0 && !isMapTypeOverlapTwo">
                        <app-tasks-work-options :rate_steps="rateStepsTwo" :works="worksTwo" :edit_enabled="false"
                                                :symbol="this.getOptionsSymbol(this.dropDownTaskMapTypeTwo, this.task_to_compare)"
                                                @optionsChanged="optionsTwoChanged"/>
                    </div>
                    <app-google-maps-tasks  class="maps-tasks"
                                            ref="googleMapsTasksTwo"
                                            :position_center="field_center"
                                            :selected_field="selected_field"
                                            :google_maps_tasks_id="'compare-tasks-2'"
                                            :task="dropDownTaskTwo"
                                            :rate_steps="rateStepsTwo"
                                            :opacity="100"
                                            :stroke="0"
                                            :works="worksTwo"
                                            :is_map_type_overlap="isMapTypeOverlapTwo"
                                            :loading="loadingTwo"></app-google-maps-tasks>

                </div>
            </pane>

        </splitpanes>


    </div>
</template>

<script>

import 'splitpanes/dist/splitpanes.css'
import {Pane, Splitpanes} from 'splitpanes'

import dateFormat from "@/mixins/DateMixin";
import AppButton from "@/components/common/AppButton";
import AppGoogleMapsTypeControl from "@/components/common/AppGoogleMapsTypeControl";
import AppGoogleMapsTasks from "@/components/views/agriculture/tasks/AppGoogleMapsTasks";
import AppTasksWorkOptions from "@/components/views/agriculture/tasks/AppTasksWorkOptions";
import {MAPS_OVERLAY_OPEN_WEATHER_TYPES, MAPS_OVERLAY_WEATHER_TYPES} from "../../real-time/AppOptions";

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from "primevue/calendar";
import InputText from 'primevue/inputtext';
import CascadeSelect from "primevue/cascadeselect";
import FieldWorksService from "@/services/FieldWorksService";
import moment from "moment";
import getYearRange from "@/mixins/DateMixin";

export default {
    mixins: [dateFormat, getYearRange],
    mounted() {
        this.setupRangeDates();
        this.setTaskOneDates();
        this.setTaskTwoDates();
        this.fieldWorksService = new FieldWorksService();

        this.dropDownTaskOne = this.selected_task;

        this.dropDownTaskTwo = this.task_to_compare;

        this.is_col_12 = this.isScreenMobile;
        this.top_px = this.isScreenMobile ? '60px' : '95px';
    },
    data() {
        return {
            is_col_12: false,
            top_px: '',
            worksOne: [],
            worksTwo: [],
            cardsOne: [],
            cardsTwo: [],
            rateStepsOne: [],
            rateStepsTwo: [],
            dropDownTaskOne: null,
            dropDownTaskMapTypeOne: null,
            dropDownTaskTwo: null,
            dropDownTaskMapTypeTwo: null,
            mapTypesOne: [],
            mapTypesTwo: [],
            fieldWorksService: null,
            isMapTypeOverlapOne: false,
            isMapTypeOverlapTwo: false,
            loadingOne: false,
            loadingTwo: false,
            dateSelectedOne: null,
            disabledDatesOne: [],
            initialDateOne: moment("2022-01-01", "YYYY-MM-DD"),
            dateSelectedTwo: null,
            disabledDatesTwo: [],
            initialDateTwo: moment("2022-01-01", "YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            rangeDates: []
        }
    },
    methods: {
        setupRangeDates() {
            while (this.initialDateOne.isBefore(this.endDate, 'day')) {
                this.rangeDates.push(moment(this.initialDateOne).format('YYYY-MM-DD'));
                this.initialDateOne = this.initialDateOne.add(1, 'days');
            }
        },
        // dateSelectedEventOne() {
        //     this.getWorksOne(this.dropDownTaskOne);
        // },
        // dateSelectedEventTwo() {
        //     this.getWorksTwo(this.dropDownTaskTwo);
        // },
        getAverageWorkValueSymbol(workName) {
            if (workName === 'Colheita') {
                return 't/ha'
            }

            if (workName === 'Pulverização') {
                return 'l/ha'
            }

            return null
        },
        getTotalWorkValueSymbol(workName) {
            if (workName === 'Colheita') {
                return 't'
            }

            if (workName === 'Pulverização') {
                return 'l'
            }

            return null
        },
        getOptionsSymbol(dropDownTaskMapType, task) {
            if (!dropDownTaskMapType) {
                return;
            }

            switch (dropDownTaskMapType.value) {
                case 'APPLICATION':
                    return this.getAverageWorkValueSymbol(task.name)
                case 'HUMIDITY':
                    return '%'
                case 'SPEED':
                    return 'km/h'
                default:
                    return null
            }
        },
        optionsOneChanged(newStepRateOptions) {
            this.rateStepsOne = newStepRateOptions.rate_steps;
        },
        optionsTwoChanged(newStepRateOptions) {
            this.rateStepsTwo = newStepRateOptions.rate_steps;
        },
        handleMapControlClickOne(val) {
            this.handleMapControlClick(val, 'googleMapsTasksOne')
        },
        handleMapControlClickTwo(val) {
            this.handleMapControlClick(val, 'googleMapsTasksTwo')
        },
        handleMapControlClick(val, ref) {
            switch (val) {
                case 'roadmap':
                    this.$refs[ref].setUpMapType('roadmap');
                    break;
                case 'hybrid':
                    this.$refs[ref].setUpMapType('hybrid');
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.REMOVE);
                    break;
                default:
            }
        },
        mapTypeChangeOne(event) {
            this.getWorksOne(this.dropDownTaskOne);
            this.isMapTypeOverlapOne = this.dropDownTaskMapTypeOne.value === 'OVERLAP';
        },
        getWorksTimespan(works) {
            let totalTimespan = 0;
            works.forEach(work => {
                totalTimespan += work.timespan;
            })
            return this.getTimeFromSecondsDurationFormatMixin(totalTimespan);
        },
        getProportionalAverage(works) {
            let totalArea = 0;
            let totalValue = 0;
            works.forEach(work => {
                totalValue += work.value * work.area;
                totalArea += work.area;
            })

            return (totalValue / totalArea).toFixed(2);
        },
        loadCardsData(works, dropDownTaskMapType, isOverlap, overlapArea, workedArea, producedArea, task) {
            let cards = [];

            if (!works || works.length === 0) {
                return cards;
            }

            cards = [{
                title: this.$t('AppTasksCompare.Área do Talhão'),
                value: this.selected_field.area,
                symbol: 'ha'
            }]

            if (!isOverlap) {
                cards = cards.concat([
                    {
                        title: this.$t('AppTasksCompare.Duração'),
                        value: this.getWorksTimespan(works),
                        symbol: ''
                    },
                    {
                        title: this.$t('AppTasksCompare.Área Produzida'),
                        value: (producedArea * 1000000).toFixed(3),
                        symbol: 'ha'
                    },
                    {
                        title: this.$t('AppTasksCompare.Progresso'),
                        value: (producedArea * 1000000 / this.selected_field.area * 100).toFixed(3),
                        symbol: '%'
                    }
                ])
            }
            let workAverage = this.getProportionalAverage(works)
            switch (dropDownTaskMapType.value) {
                case 'APPLICATION':
                    cards = cards.concat([
                        {
                            title: this.$t('AppTasksCompare.Taxa Média'),
                            value: this.getProportionalAverage(works),
                            symbol: this.getAverageWorkValueSymbol(task.name)
                        }
                    ]);

                    if ((workAverage * producedArea) > 0) {
                        cards = cards.concat([
                            {
                                title: this.$t('AppTasksCompare.Total'),
                                value: (producedArea * 1000000 * workAverage).toFixed(2),
                                symbol: this.getTotalWorkValueSymbol(task.name)
                            }
                        ]);
                    }
                    break;
                case 'HUMIDITY':
                    cards = cards.concat([
                        {
                            title: this.$t('AppTasksCompare.Umidade Média'),
                            value: this.getProportionalAverage(works),
                            symbol: '%'
                        }
                    ]);
                    break;
                case 'SPEED':
                    cards = cards.concat([
                        {
                            title: this.$t('AppTasksCompare.Velocidade Média'),
                            value: this.getProportionalAverage(works),
                            symbol: 'km/h'
                        }
                    ]);
                    break;
                case 'OVERLAP':
                    cards = cards.concat([
                        {
                            title: this.$t('AppTasksCompare.Área Percorrida'),
                            value: (workedArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksCompare.Área Produzida'),
                            value: (producedArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksCompare.Área Sobreposta'),
                            value: (overlapArea * 1000000).toFixed(3),
                            symbol: 'ha'
                        },
                        {
                            title: this.$t('AppTasksCompare.Sobreposição'),
                            value: ((overlapArea / workedArea) * 100).toFixed(3),
                            symbol: '%'
                        },
                    ])
                    break;
                default:
                    cards = [];
                    break;
            }

            return cards;
        },
        mapTypeChangeTwo(event) {
            this.getWorksTwo(this.dropDownTaskTwo);
            this.isMapTypeOverlapTwo = this.dropDownTaskMapTypeTwo.value === 'OVERLAP';
        },
        handleBack() {
            this.$emit('backToTasksList');
        },
        getPercentageInStep(works, upperLimit, lowerLimit) {
            let valuesInRange = 0
            let totalArea = 0;
            works.forEach(work => {
                if (work.value <= upperLimit && work.value > lowerLimit) {
                    valuesInRange += work.area;
                }
                totalArea += work.area;
            });

            return parseFloat((valuesInRange / totalArea * 100).toFixed(1));
        },
        getWorksWithoutZeroValue(works) {
            let tempWorks = [];
            works.forEach(work => {
                if (work.value !== 0) {
                    tempWorks.push(work)
                }
            })
            return tempWorks;
        },
        getSteps(works) {
            let steps = [];
            let stepsPercentage = [0.05, 0.20, 0.25, 0.20, 0.15, 0.10, 0.05];
            let worksSorted = works.sort((a, b) => b.value - a.value);
            worksSorted = this.getWorksWithoutZeroValue(worksSorted);
            if (worksSorted.length === 0) {
                return steps;
            }

            steps.push(worksSorted[0].value);

            let worksAddedToStep = 0;
            for (let i = 1; i < works.length; i++) {
                worksAddedToStep++;
                if (worksAddedToStep >= Math.floor(works.length * stepsPercentage[steps.length - 1])) {
                    worksAddedToStep = 0;
                    steps.push(works[i].value);
                }
            }

            return steps;
        },
        processStepRate(works, upperLimit, lowerLimit) {
            let rateStepsTemp = [];

            if (!works || works.length === 0) {
                rateStepsTemp.push({
                    value: steps[0], color: '#029235', percentage: 100, visible: true
                });
                return rateStepsTemp;
            }

            let steps = this.getSteps(works, upperLimit, lowerLimit)

            if (steps.length === 0) {
                rateStepsTemp.push({
                    value: steps[0], color: '#029235', percentage: 100, visible: true
                });
                return rateStepsTemp;
            }

            rateStepsTemp.push({
                value: steps[0], color: '#029235', percentage: this.getPercentageInStep(works,
                    steps[0], steps[1]), visible: true
            });
            rateStepsTemp.push({
                value: steps[1], color: '#86d100', percentage: this.getPercentageInStep(works,
                    steps[1], steps[2]), visible: true
            });
            rateStepsTemp.push({
                value: steps[2], color: '#dcf502', percentage: this.getPercentageInStep(works,
                    steps[2], steps[3]), visible: true
            });
            rateStepsTemp.push({
                value: steps[3], color: '#fef801', percentage: this.getPercentageInStep(works,
                    steps[3], steps[4]), visible: true
            });
            rateStepsTemp.push({
                value: steps[4], color: '#ffb801', percentage: this.getPercentageInStep(works,
                    steps[4], steps[5]), visible: true
            });
            rateStepsTemp.push({
                value: steps[5], color: '#ff7e01', percentage: this.getPercentageInStep(works,
                    steps[5], steps[6]), visible: true
            });
            rateStepsTemp.push({
                value: steps[6], color: '#d40000', percentage: this.getPercentageInStep(works,
                    steps[6], 0), visible: true
            });

            return rateStepsTemp;
        },
        getWorkRequestBySelectedMapType(fieldId, taskId, dropDownTaskMapType, startDate, endDate) {
            let params = {
                startDate: startDate,
                endDate: endDate
            };
            switch (dropDownTaskMapType.value) {
                case 'APPLICATION':
                    params['binElementNumber'] = dropDownTaskMapType.bin_el;
                    params['culturalPractice'] = dropDownTaskMapType.cultural_practice;
                    return this.fieldWorksService.getApplicationRateWorks(fieldId, taskId, params);
                case 'HUMIDITY':
                    return this.fieldWorksService.getHumidityWorks(fieldId, taskId, params);
                case 'SPEED':
                    return this.fieldWorksService.getSpeedWorks(fieldId, taskId, params);
                case 'OVERLAP':
                    return this.fieldWorksService.getOverlap(fieldId, taskId, params);
                default:
                    return this.fieldWorksService.getSpeedWorks(fieldId, taskId, params);
            }
        },
        getMapDropDownMapType(mapTypes) {
            if (!mapTypes || mapTypes.length === 0) {
                return null;
            }

            if (mapTypes[0].value === 'APPLICATION') {
                return mapTypes[0].subtypes[0];
            }

            return mapTypes[0];
        },
        createMapTypesFromResponse(response) {
            let mapTypes = []
            let applicationTypes = []
            response.forEach(mapType => {
                if (mapType.type === 'APPLICATION') {
                    applicationTypes.push({
                        title: mapType.cultural_practice_name, value: mapType.type,
                        bin_el: mapType.bin_element_number, cultural_practice: mapType.cultural_practice
                    })
                    return;
                }

                mapTypes.push({title: mapType.name, value: mapType.type, bin_el: this.guidGenerator()})
            })
            if (applicationTypes.length > 0) {
                mapTypes.push({title: this.$t('AppTasksCompare.Trabalho'), value: "APPLICATION", subtypes: applicationTypes})
            }
            mapTypes.push({title: this.$t('AppTasksCompare.Sobreposição'), value: "OVERLAP", bin_el: this.guidGenerator()})

            return mapTypes;
        },
        loadMapTypesOne(task) {
            this.fieldWorksService.getFieldWorkTypes(this.selected_field.id, task.id).then(response => {
                this.mapTypesOne = this.createMapTypesFromResponse(response);
                this.dropDownTaskMapTypeOne = this.getMapDropDownMapType(this.mapTypesOne);
                this.isMapTypeOverlapOne = this.dropDownTaskMapTypeOne.value === 'OVERLAP';
                this.getWorksOne(task);
            }).catch(error => {
                console.log(error); //TODO add toast
            })
        },
        loadMapTypesTwo(task) {
            this.fieldWorksService.getFieldWorkTypes(this.selected_field.id, task.id).then(response => {
                this.mapTypesTwo = this.createMapTypesFromResponse(response);
                this.dropDownTaskMapTypeTwo = this.getMapDropDownMapType(this.mapTypesTwo);
                this.isMapTypeOverlapTwo = this.dropDownTaskMapTypeTwo.value === 'OVERLAP';
                this.getWorksTwo(task);
            }).catch(error => {
                console.log(error); //TODO add toast
            })
        },
        guidGenerator() {
            let S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
        },
        getWorksOne(task) {
            if (!this.dropDownTaskMapTypeOne) {
                return;
            }

            this.worksOne = [];
            this.rateStepsOne = [];
            this.loadingOne = true;
            this.cardsOne = [];

            this.getWorkRequestBySelectedMapType(this.selected_field.id, task.id, this.dropDownTaskMapTypeOne,
                moment(this.selected_task.start).toDate().valueOf(),
                moment(this.selected_task.end).toDate().valueOf()).then(response => {

                response.field_works.forEach((work) => {
                    if (!work || !work.value) {
                        return
                    }

                    if (this.selected_task.name === 'Colheita' && this.dropDownTaskMapTypeOne.value === 'APPLICATION') {
                        work.value = work.value * 10
                    }

                    if (this.selected_task.name === 'Pulverização' && this.dropDownTaskMapTypeOne.value === 'APPLICATION') {
                        work.value = work.value /100
                    }

                })

                this.worksOne = response.field_works;
                this.rateStepsOne = this.processStepRate(response.field_works, Math.max(...response.field_works.map(o => o.value)),
                    Math.min(...response.field_works.map(o => o.value)));
                this.cardsOne = this.loadCardsData(response.field_works, this.dropDownTaskMapTypeOne,
                    this.isMapTypeOverlapOne, response.overlap_area, response.worked_area, response.produced_area, this.selected_task);
            }).catch(error => {
                console.log(error)
                if (error.response && error.response.data && error.response.data.details) {
                    this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                } else {
                    this.$toast.add({severity: 'error', summary: this.$t('AppTasksCompare.Não foi possível analisar trabalho'), life: 3000});
                }
            }).finally(() => {
                this.loadingOne = false;
            })
        },
        getWorksTwo(task) {
            if (!this.dropDownTaskMapTypeTwo) {
                return;
            }

            this.worksTwo = [];
            this.rateStepsTwo = [];
            this.loadingTwo = true;
            this.cardsTwo = [];

            this.getWorkRequestBySelectedMapType(this.selected_field.id, task.id, this.dropDownTaskMapTypeTwo,
                moment(this.task_to_compare.start).startOf('day').toDate().valueOf(),
                moment(this.task_to_compare.end).endOf('day').toDate().valueOf()).then(response => {
                response.field_works.forEach((work) => {
                    if (!work || !work.value) {
                        return
                    }

                    if (this.task_to_compare.name === 'Colheita' && this.dropDownTaskMapTypeTwo.value === 'APPLICATION') {
                        work.value = work.value * 10
                    }

                    if (this.task_to_compare.name === 'Pulverização' && this.dropDownTaskMapTypeTwo.value === 'APPLICATION') {
                        work.value = work.value /100
                    }

                })

                this.worksTwo = response.field_works;
                this.rateStepsTwo = this.processStepRate(response.field_works, Math.max(...response.field_works.map(o => o.value)),
                    Math.min(...response.field_works.map(o => o.value)));
                this.cardsTwo = this.loadCardsData(response.field_works, this.dropDownTaskMapTypeTwo,
                    this.isMapTypeOverlapTwo, response.overlap_area, response.worked_area, response.produced_area, this.task_to_compare);
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.details) {
                    this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                } else {
                    this.$toast.add({severity: 'error', summary: this.$t('AppTasksCompare.Não foi possível analisar trabalho'), life: 3000});
                }
            }).finally(() => {
                this.loadingTwo = false;
            })
        },
        shouldShowCard(val) {
            return isFinite(parseFloat(val)) && !isNaN(parseFloat(val));
        },
        setTaskOneDates() {
            let temp_dates = [];
            let enabledDatesTmp = [];

            if (!this.dropDownTaskOne) {
                return;
            }

            this.rangeDates.forEach((date) => {
                let dateToAdd = moment(date, "YYYY-MM-DD").startOf('day').toDate();
                if (dateToAdd >= moment(this.dropDownTaskOne.start).toDate() && dateToAdd < moment(this.dropDownTaskOne.end).toDate()
                    && this.field_work_dates.includes(dateToAdd.valueOf())) {
                    enabledDatesTmp.push(moment(date, "YYYY-MM-DD").startOf('day').toDate())
                    return;
                }
                temp_dates.push(moment(date, "YYYY-MM-DD").startOf('day').toDate());
            })
            this.disabledDatesOne = temp_dates;

            if (enabledDatesTmp.length > 0) {
                this.dateSelectedOne = enabledDatesTmp[0];
            }
        },
        setTaskTwoDates() {
            let temp_dates = [];
            let enabledDatesTmp = [];

            if (!this.dropDownTaskTwo) {
                return;
            }

            this.rangeDates.forEach((date) => {
                let dateToAdd = moment(date, "YYYY-MM-DD").startOf('day').toDate();
                if (dateToAdd >= moment(this.dropDownTaskTwo.start).toDate() && dateToAdd < moment(this.dropDownTaskTwo.end).toDate()
                    && this.field_work_dates.includes(dateToAdd.valueOf())) {
                    enabledDatesTmp.push(moment(date, "YYYY-MM-DD").startOf('day').toDate())
                    return;
                }
                temp_dates.push(moment(date, "YYYY-MM-DD").startOf('day').toDate());
            })
            this.disabledDatesTwo = temp_dates;

            if (enabledDatesTmp.length > 0) {
                this.dateSelectedTwo = enabledDatesTmp[0];
            }
        }
    },
    watch: {
        dropDownTaskOne: function (val) {
            this.setTaskOneDates();
            this.loadMapTypesOne(val);
        },
        dropDownTaskTwo: function (val) {
            this.setTaskTwoDates();
            this.loadMapTypesTwo(val);
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        },
        getYearRange() {
            return '2018:' + new Date().getFullYear();
        },
    },
    props: {
        selected_task: {
            type: Object
        },
        tasks: {
            type: Array
        },
        task_to_compare: {
            type: Object
        },
        field_center: {
            type: Object
        },
        selected_field: {
            type: Object
        },
        field_work_dates: {
            type: Array
        }
    },
    components: {
        Calendar,
        AppGoogleMapsTasks, Dropdown, AppButton, Dialog, Button, InputText, Splitpanes, Pane, AppGoogleMapsTypeControl,
        AppTasksWorkOptions, CascadeSelect
    }
}

</script>

<style>
.tasks-cascade > .p-cascadeselect .p-cascadeselect-label {
    height: calc(1.5em + 1.3rem + 2px) !important;
    padding: 0.8rem 0.8rem !important
}
</style>

<style scoped lang="scss">

@import "src/assets/styles/constants";


.p-calendar {
    max-width: 14ch !important;
    min-width: 13ch !important;
}

.sub-menu {
    display: flex;
    align-items: center;
    padding-left: 17px;
    background-color: $head-porlet;
    height: 60px;
    position: relative;
}

.splitpanes.default-theme .splitpanes__pane {
    background-color: #fff;
}

.splitpanes-height {
    height: calc(100vh - 81px);
}

.statistics-card {
    position: absolute;
    z-index: 200;
    left: 20px;
    top: 80px;
    background: rgba(41, 50, 60, .8);
    border-radius: 5px;
    width: fit-content;
    color: white;
    margin-top: 10px;
    padding: 10px;
    border: 2px solid white;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-dropdown{
    width:100%;
    margin-left: 0px;
}

.dropdown-width-trabalho{
    width: 48%
}

.dropdown-width-type{
    width: 48%;
    margin-left: 10px;
}

.width-250{
    width: 250px;
    margin-right: 5px;
}

.top-subtitle{
    top: 240px;
}

@media(max-width: 1024px){
    .glyphicons{
        margin: 0;
        padding: 0;
    }

    .statistics-card {
        top: 50px;
    }

    .top-subtitle{
        top: 190px;
    }
}

@media(max-width: 1245px){
    .width-250{
        width: 190px;
        margin-right: 10px;
    }
}

.maps-tasks {
    height: calc(100% - 81px) !important;
}

@media (max-width: 1024px) {
    .maps-tasks {
        height: calc(100% - 106px) !important;
    }
}

</style>
