<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-date-time-control-bar
                    @handleFilter="validateDataPreRequest"
                    :max_date="endDate.toDate()"
                    :loading_historical_data="isLoadingHistoricalData"
                    :start_date_open_date="datePickerStartDate"
                    :end_date_open_date="datePickerEndDate"
                    :no_work_dates="noWorkDates"
                    :disable_all_fields="isDataFiltered">
                    <template v-slot:selectone>
                        <Dropdown v-model="selectedOrg" :options="orgsList"
                                  optionLabel="display_name" :placeholder="$t('AppAnomalias.Organização')"
                                  class="auto-center" :disabled="isDataFiltered" :dataKey="'id'"
                                  :filter="true"
                                  style="width: 250px"
                                  @input="saveChosenOptionsToLocalStorage">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingClients">{{ $t('AppAnomalias.Carregando, aguarde') }}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                    </template>
                    <template v-slot:selecttwo>
                        <app-button v-if="isDataFiltered"
                                    type="secondary" class="auto-center" style="margin-left: 5px"
                                    @handleClick="goToServiceOrders">
                            {{ textGoToOrders }}
                        </app-button>

                        <app-button v-show="isDataFiltered"
                                    type="danger"
                                    class="auto-center"
                                    style="margin-left: 5px"
                                    @handleClick="handleBack"> {{ $t('AppAnomalias.Voltar') }}
                        </app-button>
                    </template>
                </app-date-time-control-bar>
            </div>
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius"
                 style="justify-content: center;">
                <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center"
                            :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': isLoadingHistoricalData }"
                            @handleClick="openMaximizable">
                    {{ $t('AppAnomalias.Filtrar') }}
                </app-button>
                <app-button v-if="isDataFiltered"
                            type="secondary" class="auto-center" style="margin-left: 5px"
                            @handleClick="goToServiceOrders">
                    {{ textGoToOrders }}
                </app-button>

                <app-button v-show="isDataFiltered"
                            type="danger"
                            class="auto-center"
                            style="margin-left: 7px"
                            @handleClick="handleBack"> {{ $t('AppAnomalias.Voltar') }}
                </app-button>
            </div>
            <Dialog header="Filtrar" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}"
                    :maximizable="true" :modal="true">
                <app-date-time-control-bar
                    style="display: flex; justify-content: center; margin-left: 0px;" class="row col-12"
                    @handleFilter="validateDataPreRequest"
                    :is_col_12="true"
                    :max_date="endDate.toDate()"
                    :loading_historical_data="isLoadingHistoricalData"
                    :start_date_open_date="datePickerStartDate"
                    :end_date_open_date="datePickerEndDate"
                    :no_work_dates="noWorkDates"
                    :disable_all_fields="isDataFiltered">
                    <template v-slot:selectone>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="veiculos" class="col-12">{{ $t('AppAnomalias.Organização') }}</label>
                            <Dropdown v-model="selectedOrg" :options="orgsList"
                                      optionLabel="display_name" :placeholder="$t('AppAnomalias.Organização') "
                                      class="auto-center col-12" :disabled="isDataFiltered" :dataKey="'id'"
                                      style="width: 250px"
                                      appendTo="tableDiv">
                                <template #option="slotProps" style="width: 500px">
                                    <div v-if="loadingClients">{{ $t('AppAnomalias.Carregando, aguarde') }}</div>
                                    <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                        -
                                    </div>
                                    <div style="padding-right: 20px" v-else>
                                        {{ slotProps.option.display_name }}
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label class="col-12">{{ $t('AppAnomalias.Período') }}</label>
                        </div>
                    </template>
                </app-date-time-control-bar>
            </Dialog>

            <div class="kt-portlet__body no-padding">
                <div class="no-gutters wraper">
                    <div class="col-12" v-show="showMap">
                        <div style="position: absolute; z-index: 5; right: 10px;top: 10px">
                            <app-anomalias-layer-control ref="anomalias_layer" @layerChanged="layerChanged"/>
                        </div>
                        <app-anomalias-google-maps ref="anomalias_map" v-if="showMap"
                                                   :locations="locationsData"
                                                   :markers="showMarkers"
                                                   :polygons_data="polygonsGoogleMaps"
                                                   :heat-map="showHeatMap"
                                                   :center_location="centerLocation"
                                                   :selected_fields="selectedFields"
                                                   :show_fields="showFields"
                                                   :data_filtered="isDataFiltered"
                                                   @fixPointMarkersChanged="fixPointMarkersChanged"
                                                   @polygonSelected="polygonSelected"
                                                   style="position: relative;"
                                                   class="google-maps height-map">
                        </app-anomalias-google-maps>
                        <div v-if="fields.length > 0 && showFieldsDataTable && !is_col_12"
                             style="position: absolute; z-index: 5; top: 180px; right: 14px; width: 670px">
                            <DataTable :value="fieldsToShow" style="text-align: center" dataKey="id"
                                       :selection.sync="selectedFields" id="dataTableScroll"
                                       :scrollable="true" scrollHeight="40vh">
                                <Column selectionMode="multiple" :headerStyle="{maxWidth: '40px'}"
                                        :bodyStyle="{maxWidth: '40px', paddingLeft: '5px'}"></Column>
                                <Column headerClass="headerClass"
                                        field="name" :headerStyle="{minWidth: '160px'}"
                                        :bodyStyle="{minWidth: '160px', position: 'relative', right: '7px'}"
                                        :sortable="true" :header="$t('AppAnomalias.Trecho')"></Column>
                                <Column headerClass="headerClass" bodyClass="bodyClass"
                                        field="kilometersTraveled" :headerStyle="{minWidth: '60px'}"
                                        :bodyStyle="{minWidth: '60px'}"
                                        :sortable="true" header="km"></Column>
                                <Column headerClass="headerClass" field="occurrences" :headerStyle="{minWidth: '110px'}"
                                        :bodyStyle="{minWidth: '110px'}"
                                        :sortable="true" bodyClass="bodyClass"
                                        :header="$t('AppAnomalias.Ocorrências')"></Column>
                                <Column headerClass="headerClass" field="occurrencesKm"
                                        :sortable="true" bodyClass="bodyClass"
                                        :header="$t('AppAnomalias.Ocorrências') + '/km'"
                                        :headerStyle="{minWidth: '140px'}" :bodyStyle="{minWidth: '140px'}"></Column>
                                <Column headerClass="headerClass" field="priority"
                                        :sortable="true" bodyClass="bodyClass" :header="$t('AppAnomalias.Prioridade')"
                                        :headerStyle="{minWidth: '100px'}" :bodyStyle="{minWidth: '100px'}"></Column>
                                <Column :headerStyle="{minWidth: '30px', padding: '0px'}"
                                        bodyClass="bodyClass"
                                        :bodyStyle="{minWidth: '30px', padding: '0px'}">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-info" class="p-button-rounded p-button-primary p-mr-2"
                                                style="margin-right: 5px;height: 18px;width:18px"
                                                :id="slotProps.data.id"
                                                @click="getFieldDetails(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #footer>
                                    <div class="row">
                                        <div style="width: 150px;text-align: left">Total</div>
                                        <div style="width: 85px;text-align: center">{{ totalKm }}</div>
                                        <div style="width: 85px;text-align: center">{{ totalOccurrences }}</div>
                                        <div style="width: 100px">{{ averageOccurrencesKm }}</div>
                                        <div style="width: 130px;text-align: right;padding-right: 0">
                                            {{ totalPriority }}
                                        </div>
                                        <div style="width: 70px;text-align: right;margin-right: 4px">
                                            <Button icon="pi pi-info" class="p-button-rounded p-button-help p-mr-2"
                                                    style="margin-right: 10px;height: 18px;width:18px"
                                                    @click="getTotalFieldsDetails()"/>
                                        </div>
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                        <app-anomalias-arrow v-if="fields.length > 0 && !is_col_12" class="d-none d-md-block"
                                             @handleChange="handleShowHideFieldsDataTable"></app-anomalias-arrow>

                        <div v-if="fieldsKilometersTraveled.length > 0"
                             style="position: absolute; z-index: 5;bottom: 30px;left: 14px;width: 20px;color: white;
                             font-size: 14px;font-weight: bold;min-height: 75px">
                            {{ sliderValue }}
                            <div style="background-image: linear-gradient(#ff0000, #1f00ff);">
                                <Slider v-model="sliderValue" orientation="vertical" :min="sliderMin"
                                        :max="sliderMax"
                                        :step="0.5" @input="sliderChange"/>
                            </div>
                        </div>

                        <div style="position: absolute; z-index: 5; bottom: 14px; right: 14px;"
                             v-show="numberOfPlacesToCreateOrder > 0 && selectedOrg">
                            <app-button type="primary" @handleClick="openCreateOrderDialog"
                                        :data_count="numberOfPlacesToCreateOrder">
                                {{ $t('AppAnomalias.Criar ordem') }}
                                <i class="pi pi-plus-circle" style="fontSize: 1.2rem;padding-left: 4px"></i>
                            </app-button>
                            <span class="badge">{{ numberOfPlacesToCreateOrder }}</span>
                        </div>
                    </div>
                    <div v-if="showMap && is_col_12" class="card data-table-width">
                        <div v-if="fields.length > 0 && showFieldsDataTable && is_col_12">
                            <DataTable :value="fieldsToShow" style="text-align: center" dataKey="id"
                                       :selection.sync="selectedFields" id="dataTableScroll"
                                       :scrollable="true" scrollHeight="40vh">
                                <Column selectionMode="multiple" :headerStyle="{maxWidth: '40px'}"
                                        :bodyStyle="{maxWidth: '40px', paddingLeft: '5px'}"></Column>
                                <Column headerClass="headerClass"
                                        field="name" :headerStyle="{minWidth: '160px'}"
                                        :bodyStyle="{minWidth: '160px', position: 'relative', right: '7px'}"
                                        :sortable="true" :header="$t('AppAnomalias.Trecho') "></Column>
                                <Column headerClass="headerClass" bodyClass="bodyClass"
                                        field="kilometersTraveled" :headerStyle="{minWidth: '60px'}"
                                        :bodyStyle="{minWidth: '60px'}"
                                        :sortable="true" header="Km"></Column>
                                <Column headerClass="headerClass" field="occurrences" :headerStyle="{minWidth: '110px'}"
                                        :bodyStyle="{minWidth: '110px'}"
                                        :sortable="true" bodyClass="bodyClass"
                                        :header="$t('AppAnomalias.Ocorrências')"></Column>
                                <Column headerClass="headerClass" field="occurrencesKm"
                                        :sortable="true" bodyClass="bodyClass"
                                        :header="$t('AppAnomalias.Ocorrências') + '/km'"
                                        :headerStyle="{minWidth: '140px'}" :bodyStyle="{minWidth: '140px'}"></Column>
                                <Column headerClass="headerClass" field="priority"
                                        :sortable="true" bodyClass="bodyClass" :header="$t('AppAnomalias.Prioridade')"
                                        :headerStyle="{minWidth: '100px'}" :bodyStyle="{minWidth: '100px'}"></Column>
                                <Column :headerStyle="{minWidth: '30px', padding: '0px'}"
                                        bodyClass="bodyClass"
                                        :bodyStyle="{minWidth: '30px', padding: '0px'}">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-info" class="p-button-rounded p-button-primary p-mr-2"
                                                style="margin-right: 5px;height: 18px;width:18px"
                                                :id="slotProps.data.id"
                                                @click="getFieldDetails(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #footer>
                                    <div style="display: flex;">
                                        <div style="width: 200px;text-align: left;">Total</div>
                                        <div style="width: 40px;text-align: center;">{{ totalKm }}</div>
                                        <div style="width: 40px;text-align: center;">{{ totalOccurrences }}</div>
                                        <div style="width: 80px;">{{ averageOccurrencesKm }}</div>
                                        <div style="width: 90px;text-align: right;padding-right: 0;">
                                            {{ totalPriority }}
                                        </div>
                                        <div style="width: 70px;text-align: right;margin-right: 4px;">
                                            <Button icon="pi pi-info" class="p-button-rounded p-button-help p-mr-2"
                                                    style="margin-right: 10px;height: 18px;width:18px"
                                                    @click="getTotalFieldsDetails()"/>
                                        </div>
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </div>
                    <div class="col-12" v-if="!showMap">
                        <app-anomalias-service-orders-dashboard :organization="selectedOrg"
                                                                :polygons_google_maps="originalPolygons"
                                                                :fields_data="fields"
                                                                :last_searched_dates="lastSearchedDates"/>
                    </div>
                </div>
            </div>
        </div>

        <app-anomalias-create-order-dialog v-if="isDataFiltered"
                                           :display_dialog="displayCreateOrderDialog" :selected_fields="selectedFields"
                                           :polygons_google_maps="originalPolygons"
                                           :organization_id="selectedOrg.id"
                                           :fix_point_markers="fixPointMarkers"
                                           @handleClose="handleCloseCreateOrderDialog"/>
        <app-anomalias-field-details-dialog :display_dialog="displayFieldDetailsDialog"
                                            :selected_field="selectedFieldToShowDetails"
                                            :fields="fields"
                                            :fields_kilometers_traveled="fieldsKilometersTraveled"
                                            :all_fields_details="allFieldsDetails"
                                            @handleClose="handleCloseFieldDetailsDialog"/>
    </div>
</template>

<script>

import AppButton from "../../common/AppButton";
import AppAnomaliasGoogleMaps from "./AppAnomaliasGoogleMaps";
import AppDateTimeControlBar from "../../common/AppDateTimeControlBar";
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import OrganizationsService from "../../../services/OrganizationsService";
import AnomaliasService from "../../../services/AnomaliasService";
import moment from 'moment';
import Checkbox from 'primevue/checkbox';
import getEventsAnomaliasFromMultipleVehicles from "../../../mixins/ReportsMixin";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Button from 'primevue/button';
import * as jsts from 'jsts';
import FieldsService from '../../../services/FieldsService';
import {generateColor} from '../../../utils/colorGradient.js';
import VehiclesService from "../../../services/VehiclesService";
import ReportsService from "../../../services/ReportsService";
import Slider from 'primevue/slider';
import AppAnomaliasCreateOrderDialog from "./AppAnomaliasCreateOrderDialog";
import AppAnomaliasServiceOrdersDashboard from "./AppAnomaliasServiceOrdersDashboard";
import AppAnomaliasFieldDetailsDialog from "./AppAnomaliasFieldDetailsDialog";
import AppAnomaliasArrow from "./AppAnomaliasArrow";
import displayToast from "@/mixins/ToastMixin";
import AppAnomaliasLayerControl from "./AppAnomaliasLayerControl";
import {ANOMALIAS_LAYERS_LIST} from "@/components/views/anomalias/AppAnomaliasOptions";
import EventsService from "../../../services/EventsService";
import Dialog from 'primevue/dialog';


export default {
    mixins: [getEventsAnomaliasFromMultipleVehicles, displayToast],
    mounted() {
        this.organizationsService = new OrganizationsService();
        this.anomaliasService = new AnomaliasService();
        this.fieldsService = new FieldsService();
        this.vehiclesService = new VehiclesService();
        this.reportsService = new ReportsService();
        this.eventsService = new EventsService();

        this.colorGradient = generateColor('#ff0000', '#1f00ff', 11);
        this.getCustomerList();

        if (window.screen.width <= 1024) {
            this.showDialogFilters = true;
        }
        this.is_col_12 = this.isScreenMobile;
    },

    data() {
        return {
            is_col_12: false,
            showDialogFilters: false,
            displayMaximizable: false,
            selectedOrg: null,
            orgsList: [],
            loadingClients: true,
            organizationsService: null,
            anomaliasService: null,
            eventsService: null,
            //guarda o valor inicial para o caso de selecionar o trecho
            locations: [],
            //valor passado para o mapa
            locationsData: [],

            fieldsService: null,

            centerLocation: null,
            datePickerStartDate: moment().startOf('day').toDate(),
            datePickerEndDate: moment().endOf('day').toDate(),
            noWorkDates: [],
            showMarkers: false,
            showHeatMap: true,
            showFields: true,
            polygonsGoogleMaps: [],
            fields: [],
            selectedFields: [],
            colorGradient: null,
            isLoadingHistoricalData: false,
            vehiclesService: null,
            reportsService: null,
            vehicles: [],
            fieldsKilometersTraveled: [],
            sliderMin: 0,
            sliderMax: 8,
            sliderValue: 0.0,
            slideEndMethod: null,
            originalPolygons: [],
            fieldsToShow: [],
            maximumOccurrencesKm: 0,
            displayCreateOrderDialog: false,
            displayFieldDetailsDialog: false,
            showMap: true,
            textGoToOrders: this.$t('AppAnomalias.Ordens'),
            lastSearchedDates: {
                start_date: moment().subtract(1, 'day').startOf('day').toDate(),
                end_date: moment().subtract(1, 'day').endOf('day').toDate(),
            },
            selectedFieldToShowDetails: {},
            showFieldsDataTable: true,
            allFieldsDetails: false,
            fixPointMarkers: [],
            endDate: moment().add(3, 'M'),
            isDataFiltered: false,
            storedChosenOptions: {},
            anomaliasLayersList: ANOMALIAS_LAYERS_LIST()
        }
    },

    methods: {
        openMaximizable() {
            this.displayMaximizable = true;
        },
        getOrgDisplayName(org) {
            return org.trade_name ? org.name + " - " + org.trade_name : org.name;
        },
        layerChanged(val) {
            this.showMarkers = val.some(obj => {
                return obj === this.anomaliasLayersList[0]
            });
            this.showHeatMap = val.some(obj => {
                return obj === this.anomaliasLayersList[1]
            });
            this.showFields = val.some(obj => {
                return obj === this.anomaliasLayersList[2]
            });
        },
        handleShowHideFieldsDataTable() {
            this.showFieldsDataTable = !this.showFieldsDataTable;
        },
        getTotalFieldsDetails() {
            this.displayFieldDetailsDialog = true;
            this.allFieldsDetails = true;
        },
        getFieldDetails(data) {
            this.allFieldsDetails = false;
            this.selectedFieldToShowDetails = data;
            this.displayFieldDetailsDialog = true;
        },
        goToServiceOrders() {
            this.showMap = !this.showMap;
            this.textGoToOrders = this.showMap ? this.$t('AppAnomalias.Ordens') : this.$t('AppAnomalias.Mapa');

            if (this.showMap) {
                this.validateDataPreRequest(this.lastSearchedDates);
                this.showFieldsDataTable = true;
                this.fixPointMarkers = [];
            }
        },
        sliderChange() {
            let vm = this;
            clearTimeout(this.slideEndMethod);
            this.slideEndMethod = setTimeout(function () {
                vm.calculateNewColorGradient();
                clearTimeout(this.slideEndMethod);
            }, 300);
        },
        calculateNewColorGradient() {
            let polygonsGoogleMapsTemp = [];

            let stepColorGradient = (this.sliderMax - this.sliderMin) / 10;

            if (stepColorGradient === 0) {
                stepColorGradient = 1;
            }

            this.fieldsToShow = [];

            this.fields.forEach(field => {

                let index = this.originalPolygons.findIndex(i => i.id === field.id);

                if (index < 0) {
                    return;
                }

                if (this.originalPolygons[index].occurrencesKm - this.sliderValue < 0) {
                    return;
                }

                let colorIndex = this.originalPolygons[index].occurrencesKm > this.sliderMax ? 10 :
                    ((this.originalPolygons[index].occurrencesKm) / stepColorGradient);

                if (parseInt(colorIndex) > 10) {
                    colorIndex = 10;
                }

                this.originalPolygons[index].color = '#' + this.colorGradient[parseInt(colorIndex)];
                polygonsGoogleMapsTemp.push(this.originalPolygons[index]);
                this.fieldsToShow.push(field);
            })

            this.selectedFields = [];
            this.polygonsGoogleMaps = polygonsGoogleMapsTemp;
        },
        openCreateOrderDialog() {
            if (!this.selectedOrg) {
                return;
            }
            this.displayCreateOrderDialog = true;
        },
        handleCloseCreateOrderDialog() {
            this.displayCreateOrderDialog = false;
        },
        handleCloseFieldDetailsDialog() {
            this.displayFieldDetailsDialog = false;
        },
        getCustomerList() {
            this.organizationsService.getAllV3("ROADS")
                .then((response) => {
                    this.getOrgsList(response);
                    this.loadFilteredOptions();
                }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loadingClients = false;
            })
        },
        getOrgsList(orgs) {
            this.orgsList = orgs.sort(function (a, b) {
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
            });
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        getPolygons(valSelected) {
            return this.fieldsService.getFieldsV3(valSelected.id);
        },
        getAnomalias(orgId, data) {
            return this.anomaliasService.getAnomaliasByOrgV3(orgId, {
                start: data.start_date.valueOf(),
                end: data.end_date.valueOf(),
            })
        },
        getVehiclesFromOrg(orgId) {
            this.vehiclesService.getVehiclesByOrgV3(orgId)
                .then((response) => {
                    this.vehicles = response;
                    this.getDatesWithData(orgId)
                }).catch((error) => {
                console.log(error);
            })
        },
        getDatesWithData(orgId) {
            this.anomaliasService.getDatesWithDataV3(orgId)
                .then((response) => {
                    this.populateDatesHighlighted(response.dates);
                }).catch((error) => {
                console.log(error);
            })
        },
        populateDatesHighlighted(dates) {


            if (dates.length <= 0) {
                this.noWorkDates = [];
                return;
            }

            //caso esteja fora de ordem ordena as datas
            dates.sort(function (a, b) {
                let start = moment(a, "YYYY-MM-DD");
                let end = moment(b, "YYYY-MM-DD");

                if (start.isBefore(end)) {
                    return -1;
                } else if (start.isAfter(end)) {
                    return 1;
                }
                return 0;
            });

            let endDate = this.endDate.format("YYYY-MM-DD");
            let initialDate = moment("2021-01-01", "YYYY-MM-DD");

            //se alguma data for antes de initialDate e depois de endDate remove elas
            for (let i = 0; i <= dates.length; i++) {
                if (moment(dates[i], "YYYY-MM-DD").isBefore(initialDate, 'day') || moment(dates[i], "YYYY-MM-DD").isAfter(endDate, 'day')) {
                    dates.splice(i, 1);
                }
            }

            let noWorkDates = [];

            //cria um array com as datas initialDate ate endDate
            while (initialDate.isBefore(endDate, 'day')) {
                noWorkDates.push(moment(initialDate).format('YYYY-MM-DD'));
                initialDate = initialDate.add(1, 'days');
            }

            //se a data veio no request remove ela das datas desabilitadas
            dates.forEach(function (data) {
                let index = noWorkDates.indexOf(moment(data).format("YYYY-MM-DD"));
                if (index > -1) {
                    noWorkDates.splice(index, 1);
                }
            });

            //formata as datas cfme o componente pede
            let temp_dates = [];
            noWorkDates.forEach(function (data) {
                temp_dates.push(moment(data, "YYYY-MM-DD")._d)
            })

            this.noWorkDates = temp_dates;
        },
        treatPolygonsResponse(response) {
            if (!response || !response.fields || response.fields.length === 0) {
                return;
            }
            this.fields = response.fields.sort((a, b) => a.name.localeCompare(b.name));
            this.processFields();
        },
        treatAnomaliasResponse(response) {
            if (response.length === 0) {
                return;
            }
            this.populeData(response);
        },
        createBeginningOfDayDate(epoch) {
            let newDate = new Date(0);
            newDate.setUTCSeconds(epoch);
            newDate.setMinutes(0);
            newDate.setHours(0);
            newDate.setSeconds(0);

            return newDate;
        },
        treatEventsResponse(response) {
            this.fieldsKilometersTraveled = [];

            if (!response || response.length === 0) {
                return;
            }

            response.forEach((vehicleResponse) => {
                vehicleResponse.forEach(event => {
                    let fields = event.field.split(',');
                    let eventDate = this.createBeginningOfDayDate(event.event_start);

                    fields.forEach(field => {
                        let index = this.fieldsKilometersTraveled.findIndex(i => i.name === field);
                        if (index >= 0) {
                            this.fieldsKilometersTraveled[index].kilometersTraveled += event.distance;

                            let indexByDay = this.fieldsKilometersTraveled[index].kilometersTraveledByDay.findIndex(
                                i => i.day.getTime() === eventDate.getTime()
                            );

                            if (indexByDay >= 0) {
                                this.fieldsKilometersTraveled[index].kilometersTraveledByDay[indexByDay].kms += event.distance;
                                return;
                            }

                            this.fieldsKilometersTraveled[index].kilometersTraveledByDay.push({
                                day: eventDate,
                                kms: event.distance
                            })
                            return;
                        }

                        this.fieldsKilometersTraveled.push({
                            name: field,
                            kilometersTraveled: event.distance,
                            kilometersTraveledByDay: [{
                                day: eventDate,
                                kms: event.distance
                            }]
                        })
                    })
                })
            })

        },
        clearData() {
            this.locations = [];
            this.locationsData = [];
            this.fieldsKilometersTraveled = [];
        },
        validateDataPreRequest(data) {
            if (!this.selectedOrg) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAnomalias.Selecione uma organização'),
                    life: 3000
                });
                return;
            }

            let start_date = moment.tz(data.start_date, 'America/Sao_Paulo').utc();
            let end_date = moment.tz(data.end_date, 'America/Sao_Paulo').utc();

            if (end_date.diff(start_date, 'days') > 30) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnomalias.Análise não realizada'),
                    detail: this.$t('AppAnomalias.Selecione um período menor que 30 dias'),
                    life: 5000
                });
                return;
            }

            this.getRequestData(data);
        },
        getRequestData(data) {
            //Retornando ao mapa se trocou de cliente
            this.showMap = true;
            this.textGoToOrders = this.$t('AppAnomalias.Ordens');
            this.polygonsGoogleMaps = [];
            this.fieldsToShow = [];
            this.fields = [];

            let vm = this;
            const promises = [];

            promises.push(this.getAnomalias(this.selectedOrg.id, data));
            promises.push(this.getEventsAnomaliasFromMultipleVehicles(data, this.vehicles));
            promises.push(this.getPolygons(this.selectedOrg));

            vm.isLoadingHistoricalData = true
            Promise.all(promises)
                .then((results) => {
                    if (results.length > 2) {
                        vm.clearData();
                        vm.treatAnomaliasResponse(results[0]);
                        vm.treatEventsResponse(results[1]);
                        vm.treatPolygonsResponse(results[2]);
                        vm.isDataFiltered = true;
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.displayToast(this.$data.toastWarn, this.$t('AppAnomalias.Análise não realizada'),
                            this.$t('AppAnomalias.Não foi possível realizar a análise no período informado'));
                        return;
                    }

                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAnomalias.Não foi possivel completar a operação'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary:  this.$t('AppAnomalias.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                }).finally(() => {
                vm.isLoadingHistoricalData = false;
                vm.lastSearchedDates = data;
            });
            this.displayMaximizable = false;
        },
        populeData(data) {
            this.locations = data;
            this.locationsData = data;
            this.centerLocation = data[1]
        },
        getLocationsInsideField(field) {
            var reader = new jsts.io.WKTReader();
            var polygon = this.buildPolygonFromCoordinates(field.coordinates);

            let numberOfLocations = 0.0;
            let piorityOfLocations = 0.0;
            let fieldOccurrencesByDay = [];

            this.locations.forEach((value) => {
                let point = reader.read('POINT (' + value.lng + ' ' + value.lat + ')');
                if (polygon.contains(point)) {
                    piorityOfLocations += (value.ocorr * value.rms);
                    numberOfLocations += value.ocorr;

                    let occurrence_date = this.createBeginningOfDayDate(value.data_ocorrencia);
                    let indexByDay = fieldOccurrencesByDay.findIndex(
                        i => i.day.getTime() === occurrence_date.getTime()
                    );

                    if (indexByDay >= 0) {
                        fieldOccurrencesByDay[indexByDay].occurrences += parseInt(value.ocorr);
                        return;
                    }

                    fieldOccurrencesByDay.push({
                        day: occurrence_date,
                        occurrences: parseInt(value.ocorr)
                    })
                }
            });

            return {
                occurrences: numberOfLocations,
                priority: piorityOfLocations,
                fieldOccurrencesByDay: fieldOccurrencesByDay
            }
        },
        setOccurrencesAndPriority() {
            let maximumOccurrencesKm = -1;
            let minimumOccurrencesKm = 1000;

            this.fields.forEach(field => {

                let locationsInsideInfo = this.getLocationsInsideField(field);

                let occurrences = locationsInsideInfo.occurrences;
                let priority = locationsInsideInfo.priority;

                let numberOfKilometers = 1;

                let fieldKilometersTraveled = this.fieldsKilometersTraveled.find(obj => {
                    return obj.name === field.name
                })

                if (fieldKilometersTraveled && fieldKilometersTraveled.kilometersTraveled > 0) {
                    numberOfKilometers = fieldKilometersTraveled.kilometersTraveled;
                }

                field['kilometersTraveled'] = fieldKilometersTraveled ? numberOfKilometers.toFixed(2) : 0;

                if (field['kilometersTraveled'] > 0 && parseInt(occurrences) > 0) {
                    field['occurrencesKm'] = parseFloat((parseInt(occurrences) / field['kilometersTraveled']).toFixed(2));
                    priority = priority / numberOfKilometers;
                } else {
                    field['occurrencesKm'] = 0.0;
                    priority = 0.0;
                }

                if (maximumOccurrencesKm < field['occurrencesKm']) {
                    maximumOccurrencesKm = field['occurrencesKm']
                }
                if (minimumOccurrencesKm > field['occurrencesKm']) {
                    minimumOccurrencesKm = field['occurrencesKm']
                }

                field['occurrences'] = parseInt(occurrences);
                field['priority'] = priority.toFixed(2);
                field['fieldOccurrencesByDay'] = locationsInsideInfo.fieldOccurrencesByDay;

            })

            if (minimumOccurrencesKm <= 0) {
                minimumOccurrencesKm = 1;
            }
            if (maximumOccurrencesKm <= 0) {
                maximumOccurrencesKm = 1;
            }

            return {maximumOccurrencesKm: maximumOccurrencesKm, minimumOccurrencesKm: minimumOccurrencesKm}
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        processFields() {
            let polygonsGoogleMapsTemp = [];
            const offsetOpacity = 0.5;

            let fieldLocationsInfo = this.setOccurrencesAndPriority();
            this.maximumOccurrencesKm = fieldLocationsInfo.maximumOccurrencesKm;

            //set min and max
            this.sliderValue = 0.0;

            this.fields.forEach(field => {
                polygonsGoogleMapsTemp.push({
                    name: field.name,
                    geom: this.buildPolygonFromCoordinates(field.coordinates),
                    id: field.id,
                    color: '#' + this.colorGradient[0],
                    opacity: offsetOpacity,
                    occurrencesKm: parseFloat(field['occurrencesKm'])
                });
            })

            this.fieldsToShow = this.fields;
            this.polygonsGoogleMaps = polygonsGoogleMapsTemp;
            this.originalPolygons = polygonsGoogleMapsTemp;
            this.calculateNewColorGradient();
        },
        makeLatLngToGoogleMaps(lat, lng) {
            return {lat: parseFloat(lat), lng: parseFloat(lng)}
        },
        polygonSelected(val) {
            if (val === -1) {
                this.selectedFields = [];
            }

            let index = this.selectedFields.findIndex(i => i.id === val);

            if (index >= 0) {
                this.selectedFields = this.selectedFields.filter(item => item !== this.selectedFields[index])
                return;
            }

            let indexInFieldsList = this.fields.findIndex(i => i.id === val);

            if (indexInFieldsList < 0) {
                return;
            }

            this.selectedFields.push(this.fields[indexInFieldsList]);
        },
        fixPointMarkersChanged(val) {
            this.fixPointMarkers = [];

            val.forEach(marker => {
                this.fixPointMarkers.push({
                    lat: marker.position.lat(),
                    lng: marker.position.lng()
                })
            })
        },
        scrollTo(element, to, duration) {
            let vm = this;
            let start = element.scrollTop,
                change = to - start,
                currentTime = 0,
                increment = 20;

            let animateScroll = function () {
                currentTime += increment;
                element.scrollTop = vm.easeInOutQuad(currentTime, start, change, duration);
                if (currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
            };
            animateScroll();
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        },
        handleBack() {
            this.clearDataFromHandleBack();
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async clearDataFromHandleBack() {
            this.showMap = true;
            this.textGoToOrders = this.$t('AppAnomalias.Ordens');

            await this.sleep(5);
            this.$refs.anomalias_map.clearMarkersMap();
            this.$refs.anomalias_map.showHideHeatMap(false);
            this.$refs.anomalias_layer.heatMapChanged(false);

            this.$refs.anomalias_map.clearFixMarkers();

            this.isDataFiltered = false;
            this.locations = [];
            this.fieldsKilometersTraveled = [];
            this.polygonsGoogleMaps = [];
            this.fields = [];
            this.selectedFields = [];
            this.originalPolygons = [];
            this.fieldsToShow = [];

            this.$refs.anomalias_layer.heatMapChanged(true);
            this.showHeatMap = true;
        }

    },
    watch: {
        selectedFields: function (val) {
            if (!val.length > 0) {
                return;
            }

            let scrollableBody = document.getElementsByClassName("p-datatable-wrapper");
            if (!scrollableBody || !scrollableBody.length > 0) {
                return;
            }

            let fieldElement = document.getElementById(val[val.length - 1].id);

            if (!fieldElement) {
                return;
            }

            this.scrollTo(scrollableBody[0], fieldElement.offsetTop - 100, 600);
        },
        selectedOrg: function (val) {
            this.getVehiclesFromOrg(val.id)
        },
    },
    computed: {
        totalKm: function () {
            let total = 0;
            this.fieldsToShow.forEach(field => {
                if (field.kilometersTraveled === 0) {
                    return;
                }
                total += parseFloat(field.kilometersTraveled)
            })
            return total.toFixed(2);
        },
        totalOccurrences: function () {
            let total = 0;
            this.fieldsToShow.forEach(field => {
                if (field.kilometersTraveled === 0) {
                    return;
                }
                total += parseFloat(field.occurrences)
            })
            return parseInt(total);
        },
        averageOccurrencesKm: function () {
            let totalKm = 0;
            let totalOccurrences = 0;
            this.fieldsToShow.forEach(field => {
                if (field.kilometersTraveled === 0) {
                    return;
                }
                totalKm += parseFloat(field.kilometersTraveled)
                totalOccurrences += parseFloat(field.occurrences)
            })

            if (totalKm === 0) {
                return 0;
            }

            return (totalOccurrences / totalKm).toFixed(2);
        },
        totalPriority: function () {
            let total = 0;
            this.fieldsToShow.forEach(field => {
                if (field.kilometersTraveled === 0) {
                    return;
                }
                total += parseFloat(field.priority)
            })
            return total.toFixed(2);
        },
        numberOfPlacesToCreateOrder: function () {
            return this.selectedFields.length + this.fixPointMarkers.length
        },
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    components: {
        AppButton, AppAnomaliasGoogleMaps, AppDateTimeControlBar, MultiSelect, Checkbox, DataTable,
        Column, Slider, AppAnomaliasCreateOrderDialog, AppAnomaliasServiceOrdersDashboard, Button,
        AppAnomaliasFieldDetailsDialog, AppAnomaliasArrow, ColumnGroup, Row, AppAnomaliasLayerControl,
        Dropdown, Dialog
    },
}
</script>

<style scoped lang="scss">

@import "./src/assets/styles/primevue";

.wraper .google-maps {
    height: calc(100vh - 81px) !important;
}

.padding-label {
    padding-left: 5px
}

.p-slider-vertical {
    background-color: #ff0000;
    height: 14rem;
}

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: .8em;
    background: red;
    color: black;
    font-weight: bold;
    font-size: 10px;
    border-radius: 8px;
}

@media (max-width: 1024px) {
    .data-table-width {
        width: 100vw;
        margin-top: 15px;
    }
    .wraper .google-maps {
        height: 70vh !important;
    }
}
</style>

<style>
@media (max-width: 1024px) {
    .p-dropdown-item {
        width: 480px !important;
    }

    .p-dropdown-panel {
        width: 250px !important;
        overflow-x: scroll !important;
    }
}
</style>
