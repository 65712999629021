<template>
    <transition name="container-transitions" mode="out-in">
        <div v-if="show">
            <div class="background kt-grid kt-grid--ver kt-grid--root kt-page">
                <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div class="kt-login__container">
                                <div class="kt-login__logo">
                                    <a href="#">
                                        <img :src="loginImage" class="img-fluid" style="max-height: 200px">
                                    </a>
                                </div>
                                <div class="kt-login__signin">
                                    <div class="kt-login__head">
                                        <h2 class="kt-login__title">{{ $t('AppChangePassword.Troca de senha') }}</h2>
                                    </div>
                                    <form class="kt-form" v-on:submit.prevent>
                                        <div class="input-group">
                                            <app-input class="form-control" type="password" :placeholder="$t('AppChangePassword.Nova Senha')"
                                                       :maxlength="60"
                                                       v-model="password"></app-input>
                                        </div>
                                        <div class="input-group">
                                            <app-input class="form-control" type="password"
                                                       :placeholder="$t('AppChangePassword.Confirmar Nova Senha')"
                                                       :maxlength="60"
                                                       v-model="passwordConfirm"></app-input>
                                        </div>
                                        <div class="kt-login__actions text-center">
                                            <app-button type="primary" size="lg" @handleClick="validateForm">Alterar
                                            </app-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import AppButton from '@/components/common/AppButton.vue'
    import AppInput from '@/components/common/AppRegisterInput.vue'

    import {router} from '../../../router';
    import axios from "axios";

    export default {
        created() {
        },
        mounted() {
            this.fade();
            if (this.$route.params.token) {
                this.token = this.$route.params.token;
            }
        },
        beforeDestroy() {
            this.fade();
        },
        data() {
            return {
                themeActive: process.env.VUE_APP_THEME_ACTIVE,
                loginImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE + ".png"),
                show: false,
                locale: navigator.language || navigator.userLanguage,
                password: null,
                passwordConfirm: null,
                token: null
            }
        },
        components: {AppButton, AppInput},
        methods: {
            fade() {
                this.show = !this.show;
            },
            validateForm() {

                if (!this.password || !this.passwordConfirm || this.password !== this.passwordConfirm) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChangePassword.Senhas não conferem'),
                        detail: this.$t('AppChangePassword.Informe a mesma senha nos dos campos'),
                        life: 3000
                    });
                    return;
                }

                if (this.password.length < 6 || this.password.length > 60) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChangePassword.Tamanho da senha'),
                        detail: this.$t('AppChangePassword.A senha deve ter entre 6 e 60 caracteres'),
                        life: 3000
                    });
                    return;
                }
                this.changePassword();
            },
            changePassword() {

                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/v1/change-password', {
                    token: this.token,
                    password: this.password
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                        if (response.status === 200) {
                            this.$toast.add({
                                severity: 'success',
                                summary: this.$t('AppChangePassword.Senha alterada'),
                                detail: this.$t('AppChangePassword.A senha foi alterada com sucesso, efetue o login usando a nova senha'),
                                life: 5000
                            });
                            router.push({name: 'login'});
                        }
                    }
                ).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChangePassword.Senha não alterada'),
                        detail: this.$t('AppChangePassword.Não foi possível alterar a senha'),
                        life: 3000
                    });
                });
            },
            goToRegister() {
                router.push({name: 'register'});
            },
            goToForgotPassword() {
                router.push({name: 'forgot-password'});
            },
        },
    }
</script>

<style scoped>
    @import "../../../assets/styles/login-4.css";

    .container-transitions-enter-active {
        transition-duration: 2.1s !important;
        transition-property: opacity !important;
        transition-timing-function: ease !important;
    }

    .container-transitions-enter, .container-transitions-leave-active {
        opacity: 0 !important;
    }

</style>

<i18n>
    {
        "pt-BR": {
            "sing_up": "Registrar"
        },
        "en-US": {
            "sing_up": "Sign Up"
        }
    }

</i18n>
