export default {
    methods: {
        jstsWithHolesToGoogleMaps(geometry, gmapsInstance) {
            let polygonCoordinatesTemp = []
            let eachPolygonTemp = [];
            geometry._shell.getCoordinates().forEach(coord => {
                eachPolygonTemp.push(new gmapsInstance.LatLng(coord.y, coord.x));
            })
            polygonCoordinatesTemp.push(eachPolygonTemp)

            geometry._holes.forEach(hole => {
                eachPolygonTemp = [];
                hole.getCoordinates().forEach(coord => {
                    eachPolygonTemp.push(new gmapsInstance.LatLng(coord.y, coord.x));
                })
                polygonCoordinatesTemp.push(eachPolygonTemp)
            })

            return polygonCoordinatesTemp;
        },
        jstsWithoutHolesToGoogleMaps(geometry, gmapsInstance) {
            let polygonCoordinatesTemp = []
            let eachPolygonTemp = [];
            geometry._shell.getCoordinates().forEach(coord => {
                eachPolygonTemp.push(new gmapsInstance.LatLng(coord.y, coord.x));
            })
            polygonCoordinatesTemp.push(eachPolygonTemp)

            return polygonCoordinatesTemp;
        }
    }
}
