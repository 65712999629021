import axios from '../axios/axios-auth'

export default class CropsService {

    /**
     * V3
     **/
    saveCrops(fieldId, postObj) {
        return axios.post(`/api/v3/fields/${fieldId}/crops`, postObj).then(response => response.data);
    }

    getCrops(fieldId) {
        return axios.get(`/api/v3/fields/${fieldId}/crops`).then(response => response.data);
    }


}
