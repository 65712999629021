<template>
    <div class="row" style="padding: 5px; background-color: #282828; border-radius: 5px; margin-bottom: 35px; margin-left: 10px;">

        <span v-if="show_fields_manager" class="dot" style="background-color: #FF0000"></span>
        <span v-if="show_fields_manager" class="legend">{{ $t('AppGoogleMapsLegend.Locais salvos') }}</span>
        <span v-if="show_fields_manager" class="dot" style="background-color: #000000"></span>
        <span v-if="show_fields_manager" class="legend">{{ $t('Locais não salvos') }}</span>


        <span v-if="there_is_idle_track_data || show_at_real_time" class="dot"
              style="background-color: #d62728"></span>
        <span v-if="there_is_idle_track_data || show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Ocioso') }}</span>

        <span v-if="there_is_maneuver_track_data || show_at_real_time"
              class="dot" style="background-color: #ff7e0e"></span>
        <span v-if="there_is_maneuver_track_data  || show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Manobrando') }}</span>

        <span v-if="there_is_work_track_data  || show_at_real_time"
              class="dot" style="background-color: #2ca02c"></span>
        <span v-if="there_is_work_track_data  || show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Produzindo') }}</span>

        <span v-if="there_is_travel_track_data  || show_at_real_time" class="dot"
              style="background-color: #1f76b4"></span>
        <span v-if="there_is_travel_track_data  || show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Deslocando') }}</span>

        <span v-if="show_at_real_time" class="dot"
              style="background-color: #5e3f20"></span>
        <span v-if="show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Desligado') }}</span>

        <span v-if="show_at_real_time" class="dot" style="background-color: #797979"></span>
        <span v-if="show_at_real_time" class="legend">{{ $t('AppGoogleMapsLegend.Sem Conexão') }}</span>

    </div>
</template>

<script>
    export default {
        props: {
            there_is_work_track_data: {
                type: Boolean,
                default: false,
            },
            there_is_idle_track_data: {
                type: Boolean,
                default: false,
            },
            there_is_maneuver_track_data: {
                type: Boolean,
                default: false,
            },
            there_is_travel_track_data: {
                type: Boolean,
                default: false,
            },
            show_fields_manager: {
                type: Boolean,
                default: false,
            },
            show_at_real_time: {
                type: Boolean,
                default: false,
            }
        },
    }
</script>

<style scoped>
    .dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
    }

    .legend {
        margin-left: 5px;
        margin-right: 5px;
        color: #f2f2f2;
    }

</style>
