<template>
    <div>
        <div>
            <h5>
                {{ $t('AppChecklistsAnswersList.Selecione um regístro e depois clique em continuar') }}:
            </h5>
        </div>

        <DataTable :value="answersList" :paginator="true" :rows="10" :filters.sync="filters" filterDisplay="menu"
                   :globalFilterFields="['checklist_title', 'operator_name', 'vehicle_display_id', 'vehicle_chassis', 'date_string']"
                   dataKey="answer_id" :rowHover="true" :loading="answersListLoading" responsiveLayout="scroll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                           CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                   :currentPageReportTemplate="$t('AppChecklistsAnswersList.currentPageReportTemplate')"
                   :selection.sync="selectedAnswerFromList" selectionMode="single">
            <template #header>
                <div class="flex">
                            <span class="p-input-icon-left w-25">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" :placeholder="$t('AppChecklistsAnswersList.Busca por palavra-chave')"/>
                            </span>
                </div>
            </template>
            <template #empty>
                {{ $t('AppChecklistsAnswersList.Sem regístros encontrados para a organização selecionada') }}.
            </template>
            <template #loading>
                {{ $t('AppChecklistsAnswersList.Carregando dados, por favor aguarde') }}.
            </template>
            <Column field="answerDate" :header="$t('AppChecklistsAnswersList.Data e Horário')" sortable dataType="date" sortField="answer_date">
                <template #body="{data}">
                    <div class="d-flex justify-content-center">
                        {{ data.date_string }}
                    </div>
                </template>
            </Column>
            <Column field="checklistTitle" :header="$t('AppChecklistsAnswersList.Título do Checklist')" sortable sortField="checklist_title">
                <template #body="{data}">
                    <div class="d-flex justify-content-center">
                        {{ data.checklist_title }}
                    </div>
                </template>
            </Column>
            <Column field="operatorName" :header="$t('AppChecklistsAnswersList.Nome do Operador')" sortable sortField="operator_name">
                <template #body="{data}">
                    <div class="d-flex justify-content-center">
                        {{ data.operator_name }}
                    </div>
                </template>
            </Column>
            <Column field="vehicleDisplayId" :header="$t('AppChecklistsAnswersList.Frota')" sortable sortField="vehicle_display_id">
                <template #body="{data}">
                    <div class="d-flex justify-content-center">
                        {{ data.vehicle_display_id }}
                    </div>
                </template>
            </Column>
            <Column field="vehicleChassis" :header="$t('AppChecklistsAnswersList.Chassis')" sortable sortField="vehicle_chassis">
                <template #body="{data}">
                    <div class="d-flex justify-content-center">
                        {{ data.vehicle_chassis }}
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column"
import InputText from 'primevue/inputtext';
import {FilterMatchMode} from "primevue/api";

export default {
    props: {
        answersList: {
            type: Array
        },
        answersListLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedAnswerFromList: null,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    },
    watch: {
        selectedAnswerFromList: function (value) {
            this.$emit('handleSelectedAnswer', value);
        },
    },
    components: {
        DataTable, Column, InputText
    }
}

</script>
