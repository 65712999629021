import ReportService from "../services/ReportsService";
import i18n from "@/i18n";
export default {

    methods: {
        saveData(data) {
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS_DATA, JSON.stringify(data));
            console.log("reportsData salvo")
        },
        retrieveData() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS_DATA));
        },
        getEventsFromMultipleVehicles(dates, selectedVehicles) {
            let vm = this;
            const promises = [];

            selectedVehicles.forEach(function (vehicle) {
                promises.push(vm.reportsService.getReportV2(vehicle.id, dates.start_date.valueOf().toString(),
                    dates.end_date.valueOf().toString()));
            })
            return Promise.all(promises)
                .then((results) => {
                    if (results.length > 0) {
                        return results;
                    }
                })
                .catch((error) => {
                    //maquina não localizada ou não conseguiu achar nenhum dispositivo associado a essa máquina
                    if (error.response && error.response.status === 404) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: i18n.t('ReportsMixin.Apontamentos não localizados'),
                            detail:  i18n.t('ReportsMixin.Não foi possível localizar as estatísticas no período informado'),
                            life: 5000
                        });
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary:  i18n.t('ReportsMixin.Operação falhou'),
                            detail:  i18n.t('ReportsMixin.Não foi possível realizar a operação'),
                            life: 5000
                        });
                    }
                    return [];
                }).finally(() => {
                    return [];
                });
        },
        getEventsAnomaliasFromMultipleVehicles(dates, selectedVehicles) {
            let vm = this;
            const promises = [];

            selectedVehicles.forEach(function (vehicle) {
                promises.push(vm.reportsService.getReportV2Anomalias(vehicle.id, dates.start_date.valueOf().toString(),
                    dates.end_date.valueOf().toString()));
            })
            return Promise.all(promises)
                .then((results) => {
                    if (results.length > 0) {
                        return results;
                    }
                })
                .catch((error) => {
                    //maquina não localizada ou não conseguiu achar nenhum dispositivo associado a essa máquina
                    if (error.response && error.response.status === 404) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: i18n.t('ReportsMixin.Apontamentos não localizados'),
                            detail:  i18n.t('ReportsMixin.Não foi possível localizar as estatísticas no período informado'),
                            life: 5000
                        });
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary:  i18n.t('ReportsMixin.Operação falhou'),
                            detail:  i18n.t('ReportsMixin.Não foi possível realizar a operação'),
                            life: 5000
                        });
                    }
                    return [];
                }).finally(() => {
                    return [];
                });
        }
    }
}
