<template>
    <v-chart class="chart" :option="chartOption" :autoresize="true" theme="report-theme" ref="chartInstance"/>
</template>

<script>
import {use, registerTheme} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {PieChart} from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";

import reportTheme from '@/configurations/iotag_echarts_report_theme.json'

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

// registering custom theme
registerTheme("report-theme", reportTheme);

export default {

    components: {
        VChart
    },

    beforeMount() {
        this.setupChart();
    },

    data() {
        return {
            chartOption: null,
        };
    },
    props: {
        chartData: {
            type: Array,
            default() {
                return []
            }
        },
    },
    methods: {
        setupChart() {
            this.chartOption = {
                fontFamily: "'Unica One', sans-serif",
                tooltip: {
                    trigger: "item",
                    formatter: function (args) {
                        return `${args.marker} ${args.name} <b>${args.value}% </b><br>`;
                    }
                },
                legend: {
                    top: "bottom",
                    left: "center",
                },
                series: []
            }
        },
        setupSeries(chartData) {
            this.chartOption.series = [
                {
                    colorBy: 'data',
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: function (d) {
                            if (d.data.value === 0) {
                                return '';
                            }

                            return d.data.value + '%';
                        }
                    },
                    type: "pie",
                    data: chartData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    center: ['50%', '40%']
                }
            ]
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler(val) {
                if (val.length === 0) {
                    if (this.chartOption && this.chartOption.series && this.chartOption.series.length > 0 &&
                        this.chartOption.series[0].data) {
                        this.$refs.chartInstance.chart.clear();
                    }
                } else {
                    this.setupSeries(JSON.parse(JSON.stringify(val)));
                }
            }
        },
    },
};
</script>

<style scoped>
.chart {
    height: 400px;
}
</style>
