import i18n from "../../../i18n";

export const stopReasonsTypes = [
    {
        type: "UNKNOWN",
        description: i18n.t("stopReasonsTypes.Desconhecido")
    },
    {
        type: "OPERATIONAL_STOP",
        description: i18n.t('stopReasonsTypes.Parada Operacional')
    },
    {
        type: "MAINTENANCE_STOP",
        description: i18n.t('stopReasonsTypes.Parada para manutenção')
    },
    {
        type: "TRAVEL",
        description: i18n.t('stopReasonsTypes.Deslocamento')
    },
    {
        type: "FUEL_SUPPLY",
        description: i18n.t('stopReasonsTypes.Abastecimento')
    },
]
