<template>
    <div class="height-info-vehicle">
        <div class="header-info" style="" v-if="item.name != 'Total'">
            <div class="header-info-container">
                <div class="header-info-lastContact">
                    {{ item.lastContact ? $t('Último Contato') + ": " +  moment(item.lastContact * 1000).format('DD/MM/YY HH:mm:ss') : '' }}
                </div>
                <div class="header-info-subTitle">
                    {{ item.subTitle ? item.subTitle : '' }}
                </div>
                <div class="header-info-showFilterShiftProp">
                    {{ showFilterShiftProp === '' ? $t('AppWorkingModesInfoVehicle.Sem filtro por Turno')  : showFilterShiftProp }}
                </div>
            </div>
        </div>
        <div class="working-modes-container" v-if="!thereIsValueInArrayVehicles(item)">
            <div class="box-style-hour-meter" v-if="item.name != 'Total'">
                <div class="hour-meter-style">
                    <div class="hour-meter-value">
                        {{ item.timeOn }} h
                    </div>
                    <h6 class="hour-meter-title">
                        {{ $t('AppWorkingModesInfoVehicle.Tempo Ligado') }}
                    </h6>
                </div>
                <div class="hour-meter-style" style="margin-top: 7.5px;">
                    <div class="hour-meter-value">
                        {{ item.firstHourMeter }} h
                    </div>
                    <h6 class="hour-meter-title">
                        {{ $t('AppWorkingModesInfoVehicle.Horímetro inicial') }}
                    </h6>
                </div>
                <div class="hour-meter-style" style="margin-top: 7.5px;">
                    <div class="hour-meter-value">
                        {{ item.lastHourMeter }} h
                    </div>
                    <h6 class="hour-meter-title">
                        {{ $t('AppWorkingModesInfoVehicle.Horímetro final') }}
                    </h6>
                </div>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #2CA02C 15px;" v-if="item.workingSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Produzindo') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.workingSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(workingModeMoney, item.workingSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.workingConsumption.toFixed(2)) }} L
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.workingConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #FFAA00 15px;" v-if="item.maneuveringSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Manobrando') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.maneuveringSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(maneuveringModeMoney, item.maneuveringSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.maneuveringConsumption.toFixed(2)) }} L
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.maneuveringConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #1F76B4 15px;" v-if="item.travelingSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Deslocando') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.travelingSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(travelingModeMoney, item.travelingSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.travelingConsumption.toFixed(2)) }} L
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.travelingConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #D62728 15px;" v-if="item.idleSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Ocioso') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.idleSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(idleModeMoney, item.idleSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.idleConsumption.toFixed(2)) }} L
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.idleConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #5E3F20 15px;" v-if="item.offSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Desligado') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.offSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(offModeMoney, item.offSeconds) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #FFFF33 15px;" v-if="item.waitingSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Aguardando Dados') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.waitingSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(waitingDataModeMoney, item.waitingSeconds) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" style="border-bottom: solid #b507a7 15px;" v-if="item.maintenanceSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Manutenção') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.maintenanceSeconds) }}
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ showModePrices(maintenanceModeMoney, item.maintenanceSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}Combustível</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.maintenanceConsumption.toFixed(2)) }} L
                </h5>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.maintenanceConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}" v-if="item.dataSeconds != 0">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Dados Monitorados') }}</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <i class="pi pi-clock pi-clock-style" />
                    {{ showFormattedTime(item.dataSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <img src="@/assets/images/costs/fuel.png" class="icon-fuel">
                    {{ transformToLocaleString(item.dataConsumption.toFixed(2)) }} L
                </h5>

                <template v-if="item.name === 'Total'">
                    <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Percentual') }}</h6>
                    <h5 class="f-bold">{{ parseFloat(item.totalPercent.replace(",", ".")) > 100 ? 100.00 + '%' : item.totalPercent + '%' }}</h5>
                </template>
            </div>
            <div :class="{'box-style': item.name != 'Total', 'box-style-total': item.name === 'Total'}">
                <h5 class="f-bold">{{ $t('AppWorkingModesInfoVehicle.Tempo') }} Total</h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo') }}</h6>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ sumOfCosts(item.workingSeconds, item.idleSeconds, item.maneuveringSeconds,
                        item.travelingSeconds, item.offSeconds, item.waitingSeconds, item.maintenanceSeconds) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{ transformToLocaleString((item.dataConsumption * consumptionMoney).toFixed(2)) }}
                </h5>
                <h6 class="m-t-17">{{ $t('AppWorkingModesInfoVehicle.Tempo + Combustível') }}</h6>
                <h5 class="f-bold d-flex">
                    <div class="icon-RS">R$</div>
                    {{
                        timeAndConsumption(
                            sumOfCosts(item.workingSeconds, item.idleSeconds, item.maneuveringSeconds,
                                item.travelingSeconds, item.offSeconds, item.waitingSeconds, item.maintenanceSeconds),
                            transformToLocaleString((item.dataConsumption * consumptionMoney).toFixed(2))
                        )
                    }}
                </h5>
            </div>
        </div>
        <div :class="{'height-graphics': item.name != 'Total', 'height-graphics-total': item.name === 'Total'}" v-if="!thereIsValueInArrayVehicles(item)">
            <div :class="{'graphics-container-pies': item.name != 'Total', 'graphics-container-pies-total': item.name === 'Total'}">
                <div :class="{'graphic-container-style': item.name != 'Total', 'graphic-container-style-total': item.name === 'Total'}">
                    <AppWorkingModesPie :data_to_pie="item.dataToPieTime" :time_or_consumption="$t('AppWorkingModesInfoVehicle.Atividade')"
                        :class="{'graphic-style-pie': item.name != 'Total', 'graphic-style-pie-total': item.name === 'Total'}" />
                </div>
                <div :class="{'graphic-container-style margin-graphic-container-style': item.name != 'Total', 'graphic-container-style-total margin-graphic-container-style-total': item.name === 'Total'}">
                    <AppWorkingModesPie :data_to_pie="item.dataToPieConsumption" :time_or_consumption="$t('AppWorkingModesInfoVehicle.Combustível')"
                        :class="{'graphic-style-pie': item.name != 'Total', 'graphic-style-pie-total': item.name === 'Total'}" />
                </div>
            </div>
            <div class="graphic-line-chart" v-if="item.name != 'Total'">
                <div class="graphic-div-line-chart">
                    <AppWorkingModesLineChart :historical_charts_data="item.dataToLine"
                        class="graphic-style-line-chart" />
                </div>
            </div>
        </div>
        <div class="subtitle-position" v-if="!thereIsValueInArrayVehicles(item)">
            <div class="subtitle-style">
                <div class="subtitle-mode-style" v-if="item.workingSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #2CA02C;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Produzindo') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.maneuveringSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #FFAA00;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Manobrando') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.travelingSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #1F76B4;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Deslocando') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.idleSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #D62728;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Ocioso') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.offSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #5E3F20;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Desligado') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.waitingSeconds != 0">
                    <div class="subtitle-mode-box" style="background-color: #FFFF33;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Aguardando Dados') }}</h5>
                </div>
                <div class="subtitle-mode-style" v-if="item.lostSecods != 0">
                    <div class="subtitle-mode-box" style="background-color: #696969;"></div>
                    <h5 style="position: relative; top: 3px;">{{ $t('AppWorkingModesInfoVehicle.Não Monitorado') }}</h5>
                </div>
            </div>
        </div>
        <div v-if="thereIsValueInArrayVehicles(item)"
            style="height: 94%;">
            <div style="background-color: white; width: 50%; height: 100px; border-radius: 8px; box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
                    text-align: center; justify-content: center; font-size: 20px; font-weight: bold; color: #696969;
                    display: flex; flex-direction: column; margin: auto;">
                {{ $t('AppWorkingModesInfoVehicle.Sem dados') }}
            </div>
        </div>
    </div>
</template>

<script>
import getTimeFromSecondsDurationFormatMixinShowZero from '../../../mixins/DateMixin';
import AppWorkingModesLineChart from './AppWorkingModesLineChart';
import AppWorkingModesPie from './AppWorkingModesPie';
export default {
    mixins: [getTimeFromSecondsDurationFormatMixinShowZero],
    props: {
        item: {
            type: Object,
            default(){
                return {}
            }
        },
        showFilterShiftProp: {
            type: String,
            default: ''
        },
        workingModeMoney: {
            type: Number,
            default: 0
        },
        idleModeMoney: {
            type: Number,
            default: 0
        },
        maneuveringModeMoney: {
            type: Number,
            default: 0
        },
        travelingModeMoney: {
            type: Number,
            default: 0
        },
        offModeMoney: {
            type: Number,
            default: 0
        },
        waitingDataModeMoney: {
            type: Number,
            default: 0
        },
        maintenanceModeMoney: {
            type: Number,
            default: 0
        },
        consumptionMoney: {
            type: Number,
            default: 0
        },
        showFormatHour: {
            type: Boolean,
            default: true
        }
    },
    components: {
        AppWorkingModesLineChart,
        AppWorkingModesPie
    },
    data() {
        return {
            SECONDS_IN_HOUR: 3600,
        }
    },
    methods: {
        thereIsValueInArrayVehicles(item){
            return item.workingSeconds === 0 &&
                item.idleSeconds === 0 &&
                item.maneuveringSeconds === 0 &&
                item.travelingSeconds === 0&&
                item.offSeconds === 0 &&
                item.waitingSeconds === 0 &&
                item.dataSeconds === 0 &&
                item.maintenanceSeconds === 0
        },
        showFormattedTime(val){
            if(this.showFormatHour){
                return this.getTimeFromSecondsDurationFormatMixinShowZero(val)
            }

            return this.showDecimalHours(val)
        },
        showDecimalHours(val){
            return (val / 3600).toFixed(4).replace(".", ",")
        },
        showModePrices(val1, val2){
            let price = (val1 * (val2 / this.SECONDS_IN_HOUR)).toFixed(2);
            return Number(price).toLocaleString('pt-BR');
        },
        transformToLocaleString(val){
            return Number(val).toLocaleString('pt-BR');
        },
        sumOfCosts(itemWorking, itemIdle, itemManeuvering, itemTraveling, itemOffMode, itemWaitingData, itemMaintenance){
            let price = ((this.workingModeMoney * (itemWorking / this.SECONDS_IN_HOUR)) +
                (this.idleModeMoney * (itemIdle / this.SECONDS_IN_HOUR)) +
                (this.maneuveringModeMoney * (itemManeuvering / this.SECONDS_IN_HOUR)) +
                (this.travelingModeMoney * (itemTraveling / this.SECONDS_IN_HOUR)) +
                (this.offModeMoney * (itemOffMode / this.SECONDS_IN_HOUR)) +
                (this.waitingDataModeMoney * (itemWaitingData / this.SECONDS_IN_HOUR)) +
                (this.maintenanceModeMoney * (itemMaintenance / this.SECONDS_IN_HOUR))).toFixed(2);
            return Number(price).toLocaleString('pt-BR');
        },
        timeAndConsumption(time, consumption){
            let val1 = parseFloat(time.replace('.', '').replace(',', '.'));
            let val2 = parseFloat(consumption.replace('.', '').replace(',', '.'));
            return this.transformToLocaleString(val1 + val2)
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    }
}
</script>

<style scoped lang="scss">

.header-info{
    height: 41px;
    width: 100%;
}

.header-info-container{
    font-weight: bold;
    font-size: 17px;
    color: #696969;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

.header-info-lastContact{
    flex: 1;
    text-align: left;
    flex-shrink: 1;
}

.header-info-subTitle{
    flex: 1;
    text-align: center;
    flex-shrink: 1;
}

.header-info-showFilterShiftProp{
    flex: 1;
    text-align: right;
    flex-shrink: 1;
    color: #de9302;
}

.height-info-vehicle{
    height: 700px;
}

.height-graphics{
    height: calc(66% - 61px);
    width: 100%;
    display: flex;
}

.height-graphics-total{
    height: calc(66% - 20px);
    width: 100%;
    display: flex;
}

.icon-RS {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #F3AD26;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
    border: 1px solid #FEE440;
    font-size: 10px;
    position: relative;
    right: 3px;
    margin-right: 2px;
}

.pi-clock-style {
    margin-right: 2px;
    font-size: 16px;
    position: relative;
    right: 1px;
    top: 1px;
}

.icon-fuel {
    height: 16px;
    width: 16px;
    margin-right: 2px;
}

.f-bold {
    font-weight: bold;
}

.m-t-17 {
    margin-top: 17px;
}

.graphic-div-line-chart {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.graphic-style-pie {
    height: 100%;
    width: 100%;
}

.graphic-style-pie-total {
    height: 100%;
    width: 100%;
}

.graphic-style-line-chart {
    height: 100%;
    width: 100%;
}

.working-modes-container {
    height: 34%;
    margin: 0 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    color: #696969;
}

.box-style {
    width: calc((100% / 10) - (90px / 10));
    height: 100%;
    margin: 0 10px 0 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    text-align: left;
    padding: 13px;
}

.box-style-total{
    width: calc((100% / 9) - (80px / 9));
    height: 100%;
    margin: 0 10px 0 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    text-align: left;
    padding: 13px;
}

.box-style-total:last-child {
    margin: 0;
}

.box-style:last-child {
    margin: 0;
}

.box-style-hour-meter {
    width: calc((100% / 10) - (90px / 10));
    height: 100%;
    margin: 0 10px 0 0;
}

.hour-meter-style {
    height: calc((100% / 3) - 5px);
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    text-align: left;
    padding-top: 15px;
}

.hour-meter-value {
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.hour-meter-title {
    float: right;
    font-size: 12px;
    margin-right: 10px;
}

h5 {
    font-size: 15px
}

.subtitle-position {
    display: flex;
    justify-content: center;
    position: relative;
    top: 15px;
    color: #696969;
}

.subtitle-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 5px;
    padding: 0 15px;
}

.subtitle-mode-style {
    margin: 0 8px;
    display: flex;
    align-items: center;
}

.subtitle-mode-box {
    height: 15px;
    width: 27px;
    border-radius: 3px;
    margin-right: 5px;
}

.graphic-container-style {
    width: 100%;
    height: calc(50% - 5px);
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.margin-graphic-container-style{
    margin-top: 10px;
}

.graphic-container-style-total {
    width: calc(50% - 5px);
    height: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.margin-graphic-container-style-total{
    margin-left: 10px;
}

.graphics-container-pies {
    height: 100%;
    width: 25%;
    padding-right: 5px;
}

.graphics-container-pies-total {
    height: 100%;
    width: 100%;
    display: flex;
}

.graphic-line-chart {
    height: 100%;
    width: 75%;
    padding-left: 5px;
}

@media (min-width: 1399px) and (max-width: 1635px) {

    .dashboard-container {
        width: 97%;
    }

    .dashboard-container-height {
        width: 97%;
    }

    h6 {
        font-size: 11px
    }

    h5 {
        font-size: 13px
    }

    h4 {
        font-size: 18px;
    }
}

@media (min-width: 1025px) and (max-width: 1398px) {

    .dashboard-container {
        width: 97%;
        height: auto;
    }

    .dashboard-container-height {
        width: 97%;
        height: auto;
    }

    .working-modes-container {
        height: 450px;
        gap: 0 10px;
    }

    .height-graphics-total{
        height: 380px;
    }

    .height-graphics{
        height: 380px;
    }

    .box-style {
        width: calc((100% / 5) - 8px);
        margin: 0 0 10px 0;
        height: calc(50% - 5px);
    }

    .box-style:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-total {
        width: calc((100% / 5) - 8px);
        margin: 0 0 10px 0;
        height: calc(50% - 5px);
    }

    .box-style-total:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-hour-meter {
        width: calc((100% / 5) - 8px);
        height: calc(50% - 5px);
        margin: 0 0 10px 0;
    }

    .height-info-vehicle{
        height: auto;
    }

    h6 {
        font-size: 11px
    }

    h5 {
        font-size: 13px
    }

    h4 {
        font-size: 18px;
    }
}

@media (max-width: 1024px) {

    .header-info{
        height: auto;
    }

    .header-info-container{
        display: block;
        white-space: wrap;
    }

    .header-info-lastContact{
        text-align: center;
        margin-bottom: 13px;
    }

    .header-info-subTitle{
        text-align: center;
        margin-bottom: 13px;
    }

    .header-info-showFilterShiftProp{
        text-align: center;
        margin-bottom: 13px;
    }

    .working-modes-container {
        margin-bottom: 0;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0 10px;
    }

    .box-style {
        width: calc((100% / 3) - (20px / 3));
        margin: 0 0 10px 0;
        height: 235px;
    }

    .box-style:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-total {
        width: calc((100% / 3) - (20px / 3));
        margin: 0 0 10px 0;
        height: 235px;
    }

    .box-style-total:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-hour-meter {
        width: calc((100% / 3) - (20px / 3));
        height: 235px;
        margin: 0 0 10px 0;
    }

    h5 {
        font-size: 14px
    }

    .dashboard-container {
        width: 95%;
        height: auto;
        padding: 5px 10px 20px 10px;
    }

    .dashboard-container-height {
        width: 95%;
        height: 680px;
        padding: 5px 10px 0 10px;
    }

    .subtitle-position {
        margin: 20px 0 0 0;
    }

    .height-graphics{
        height: 780px;
        display: block;
    }

    .height-graphics-total{
        height: 350px;
    }

    .graphics-container-pies{
        margin: 0 0 10px 0;
        padding: 0;
        width: 100%;
        height: 40%;
        display: flex;
    }

    .graphic-container-style{
        width: calc(50% - 5px);
        height: 100%;
    }

    .graphic-container-style:first-child {
        margin-right: 10px;
    }

    .margin-graphic-container-style{
        margin: 0px;
    }

    .graphic-line-chart{
        height: 60%;
        width: 100%;
        padding: 0;
    }
}

@media (max-width: 500px) {

    .box-style {
        width: calc(50% - 5px);
        margin: 0 0 10px 0;
        height: 220px;
    }

    .box-style:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-total {
        width: calc(50% - 5px);
        margin: 0 0 10px 0;
        height: 220px;
    }

    .box-style-total:last-child {
        margin: 0 0 10px 0;
    }

    .box-style-hour-meter {
        width: calc(50% - 5px);
        height: 220px;
        margin: 0 0 10px 0;
    }

    .height-graphics-total{
        height: 210px;
    }

    .height-graphics{
        height: 525px;
        display: block;
    }

}
</style>
