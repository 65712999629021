<template>
    <div class="row control-bar col-md-12" style="padding-top: 10px; padding-bottom: 10px; width: 100%;">
        <slot name="selectone"></slot>
        <Calendar id="datesArray" v-model="datesArray" ref="datesArray"
                  :showTime="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="getYearRange"
                  :maxDate="max_date ? max_date : new Date()"
                  :disabledDates="disabled_dates"
                  appendTo="tableDiv"
                  :touchUI="show_col_12"
                  @date-select="dateSelected"
                  dateFormat="dd/mm/yy"
                  selectionMode="range"
                  :class="{ 'col-12': show_col_12 }"
                  class="kt-margin-5-desktop auto-center calendar-width"
                  :disabled="disable_all_fields || disable_calendar">
            <template #date="slotProps">
                <div :class="getDayClass(slotProps.date)">{{slotProps.date.day}}</div>
            </template>
            <template #footer>
                <div v-if="display_time" style="display: flex;text-align: center;margin-top: 20px">
                    <div style="flex: 0 0 50%">
                        <div>{{ $t('AppDateTimeControlBar.Hora Inicial') }} </div>
                        <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="start_time"/>
                    </div>
                    <div style="flex: 1">
                        <div>{{ $t('AppDateTimeControlBar.Hora Final') }}</div>
                        <Calendar class="time-calendar" :inline="true" :showWeek="true" :timeOnly="true" v-model="end_time"/>
                    </div>
                </div>
            </template>
        </Calendar>
        <div class="group-filter-report" :class="{ 'pt-4': show_col_12 }">
        <app-button
                type="primary"
                :disabled="disable_all_fields"
                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loading_historical_data, 'filter-historico-width': show_col_12 }"
                class="auto-center"
                @handleClick="validateDataPreRequest">
                <span v-if="historico">{{ $t('AppDateTimeControlBar.Histórico') }}</span>
                <span v-else>{{btn_text}}</span>
        </app-button>
        <app-button
                v-show="show_btn_back"
                type="danger"
                class="auto-center filter-width"
                style="margin-left: 5px"
                @handleClick="handleBackToRealTime"> {{ $t('AppDateTimeControlBar.Voltar') }}
        </app-button>
        <slot name="selecttwo"></slot>
        <app-button
                type="secondary"
                style="margin-left: 5px"
                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loading_report }"
                class="auto-center"
                v-show="show_report_button"
                @handleClick="requestPDFReport">{{ $t('AppDateTimeControlBar.Relatório') }}
        </app-button>
    </div>
    </div>
</template>

<script>

    import AppDatePicker from 'vuejs-datepicker';
    import AppButton from "./AppButton";
    import moment from 'moment';
    import Calendar from 'primevue/calendar';
    import getYearRange from "../../mixins/DateMixin";
    import i18n from "../../i18n";

    export default {
        mixins: [getYearRange,],
        data() {
            return {
                theme: process.env.VUE_APP_THEME_ACTIVE,
                datesArray: [this.start_date_open_date, this.end_date_open_date],
                position: 'center',
                state: {
                    highlighted: {
                        dates: []
                    },
                    includeDisabled: true,
                    disabledDates: {
                        //desabilita as datas depois de hoje
                        from: new Date(),
                    }
                },
                //Apenas nos importamos com as horas
                start_time: new Date(1910, 9, 1, 0, 0, 0, 0),
                end_time: new Date(1910, 9, 1, 23, 59, 59, 0),
                dateToShow: null,
                show_col_12: this.is_col_12
            }
        },
        props: {
            //as datas em que os datepickers vão ser abertos
            start_date_open_date: {
                type: Date,
                default() {
                    return moment().subtract(1, 'days').toDate()
                }
            },
            end_date_open_date: {
                type: Date,
                default() {
                    return moment().subtract(1, 'days').toDate()
                }
            },
            //controla se aparece o loading no botão
            loading_historical_data: {
                type: Boolean,
                default: false
            },
            //controla se mostra o botão de voltar para o tempo real
            show_btn_back: {
                type: Boolean,
                default: false
            },
            highlighted_dates: {
                type: Array
            },
            disabled_dates: {
                type: Array
            },
            btn_text: {
                type: String,
                default: i18n.t('AppDateTimeControlBar.Filtrar')
            },
            disable_all_fields: {
                type: Boolean,
                default: false
            },
            disable_calendar: {
                type: Boolean,
                default: false
            },
            loading_report: {
                type: Boolean,
                default: false
            },
            show_report_button: {
                type: Boolean,
                default: false
            },
            display_time: {
              type: Boolean,
              default: true
            },
            no_work_dates: {
                type: Array
            },
            max_date: {
                type: Date
            },
            is_col_12: {
                type: Boolean,
                default: false
            },
            historico: {
                type: Boolean,
                default: false
            }

        },
        components: {
            AppDatePicker, AppButton, Calendar

        },
        methods: {
            getDayClass(val) {
                let noWork = false;

                if (!this.no_work_dates || this.no_work_dates.length === 0) {
                    return '';
                }

                if (val.otherMonth) {
                    return 'no-work-day'
                }

                this.no_work_dates.forEach(date => {
                    if (date.getDate() !== val.day) {
                        return;
                    }

                    if (date.getMonth() !== val.month) {
                        return;
                    }

                    if (date.getFullYear() !== val.year) {
                        return;
                    }

                    noWork = true;
                })

                if (noWork) {
                    return 'no-work-day'
                }

                return '';
            },
            validateDataPreRequest() {

                if (!this.datesArray[0]) {
                    this.displayToast(this.$t('AppDateTimeControlBar.Selecione a data inicial e final'));
                    return;
                } else if (!this.datesArray[1]) {
                    this.displayToast(this.$t('AppDateTimeControlBar.Selecione a data final'));
                    return;
                }

                //valida se são datas válidas
                if (!moment(this.datesArray[0]).isValid() || !moment(this.datesArray[1]).isValid()) {
                    this.displayToast(this.$t('AppDateTimeControlBar.Datas inválidas'));
                    return;
                }

                //Seta horas e minutos dos calendários de horas
                this.datesArray[0].setHours(this.start_time.getHours());
                this.datesArray[0].setMinutes(this.start_time.getMinutes());

                this.datesArray[1].setHours(this.end_time.getHours());
                this.datesArray[1].setMinutes(this.end_time.getMinutes());
                this.datesArray[1].setSeconds(this.end_time.getSeconds())

                //valida novamente se são datas válidas
                if (!moment(this.datesArray[0]).isValid() || !moment(this.datesArray[1]).isValid()) {
                    this.displayToast(this.$t('AppDateTimeControlBar.Datas inválidas'));
                    return;
                }

                //Valida se a data final é depois da data inicial
                if (moment(this.datesArray[1]).isBefore( this.datesArray[0])) {
                    this.displayToast(this.$t('AppDateTimeControlBar.Data inicial deve ser antes da data final'));
                    return;
                }

                this.$emit('handleFilter', {start_date:  this.datesArray[0], end_date: this.datesArray[1]})
            },
            displayToast(message) {
                this.$toast.add({
                    severity: 'error',
                    summary: message,
                    life: 5000
                });
            },
            requestPDFReport() {
                //Seta horas e minutos dos calendários de horas
                this.datesArray[0].setHours(this.start_time.getHours());
                this.datesArray[0].setMinutes(this.start_time.getMinutes());

                this.datesArray[1].setHours(this.end_time.getHours());
                this.datesArray[1].setMinutes(this.end_time.getMinutes());
                this.$emit('requestPDFReport', {start_date: this.datesArray[0], end_date: this.datesArray[1]})
            },
            handleBackToRealTime() {
                this.$emit('handleBackToRealTime');
            },
            handleClick() {
                this.$emit('handleClick');
            },
            dateSelected() {
                //https://github.com/primefaces/primevue/blob/2.x/src/components/calendar/Calendar.vue
                //Alterando propriedade direto da implementação
                if (this.datesArray[1] && this.show_col_12) {
                    this.$refs.datesArray.overlayVisible = false;
                }
                this.$emit('dateSelected');
            },
        },
        watch: {
            highlighted_dates: function (val) {
                this.state.highlighted.dates = val;
            },
            start_date_open_date: function (val) {
                while (this.datesArray.length > 0) {
                    this.datesArray.pop();
                }
                this.datesArray.push(val);
            },
            end_date_open_date: function (val) {
                this.datesArray.push(val);
            }
        },
        computed: {
            getYearRange() {
                return '2018:' + new Date().getFullYear();
            }
        }
    }
</script>

<style scoped lang="scss">

    @import "src/assets/styles/primevue";


    .auto-center {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .time-calendar {
        margin-bottom: 10px;
        outline: 5px solid white;
        outline-offset: -2px;
    }

    /*.control-bar {*/
    /*    margin: 5px;*/
    /*    background-color: #fff;*/
    /*    height: 69px !important;*/
    /*}*/


    .no-work-day {
        text-decoration: line-through;
    }

    .calendar-margin-left{
        margin-left: 9px !important;
    }

    @media (min-width: 1025px){
        .group-filter-report{
            margin: 11.4px 0px 0px 5px;
        }
    }

    .filter-margin-right{
        margin-right: 5px;
    }

    .filter-historico-width{
        width: 85px;
    }
</style>
