<template>
    <div class="dashboard">
        <div class="row no-gutters">
            <div class="col-12">
                <app-water-withdrawal-resume :resume="resume"></app-water-withdrawal-resume>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 col-md-6">
                <app-water-withdrawal-month-card :info="byMonth"></app-water-withdrawal-month-card>
            </div>
            <div class="col-12 col-md-6">
                <app-water-withdrawal-type-card :typeProcess="byProcess"
                                                :typeState="byState"></app-water-withdrawal-type-card>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 col-sm-6 col-md-3">
                <app-water-withdrawal-pie-card :title="$t('AppWaterWithdrawalReport.processo')" :info="byProcess"></app-water-withdrawal-pie-card>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <app-water-withdrawal-pie-card :title="$t('AppWaterWithdrawalReport.estado')" :info="byState"></app-water-withdrawal-pie-card>
            </div>
            <div class="col-12 col-md-6">
                <app-water-withdrawal-type-month-card :processes="byProcess"
                                                      :info="byMonth"></app-water-withdrawal-type-month-card>
            </div>
        </div>
    </div>
</template>

<script>
import AppWaterWithdrawalResume from "@/components/views/water_withdrawal/AppWaterWithdrawalResume";
import AppWaterWithdrawalMonthCard from "@/components/views/water_withdrawal/AppWaterWithdrawalMonthCard";
import AppWaterWithdrawalTypeCard from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeCard";
import AppWaterWithdrawalTypeMonthCard from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeMonthCard";
import AppWaterWithdrawalPieCard from "@/components/views/water_withdrawal/AppWaterWithdrawalPieCard";

export default {
    components: {
        AppWaterWithdrawalResume,
        AppWaterWithdrawalMonthCard,
        AppWaterWithdrawalTypeCard,
        AppWaterWithdrawalTypeMonthCard,
        AppWaterWithdrawalPieCard,
    },
    props: {
        resume: {
            type: Object
        },
        byProcess: {
            type: Array
        },
        byState: {
            type: Array
        },
        byMonth: {
            type: Array
        }
    }
}
</script>

<style scoped>
.dashboard {
    margin: 0.6rem;
}

@media only screen and (max-width: 800px) {
    .dashboard {
        flex-direction: column;
        display: flex;
    }

    .row {
        flex-direction: column;
        display: flex;
        align-items: center;
    }
}

</style>
