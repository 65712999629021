<template>
    <input class="form-control" :type="type" :placeholder="placeholder" v-model="content" @input="handleInput"
           autocomplete="new-password" >
</template>

<script>
    export default {
        props: {
            value: String,
            placeholder: String,
            type: {
                type: String,
                required: true,
                default: 'number'
            },
        },
        data() {
            return {
                content: this.value
            }
        },
        methods: {
            handleInput(e) {
                this.$emit('input', this.content)
            }
        }
    }
</script>

<style scoped>

</style>
