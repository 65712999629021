<template>
    <div>
        <DataTable v-if="report_clustered.length > 0" style="overflow: auto;max-height: calc(50vh - 73px)"
                   :value="report_clustered" :expandedRows.sync="expandedRows"
                   :loading="report_clustered_loading" class="p-datatable-striped">
            <template #header>
                <div class="datatable-header">
                     {{ $t('AppGoogleMapsWeatherLegend.Precipitação') }}
                </div>
                <div class="datatable-subtitle">
                    de {{dateFormatWithoutYear(firstEventDate)}}
                    <div v-if="dateFormatWithoutYear(firstEventDate) != dateFormatWithoutYear(lastEventDate)" style="display: inline">
                        a {{dateFormatWithoutYear(lastEventDate)}}
                    </div>
                    <Button icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text info-button"
                            v-tooltip="tooltipText(daysWithData)" @click="openPosition('topright')"/>
                    <Dialog :style="{width: setDialogWidth}" :header="$t('AppGoogleMapsWeatherLegend.Dias com apontamentos') + ':'" :visible.sync="displayPosition" :position="position" :modal="true">
                        <p class="p-m-0">{{daysWithData}}</p>
                        <template #footer>
                            <app-button
                                    type="primary"
                                    class="auto-center"
                                    @handleClick="closePosition">{{ $t('AppGoogleMapsWeatherLegend.Ok') }}
                            </app-button>
                        </template>
                    </Dialog>
                </div>
            </template>
            <Column>
                <template #header>
                    <Dropdown v-model="selectedOption" :options="dropDownOptions" optionLabel="name"
                              :placeholder="$t('AppGoogleMapsWeatherLegend.Selecione')" style="min-width: 130px"/>
                </template>
                <template #body="slotProps">
                    {{showOption(slotProps.data)}}
                </template>
            </Column>
            <Column field="total_time" :header="$t('AppGoogleMapsWeatherLegend.Duração')" :sortable="true">
                <template #body="slotProps">
                    {{getTimeFromSecondsDurationFormatMixin(slotProps.data.total_time)}}
                </template>
                >
            </Column>
            <template #footer>
                <div>Total: {{getTimeFromSecondsDurationFormatMixin(totalTime)}}</div>
            </template>
        </DataTable>
        <div v-else class="show-no-data">
            {{ $t('AppGoogleMapsWeatherLegend.Sem dados no período') }}
        </div>
    </div>
</template>

<script>
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import dateFormatWithoutYear from "@/mixins/DateMixin";

import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import AppButton from "../../common/AppButton";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';

export default {
    mixins: [getTimeFromSecondsDurationFormatMixin, dateFormatWithoutYear],
    data() {
        return {
            expandedRows: [],
            displayPosition: false,
            position: 'center',
            dropDownOptions: [
                {name: this.$t('AppGoogleMapsWeatherLegend.Código'), code: 'code'},
                {name: this.$t('AppGoogleMapsWeatherLegend.Descrição'), code: 'description'},
            ],
            selectedOption: {name: this.$t('AppGoogleMapsWeatherLegend.Código'), code: 'code'},
        }
    },
    props: {
        report_clustered: {
            type: Array,
            required: true
        },
        report_clustered_loading: {
            type: Boolean,
        },
        report: {
            type: Array,
            required: true
        },
    },
    components: {DataTable, Column, Button, Tooltip, Dialog, AppButton, Dropdown},
    computed: {
        totalTime: function() {
            let sumOfClusteredTimes = 0;
            this.report_clustered.forEach(function (data) {
                sumOfClusteredTimes += data.total_time;
            });
            return sumOfClusteredTimes;
        },
        firstEventDate: function() {
            if (this.report && this.report.length !== 0) {
                return this.report[0].event_end;
            }
            return "";
        },
        lastEventDate: function() {
            if (this.report && this.report.length !== 0) {
                return this.report[this.report.length - 1].event_start;
            }
            return "";
        },
        daysWithData: function () {
            let text = '';

            let dates = new Set();

            this.report.forEach((data) => {
                dates.add(this.dateFormatWithoutYear(data.event_start));
                dates.add(this.dateFormatWithoutYear(data.event_end));
            });

            for (let value of dates.values()) {
                text += value + ', ';
            }
            return text.substring(0, text.length - 2);
        },
        setDialogWidth() {
            const MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN = 600;
            var width = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

            if (width > MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN) {
                return '22vw';
            }
            return '95vw';
        },
    },
    directives: {
        'tooltip': Tooltip
    },
    methods: {
        openPosition(position) {
            this.position = position;
            this.displayPosition = true;
        },
        closePosition() {
            this.displayPosition = false;
        },
        tooltipText(daysWithData) {
            const MAX_NUMBER_OF_CHARACTERS_IN_TOOLTIP = 40;
            if (daysWithData && daysWithData.length >  MAX_NUMBER_OF_CHARACTERS_IN_TOOLTIP) {
                return daysWithData.substring(0, MAX_NUMBER_OF_CHARACTERS_IN_TOOLTIP) + '...';
            }
            return daysWithData;
        },
        showOption(data) {
            return this.selectedOption.code === 'code' ? data.reason_code : data.reason_description;
        },
    },
}
</script>

<style scoped>

.datatable-header {
    text-align: center;
    font-size: 22px;
    font-weight: 900;
}

.datatable-subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 10;
}

.show-no-data {
    background-color: #323234;
    height: 100%;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-button {
    border-radius: 15px !important;
    max-width: 20px !important;
    min-width: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
}


</style>
