<template>
    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
        <table class="table table-hover">
            <thead class="text-center">
            <tr>
                <th scope="col"></th>
                <th scope="col" class="mouse-hover" v-for="(key,index) in columns" @click="sortBy(key)" :class="{ active: sortKey === key }"> {{ columnsname[index] }}
                    <i class="glyphicons " :class="sortOrders[key] > 0 ? 'glyphicons-chevron-up' : 'glyphicons-chevron-down'"/>
                </th>
                <th scope="col" v-if="show_details || show_edit || show_remove"></th>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr v-for="entry in filteredData">
                <td><span v-on:click="handleShowDetails(entry)" :class="entry.status"></span></td>
                <td class="kt-datatable__cell" v-for="key in columns"> {{entry[key]}}</td>
                <td>
                    <span v-if="show_reports" style="overflow: visible; position: relative; width: 110px;">
                        <a v-on:click="handleAdd(entry)" title="add" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i color="red" class="glyphicons glyphicons-plus x1-5" style="color:green"/></a>
                        <a v-on:click="handleEdit(entry)" title="edit" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-pencil x1-5" style="color:blue"/></a>
                        <a v-on:click="handleShowDetails(entry)" title="details" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-search x1-5" style="color:Gold"/></a>
                        <a v-on:click="handleRemove(entry)" :disabled="is_removed(entry)" title="remove" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-bin x1-5" style="color:red"/></a>
                    </span>
                    <span v-else style="overflow: visible; position: relative; width: 110px;">
                        <a v-on:click="handleDownload(entry)" title="download" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-cloud-download x1-5" style="color:green"/></a>
                        <a v-on:click="handleMail(entry)" title="mail" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-message-in x1-5" style="color:blue"/></a>
                        <a v-on:click="handleSend(entry)" title="send" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-earphone x1-5" style="color:Gold"/></a>
                        <a v-on:click="handleRemove(entry)" :disabled="is_removed(entry)" title="remove" class="btn btn-sm btn-clean btn-icon btn-icon-md"><i class="glyphicons glyphicons-bin x1-5" style="color:red"/></a>
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
        <app-pagination :total-pages="total_pages" :total="filteredData.length" :per-page="2" :current-page="currentPage" @pagechanged="onPageChange"/>
    </div>
</template>

<script>
    import AppPagination from "./AppPagination";
    import AppButton from "./AppButton";

    export default {
        props: {
            data: {
                type: Array,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            columnsname: {
                type: Array,
                required: true
            },
            filterKey: String,

            show_edit: {
                type: Boolean,
                default: false
            },
            show_details: {
                type: Boolean,
                default: false
            },
            show_remove: {
                type: Boolean,
                default: false
            },
            show_reports: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            let sortOrders = {};
            this.columns.forEach(function (key) {
                sortOrders[key] = 1
            });
            return {
                filteredData: [],
                currentPage: 1,
                sortKey: '',
                sortOrders: sortOrders,
                records_per_page: 10,
            }
        },
        components: {
            AppPagination,
            AppButton
        },
        watch: {
            currentPage: function () {
                let inicio;
                if (this.currentPage === 1) {
                    inicio = 0;
                } else {
                    inicio = (this.currentPage * this.records_per_page) - this.records_per_page;
                }
                let fim = inicio + this.records_per_page;
                this.filteredData = this.data.slice(inicio, fim);
            },
            filterKey: function () {
                let filterKey = this.filterKey && this.filterKey.toLowerCase();
                let data = this.data;
                if (filterKey) {
                    data = data.filter(function (row) {
                        return Object.keys(row).some(function (key) {
                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                        })
                    })
                }
                if (!filterKey) {
                    data = this.data.slice(0, this.records_per_page);
                    this.currentPage = 1;
                }
                this.filteredData = data;
            },
            data: function () {
                this.filteredData = this.data.slice(0, this.records_per_page);
            }


        },
        computed: {
            total_pages: function () {
                return Math.ceil(this.data.length / this.records_per_page);
            },
        },
        filters: {
            capitalize: function (str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }
        },
        methods: {
            is_removed: function (val) {
                return val && val.removed_date && val.removed_date !== "Ativo";
            },
            handleShowDetails(entry) {
                if (entry.identification) {
                    entry.identification = this.removeSpecialCharacters(entry.identification)
                } else if (entry.mac_address) {
                    entry.mac_address = this.removeSpecialCharacters(entry.mac_address)
                }
                this.$emit('handleShowDetails', entry)
            },
            handleAdd(entry) {

            },
            handleDownload(entry) {

            },
            handleMail(entry) {

            },
            handleSend(entry) {

            },
            handleEdit(entry) {
                if (entry.identification) {
                    entry.identification = this.removeSpecialCharacters(entry.identification)
                } else if (entry.mac_address) {
                    entry.mac_address = this.removeSpecialCharacters(entry.mac_address)
                }
                this.$emit('handleEdit', entry)
            },
            handleRemove(entry) {
                if (entry.identification) {
                    entry.identification = this.removeSpecialCharacters(entry.identification)
                } else if (entry.mac_address) {
                    entry.mac_address = this.removeSpecialCharacters(entry.mac_address)
                }
                this.$emit('handleRemove', entry)
            },
            removeSpecialCharacters(val) {
                return val.replace(/[\W_]+/g, '')
            },
            onPageChange(page) {
                this.currentPage = page;
            },
            //TODO atualmente esta ordenando so o que está na tela, implementar para ordenar o array inteiro
            sortBy: function (key) {
                console.log("Desabilitado temporiariamente, ver TODO")
                // this.sortOrders[key] = this.sortOrders[key] * -1;
                // let order = this.sortOrders[key] || 1;
                // let data = this.data;
                // if (key) {
                //     data = data.slice().sort(function (a, b) {
                //         a = a[key];
                //         b = b[key];
                //         return (a === b ? 0 : a > b ? 1 : -1) * order
                //     })
                // }
                // this.filteredData = data.splice(0, this.records_per_page);
            },
        }
    }
</script>

<style scoped>
    thead {
        color: #6c7293;
    }

    td {
        color: #595d6e;
    }

    .espaco {
        margin: 5px;
    }

    .mouse-hover:hover {
        cursor: pointer;
    }


</style>
