import i18n from "@/i18n";

export const inputTypes = () => [
    {
        title: i18n.t("ChecklistInputTypes.Múltipla escolha"),
        type: "CHECKBOX_EXCLUSIVE",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": '',
            "dependent_items": []
        },{
            "sorting_order": '2',
            "option_text": '',
            "dependent_items": []
        }]
    },
    {
        title: i18n.t("ChecklistInputTypes.Pergunta aberta"),
        type: "TEXT",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": null,
        }]
    },
    {
        title: i18n.t("ChecklistInputTypes.Pergunta numérica"),
        type: "NUMBER_TYPING",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": null,
        }, {
            "sorting_order": '2',
            "option_text": null,
        }]
    },
]
