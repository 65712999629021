import axios from '../axios/axios-auth'

export default class TasksService {

    /**
     * V3
     **/
    saveTasks(fieldId, postObj) {
        return axios.post(`/api/v3/fields/${fieldId}/tasks`, postObj).then(response => response.data);
    }

    getTasks(fieldId) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks`).then(response => response.data);
    }

}
