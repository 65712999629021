<template>
    <div>
        <div @click="toggleDropdown" :class="{ 'cascade-select-none': !isVehicle || show_historical_data, 'cascade-select': showCursorPointer()}">
            <span v-if="selected_vehicles.length === 0" class="title-cascade">
                {{ $t('AppCascadeMultiSelect.Veículos') }}
            </span>
            <span v-if="selected_vehicles.length > 1" class="title-cascade">
                {{ $t('AppCascadeMultiSelect.Veículos selecionados') }} : {{ selected_vehicles.length }}
            </span>
            <span v-if="selected_vehicles.length === 1">
                <div v-for="selected in selected_vehicles.slice(0, 1)" :class="{'chip-multiselect': !show_historical_data, 'chip-multiselect-desable': show_historical_data}">
                    <span style="margin-right: 5px">

                        {{ selected.display_id.slice(0, 20) }}{{ selected.display_id.length > 20 ? '...' : '' }}
                    </span>
                    <i class="pi pi-times-circle chip-icon" :class="{'cursor-pointer': showCursorPointer()}" style="font-size: 14px" @click.stop="chipClickedVehicle(selected)"></i>
                </div>
            </span>
            <i class="pi pi-chevron-down icon-dropdown" :class="{'cursor-pointer': showCursorPointer()}"></i>
            <span v-if="selected_vehicles.length > 1" class="icon-close" :class="{'cursor-pointer': showCursorPointer()}"
                @click="cleanSelect()">
                <i class="pi pi-times" style="margin: 11px 0px 0px 5px;"/>
            </span>
        </div>

        <div class="cascade-dropdown" v-show="isDropdownOpen">
            <div v-if="selected_organizations.length > 0" class="menu shadow-lg">
                <span class="p-input-icon-right" style="margin: 0px 0px 7px 13px;">
                    <i class="pi pi-search" />
                    <InputText id="inputtext-right" type="text" :placeholder="$t('AppCascadeMultiSelect.Veículos selecionados')" @click="closeSubMenu()" v-model="textFilter" />
                </span>
                <span class="icon-close-all" :class="{'cursor-pointer': showCursorPointer()}" @click="cleanSelect()">
                    <i class="pi pi-times" style="margin: 7px 0px 0px 10px; font-size: 15px;"/>
                </span>
                <div style="max-height: 330px; overflow: auto;" @scroll="closeSubMenu">
                    <div v-for="vehicle in newJson" style="height: 33px; background-color: white; border-radius: 3px;">
                        <div @click="toggleSubmenu(vehicle.vehicle_type)" :id="vehicle.vehicle_type" style="height: 100%; width: 100%;"
                            :class="{'vehicleType-selected': isVehicleSelected(vehicle), 'vehicle-type': !isVehicleSelected(vehicle), 'cursor-pointer': showCursorPointer()}">
                            <span class="menu-text">{{ vehicle.vehicle_type }}</span>
                            <i class="pi pi-chevron-right menu-icon"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sub-menu shadow-lg" :style="{bottom: bottomOption}" v-show="isSubmenuOpen">
                <div v-for="vehicleSub in vehicleSelect" style="padding: 7px 0px 7px 0px;">
                    <div style="max-height: 330px; overflow: auto;">
                        <div @click="selectAll(vehicleSub.vehicle_type)" :class="{'vehicle-selected' : isSelectAll(vehicleSub.vehicle_type), 'option-type': !isSelectAll(vehicleSub.vehicle_type),
                            'cursor-pointer': showCursorPointer()}" style="height: 33px;">
                            <Checkbox v-model="vehicleSub.selectAll" :binary="true" style="margin: 6px 5px 0px 10px;" />
                        </div>
                        <div v-for="vehicleOption in vehicleSub.vehicle" style="height: 33px;">
                            <div @click="handleCheckbox(vehicleOption)" style="height: 100%; width: 100%;"
                                    :class="{'vehicle-selected' : vehicleOption.selected, 'option-type': !vehicleOption.selected, 'cursor-pointer': showCursorPointer()}">
                                <Checkbox v-if="!vehicleOption.selected && !show_panel" v-model="vehicleOption.selected" :disabled="desabledCheckbox()" :binary="true" style="margin: 6px 5px 0px 10px;" />
                                <Checkbox v-if="vehicleOption.selected || show_panel" v-model="vehicleOption.selected" :binary="true" style="margin: 6px 5px 0px 10px;" />
                                <span style="font-size: 13px;" v-if="!vehicleOption.selected && !show_panel" :class="{'disable-option': selected_vehicles.length >= MAXIMUM_VEHICLES}">
                                    {{ vehicleOption.display_id }}
                                </span>
                                <span style="font-size: 13px;" v-if="vehicleOption.selected || show_panel">
                                    {{ vehicleOption.display_id }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';

export default {
    mounted() {
        window.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.handleClickOutside);
    },
    data() {
        return {
            vehicleSelecionado: null,
            isDropdownOpen: false,
            isSubmenuOpen: false,
            isVehicle: false,
            bottomOption: null,
            newJson: [],
            textFilter: null,
            vehicleSelect: [],
            MAXIMUM_VEHICLES: 300
        }
    },
    props: {
        vehicles: {
            type: Array,
        },
        selected_vehicles: {
            type: Array,
        },
        selected_organizations: {
            type: Array,
        },
        show_panel: {
            type: Boolean,
            default: false
        },
        show_historical_data: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        showCursorPointer(){
            if(this.isVehicle && !this.show_historical_data){
                return true
            }
            return false
        },
        desabledCheckbox(){
            if(this.selected_vehicles.length >= this.MAXIMUM_VEHICLES){
                return true
            }
            return false
        },
        cleanSelect(){
            if(this.show_historical_data){
                return
            }

            this.newJson.forEach(element => {
                element.vehicles.forEach(element => {
                    element.selected = false
                })
            })
            this.textFilter = ""
            this.$emit('cleanSelectedVehicles')
        },
        isVehicleSelected(val){
            return val.vehicles.some(element => element.selected === true)
        },
        isSelectAll(val){
            let existFalse = true
            this.newJson.forEach(element => {
                if(element.vehicle_type === val){
                    element.vehicles.forEach(element => {
                        if(element.selected === false){
                            existFalse = false;
                        }
                    })
                }
            })
            return existFalse
        },
        chipClickedVehicle(val) {
            if(this.show_historical_data){
                return
            }

            const index = this.selected_vehicles.findIndex(element => val.id === element.id)
            val.selected = false
            this.selected_vehicles.splice(index, 1)
            this.close()
        },
        selectAll(val){
            let existFalse = true
            let filteredElements = this.newJson.filter(element => element.vehicle_type === val);

            filteredElements.forEach(element => {
                if (element.vehicles.some(vehicle => vehicle.selected === false)) {
                    existFalse = false;
                }
            });

            if (existFalse) {
                this.deselectAllVehicles(val);
                return;
            }

            if(this.selected_vehicles.length >= this.MAXIMUM_VEHICLES && !this.show_panel && !existFalse){
                this.selectLimitVehicles(val);
                return;
            }

            this.selectAllVehicles(val);
        },
        deselectAllVehicles(val){
            this.newJson.forEach(element => {
                if (element.vehicle_type === val) {
                    element.vehicles.forEach(item => {
                        item.selected = false;
                        const index = this.selected_vehicles.findIndex(element => item.id === element.id);
                        this.selected_vehicles.splice(index, 1);
                    });
                }
            });
            this.updateVehicleSelect()
        },
        selectLimitVehicles(val){
            let existFalse10 = true;
            this.newJson.forEach(element => {
                if (element.vehicle_type === val) {
                    element.vehicles.forEach(item => {
                        const index = this.selected_vehicles.findIndex(element => item.id === element.id);
                        if(index != -1){
                            this.selected_vehicles.splice(index, 1);
                            item.selected = false;
                            existFalse10 = false;
                        }
                    });
                }
            });

            if(existFalse10){
                this.showMaxVehicleToast()
                return;
            }
            this.updateVehicleSelect()
        },
        selectAllVehicles(val){
            this.newJson.forEach(element => {
                if (element.vehicle_type === val) {
                    element.vehicles.forEach(item => {
                        if (this.selected_vehicles.length < this.MAXIMUM_VEHICLES || this.show_panel) {
                            const index = this.selected_vehicles.findIndex(element => item.id === element.id);
                            if(index === -1){
                                item.selected = true;
                                this.selected_vehicles.push(item);
                            }
                        }
                    });
                }
            });
            this.updateVehicleSelect()
        },
        handleClickOutside(){
            const componentElement = this.$el;
            if (!componentElement.contains(event.target)) {
                this.close();
            }
        },
        typeUndefined(val){
            if(val === undefined){
                val = 'Indefinido'
            }
            return val
        },
        changeJson(val) {
            const grouped = {};
            for (const item of val) {
                if (!grouped[item.type]) {
                    grouped[item.type] = {
                        vehicle_type: this.typeUndefined(item.type),
                        vehicles: [],
                    };
                }
                grouped[item.type].vehicles.push({
                    id: item.id,
                    identification_number: item.identification_number,
                    organization_id: item.organization_id,
                    identification: item.identification,
                    display_id: item.display_id,
                    mac_address: item.mac_address,
                    selected: this.isSelected(item.id),
                    last_modified_date: item.last_modified_date,
                    last_contact: item.last_contact,
                    organization: item.organization,
                    state: item.state,
                    city: item.city,
                    manufacturer: item.manufacturer,
                    model: item.model,
                    hour_meter: item.hour_meter,
                    type: item.type,
                    device_id: item.device_id,
                    latitude: item.latitude,
                    longitude: item.longitude
                });
            }
            this.newJson = Object.values(grouped).sort((a, b) => a.vehicle_type.localeCompare(b.vehicle_type));
        },
        isSelected(val) {
            const index = this.selected_vehicles.findIndex(element => val === element.id);
            return index === -1 ? false : true;
        },
        toggleDropdown() {
            if(this.show_historical_data){
                return;
            }

            this.isDropdownOpen = !this.isDropdownOpen;
            this.isSubmenuOpen = false;
            this.vehicleSelecionado = null;
            this.newJson.forEach(element => {
                element.vehicles.forEach(element => {
                    element.selected = this.isSelected(element.id);
                });
            });
        },
        toggleSubmenu(val) {
            this.vehicleSelect = [];

            this.newJson.forEach(element => {
                if (element.vehicle_type === val) {
                    this.vehicleSelect = [{
                        vehicle_type: element.vehicle_type,
                        vehicle: element.vehicles,
                        selectAll: this.isSelectAll(element.vehicle_type)
                    }];
                }
            });

            this.calcBottomValue(val);

            if (this.vehicleSelecionado === val || this.vehicleSelecionado === null) {
                this.isSubmenuOpen = !this.isSubmenuOpen;
                this.vehicleSelecionado = null;
            }
            if (this.isSubmenuOpen) {
                this.vehicleSelecionado = val;
            }
        },
        calcBottomValue(val){
            let elementRect = document.getElementById(val).getBoundingClientRect();
            const basePosition = this.newJson.length >= 10 ? 10 * 33 + 14 : this.newJson.length * 33 + 14;
            let position = basePosition - (elementRect.y - 111.5);
            this.bottomOption = position + 'px';
        },
        handleCheckbox(val) {
            const index = this.selected_vehicles.findIndex(element => val.id === element.id);
            if (index === -1) {
                if(this.selected_vehicles.length >= this.MAXIMUM_VEHICLES && !this.show_panel){
                    this.showMaxVehicleToast();
                    return;
                }
                val.selected = true;
                this.selected_vehicles.push(val);
            } else {
                val.selected = false;
                this.selected_vehicles.splice(index, 1);
            }

            this.updateVehicleSelect()
        },
        updateVehicleSelect() {
            this.vehicleSelect.forEach(element => {
                element.selectAll = this.isSelectAll(element.vehicle_type);
            });
        },
        showMaxVehicleToast() {
            this.$toast.add({
                severity: 'error',
                summary: this.$t('AppCascadeMultiSelect.Máximo de Veículos'),
                detail: this.$t('AppCascadeMultiSelect.Você pode selecionar no máximo 300 veículos'),
                life: 5000
            });
        },
        isEmpty(val){
            if (val === null || val === undefined) {
                return "";
            }
            return val;
        },
        filterCascade(){
            let newGridData = [];

            this.vehicles.forEach(element => {
                let condition1 = false;
                let condition2 = false;
                let condition3 = false;
                let condition4 = false;

                // Identificação
                if(!this.isEmpty(element.display_id).toLocaleLowerCase().includes(this.textFilter.toLocaleLowerCase())){
                    condition1 = true;
                }

                // Chassis
                if(!this.isEmpty(element.identification_number).toLocaleLowerCase().includes(this.textFilter.toLocaleLowerCase())){
                    condition2 = true;
                }

                // Serial
                if(!this.isEmpty(element.mac_address).toLocaleLowerCase().includes(this.textFilter.toLocaleLowerCase())){
                    condition3 = true;
                }

                // Marca Modelo
                let modelo = element.manufacturer + " - " + element.model;
                if(!this.isEmpty(modelo).toLocaleLowerCase().includes(this.textFilter.toLocaleLowerCase())){
                    condition4 = true;
                }

                if(condition1 && condition2 && condition3 && condition4){
                    return;
                }

                newGridData.push(element);
            });
            this.changeJson(newGridData);
        },
        close(){
            this.textFilter = ''
            this.isSubmenuOpen = false;
            this.vehicleSelecionado = null;
            this.isDropdownOpen = false;
        },
        closeSubMenu(){
            this.isSubmenuOpen = false;
            this.vehicleSelecionado = null;
        },
    },
    watch: {
        textFilter: function(val){
            this.filterCascade();
        },
        vehicles: function (val) {
            this.changeJson(val);
            this.closeSubMenu();
        },
        selected_organizations: function (val) {
            this.isVehicle = val.length > 0 ? true : false;
            this.isSubmenuOpen = false;
            this.vehicleSelecionado = null;
            this.isDropdownOpen = false;
        }
    },
    components: {
        InputText, Checkbox
    }
}

</script>

<style scoped>

.cascade-select-none {
    background-color: #C9C9C9;
    width: 250px;
    height: 36px;
    border-radius: 3px;
    border: solid 1px #CED4DA;
    margin-top: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cascade-select {
    background-color: white;
    width: 250px;
    height: 36px;
    border-radius: 3px;
    border: solid 1px #CED4DA;
    margin-top: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.vehicle-type:hover {
    background-color: rgb(235, 235, 235);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.option-type:hover {
    background-color: rgb(235, 235, 235);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chip-multiselect {
    display: inline-block;
    border-radius: 15px 15px 15px 15px;
    margin-right: 4px;
    background-color: #DEE2E6;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 4px;
    left: 10px;
    color: #495057;
}

.chip-multiselect:hover {
    cursor: default;
}

.chip-multiselect-desable {
    display: inline-block;
    border-radius: 15px 15px 15px 15px;
    margin-right: 4px;
    background-color: #B5B8BA;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 4px;
    left: 10px;
    color: #495057;
}

.chip-multiselect-desable:hover {
    cursor: default;
}

.vehicleType-selected{
    background-color: #E3F2FD;
}

.disable-option{
    color: #A3A7AA;
}

.vehicle-selected{
    background-color: #E3F2FD;
}

.cursor-pointer:hover{
    cursor: pointer;
}

.title-cascade{
    font-size: 13px;
    position: relative;
    left: 15px;
    top: 8px;
    color: #616971;
}

.sub-menu{
    list-style: none;
    color: #616971;
    background-color: white;
    position: relative;
    left: 250px;
    border-radius: 3px;
}

.cascade-dropdown{
    list-style: none;
    width: 250px;
    border-radius: 3px;
    position: absolute;
    z-index: 1000;
}

.menu{
    background-color: white;
    border-radius: 3px;
    padding: 7px 0px 7px 0px;
}

.icon-dropdown{
    float: right;
    font-size: 14px;
    color: #6C778E;
    margin: 11px 10px 0px 0px;
}

.icon-close{
    float: right;
    margin: 11px 5px 0px 0px;
    width: 25px;
    height: 32px;
    position: relative;
    bottom: 10px;
}

.icon-close-all{
    margin: 0px 0px 0px 10px;
    display: inline-block;
    width: 34px;
    height: 30px;
    position: relative;
    top: 2px;
}

.menu-text{
    font-size: 13px;
    position: relative;
    left: 15px;
    top: 7px;
    color: #616971;
}

.menu-icon{
    float: right;
    font-size: 8px;
    color: #6C778E;
    margin: 14px 15px 0px 0px;
}
</style>
