<template>
    <v-chart class="chart" :option="chartOption" autoresize theme="report-theme"/>
</template>

<script>
import {registerTheme, use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";

import reportTheme from '@/configurations/iotag_echarts_report_theme.json'

use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

registerTheme("report-theme", reportTheme);

export default {
    components: {
        VChart
    },
    beforeMount() {
        this.setupChart();
    },
    data() {
        return {
            chartOption: null,
            tooltipSuffix: 'm³',
        }
    },
    props: {
        processDisplay: {
            type: Array
        },
        months: {
            type: Array
        }
    },
    watch: {
        processDisplay: {
            deep: true,
            handler() {
                this.setupSeries();
            }
        }
    },
    methods: {
        setupChart() {
            this.chartOption = {
                fontFamily: "'Unica One', sans-serif",
                tooltip: {
                    trigger: 'item',
                    formatter: function (args) {
                        return `${args.name} <br> ${args.marker} ${args.seriesName}: <b>${args.value} m³</b><br>`;
                    }
                },
                legend: {
                    icon: 'circle'
                },
                series: [],
                xAxis: {
                    type: 'category',
                    data: this.months
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} m³'
                    },
                },
            }
        },
        setupSeries() {
            this.chartOption.series = this.processDisplay
        }
    }
}
</script>

<style scoped>
.chart {
    height: 400px;
}
</style>
