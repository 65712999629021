<template>
    <div v-click-outside="closeOptions">
        <div>
            <img
                src="@/assets/images/vehicles/connected.png"
                alt="" @click="showControls = !showControls" class="btn-control">
        </div>
        <div style="padding-top: 45px">
            <transition name="fade">
                <div id="controls" v-show="showControls">
                    <div style="display:flex; align-items:center;">
                        <InputSwitch v-model="connected"/>
                        <img src="@/assets/images/vehicles/connected.png" alt="" @click="connectedChanged"
                             class="mouse-pointer img-marker">
                        <div @click="connectedChanged" class="mouse-pointer">{{ $t('AppVehiclesStatusControl.Conectado') }}</div>
                    </div>
                    <div style="display:flex;align-items:center;">
                        <InputSwitch v-model="desConnected"/>
                        <img src="@/assets/images/vehicles/grey.svg" alt="" @click="desConnectedChanged"
                             class="mouse-pointer img-marker" style="margin: 7px 10px 0px 13px;">
                        <div @click="desConnectedChanged" class="mouse-pointer">{{ $t('AppVehiclesStatusControl.Sem Conexão') }}</div>
                    </div>
                    <div style="display:flex;align-items:center;">
                        <InputSwitch v-model="inactive"/>
                        <img src="@/assets/images/vehicles/grey.svg" alt="" @click="inactiveChanged"
                             class="mouse-pointer img-marker" style="margin: 10px 10px 0px 13px;">
                        <div @click="inactiveChanged" class="mouse-pointer">{{ $t('AppVehiclesStatusControl.Sem Conexão') }} <br>({{ $t('AppVehiclesStatusControl.por mais de 24h') }})</div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

    import InputSwitch from 'primevue/inputswitch';

    import {VEHICLES_STATUSES_LIST} from './AppOptions';

    export default {
        mounted() {
        },

        data() {
            return {
                showControls: false,
                connected: false,
                desConnected: false,
                inactive: false,
            }
        },

        methods: {
            closeOptions() {
                this.showControls = false;
            },
            connectedChanged() {
                this.connected = !this.connected;
            },
            desConnectedChanged() {
                this.desConnected = !this.desConnected;
            },
            inactiveChanged() {
                this.inactive = !this.inactive;
            },
            handleChange() {
                let temp = [];

                if (this.connected) {
                    temp.push(VEHICLES_STATUSES_LIST()[0]);
                }
                if (this.desConnected) {
                    temp.push(VEHICLES_STATUSES_LIST()[1]);
                }
                if (this.inactive) {
                    temp.push(VEHICLES_STATUSES_LIST()[2]);
                }

                this.$emit('statusChanged', temp);
            }
        },
        watch: {
            connected: function (val) {
                this.handleChange();
            },
            desConnected: function (val) {
                this.handleChange();
            },
            inactive: function (val) {
                this.handleChange();
            },
        },
        components: {
            InputSwitch
        }
    }
</script>

<style scoped lang="scss">

    .mouse-pointer {
        cursor: pointer;
    }

    .img-marker {
        margin-right: 5px;
        margin-left: 5px;
    }

    .header {
        font-weight: bold;
        padding-left: 10px;
    }

    .line {
        padding: 5px;
    }

    .item {
        padding: 5px;
        text-align: center;
        cursor: pointer;
        width: 90px;
    }

    .item img {
        border-radius: 6px;
    }

    #controls {
        background: #f2f2f2;
        padding: 5px;
        border-radius: 6px;
        outline: 2px solid #cbcbcb;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .btn-control {
        float: left;
        cursor: pointer;
        background-color: #f2f2f2;
        padding: 5px;
        border-radius: 50px;
        outline: 2px solid #cbcbcb;
        width: 40px;
    }

    .menu-item-on {
        border: 2px solid #0f9af0;
    }


</style>
