import axios from '../axios/axios-auth'

export default class ActivitiesService {

    getActivitiesByOrganization(orgId) {
        return axios.get(`/appConfig/v1/organizations/${orgId}/activities`).then(response => response.data)
    }

    addActivity(orgId, data) {
        return axios.post(`/appConfig/v1/organizations/${orgId}/activities`, data).then(response => response.data)
    }

    deleteActivity(activityId) {
        return axios.delete(`/appConfig/v1/activities/${activityId}`).then(response => response.data)
    }
}
