<template>
    <div>
        <div v-for="item in country" :key="item.id" style="display: inline-block">
            <button @click="doTranslation(item.code)" :title="item.title"
               href="#">
                <span class="language-item">
                    <img :alt="item.alt"
                         :src="item.image"
                         class="flag"/>
                </span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: "TranslateComponent",
    data() {
        return {
            modal: {
                'is-active': false,
            },
            country: [
                {
                    code: "en",
                    title: "English",
                    image: require("@/assets/icons/icon-ingles-colorido.svg")
                },
                {
                    code: "pt",
                    title: "Portuguese",
                    image: require("@/assets/icons/icon-portugues-colorido.svg")
                },
                {
                    code: "es",
                    title: "Spanish",
                    image: require("@/assets/icons/icon-espanhol-colorido.svg")
                }
            ]
        }
    },
    methods: {
        doTranslation(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem(process.env.VUE_APP_I18N_LOCALE, lang);
        }
    }
}
</script>

<style scoped>


a:link {
    text-decoration: none;
    font-size: large;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}


.language-item {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

button {
    overflow: visible;
    padding: 0 !important;
    background-color: transparent;
    border: none;
}

.flag {
    height: 35px;
}

h3 {
    cursor: pointer;
}




</style>
