import i18n from '../../../i18n';// @formatter:off

export const CROPS_LIST = () => [
    {name: i18n.t('AppAgricultureOptions.Soja'), src: require("../../../assets/icons/icone-soy.svg")},
    {name: i18n.t('AppAgricultureOptions.Milho'), src: require("../../../assets/icons/icone-corn.svg")},
    {name: i18n.t('AppAgricultureOptions.Trigo'), src: require("../../../assets/icons/icone-wheat.svg")},
    {name: i18n.t('AppAgricultureOptions.Feijão'), src: require("../../../assets/icons/icone-beans.svg")},
    {name: i18n.t('AppAgricultureOptions.Arroz'), src: require("../../../assets/icons/icone-rice.svg")},
    {name: i18n.t('AppAgricultureOptions.Cevada'), src: require("../../../assets/icons/icone-barley.svg")},
    {name: i18n.t('AppAgricultureOptions.Algodão'), src: require("../../../assets/icons/icone-cottom.svg")},
    {name: i18n.t('AppAgricultureOptions.Mandioca'), src: require("../../../assets/icons/icone-cassava.svg")},
    {name: i18n.t('AppAgricultureOptions.Cana'), src: require("../../../assets/icons/icone-sugarcane.svg")},
    {name: i18n.t('AppAgricultureOptions.Laranja'), src: require("../../../assets/icons/icone-orange.svg")},
    {name: i18n.t('AppAgricultureOptions.Floresta'), src: require("../../../assets/icons/icone-tree.svg")},
    {name: i18n.t('AppAgricultureOptions.Café'), src: require("../../../assets/icons/icone-coffee-beans.svg")},
];

export const TASKS_LIST =  () => [
    {name: i18n.t('AppAgricultureOptions.Plantio'), src: require("../../../assets/icons/icone-seeding.svg")},
    {name: i18n.t('AppAgricultureOptions.Colheita'), src: require("../../../assets/icons/icone-harvest.svg")},
    {name: i18n.t('AppAgricultureOptions.Adubação'), src: require("../../../assets/icons/icone-fertilizing.svg")},
    {name: i18n.t('AppAgricultureOptions.Pulverização'), src: require("../../../assets/icons/icone-fungicide.svg")},
    {name: i18n.t('AppAgricultureOptions.Preparo de Solo'), src: require("../../../assets/icons/icone-soil-preparation.svg")}
];


export const MAP_TYPES = () => Object.freeze({
    UNKNOWN: [{title: i18n.t('AppAgricultureOptions.Aplicação'), value: 'application'}, {title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'},
        {title: i18n.t('AppAgricultureOptions.Sobreposição'), value: 'overlap'}],
    PLANTING: [{title: i18n.t('AppAgricultureOptions.Aplicação'), value: 'application'}, {title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'},
        {title: i18n.t('AppAgricultureOptions.Sobreposição'), value: 'overlap'}],
    PULVERIZATION: [{title: i18n.t('AppAgricultureOptions.Aplicação'), value: 'application'}, {title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'},
        {title: i18n.t('AppAgricultureOptions.Sobreposição'), value: 'overlap'}],
    MULCHING: [{title: i18n.t('AppAgricultureOptions.Aplicação'), value: 'application'}, {title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'},
        {title: i18n.t('AppAgricultureOptions.Sobreposição'), value: 'overlap'}],
    HARVESTING: [{title: 'Rendimento', value: 'yield'}, {title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'},
        {title: i18n.t('AppAgricultureOptions.Umidade'), value: 'umidity'}],
    SOIL_PREPARATION: [{title: i18n.t('AppAgricultureOptions.Velocidade'), value: 'speed'}, {title: i18n.t('AppAgricultureOptions.Sobreposição'), value: 'overlap'}]
});


// @formatter:on
