import axios from '../axios/axios-auth'

export default class CropOptionsService {

    getAllOptions(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/cropOptions`).then(response => response.data)
    }

    saveNewOption(orgId, name) {
        return axios.post(`/api/v3/organizations/${orgId}/cropOptions`, null,{ params: {name: name} }).then(response => response.data)
    }

}
