import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import store from './store/store';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import VueTheMask from 'vue-the-mask';
import PrimeVue from 'primevue/config';

import './assets/fonts/glyphicons/css/glyphicons.css';

import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                 //core css
import 'primeicons/primeicons.css';                           //icons

import "./assets/styles/style.bundle.scss";

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import ToastService from 'primevue/toastservice';

import {BR} from "./configurations/calendar";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

// ...
moment.locale('pt');

Vue.directive("click-outside", {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

Vue.use(PerfectScrollbar);
Vue.use(ToastService);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(PrimeVue, {locale: BR()});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
