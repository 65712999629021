import axios from "../axios/axios-auth";

export default class JohnDeereService {

    requestNewToken(payload) {
        return axios.post('/john-deere-service/v1/authentication/accessToken', payload).then(response => response.data)
    }

    getOrganizations(orgId, params) {
        return axios.get(`/john-deere-service/v1/organizations/${orgId}`, {params: params}).then(response => response.data);
    }

    getConnection(userId) {
        return axios.get(`/john-deere-service/v1/authentication/connection?userId=${userId}`).then(response => response.data);
    }

    setOrgsLink(orgId, data) {
        return axios.post(`/john-deere-service/v1/organizations/${orgId}/linkOrganizations`, data).then(response => response.data);
    }

    unlinkOrgs(orgId) {
        return axios.delete(`/john-deere-service/v1/organizations/${orgId}/linkOrganizations`).then(response => response.data);
    }

    getConnectedOrganization(orgId, params) {
        return axios.get(`/john-deere-service/v1/organizations/${orgId}/getConnectedOrganization`, {params: params}).then(response => response.data);
    }

    endSession(params) {
        return axios.delete(`/john-deere-service/v1/authentication/accessToken`, {params: params}).then(response => response.data);
    }

}
