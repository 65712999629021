import axios from '../axios/axios-auth'

export default class RealtimeService {

    requestData(data) {
        return axios.post('/api/v3/vehiclesRealtime', data).then(response => response.data)
    }

    requestAdditionalData(vehicleId, vehicleDeviceMac) {
        return axios.get('/api/v1/realtime/vehicle/' + vehicleId + '/device/' + vehicleDeviceMac).then(response => response.data)
    }

    requestDataLme(data) {
        return axios.post('/api/v3/realTimeLme', data).then(response => response.data)
    }


}
