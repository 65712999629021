<template>
    <Dialog :header="edit ? $t('AppModalAddUser.Editar usuário') : $t('AppModalAddUser.Adicionar usuário')" :visible.sync="showDialog"
            :style="{width: width_dialog}"
            @hide="handleClose"  :modal="true">
        <form class="kt-form" @submit.prevent>
            <div v-if="!firstStep || edit">
                <span class="permission-modal-title mb-4">{{ title }}</span>
                <div v-for="permission in permissions" class="row slider-alignment" style="height: 100px">
                    <div class="col-lg-3 col-12 permission-info">
                        <i v-tooltip.top="getDescription(permission.description)"
                           class="pi pi-question-circle permission-info"></i> {{ permission.name }}
                    </div>
                    <div class="col-lg-9 col-12">
                        <vue-slider :tooltip="'none'" v-model="permission.status" :data="permissionStatus"
                                    :contained="true" :data-value="'code'" :data-label="'name'" style="height: 25px;">
                            <template v-slot:step="{ active }">
                                <div :class="['user-permission-step', { active }]"></div>
                            </template>
                            <template v-slot:dot>
                                <div :class="['user-permission-dot']"></div>
                            </template>
                        </vue-slider>
                    </div>
                </div>
            </div>
            <div v-else>
                <label>E-mail</label>
                <app-input class="form-control" type="text" placeholder="" v-model="form.email"
                           @keyup.enter.native="handleNext"
                           :class="{'is-invalid' : $v.form.email.$invalid && $v.form.email.$dirty}">
                </app-input>
                <span class="form-text error"
                      v-if="$v.form.email.$invalid && $v.form.email.$dirty">
                      E-mail {{$t('AppModalAddUser.inválido')}}
                </span>
            </div>
        </form>

        <template #footer>
            <div v-if="edit" class="separate-buttons">
                <app-button @handleClick="handleRemoveUser" type="danger">
                    <span class="pi pi-user-minus"></span>
                    {{$t('AppModalAddUser.Remover da organização')}}
                </app-button>
                <div style="min-height: 30px;min-width: 100px" @mouseover="handleMouseInsideImpersonate()" @mouseleave="handleMouseOutsideImpersonate()">
                    <app-button v-show="showImpersonate && mouseInsideImpersonate" @handleClick="handleImpersonate" type="warning">
                        <span class="pi pi-eye"></span>
                        {{$t('AppModalAddUser.Impersonalizar')}}
                    </app-button>
                </div>
                <app-button @handleClick="handleSave" type="primary">
                    <span class="pi pi-check"></span>
                    {{$t('AppModalAddUser.Salvar')}}
                </app-button>
            </div>
            <div v-else-if="firstStep" class="separate-buttons">
                <span style="font-weight: bold">{{$t('AppModalAddUser.Etapa 1 de 2')}}</span>
                <app-button @handleClick="handleNext" type="primary">
                    {{$t('AppModalAddUser.Próxima')}}
                    <span class="pi pi-arrow-right"></span>
                </app-button>
            </div>
            <div v-else class="separate-buttons">
                <span style="font-weight: bold">Etapa 2 de 2</span>
                <div>
                    <app-button @handleClick="handleBack" type="secondary">
                        <span class="pi pi-arrow-left"></span>
                        {{$t('AppModalAddUser.Voltar')}}
                    </app-button>
                    <app-button @handleClick="handleSave" type="primary">
                        <span class="pi pi-check"></span>
                        {{$t('AppModalAddUser.Salvar')}}
                    </app-button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import AppButton from "../../common/AppButton";
import AppInput from "../../common/AppInput";
import {email, required} from "vuelidate/lib/validators";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import {PERMISSIONS, PERMISSIONS_DESCRIPTIONS} from "@/components/views/users/AppOptions";
import Tooltip from "primevue/tooltip";
import {mapGetters} from "vuex";
import UsersService from "@/services/UsersService";
import {router} from "@/router";

export default {
    mounted() {
        this.width_dialog = this.isScreenMobile ? '85vw' : '35vw';
        this.usersService = new UsersService();
    },
    data() {
        return {
            width_dialog: null,
            usersService: null,
            showDialog: false,
            edit: false,
            firstStep: true,
            permissionStatus: PERMISSIONS(),
            permissionDescription: PERMISSIONS_DESCRIPTIONS(),
            permissions: [ {
                key: 'equipment',
                name: this.$t('AppModalAddUser.Equipamento'),
                status: 'NOT_AUTHORIZED',
                description: 'EQUIPMENT',
            }, {
                key: 'locations',
                name: this.$t('AppModalAddUser.Locais'),
                status: 'NOT_AUTHORIZED',
                description: 'LOCATIONS',
            },{
                key: 'management',
                name: this.$t('AppModalAddUser.Gerenciamento'),
                status: 'NOT_AUTHORIZED',
                description: 'MANAGEMENT',
            }],
            form: {
                email: null,
            },
            mouseInsideImpersonate: false
        }
    },
    methods: {
        handleMouseInsideImpersonate() {
            this.mouseInsideImpersonate = true;
        },
        handleMouseOutsideImpersonate() {
            this.mouseInsideImpersonate = false;
        },
        handleImpersonate() {
            this.usersService.impersonate(this.selectedUser.user_id)
                .then((response) => {
                    response.expiration = Math.floor(Date.now() / 1000) + response.expires_in;
                    this.storeUser(response);
                }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppModalAddUser.Não foi poissível impersonaliza'),
                        life: 5000
                    });
                    console.log(error)
                });
        },
        storeUser(data) {
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE, JSON.stringify(data));
            this.$store.dispatch('storeUser', data);

            this.usersService.getOrgFeatureFlags().then((response) => {
                this.$store.dispatch('storeOrganizationFeatureFlags', response);
                router.push({name: 'home'}).then(() => {
                    router.go();
                })
            }).catch((error) => {
                console.log(error)
            })


        },
        getDescription(permission) {
            let result = this.permissionDescription.find(({code}) => code === permission);
            if (result) {
                return result.description;
            }
        },
        handleNext() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.firstStep = false
            }
        },
        handleBack() {
            this.firstStep = true
        },
        handleSave() {
            let user = {}
            if (this.edit) {
                user = {
                    permissions: this.setPermissions(),
                }
            } else {
                user = {
                    email: this.form.email,
                    permissions: this.setPermissions(),
                }
            }
            this.$emit('handleSave', user);
            this.firstStep = true
            this.cleanForm();
        },
        setPermissions() {
            let newPermissions = {
                locations: 'NOT_AUTHORIZED',
                equipment: 'NOT_AUTHORIZED',
                management: 'NOT_AUTHORIZED',
            }
            this.permissions.forEach(permission => {
                if (permission.key === 'locations') {
                    newPermissions.locations = permission.status
                } else if (permission.key === 'equipment') {
                    newPermissions.equipment = permission.status
                } else {
                    newPermissions.management = permission.status
                }
            })
            return newPermissions
        },
        handleClose() {
            this.$emit('handleClose');
            this.firstStep = true
            this.cleanForm();
        },
        handleRemoveUser() {
            this.$emit('handleRemoveUser');
        },
        cleanForm() {
            this.form.email = null;
            this.permissions.forEach(permission => {
                permission.status = 'NOT_AUTHORIZED'
            })
            this.$v.$reset();
        }
    },
    components: {
        Dialog,
        AppButton,
        AppInput,
        VueSlider,
    },
    validations: {
        form: {
            email: {required, email},
        }
    },
    directives: {
        'tooltip': Tooltip
    },
    props: {
        displayDialogAddUser: {
            type: Boolean,
            default: false,
            required: true
        },
        editUserPermissions: {
            type: Boolean,
            required: true
        },
        selectedUserPermissions: {
            type: Object,
        },
        selectedUser: {
            type: Object,
        },
    },
    watch: {
        displayDialogAddUser: function (show) {
            this.showDialog = show;
        },
        editUserPermissions: function (isEditing) {
            this.edit = isEditing;
        },
        selectedUserPermissions: {
            deep: true,
            handler(currentPermissions) {
                if (!currentPermissions) {
                    return;
                }

                this.permissions.forEach(permission => {
                    if (permission.key === 'locations') {
                        permission.status = currentPermissions.locations
                    } else if (permission.key === 'equipment') {
                        permission.status = currentPermissions.equipment
                    } else {
                        permission.status = currentPermissions.management
                    }
                })
            }
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        },
        title() {
            if (this.edit) {
                return this.$t('AppModalAddUser.Editar permissões de ') + this.selectedUser.username
            }
            return this.$t('AppModalAddUser.Conceder permissões')
        },
        showImpersonate() {
            if (this.user.username === 'mariana.maeda@iotag.com.br' ||
                this.user.username === 'eleandro.gaioski@iotag.com.br' ||
                this.user.username === 'cassio.morales@iotag.com.br' ||
                this.user.username === 'marcelo.kaluf@iotag.com.br') {
                return true;
            }
            return false;
        },
        ...mapGetters([
            'user'
        ])
    },

}
</script>

<style lang="scss">

@import "../../../assets/styles/constants";

.separate-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.permission-modal-title {
    display: flex;
    justify-content: center;
    font-size: large;
}

.user-permission-step {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #ccc;
    background-color: #fff;
}

.user-permission-step.active {
    box-shadow: 0 0 0 2px $btn-primary-background-color;
    background-color: $btn-primary-background-color;
}

.vue-slider-rail {
    height: 10px !important;
}

.permission-info {
    font-size: medium;
}
.dialog-width {
    width: 35vw;
}

@media (max-width: 992px) {
    .dialog-width {
        width: 50vw;
    }
}

@media (max-width: 768px) {
    .dialog-width {
        width: 70vw;
    }
}

@media (max-width: 576px) {
    .dialog-width {
        width: 80vw;
    }
}

</style>
