<template>
    <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper"
         @mouseleave="exitLeaveSubMenu()">
        <div id="kt_aside_menu" class="kt-aside-menu  kt-aside-menu--dropdown" data-ktmenu-vertical="1"
             data-ktmenu-dropdown="1" data-ktmenu-scroll="0">
            <perfect-scrollbar>
                <ul class="kt-menu__nav ">
                    <li v-show="hasFeatureEnabled('TRACK')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('track'),inactive: !isCurrentRouteActive('track')}">
                        <router-link :to="{name: 'home'}" class="kt-menu__link kt-menu__toggle submenu__hover">
                            <img src="../../assets/icons/icone-mapa.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Localização') }}</span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('ANALYSIS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('analysis'),inactive: !isCurrentRouteActive('analysis')}">
                        <router-link :to="{name: 'analysis'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-relatorios-1.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Análises') }}</span>
                        </router-link>
                    </li>
                    <li class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        v-show="hasFeatureEnabled('DASHBOARD')"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('dashboard'),inactive: !isCurrentRouteActive('dashboard')}">
                        <router-link :to="{name: 'dashboard'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/bar-chart.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text"> {{ $t('AppAsideMenu.Painel') }}</span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('AGRICULTURE')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        id="agriculture" @mouseover="enterAgricultureMenu()" @click="enterClickAgricultureMenu()"
                        :class="{active: isCurrentRouteActive('agriculture'), inactive: !isCurrentRouteActive('agriculture'), 'bg-color-menu': showAgricultureMenu}">
                        <router-link :to="{name: 'agriculture-tasks'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-agro.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Agro') }}</span>
                            <span class="arrow">
                                <i class="glyphicons  x1" :class="'glyphicons-chevron-right'"></i>
                            </span>
                        </router-link>
                    </li>
                    <ul v-show="showAgricultureMenu" class="kt-menu__nav subMenu" id="subMenuAgro">
                        <li class="kt-menu__item kt-menu__item--submenu bg-color submenu__hover" aria-haspopup="true"
                            style="border-top-right-radius: 6px !important;"
                            :class="{active: isCurrentRouteActive('agriculture/manage-tasks'),
                            inactive: !isCurrentRouteActive('agriculture/manage-tasks')}">
                            <router-link :to="{name: 'agriculture-manage-tasks'}"
                                         class="kt-menu__link kt-menu__toggle submenu__hover_top">
                                <img src="../../assets/icons/icone-agro-settings.svg" alt=""
                                     class="img-fluid icon-size">
                                <span class="menu-link-text ">{{ $t('AppAsideMenu.Gerenciamento') }} </span>
                            </router-link>
                        </li>
                        <li class="kt-menu__item bg-color kt-menu__item--submenu" aria-haspopup="true"
                            style="border-top-right-radius: 0px !important;"
                            :class="{active: isCurrentRouteActive('agriculture/tasks'),
                            inactive: !isCurrentRouteActive('agriculture/tasks')}">
                            <router-link :to="{name: 'agriculture-tasks'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../assets/icons/icone-agro-analise.svg" alt="" class="img-fluid icon-size">
                                <span class="menu-link-text">{{ $t('AppAsideMenu.Análise') }} </span>
                            </router-link>
                        </li>
                        <li class="kt-menu__item bg-color kt-menu__item--submenu" aria-haspopup="true"
                            style="border-bottom-right-radius: 6px !important;" v-show="hasFeatureEnabled('FIELDS')"
                            :class="{active: isCurrentRouteActive('fields'), inactive: !isCurrentRouteActive('fields')}">
                            <router-link :to="{name: 'fields'}"
                                         class="kt-menu__link kt-menu__toggle submenu__hover_bottom">
                                <img src="../../assets/icons/icone-talhoes.svg" alt="" class="img-fluid icon-size">
                                <span class="menu-link-text"> {{ $t('AppAsideMenu.Talhões') }} </span>
                            </router-link>
                        </li>
                    </ul>
                    <li v-show="hasFeatureEnabled('ROADS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('anomalias'),inactive: !isCurrentRouteActive('anomalias')}">
                        <router-link :to="{name: 'anomalias'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-estrada.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text"> {{ $t('AppAsideMenu.Estradas') }}</span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('OPERATORS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('operators'),inactive: !isCurrentRouteActive('operators')}">
                        <router-link :to="{name: 'operators'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-operador.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Motoristas') }} </span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('VEHICLES')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('vehicles'),inactive: !isCurrentRouteActive('vehicles')}">
                        <router-link :to="{name: 'vehicles'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-maquinario.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Veículos') }} </span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('COSTS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @mouseover="enterCostsMenu()" @click="enterClickCostsMenu()" id="costs"
                        :class="{active: isCurrentRouteActive('costs'),inactive: !isCurrentRouteActive('costs'), 'bg-color-menu': showCostsMenu}">
                        <router-link :to="{name: 'costs-analysis'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-costs.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Custos') }}</span>
                            <span class="arrow">
                                <i class="glyphicons  x1" :class="'glyphicons-chevron-right'"></i>
                            </span>
                        </router-link>
                    </li>
                    <ul v-show="showCostsMenu" class="kt-menu__nav subMenu" id="subMenuCosts">
                        <li class="kt-menu__item kt-menu__item--submenu bg-color submenu__hover" aria-haspopup="true"
                            style="border-top-right-radius: 6px !important"
                            :class="{active: isCurrentRouteActive('costs/costs-analysis'),
                            inactive: !isCurrentRouteActive('costs/costs-analysis')}">
                            <router-link :to="{name: 'costs-analysis'}"
                                         class="kt-menu__link kt-menu__toggle submenu__hover_top">
                                <img src="../../assets/icons/costs-coin.svg" alt="" class="img-fluid icon-size">
                                <span class="menu-link-text ">{{ $t('AppAsideMenu.Economia') }}</span>
                            </router-link>
                        </li>
                        <li class="kt-menu__item kt-menu__item--submenu bg-color" aria-haspopup="true"
                            style="border-top-right-radius: 0px !important"
                            :class="{active: isCurrentRouteActive('costs/working-modes'),
                            inactive: !isCurrentRouteActive('costs/working-modes')}">
                            <router-link :to="{name: 'working-modes'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../assets/icons/costs-tractor.svg" alt=""
                                     class="img-fluid icon-size">
                                <span class="menu-link-text">{{ $t('AppAsideMenu.Análise') }}</span>
                            </router-link>
                        </li>
                    </ul>
                    <li v-show="hasFeatureEnabled('ALERTS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @mouseover="enterAlertsMenu()" @click="enterClickAlertMenu()" id="alerts"
                        :class="{active: isCurrentRouteActive('alerts'),inactive: !isCurrentRouteActive('alerts'), 'bg-color-menu': showAlertsMenu}">
                        <router-link :to="{name: 'alerts-machine'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-alerta.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Alertas') }}</span>
                            <span class="arrow">
                                <i class="glyphicons  x1" :class="'glyphicons-chevron-right'"></i>
                            </span>
                        </router-link>
                    </li>
                    <ul v-show="showAlertsMenu" class="kt-menu__nav subMenu" id="subMenuAlerts">
                        <li class="kt-menu__item kt-menu__item--submenu bg-color submenu__hover" aria-haspopup="true"
                            style="border-top-right-radius: 6px !important"
                            :class="{active: isCurrentRouteActive('alerts/machine'),
                            inactive: !isCurrentRouteActive('alerts/machine')}">
                            <router-link :to="{name: 'alerts-machine'}"
                                         class="kt-menu__link kt-menu__toggle submenu__hover_top">
                                <img src="../../assets/icons/icone-fleet-alert.svg" alt="" class="img-fluid icon-size">
                                <span class="menu-link-text ">{{ $t('AppAsideMenu.Máquina') }}</span>
                            </router-link>
                        </li>
                        <li class="kt-menu__item kt-menu__item--submenu bg-color" aria-haspopup="true"
                            style="border-top-right-radius: 0px !important"
                            :class="{active: isCurrentRouteActive('alerts/operational'),
                            inactive: !isCurrentRouteActive('alerts/operational')}">
                            <router-link :to="{name: 'alerts-operational'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../assets/icons/icone-operational-alert.svg" alt=""
                                     class="img-fluid icon-size">
                                <span class="menu-link-text">{{ $t('AppAsideMenu.Operacionais') }}</span>
                            </router-link>
                        </li>
                    </ul>
                    <li v-show="hasFeatureEnabled('DEVICES')"
                        class="kt-menu__item kt-menu__item--submenu " aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('devices'),inactive: !isCurrentRouteActive('devices')}">
                        <router-link :to="{name: 'devices'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-dispositivo.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Dipositivos') }}</span>
                        </router-link>
                    </li>
                    <li v-show="hasFeatureEnabled('USERS')"
                        class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('users'), inactive: !isCurrentRouteActive('users')}">
                        <router-link :to="{name: 'users'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-organization.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Organizações') }}</span>
                        </router-link>
                    </li>
                    <li class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('profile'), inactive: !isCurrentRouteActive('profile')}">
                        <router-link :to="{name: 'profile'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/user-icon.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Perfil') }}</span>
                        </router-link>
                    </li>
                    <li class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        @click="exitClickSubMenu()" @mouseover="exitLeaveSubMenu()"
                        :class="{active: isCurrentRouteActive('settings'), inactive: !isCurrentRouteActive('settings')}">
                        <router-link :to="{name: 'settings'}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-ajustes.svg" alt="" class="img-fluid icon-size">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Configurações') }}</span>
                        </router-link>
                    </li>

                    <li class="kt-menu__item kt-menu__item--submenu" aria-haspopup="true"
                        :class="{active: isCurrentRouteActive('login'), inactive: !isCurrentRouteActive('login')}"
                        @click="logout">
                        <router-link :to="{}" class="kt-menu__link kt-menu__toggle">
                            <img src="../../assets/icons/icone-sair.svg" alt="" class="img-fluid icon-size"
                                 style="margin-top: 6px">
                            <span class="menu-link-text">{{ $t('AppAsideMenu.Terminar') }}</span>
                        </router-link>
                    </li>

                </ul>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import hasFeatureEnabled from "@/mixins/OrganizationFeatureFlagsMixin";
import {router} from "@/router";
import auth from "@/services/auth";

export default {
    mixins: [hasFeatureEnabled],
    data() {

        return {
            theme: process.env.VUE_APP_THEME_ACTIVE,
            route: null,
            locale: 'pt-BR',
            showAgricultureMenu: false,
            showAlertsMenu: false,
            showCostsMenu: false,
        }
    },
    methods: {
        logout() {

            let dataSSO = JSON.parse(localStorage.getItem(process.env.VUE_APP_SSO_LOGIN));

            if (dataSSO) {
                auth.logout()
            } else {
                this.$store.dispatch('logout');
                router.push({name: 'login'});
            }
        },
        exitClickSubMenu() {
            this.showAgricultureMenu = false;
            this.showAlertsMenu = false;
            this.showCostsMenu = false;
        },
        exitLeaveSubMenu() {
            if (!this.isScreenMobile) {
                this.showAgricultureMenu = false;
                this.showAlertsMenu = false;
                this.showCostsMenu = false;
            }
        },
        enterClickAgricultureMenu() {
            this.showAlertsMenu = false;
            this.showCostsMenu = false;
        },
        enterClickAlertMenu() {
            this.showAgricultureMenu = false;
            this.showCostsMenu = false;
        },
        enterClickCostsMenu() {
            this.showAgricultureMenu = false;
            this.showAlertsMenu = false;
        },
        enterAgricultureMenu() {
            let offsets = document.getElementById('agriculture').getBoundingClientRect();
            let top = offsets.top - 43;
            let right = offsets.right;

            let agricultureMenu = document.getElementById('subMenuAgro');

            if (this.getScreenWidth() <= 1024) {
                this.showAgricultureMenu = true;
                agricultureMenu.style.left = 0;
                agricultureMenu.style.top = 0;
                return
            }

            agricultureMenu.style.position = "fixed";
            agricultureMenu.style.left = right + 'px';
            agricultureMenu.style.top = top + 'px';

            this.showAgricultureMenu = true;
            this.showAlertsMenu = false;
            this.showCostsMenu = false;
        },
        enterAlertsMenu() {
            let offsets = document.getElementById('alerts').getBoundingClientRect();
            let top = offsets.top - 43;
            let right = offsets.right;

            let alertsMenu = document.getElementById('subMenuAlerts');

            if (this.getScreenWidth() <= 1024) {
                this.showAlertsMenu = true;
                alertsMenu.style.left = 0;
                alertsMenu.style.top = 0;
                return
            }

            alertsMenu.style.position = "fixed";
            alertsMenu.style.left = right + 'px';
            alertsMenu.style.top = top + 'px';

            this.showAgricultureMenu = false;
            this.showAlertsMenu = true;
            this.showCostsMenu = false;
        },
        enterCostsMenu() {
            let offsets = document.getElementById('costs').getBoundingClientRect();
            let top = offsets.top - 43;
            let right = offsets.right;

            let alertsMenu = document.getElementById('subMenuCosts');

            if (this.getScreenWidth() <= 1024) {
                this.showCostsMenu = true;
                alertsMenu.style.left = 0;
                alertsMenu.style.top = 0;
                return
            }

            alertsMenu.style.position = "fixed";
            alertsMenu.style.left = right + 'px';
            alertsMenu.style.top = top + 'px';

            this.showAgricultureMenu = false;
            this.showAlertsMenu = false;
            this.showCostsMenu = true;
        },
        getScreenWidth() {
            return window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
        },
        getLocale() {
            return this.$store.getters.user.language;
        },
        isCurrentRouteActive(routeName) {
            return this.$route.name === routeName;
        },
    },

    watch: {
        '$route': {
            handler: function (to) {
                this.route = to.path;
            },
            deep: true,
            immediate: true
        }
    },
    components: {},
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        },
        ...mapGetters([
            'hasAdminRoles',
            'getUserEmail',
            'user'
        ])
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

.glyphicons.x2::before {
    padding: 0px 0 0px 0px;
}

.glyphicons.x1::before {
    padding: 0px 0 0px 0px;
}

.arrow {
    z-index: 999;
    position: absolute;
    top: 40%;
    right: 0;
    color: gray;
    opacity: 0.8;
    cursor: pointer
}

.arrow:hover {
    opacity: 1.0;
}

.menu-link-text {
    color: $aside-menu-text;
}

.active {
    background-color: $aside-menu-item-active;
}

.active span {
    color: white;
}

.active img {
    filter: brightness(0) invert(1);
}

.inactive img {
    filter: $aside-menu-item-inactive;
}


.icon-size {
    width: 50px;
    height: 50px;
}

.sidebar-item {
    padding: 5px;
}

.ps {
    max-height: calc(100vh - 81px);
}

.kt-menu__item--submenu:hover span {
    color: white;
}

.kt-menu__item--submenu:hover img {
    filter: brightness(0) invert(1);
}

/*#kt_aside_menu_wrapper {
   padding-top: 6px;
    border: 1px solid #0f0f16;
}*/

.kit-menu__toggle {
    border: 1px solid #0f0f16;
}

.subMenu {
    z-index: 999;
    background-color: white;
    width: 140px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-color: #DCDCDC;
}

.subMenu, .kit-menu__nav {
    border-style: solid;
    border-width: thin;
    border-left: none;
}

@media (min-width: 1025px) {
    .submenu__hover_top:hover {
        border-top-right-radius: 6px !important;
    }

    .submenu__hover_bottom:hover {
        border-bottom-right-radius: 6px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .subMenu {
        width: 100% !important;
        height: 100% !important;
    }

    .ps {
        max-height: calc(100vh - 50px) !important;
    }

    .menu-link-text {
        position: relative;
        top: 15px;
        left: 7px;
    }

    .subMenu, .kit-menu__nav {
        border-bottom: solid #bbb 3px !important;
        border: none;
        border-radius: 0px;
    }

    .bg-color {
        background-color: $aside-submenu-background;
    }

    .bg-color-menu {
        background-color: $aside-menu-item-active;
    }

    .bg-color-menu span {
        color: white;
    }

    .bg-color-menu img {
        filter: brightness(0) invert(1);
    }
}

</style>
