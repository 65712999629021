<template>
    <div id="google-maps"></div>
</template>

<script>

    import gmapsInit from '../../../utils/gmaps';
    import {mapGetters} from 'vuex';
    import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";

    export default {
        mixins: [jstsWithHolesToGoogleMaps],
        async mounted() {
            try {

                let mapType = 'hybrid';

                if (this.isUserDemo) {
                    mapType = 'satellite'
                }


                let vm = this;
                const google = await gmapsInit();
                vm.google = google;
                vm.infoWindow = new google.maps.InfoWindow();
                this.map = new google.maps.Map(document.getElementById("google-maps"), {
                    minZoom: 4, maxZoom: 20,
                    zoom: 4,
                    center: {lat: -25.407688, lng: -49.253990},
                    mapTypeId: mapType,
                    rotateControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    tilt: 0,
                    disableDefaultUI: true

                });


                this.map.addListener("click", (event) => {
                    vm.addFixPointMarker(event.latLng);
                });

                this.heatmap = new google.maps.visualization.HeatmapLayer({
                    map: vm.map,
                });

            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                map: null,
                heatmap: null,
                google: {},
                markerMap: new Map(),
                infoWindow: null,
                polygons: [],
                polygonsColors: [],
                selectedColor: '#fbff00',
                fixPointMarkers: [],
                fixPointMarkersIndex: 0
            }
        },
        props: {
            locations: {
                type: Array,
            },
            center_location: {
                required: true
            },
            polygons_data: {
                type: Array,
            },
            markers: {
                type: Boolean
            },
            heatMap: {
                type: Boolean
            },
            selected_fields: {
                type: Array
            },
            show_fields: {
                type: Boolean
            },
            data_filtered: {
                type: Boolean
            }
        },
        methods: {
            clearFixMarkers() {
                this.fixPointMarkers.forEach(marker => {
                    marker.setMap(null)
                })
                this.fixPointMarkers = [];
            },
            createPoints() {

                this.clearMarkers();

                let locationsMaps = [];

                let rmsMax = -1;
                let rmsMin = 1000;

                let ocorrMax = -1;
                let ocorrMin = 1000;

                this.locations.forEach((location) => {
                    locationsMaps.push(new this.google.maps.LatLng(location));

                    if (rmsMax < location.rms) {
                        rmsMax = location.rms
                    }
                    if (rmsMin > location.rms) {
                        rmsMin = location.rms
                    }
                    if (ocorrMax < location.ocorr) {
                        ocorrMax = location.ocorr
                    }
                    if (ocorrMin > location.ocorr) {
                        ocorrMin = location.ocorr
                    }

                });
                this.heatmap.setData(locationsMaps);

                //so um int para diferenciar os markers
                let id = 0;
                let vm = this;
                this.locations.forEach((location) => {

                    // Vai variar de 0 a 1 - Quanto mais próximo de 0, mais azul -  Quanto mais proximo de 1, mais vermelho
                    let rmsCalc = (location.rms - rmsMin) / (rmsMax - rmsMin);


                    // Quanto mais próximo de 0, menor é o raio - Quanto mais proximo de 1, Quanto mais proximo de 1, maior é o raio
                    let ocorrCalc = (location.ocorr - ocorrMin) / (ocorrMax - ocorrMin);

                    if (isNaN(rmsCalc) || isNaN(ocorrCalc)) {
                        return;
                    }

                    location.id = id;
                    location.rmsCalc = rmsCalc;
                    location.ocorrCalc = ocorrCalc;

                    let marker = new this.google.maps.Marker({
                        position: location,
                        map: this.map,
                        icon: this.getIconFromRmsAndOcc(rmsCalc, ocorrCalc),
                    });

                    marker.addListener('click', function () {
                        vm.showInfoWindow(location.id)
                    });
                    this.markerMap.set(location.id, {marker: marker, rawData: location});

                    id++;
                })
            },
            showInfoWindow(markerId) {
                let markerMap = this.markerMap.get(markerId);
                let content = this.buildInfoWindow(markerMap.rawData);
                this.infoWindow.setContent(content);
                this.infoWindow.open(this.map, markerMap.marker);
            },
            getIconFromRmsAndOcc(rms, occ) {
                let size = this.getImageSizeFromOcc(Math.trunc(occ * 10));
                return {
                    url: this.getImageColorFromRms(Math.trunc(rms * 10)),
                    scaledSize: new this.google.maps.Size(size, size),
                    anchor: new this.google.maps.Point(7, 7)
                }
            },
            getImageColorFromRms(rms) {
                switch (rms) {
                    case 0:
                        return require('@/assets/images/anomalias/0.png');
                    case 1:
                        return require('@/assets/images/anomalias/1.png');
                    case 2:
                        return require('@/assets/images/anomalias/2.png');
                    case 3:
                        return require('@/assets/images/anomalias/3.png');
                    case 4:
                        return require('@/assets/images/anomalias/4.png');
                    case 5:
                        return require('@/assets/images/anomalias/5.png');
                    case 6:
                        return require('@/assets/images/anomalias/6.png');
                    case 7:
                        return require('@/assets/images/anomalias/7.png');
                    case 8:
                        return require('@/assets/images/anomalias/8.png');
                    case 9:
                        return require('@/assets/images/anomalias/9.png');
                    default:
                        return require('@/assets/images/anomalias/0.png');
                }

            },
            getImageSizeFromOcc(occ) {
                switch (occ) {
                    case 0:
                        return 10;
                    case 1:
                        return 12;
                    case 2:
                        return 14;
                    case 3:
                        return 16;
                    case 4:
                        return 18;
                    case 5:
                        return 20;
                    case 6:
                        return 22;
                    case 7:
                        return 24;
                    case 8:
                        return 26;
                    case 9:
                        return 28;
                    default:
                        return 10;
                }
            },
            centerMap() {
                this.map.setCenter({lat: this.center_location.lat, lng: this.center_location.lng});
                if (this.map.getZoom() < 12) {
                    this.map.setZoom(12)
                }
            },
            // Sets the map on all markers in the array.
            setMapOnAll(map) {
                for (let [k, v] of this.markerMap) {
                    v.marker.setMap(map)
                }
            },

            // Removes the markers from the map, but keeps them in the array.
            clearMarkers() {
                this.setMapOnAll(null);
            },

            // Shows any markers currently in the array.
            showMarkers() {
                this.setMapOnAll(this.map);
            },

            buildInfoWindow(values) {
                let content = '<div style="min-width: 100px">';
                content += '<b>Ocorrências:</b> ' + values.ocorr + ' vezes<br>';
                content += '<b>Intensidade:</b> ' + Math.trunc(values.rmsCalc * 100) + '%';
                content += '</div>';
                return content;
            },
            deleteDrawnPolygons() {
                this.polygons.forEach(polygon => {
                    polygon.setMap(null);
                })
            },
            addFixPointMarker(location) {
                let vm = this;

                if (!this.data_filtered) {
                    return;
                }

                const roadWorkerImage = require('@/assets/images/anomalias/road_worker.png');
                let icon = {
                    url: roadWorkerImage,
                    scaledSize: new this.google.maps.Size(40, 40),
                    anchor: new this.google.maps.Point(20, 20)
                }

                const marker = new this.google.maps.Marker({
                    id: vm.fixPointMarkersIndex,
                    position: location,
                    map: vm.map,
                    icon: icon
                });

                marker.addListener("click", (event) => {
                    marker.setMap(null);
                    let newMarkers = vm.fixPointMarkers;
                    for (let i = marker.id + 1; i < vm.fixPointMarkers.length; i++) {
                        newMarkers[i].id--;
                    }
                    newMarkers.splice(marker.id, 1);
                    vm.fixPointMarkers = newMarkers;
                    vm.fixPointMarkersIndex--;
                });

                vm.fixPointMarkersIndex++;
                vm.fixPointMarkers.push(marker);
            },
            showHideMarkers(val) {
                if (val) {
                    this.showMarkers();
                } else {
                    this.clearMarkers();
                }
            },
            showHideHeatMap(val) {
                if (val) {
                    this.heatmap.setMap(this.map)
                } else {
                    this.heatmap.setMap(null);
                }
            },
            clearMarkersMap() {
                this.clearMarkers();
                this.markerMap = new Map();
            }
        },
        watch: {
            locations: {
                deep: true,
                handler: function (val) {
                    this.createPoints();
                    this.clearMarkers();

                    this.showHideMarkers(this.markers);
                    this.showHideHeatMap(this.heatMap);
                }
            },
            center_location: function (val) {
                if (val) {
                    this.centerMap();
                }
            },
            markers: function (val) {
                this.showHideMarkers(val);
            },
            heatMap: function (val) {
                this.showHideHeatMap(val);
            },
            polygons_data: function (val) {
                let vm = this;
                this.deleteDrawnPolygons();

                this.polygons = [];
                this.polygonsColors = [];
                val.forEach(polygon => {
                    let newPolygon = new this.google.maps.Polygon({
                        fillColor: polygon.color,
                        fillOpacity: polygon.opacity,
                        strokeColor: polygon.color,
                        strokeOpacity: 1,
                        strokeWeight: 0.8,
                        map: this.map,
                        paths: vm.jstsWithHolesToGoogleMaps(polygon.geom, this.google.maps),
                        indexID: polygon.id,
                        zIndex: 1,
                        visible: this.show_fields
                    });
                    this.polygons.push(newPolygon);

                    this.google.maps.event.addListener(newPolygon, 'click', function (event) {
                        vm.$emit('polygonSelected', this.indexID);
                    });
                })
            },

            selected_fields: {
                deep: true,
                handler(val) {

                    this.polygons.forEach(polygon => {
                        let polygonFromProps = this.polygons_data.find(obj => {
                            return obj.id === polygon.indexID
                        })
                        polygon.setOptions({strokeColor: polygonFromProps.color, zIndex: 1, strokeWeight: 6});
                    })

                    val.forEach(selectedField => {
                        this.polygons.forEach(polygon => {
                            if (polygon['indexID'] === selectedField.id) {
                                polygon.setOptions({strokeColor: this.selectedColor, zIndex: 2, strokeWeight: 3});
                            }
                        })
                    })
                }
            },
            show_fields: function (val) {
                this.polygons.forEach(polygon => {
                    polygon.setVisible(val)
                });
            },
            fixPointMarkers: function (val) {
                this.$emit('fixPointMarkersChanged', this.fixPointMarkers)
            },
        },
        computed: {
            ...mapGetters([
                'isUserDemo'
            ])
        }
    }
</script>

<style scoped>
    #google-maps {
        height: 100%;
    }
</style>
