<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <div v-if="!showDialogFilters"  class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-date-time-control-bar
                        @handleFilter="validateDataPreRequest"
                        :start_date_open_date="datePickerStartDate"
                        :end_date_open_date="datePickerEndDate"
                        :disable_calendar="selectedOrg == null || vehicles.length == 0">
                    <template v-slot:selectone>
                        <Dropdown v-model="selectedOrg" :options="orgsList"
                                optionLabel="display_name" :placeholder="$t('AppDashboard.Organizações')"
                                class="auto-center" :dataKey="'id'"
                                :filter="true"
                                style="width: 250px"
                                @input="saveChosenOptionsToLocalStorage"/>

                        <Dropdown v-model="selectedVehicles" :options="vehicles"
                                        optionLabel="display_id" :placeholder="$t('AppDashboard.Veículos')"
                                        class="auto-center" :dataKey="'id'"
                                        :filter="true"
                                        style="width: 250px; margin: 0px 5px 0px 10px"
                                        :disabled="selectedOrg == null || vehicles.length == 0">
                                <template #value>
                                    <div v-if="loadingVehicles">
                                        {{ $t('AppDashboard.Carregando veículos') }}
                                        <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                    </div>
                                </template>
                        </Dropdown>
                    </template>
                </app-date-time-control-bar>
            </div>
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center" @handleClick="openMaximizable">
                    Filtrar {{$t('AppDashboard.Veículos')}}
                </app-button>
            </div>
            <Dialog :header="$t('AppDashboard.Filtrar')" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                <app-date-time-control-bar
                        style="display: flex; justify-content: center; margin-left: 0px;" class="row col-12"
                        @handleFilter="validateDataPreRequest"
                        :start_date_open_date="datePickerStartDate"
                        :end_date_open_date="datePickerEndDate"
                        :is_col_12="true">
                    <template v-slot:selectone>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label class="col-12">{{ $t('AppDashboard.Organização') }}</label>
                            <Dropdown v-model="selectedOrg" :options="orgsList"
                                    optionLabel="display_name" :placeholder="$t('AppDashboard.Organizações')"
                                    class="auto-center col-12" :dataKey="'id'"
                                    :filter="true"
                                    style="width: 250px"
                                    appendTo="tableDiv"
                                    @input="saveChosenOptionsToLocalStorage"/>
                        </div>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label class="col-12">Veículo</label>
                            <Dropdown v-model="selectedVehicles" :options="vehicles"
                                            optionLabel="display_id" :placeholder="$t('AppDashboard.Veículos')"
                                            class="auto-center col-12" :dataKey="'id'"
                                            :filter="true"
                                            style="width: 250px;"
                                            appendTo="tableDiv"
                                            :disabled="selectedOrg == null || vehicles.length == 0">
                                    <template #value>
                                        <div v-if="loadingVehicles">
                                            {{ $t('AppDashboard.Carregando veículos') }}
                                            <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                        </div>
                                    </template>
                            </Dropdown>
                        </div>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label class="col-12">{{ $t('AppDashboard.Período') }}</label>
                        </div>
                    </template>
                </app-date-time-control-bar>
            </Dialog>

            <div class="kt-portlet__body" style="padding: 0 !important;">
                <div style="height: calc(100vh - 86px) !important">
                    <app-grafana ref="grafana" :selected_vehicles="selectedVehicles"></app-grafana>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {router} from "../../../router";
import AppGrafana from "@/components/views/dashboard/AppGrafana";
import AppButton from "@/components/common/AppButton";
import Dialog from 'primevue/dialog';
import AppDateTimeControlBar from "../../common/AppDateTimeControlBar";
import Dropdown from 'primevue/dropdown';
import OrganizationsService from "../../../services/OrganizationsService";
import VehiclesService from '@/services/VehiclesService';
import orderCustomFieldByNaturalOrder from "../../../mixins/ArrayMixin";
import ProgressSpinner from 'primevue/progressspinner';
import moment from 'moment';

export default {
    mixins: [orderCustomFieldByNaturalOrder],
    mounted: function () {
        this.showDialogFilters = this.isScreenMobile;
        this.organizationsService = new OrganizationsService();
        this.vehiclesService = new VehiclesService();
        this.getCustomerList();
    },
    data() {
        return {
            showDialogFilters: false,
            displayMaximizable: false,
            organizationsService: null,
            selectedOrg: null,
            orgsList: [],
            loadingVehicles: false,
            vehicles: [],
            selectedVehicles: [],
            datePickerStartDate: moment().subtract(1, 'day').startOf('day').toDate(),
            datePickerEndDate: moment().toDate(),
        }
    },
    methods: {
        getCustomerList() {
            this.organizationsService.getAllV3("DASHBOARD")
                .then((response) => {
                    this.getOrgsList(response);
                    this.loadFilteredOptions();
                }).catch((error) => {
                    console.log(error)
            })
        },
        getOrgsList(orgs) {
            this.orgsList = orgs.sort(function (a, b) {
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
            });
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        getVehicles() {
            let vm = this;
            vm.loadingVehicles = true
            const promises = [];
            promises.push(this.vehiclesService.getVehiclesByOrgV3(this.selectedOrg.id));

            Promise.allSettled(promises)
                .then(async (results) => {
                    if (results.map(obj => obj.status).includes("rejected")) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: this.$t('AppDashboard.Sem acesso'),
                            detail: this.$t('AppDashboard.Você não tem acesso aos veículos de todos as organizações'),
                            life: 5000
                        });
                    }
                    let vehicles = [];
                    results.forEach(result => {
                        if (result.status === 'rejected') {
                            return;
                        }
                        vehicles.push.apply(vehicles, result.value)
                    })
                    this.vehicles = this.orderCustomFieldByNaturalOrder(vehicles, "display_id");
                    this.selectedVehicles = [];
                })
                .catch((error) => {
                    vm.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppDashboard.Operação falhou'),
                        detail: this.$t('AppDashboard.Não foi possível buscar veículos'),
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                vm.loadingVehicles = false;
            });
        },
        openMaximizable() {
            this.displayMaximizable = true;
        },
        validateDataPreRequest(val) {
            if (!this.selectedOrg) {
                this.$toast.add({severity: 'error', summary: this.$t('AppDashboard.Escolha uma organização'), life: 3000});
                return;
            }

            if (!this.selectedOrg.dashboard_org_id) {
                this.$toast.add({severity: 'error', summary: this.$t('AppDashboard.Funcionalidade está desabilitada para esta organização'), life: 3000});
                return;
            }

            if (this.selectedVehicles.length === 0) {
                this.$toast.add({severity: 'error', summary: this.$t('AppDashboard.Escolha ao menos um veículo'), life: 3000});
                return;
            }

            let timeDifference = new Date(val.end_date).getTime() - new Date(val.start_date).getTime();
            let daysDifference = timeDifference / (1000 * 3600 * 24);
            if (daysDifference > 30) {
                this.$toast.add({severity: 'error', summary: this.$t('AppDashboard.Selecione um período menor que 30 dias'), life: 3000});
                return;
            }

            this.displayMaximizable = false;
            val['vehicles'] = this.selectedVehicles;
            val['org'] = this.selectedOrg;
            this.$refs.grafana.validateDataPreRequest(val);
        },
        back() {
            router.go(-1);
        }
    },

    components: {
        AppGrafana,
        AppButton,
        Dialog,
        Dropdown,
        ProgressSpinner,
        AppDateTimeControlBar
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.getVehicles();
        }
    }

}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";
</style>

