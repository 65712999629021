<template>
    <div class="arrow" :class="show ? 'arrow-left-side' : 'arrow-right-side'"
         @click="handleClick">
        <i class="glyphicons  x2" :class="show ? 'glyphicons-chevron-right' : 'glyphicons-chevron-left'"></i>
    </div>
</template>

<script>
export default {

    data() {
        return {
            show: true,
        }
    },
    methods: {
        handleClick() {
            this.show = !this.show;
            this.$emit('handleChange')
        }
    },
    computed: {}
}
</script>

<style scoped type="scss">
.glyphicons.x2::before {
    padding: 0px 0 0px 0px;
}

.arrow {
    z-index: 999;
    position: absolute;
    top: 370px;
    color: #cecece;
    opacity: 1;
    cursor: pointer
}

.arrow-right-side {
    right: 0;
}

.arrow-left-side {
    right: 680px;
}

.arrow:hover {
    color: white;
}

</style>
