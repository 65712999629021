import axios from '../axios/axios-auth'

export default class AlertsDictionaryService {

    getAllV3(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/alerts/dictionary`).then(response => response.data)
    }

    getByCodeV3(code) {
        return axios.get('/api/v3/alerts/dictionary/' + code).then(response => response.data)
    }

    saveV3(orgId, val) {
        return axios.post(`/api/v3/organizations/${orgId}/alerts/dictionary`, val).then(response => response.data);
    }

    updateV3(failureId, val) {
        return axios.patch(`/api/v3/alerts/dictionary/${failureId}`, val).then(response => response.data);
    }
}
