<template>
    <div class="card-chart">
        <div class="header">
            <span style="font-size: large; color: #000;">{{ $t('AppWaterWithdrawalMonthCard.Total captação por mês') }}</span>
            <dropdown v-model="selectedMode" :options="modes" optionLabel="name" @change="updateMonthSelection"/>
        </div>
        <div>
            <app-water-withdrawal-month-chart :mode-display="selectedMode"
                                              :full-data="data"></app-water-withdrawal-month-chart>
        </div>
    </div>
</template>

<script>
import AppWaterWithdrawalMonthChart from "@/components/views/water_withdrawal/AppWaterWithdrawalMonthChart";
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        AppWaterWithdrawalMonthChart, Dropdown,
    },
    data() {
        return {
            selectedMode: null,
            modes: [
                {name: 'Geral'},
                {name: 'Processos'},
                {name: 'Estados'},
            ],
            data: {
                months: [],
                mainSeries: [],
                stateSeries: [],
                processSeries: []
            }
        }
    },
    mounted() {
        this.selectedMode = this.modes[0]
    },
    props: {
        info: {
            type: Array
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        info: {
            deep: true,
            handler(val) {
                this.cleanData()
                let totalVolume = []
                let byState = this.buildList(val[0].byState)
                let byProcess = this.buildList(val[0].byProcess)

                val.forEach(month => {
                    this.data.months.push(month.name)
                    totalVolume.push(month.volume)

                    this.fillList(byState, month.byState)
                    this.fillList(byProcess, month.byProcess)
                })

                this.buildGraphSeries(this.data.mainSeries, totalVolume)
                this.buildGraphSeries(this.data.stateSeries, byState, byState.length)
                this.buildGraphSeries(this.data.processSeries, byProcess, byProcess.length)

                let series = (byState.length > byProcess.length) ? byState.length : byProcess.length

                this.equalizeSeries(this.data.mainSeries, series - this.data.mainSeries.length)
                this.equalizeSeries(this.data.stateSeries, series - this.data.stateSeries.length)
                this.equalizeSeries(this.data.processSeries, series - this.data.processSeries.length)
            }
        }
    },
    methods: {
        cleanData() {
            while (this.data.months.length > 0) {
                this.data.months.pop()
            }

            while (this.data.mainSeries.length > 0) {
                this.data.mainSeries.pop()
            }

            while (this.data.stateSeries.length > 0) {
                this.data.stateSeries.pop()
            }

            while (this.data.processSeries.length > 0) {
                this.data.processSeries.pop()
            }
        },
        buildList(ref) {
            let newList = []
            for (let i = 0; i < ref.length; i++) {
                newList.push({name: ref[i].name, volumes: []})
            }
            return newList
        },
        fillList(list, ref) {
            for (let i = 0; i < ref.length; i++) {
                for (let j = 0; j < list.length; j++) {
                    if (ref[i].name === list[j].name) {
                        list[j].volumes.push(ref[i].volume)
                        break;
                    }
                }
            }
        },
        buildGraphSeries(series, data, size = 1) {
            let vm = this;
            for (let i = 0; i < size; i++) {
                series.push({
                    name: data[i].name ? data[i].name : '',
                    type: 'bar',
                    stack: 'total',
                    color: size === 1 ? '#50ACF2' : '',
                    data: data[i].volumes ? data[i].volumes : data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    label: {
                        show: this.showLabel,
                        color: '#fff',
                        formatter: function (params) {
                            return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                        }
                    }
                })
            }
        },
        equalizeSeries(data, limit) {
            for (let i = 0; i < limit; i++) {
                data.push({
                    name: '',
                    data: []
                })
            }
        },
        updateMonthSelection() {
            this.$store.dispatch('updateMonthType', this.selectedMode);
        }
    }
}
</script>

<style scoped>
.card-chart {
    margin: 1.3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 35rem;
    padding: 2rem;
}

.header {
    padding-inline: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
