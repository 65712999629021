import axios from '../axios/axios-auth'

export default class ConfigurationListService {

    getConfigurationListByOrg(orgId, params) {
        return axios.get(`/appConfig/v1/organizations/${orgId}/appConfigs`, params).then(response => response.data)
    }

    getConfigurationListData(appConfigId) {
        return axios.get(`/appConfig/v1/appConfigs/${appConfigId}`).then(response => response.data)
    }

    saveConfigurationList(orgId, data) {
        return axios.post(`/appConfig/v1/organizations/${orgId}/appConfigs`, data).then(response => response.data)
    }

    editConfigurationList(appConfigId, data) {
        return axios.patch(`/appConfig/v1/appConfigs/${appConfigId}`, data).then(response => response.data)
    }

    deleteConfigurationList(appConfigId) {
        return axios.delete(`/appConfig/v1/appConfigs/${appConfigId}`).then(response => response.data)
    }

    getVehicleConfigurationByOrg(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/vehicles`).then(response => response.data)
    }

    sendConfigurationListToVehicle(appConfigId, data) {
            return axios.post(`/appConfig/v1/appConfigs/${appConfigId}/send`, data).then(response => response.data);
    }

}
