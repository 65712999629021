export default {
    methods: {
        formatValue(val, min, max) {
            try {
                return val.toLocaleString('pt-BR', {
                    minimumFractionDigits: min,
                    maximumFractionDigits: max
                })
            } catch (e) {
                return 0;
            }
        },
    }
}


