<template>
    <v-chart class="chart" :option="chartOption" :autoresize="true" theme="custom-theme" ref="chartInstance"/>
</template>

<script>
    import {use, registerTheme} from "echarts/core";
    import {CanvasRenderer} from "echarts/renderers";
    import {PieChart} from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from "echarts/components";
    import VChart from "vue-echarts";

    import customTheme from '../../../configurations/iotag_echarts_theme.json'

    use([
        CanvasRenderer,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent
    ]);

    // registering custom theme
    registerTheme("custom-theme", customTheme);

    export default {

        components: {
            VChart
        },

        beforeMount() {
            this.setupChart();
        },

        data() {
            return {
                chartOption: null,
            };
        },
        props: {
            chartData: {
                type: Array,
                default() {
                    return []
                }
            },
            unit: {
                type: String,
                default() {
                    return ''
                }
            },
        },
        methods: {
            setupChart() {
                let vm = this;
                this.chartOption = {
                    fontFamily: "'Unica One', sans-serif",
                    tooltip: {
                        trigger: "item",
                        formatter: function (args) {
                            if (args.data.rawValue != null){
                                return `${args.marker} ${args.name}: <b>${args.value}% </b> | <b>${args.data.rawValue.toFixed(2)}</b> ` +  vm.unit;
                            }
                            else {
                                return `${args.marker} ${args.name}: <b>${args.value}% </b><br>`;
                            }
                        }
                    },
                    legend: {
                        top: "bottom",
                        left: "center",
                    },
                    series: []
                }
            },
            setupSeries(chartData) {
                this.chartOption.series = [
                    {
                        colorBy: 'data',
                        label: {
                            show: false,
                            position: 'center'
                        },
                        type: "pie",
                        data: chartData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            },
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                    if (val.length === 0) {
                        if (this.chartOption && this.chartOption.series && this.chartOption.series.length > 0 &&
                            this.chartOption.series[0].data) {
                            this.$refs.chartInstance.chart.clear();
                        }
                    } else {
                        this.setupSeries(JSON.parse(JSON.stringify(val)));
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .chart {
        height: 400px;
    }
</style>
