<template>
    <div class="kt-datatable__pager kt-datatable--paging-loaded" style="position: static; zoom: 1;" v-show="pages.length > 1">
        <ul class="kt-datatable__pager-nav">
            <li><a title="First" class="kt-datatable__pager-link kt-datatable__pager-link--first " @click="onClickFirstPage" :class="{'kt-datatable__pager-link--disabled':isInFirstPage}"><i class="glyphicons glyphicons-fast-backward"/></a></li>
            <li><a title="Previous" class="kt-datatable__pager-link kt-datatable__pager-link--prev" @click="onClickPreviousPage" :class="{'kt-datatable__pager-link--disabled':isInFirstPage}"><i class="glyphicons glyphicons-chevron-left"/></a></li>
            <li v-for="page in pages" class="pagination-item">
                <a class="kt-datatable__pager-link kt-datatable__pager-link-number" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ 'kt-datatable__pager-link--active': isPageActive(page.name) }"> {{ page.name }}</a>
            </li>
            <li><a title="Next" class="kt-datatable__pager-link kt-datatable__pager-link--next" @click="onClickNextPage" :class="{'kt-datatable__pager-link--disabled':isInLastPage}"><i class="glyphicons glyphicons-chevron-right"/></a></li>
            <li><a title="Last" class="kt-datatable__pager-link kt-datatable__pager-link--last" @click="onClickLastPage" :class="{'kt-datatable__pager-link--disabled':isInLastPage}"> <i class="glyphicons glyphicons-fast-forward"/></a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            maxVisibleButtons: {
                type: Number,
                required: false,
                default: 3
            },
            totalPages: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: true
            },
            perPage: {
                type: Number,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            },
        },
        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }

                if (this.currentPage === this.totalPages) {
                    return this.totalPages - this.maxVisibleButtons + 1;
                }
                return this.currentPage - 1;
            },
            endPage() {
                return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
            },
            pages() {
                const range = [];
                for (let i = this.startPage; i <= this.endPage; i += 1) {
                    if (i > 0) {
                        range.push({
                            name: i,
                            isDisabled: i === this.currentPage
                        });
                    }
                }
                return range;
            },
            isInFirstPage() {
                return this.currentPage === 1;
            },
            isInLastPage() {
                return this.currentPage === this.totalPages;
            },
        },
        methods: {
            onClickFirstPage() {
                this.$emit('pagechanged', 1);
            },
            onClickPreviousPage() {
                if (this.currentPage > 1) {
                    this.$emit('pagechanged', this.currentPage - 1);
                }
            },
            onClickPage(page) {
                this.$emit('pagechanged', page);
            },
            onClickNextPage() {
                if (this.currentPage < this.endPage) {
                    this.$emit('pagechanged', this.currentPage + 1);
                }
            },
            onClickLastPage() {
                this.$emit('pagechanged', this.totalPages);
            },
            isPageActive(page) {
                return this.currentPage === page;
            },
        }
    }
</script>

<style scoped>


</style>
