<template>
    <div class="mt-2" style="height: 300px; position: relative">
        <button class="d-none d-md-block show-hide-button" v-tooltip="showChart ? $t('AppAnomaliasMachineHoursPerDay.Exibir tabela' ): $t('AppAnomaliasMachineHoursPerDay.Exibir Gráfico')"
                @click="showHideChart">
            <span :class="showHideChartClass" style="fontSize: 1.3rem"/>
        </button>

        <app-line-chart v-if="showChart"
                        :chart_data="machineryDailyChartData"  :series_symbol="$t('AppAnomaliasMachineHoursPerDay.horas')"
                        :chart_title="$t('AppAnomaliasMachineHoursPerDay.Horas de Maquinário por Dia')" style="position: absolute;width: 100%"/>

        <DataTable  v-else :value="dataTableData" style="text-align: center" dataKey="field_id">
            <template #header>
                <div class="table-header center" style="font-size: 18px;font-weight: 400;">
                    {{ $t('AppAnomaliasMachineHoursPerDay.Horas de Maquinário por Dia') }}
                </div>
                <app-button type="primary" style="position: absolute;right: 5px;top: 5px;" @handleClick="exportCsv">
                    <span class="pi pi-external-link center pr-1"></span>
                    {{ $t('AppAnomaliasMachineHoursPerDay.Exportar') }}
                </app-button>
            </template>

            <Column field="date" header="Dia">
                <template #body="slotProps">
                    {{dateFormatWithoutYear(slotProps.data.date/1000)}}
                </template>
            </Column>
            <Column headerClass="headerClass" bodyClass="bodyClass"
                    field="motoniveladoras" :sortable="true" :header="$t('AppAnomaliasMachineHoursPerDay.Motoniveladoras')">
                <template #body="slotProps">
                    {{slotProps.data.MOTONIVELADORA.toFixed(2)}}
                </template>
            </Column>
            <Column headerClass="headerClass" bodyClass="bodyClass"
                    field="retro-escavadeiras" :sortable="true" :header="$t('AppAnomaliasMachineHoursPerDay.Retro-Esacavadeiras')">
                <template #body="slotProps">
                    {{slotProps.data.RETRO_ESCAVADEIRA.toFixed(2)}}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>

import AppAnomaliasArrow from "./AppAnomaliasArrow";
import AppLineChart from "../analysis/AppLineChart";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";
import AppButton from "../../common/AppButton";
import dateFormatWithoutYear from "@/mixins/DateMixin";
import exportJsonToCsv from  "@/mixins/JsonToCsvMixin";
import {ANOMALIAS_MACHINERY, CSV_FIELDS_HOUR, CSV_LABELS_HOUR} from "./AppAnomaliasOptions";
import Tooltip from "primevue/tooltip";

export default {
    mixins: [dateFormatWithoutYear, exportJsonToCsv],
    data() {
        return {
            machineryDailyChartData: [],
            showChart: true,
            dataTableData: [],
            anomaliasMachinery: ANOMALIAS_MACHINERY()
        }
    },
    props: {
        orders_response: {
            type: Array,
        },
        last_searched_dates: {
            type: Object,
        },
    },
    methods: {
        exportCsv() {
            let csvData = [];

            let tempData = JSON.parse(JSON.stringify(this.dataTableData));

            tempData.forEach((data) => {
                data.date = this.dateFormat(data.date / 1000);
                if (data.MOTONIVELADORA) {
                    data.MOTONIVELADORA = data.MOTONIVELADORA.toFixed(2).replace(".", ",");
                }

                if (data.RETRO_ESCAVADEIRA) {
                    data.RETRO_ESCAVADEIRA = data.RETRO_ESCAVADEIRA.toFixed(2).replace(".", ",");
                }

                csvData.push(data)
            });

            this.exportJsonToCsv(CSV_FIELDS_HOUR, CSV_LABELS_HOUR, csvData, 'maquinario_hora');
        },
        showHideChart() {
            this.showChart = !this.showChart;
        },
        getMachineHoursSumByDay(orders) {
            let machineHoursSumByDay = [];

            orders.forEach(order => {
                order.machinery_list.forEach(machine => {

                    let index = machineHoursSumByDay.findIndex(i => (i.code === machine.type &&
                            i.date.diff(moment(order.start_date * 1000).startOf('day')) === 0) );

                    let hourMeterSum = 0;

                    if (machine.final_hour_meter - machine.initial_hour_meter > 0) {
                        hourMeterSum = machine.final_hour_meter - machine.initial_hour_meter;
                    }

                    if (index >= 0) {
                        machineHoursSumByDay[index].hours += hourMeterSum;
                    } else {
                        machineHoursSumByDay.push({
                            title: this.getMachineTitleFromType(machine.type),
                            date: moment(order.start_date * 1000).startOf('day'),
                            hours: hourMeterSum,
                            code: machine.type
                        })
                    }
                })
            })

            return machineHoursSumByDay;
        },
        buildDefaultSeriesDataForAllDaysAndMachines(enumeratesDates) {
            let tempSeriesData = [];

            tempSeriesData.push({
                data: [],
                findNearestPointBy: 'x',
                name: this.anomaliasMachinery[0].name,
                step: false,
                code: this.anomaliasMachinery[0].code
            });

            tempSeriesData.push({
                data: [],
                findNearestPointBy: 'x',
                name: this.anomaliasMachinery[5].name,
                step: false,
                code: this.anomaliasMachinery[5].code
            });

            enumeratesDates.forEach(date => {
                tempSeriesData.forEach(tempData => {
                    tempData.data.push([date.valueOf(), 0]);
                });
            });

            return tempSeriesData;
        },
        populateMachinesDailyUsageChartData(orders, dates) {
            let enumeratesDates = this.enumerateDaysBetweenDate(dates.start_date, dates.end_date);
            let machineHoursSumByDay = this.getMachineHoursSumByDay(orders);
            let tempSeriesData = this.buildDefaultSeriesDataForAllDaysAndMachines(enumeratesDates);

            machineHoursSumByDay.forEach(machineData => {
                tempSeriesData.forEach(machineryTypeTempData => {
                    machineryTypeTempData.data.forEach(dataItem => {
                        if (machineData.date.diff(dataItem[0]) === 0 && machineryTypeTempData.code === machineData.code) {
                            dataItem[1] = parseFloat(machineData.hours.toFixed(2));
                        }
                    })
                })
            })

            this.machineryDailyChartData = tempSeriesData;
            this.computeDataTableData(machineHoursSumByDay, enumeratesDates);
        },
        enumerateDaysBetweenDate(startDate, endDate) {
            let dates = [];

            let currentDate = moment(startDate).startOf('day');
            let lastDate = moment(endDate).startOf('day');

            dates.push(currentDate.clone().toDate());
            while(currentDate.add(1, 'days').diff(lastDate) <= 0) {
                dates.push(currentDate.clone().toDate());
            }

            return dates;
        },
        getMachineTitleFromType(type) {
            let machinery = this.anomaliasMachinery.find(obj => obj.code === type);
            return machinery.name;
        },
        computeDataTableData(machineHoursSumByDay, enumeratesDates) {
            let dataTableTemp = [];

            enumeratesDates.forEach(date => {
                dataTableTemp.push({
                    date: date.valueOf(),
                    MOTONIVELADORA: 0,
                    RETRO_ESCAVADEIRA: 0
                });

                machineHoursSumByDay.forEach(machineHoursByDay => {
                    if (machineHoursByDay.date.diff(moment(dataTableTemp[dataTableTemp.length - 1].date).startOf('day')) === 0) {
                        dataTableTemp[dataTableTemp.length - 1][machineHoursByDay.code] = machineHoursByDay.hours;
                    }
                })
            })
            this.dataTableData = dataTableTemp;
        }
    },
    watch: {
        orders_response: {
            handler: function (val) {
                this.populateMachinesDailyUsageChartData(val, this.last_searched_dates);
            },
            deep: true
        },
    },
    components: {
        AppButton,
        AppAnomaliasArrow, AppLineChart, DataTable, Column
    },
    computed: {
        showHideChartClass: function () {
            return this.showChart ? 'pi pi-table' : 'pi pi-chart-line';
        }
    },
    directives: {
        tooltip: Tooltip
    },

}

</script>

<style scoped lang="scss">

.show-hide-button {
    z-index: 999;
    position: absolute;
    color: gray;
    opacity: 0.8;
    cursor: pointer;
    left: 0;
    border: none;
    background-color: white;
    padding: 15px 0 0 10px;
}

</style>
