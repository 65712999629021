import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import waterDashboard from './modules/waterDashboard';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        waterDashboard
    }
});
