export default {
    methods: {
        maskCpfCnpj(val) {
            if (val) {
                if (val.length <= 11) {
                    return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
                } else {
                    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
                }
            }
        },
        maskMacAddress(val) {
            if (val) {
                if (val.length === 12) {
                    return val.replace(/(.{2})/g, "$1:").slice(0, -1).toUpperCase();
                } else {
                    return val;
                }
            }
        },
        maskMacAddressArrays(val) {
            let response = [];


            val.forEach(function (mac) {
                if (mac.length === 12) {
                    response.push(mac.replace(/(.{2})/g, "$1:").slice(0, -1).toUpperCase());
                } else {
                    response.push(mac);
                }
            });

            return response.toString();
        },
        maskPhone(val) {
            if (val) {
                if (val.length === 10) {
                    //fixo
                    return val.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
                } else if (val.length === 11) {
                    //Celular
                    return val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "($1) $2 $3-$4");
                } else {
                    return val;
                }
            }
        },
    },
};
