export default {

    methods: {
        /**
         * Função para filtrar os macs/cpf/cnpj nas tabelas do front,
         * deixa so numeros e letras
         *
         * @param filter é o que esta sendo digitado no campo de busca da tabela
         * @param value é o que tem no array de valores da coluna
         * */

        filterValue(value, filter) {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return value.includes(filter.replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase());
        },
    }
}