<template>
    <Dialog :header="$t('AppChecklistsNewDialog.Novo Checklist')" :visible="showDialog" @update:visible="closeDialog" :modal="true">
        <div v-if="!copyChecklistFlag" style="width: 400px">
            <p>{{ $t('AppChecklistsNewDialog.Escolha uma das opções abaixo') }}:</p>
            <div class="d-flex flex-column justify-content-center">
                    <Button :label="$t('AppChecklistsNewDialog.Novo Checklist')" class="p-button-raised"
                            style="font-size: medium" @click="newChecklistClicked" />
                    <Button :label="$t('AppChecklistsNewDialog.Copiar Checklist Existente')" class="p-button-raised"
                            style="font-size: medium; margin-top: 1rem" @click="copyChecklistClicked"/>
            </div>
        </div>
        <div v-else>
            <div v-if="organizationSelected === null">
                <h5>{{$t('AppChecklistsNewDialog.Selecione uma organização da qual deseja copiar o checklist')}}:</h5>
                <Listbox v-model="organizationSelected" :options="organizations" optionLabel="display_name"
                         :dataKey="'id'"
                         :filter="true" :filterPlaceholder="$t('AppChecklistsNewDialog.Procure pelo nome da organização')" />
            </div>
            <div v-else style="width: 500px">
                <div v-if="loadingChecklists">
                    <div class="d-flex justify-content-center">
                        <p>{{ $t('AppChecklistsNewDialog.Carregando, aguarde') }}</p>
                    </div>
                </div>
                <div v-else>
                    <div v-if="checklists.length > 0">
                        <h5>{{ $t('AppChecklistsNewDialog.Selecione um checklist para realizar a cópia') }}:</h5>
                        <Listbox v-model="checklistSelected" :options="checklists" optionLabel="title"
                                 :filter="true" :filterPlaceholder="$t('AppChecklistsNewDialog.Procure pelo título do checklist')" class="existent-list">
                            <template #option="slotProps">
                                <div class="checklist-item">
                                    <div>
                                        {{ slotProps.option.title }} ({{ dateFormat(slotProps.option.created_date) }})
                                    </div>
                                </div>
                            </template>
                        </Listbox>
                    </div>
                    <div v-else class="d-flex justify-content-center">
                        <p>{{ $t('AppChecklistsNewDialog.Nenhum checklist cadastrado para a organização')}}, <br>
                            {{ $t('AppChecklistsNewDialog.Clique em Voltar e selecione outra organização') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <template #footer v-if="copyChecklistFlag && organizationSelected !== null">
            <Button :label="$t('AppChecklistsNewDialog.Voltar')" icon="pi pi-arrow-left" @click="handleBackButton" class="p-button-text"/>
            <Button :label="$t('AppChecklistsNewDialog.Copiar')" icon="pi pi-copy" :disabled="checklistSelected === null" @click="handleCopyButton" />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import Button from 'primevue/button';
import dateFormat from "../../../mixins/DateMixin";

export default {
    mixins: [dateFormat],
    data(){
        return{
            copyChecklistFlag: false,
            organizationSelected: null,
            checklistSelected: null
        }
    },
    watch: {
        organizationSelected: {
            deep: true,
            handler(val){
                if(val !== null){
                    this.getOrganizationChecklists();
                }
            }
        }
    },
    methods: {
        copyChecklistClicked(){
            if(this.organizations.length === 1){
                this.handleCopyOrganizationSelected(this.organizations[0]);
            }
            this.copyChecklistFlag = true;
        },
        handleCopyOrganizationSelected(organization){
            this.organizationSelected = organization;
        },
        newChecklistClicked(){
            this.$emit('newChecklistClicked');
        },
        getOrganizationChecklists(){
            this.$emit('getOrganizationChecklists', this.organizationSelected);
        },
        handleBackButton(){
            this.organizationSelected = null;
        },
        handleCopyButton(){
            this.$emit('copyChecklistClicked', this.organizationSelected, this.checklistSelected);
        },
        closeDialog(){
            this.organizationSelected = null;
            this.checklistSelected = null;
            this.copyChecklistFlag = false;
            this.$emit('closeDialog');
        }
    },
    props:{
        showDialog: {
            required: true,
            type: Boolean
        },
        organizations:{
            required: true
        },
        checklists:{
            type: Array
        },
        loadingChecklists:{
            type: Boolean,
            default: false
        }
    },
    components:{
        Dialog, SelectButton, Listbox, Button
    }
}
</script>

<style scoped>

</style>
