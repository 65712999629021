import i18n from "@/i18n";

export const CSV_FIELDS_HOUR = ['date', 'MOTONIVELADORA', 'RETRO_ESCAVADEIRA'];

export const CSV_LABELS_HOUR = ['Data', 'Horas de Motoniveladoras', 'Horas de Retro-Escavadeiras'];

export const CSV_FIELDS_DAILY = ['date', 'PA_CARREGADEIRA', 'BASCULANTE', 'PIPA', 'ESCAVADEIRA'];

export const CSV_LABELS_DAILY = ['Data', 'Diárias de Pás-Carregadeiras', 'Diárias de Caminhões Basculantes', 'Diárias de Caminhões Pipa', 'Diárias de Escavadeiras'];

export const ANOMALIAS_LAYERS_LIST = () => {
    return [
    {name: i18n.t('ANOMALIAS_LAYERS_LIST.Qualidade'), code: "QUALITY"},
    {name: i18n.t('ANOMALIAS_LAYERS_LIST.Mapa de Calor'), code: "HEAT_MAP"},
    {name: i18n.t('ANOMALIAS_LAYERS_LIST.Trechos'), code: "STRETCHES"},
];}

export const ANOMALIAS_MACHINERY = () => {
    return [
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Motoniveladora'), code: "MOTONIVELADORA", time_control: 'HOURLY'},
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Pá-Carregadeira'), code: "PA_CARREGADEIRA", time_control: 'DAILY'},
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Caminhão Basculante'), code: "BASCULANTE", time_control: 'DAILY'},
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Caminhão Pipa'), code: "PIPA", time_control: 'DAILY'},
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Escavadeira'), code: "ESCAVADEIRA", time_control: 'DAILY'},
        {name: i18n.t('ANOMALIAS_LAYERS_LIST.Retro-Escavadeira'), code: "RETRO_ESCAVADEIRA", time_control: 'HOURLY'},
    ];
}
