<template>
    <v-chart class="chart" :option="chartOption" :autoresize="true" ref="chartInstance"/>
</template>

<script>
    import {use} from "echarts/core";
    import {CanvasRenderer} from "echarts/renderers";
    import {PieChart} from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from "echarts/components";
    import VChart from "vue-echarts";

    use([
        CanvasRenderer,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent
    ]);

    export default {

        components: {
            VChart
        },

        beforeMount() {
            this.setupChart();
        },

        data() {
            return {
                chartOption: null,
            };
        },
        props: {
            chartData: {
                type: Array,
                default() {
                    return []
                }
            },
        },
        methods: {
            setupChart() {
                this.chartOption = {
                    fontFamily: "'Unica One', sans-serif",
                    tooltip: {
                        trigger: "item",
                        formatter: function (args) {
                            return `${args.marker} ${args.name} <b>${args.value}% </b><br>`;
                        }
                    },
                    legend: {
                        top: "bottom",
                        left: "center",
                    },
                    series: []
                }
            },
            setupSeries(chartData) {
                this.chartOption.series = [
                    {
                        colorBy: 'data',
                        label: {
                            show: false,
                            position: 'center'
                        },
                        type: "pie",
                        data: chartData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            },
        },
        watch: {
            chartData: {
                deep: true,
                handler(val) {
                    if (val.length === 0) {
                        if (this.chartOption && this.chartOption.series && this.chartOption.series.length > 0 &&
                            this.chartOption.series[0].data) {
                            this.$refs.chartInstance.chart.clear();
                        }
                    } else {
                        this.setupSeries(JSON.parse(JSON.stringify(val)));
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .chart {
        height: 400px;
    }
</style>