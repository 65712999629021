<template>
    <div>
        <input class="form-control" :placeholder="placeholder" :type="type" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :disabled="isDisabled" :class="{'is-invalid' : is_invalid}">
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        props: {
            value: {
                type: [Number, String]
            },
            placeholder: String,
            type: {
                type: String,
                default: 'text'
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            is_invalid: {
                type: Boolean,
                default: false
            }
        },
        watch: {}
    }
</script>

<style scoped>

</style>
