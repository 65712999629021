export default class CoordMapType {

    tileSize;
    tilesList;

    constructor(tileSize, tilesList) {
        this.tileSize = tileSize;
        this.tilesList = tilesList;
    }

    getTile(coord, zoom, ownerDocument) {

        const div = ownerDocument.createElement("div");

        //Imprime os tiles na tela
        // var coorStr = document.createElement("div");
        // coorStr.innerHTML = String("ZOOM: " + zoom + "<br> → x: " + coord.x + "<br> ↓ y: " + coord.y);
        // coorStr.style.position = 'absolute';
        // div.appendChild(coorStr);
        // div.style.width = this.tileSize.width + "px";
        // div.style.height = this.tileSize.height + "px";
        // div.style.fontSize = "15";
        // div.style.color = "red";
        // div.style.borderStyle = "solid";
        // div.style.borderWidth = "1px";
        // div.style.borderColor = "red";

        let baseURL = "https://tiles.iotag.com.br/";
        let urlBucket = "https://tiles.iotag.com.br/";

        if (this.tilesList && this.tilesList.length > 0) {
            for (const tilesByVehicle of this.tilesList) {

                let tileUrl = tilesByVehicle.zooms[zoom].tiles

                if (!tilesByVehicle) {
                    continue;
                }

                let zxy = zoom + "/" + coord.x + "/" + coord.y;

                for (const tile of tileUrl) {

                    if (tile.includes(zxy)) {
                        var img = document.createElement("img");
                        img.style.position = 'absolute';
                        img.src = urlBucket + tile;
                        img.crossOrigin = "anonymous";
                        div.appendChild(img);
                    }
                }
            }
        }
        return div;
    }

    releaseTile(tile) {
        //TODO está funcionando?
        tile.remove();
    }
}
