import axios from '../axios/axios-auth'

export default class OperationalAlertsOccurrencesService {

    findOccurrencesByVehicle(vehicleId, params) {
        return axios.get(`/api/v3/operationalAlertsOccurrences/vehicles/${vehicleId}`, {params: params}).then(response => response.data)
    }

    findAllByIdAndBetween(val) {
        return axios.post('/api/v3/operationalAlertsOccurrences', val).then(response => response.data);
    }

}
