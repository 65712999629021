<template>
    <div>Logout</div>
</template>

<script>
import {router} from "@/router";

export default {
    created() {
        this.logout();
    },
    methods: {
        logout() {
            //precisa limpar o sessionStorage para o msal liberar a popup do login
            sessionStorage.clear();
            this.$store.dispatch('logout');
            router.push({name: 'login'});
        },
    }
}
</script>

<style scoped>

</style>
