<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <!--begin::Portlet-->
            <div class="kt-portlet">
                <div class="kt-portlet__head no-border-radius">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
<!--                            Cadastrar nova máquina-->
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <app-button type="secondary" @handleClick="back">
                            <i class="glyphicons glyphicons-arrow-left"></i>{{ $t('AppVehicleNew.Voltar') }}
                        </app-button>
                    </div>
                </div>
                <!--begin::Form-->
                <div class="kt-form-60">
                    <app-vehicles-form @handleSalve="registerNewVehicle"/>
                </div>
                <!--end::Form-->
            </div>
            <!--end::Portlet-->
        </div>
</template>

<script>

    import axios from '../../../axios/axios-auth'
    import {mapGetters} from 'vuex';
    import {router} from '../../../router';

    import AppVehiclesForm from "./AppVehiclesForm";
    import AppButton from "../../common/AppButton";

    export default {
        data() {
            return {}
        },
        components: {
            AppVehiclesForm, AppButton
        },
        methods: {
            registerNewVehicle(value) {
                let vm = this;
                axios.post('/api/v1/vehicles', value)
                    .then(function (response) {
                        vm.$toast.add({
                            severity: 'success',
                            summary: this.$t('AppVehicleNew.Registro realizado com sucesso'),
                            life: 5000
                        });
                        router.push({name: 'vehicles'})
                    }).catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403) {
                            vm.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppVehicleNew.Você não tem acesso a esssa funcionalidade'),
                                life: 5000
                            });
                        } else {
                            vm.$toast.add({
                                severity: 'error',
                                summary: this.$t('AppVehicleNew.Não foi possivel completar a operação, tente novamente'),
                                life: 5000
                            });
                        }
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppVehicleNew.Não foi possivel completar a operação, tente novamente'),
                            life: 5000
                        });
                    }
                });
            },
            back() {
                router.go(-1);
            }
        },
        computed: {},
        validations: {}
    }
</script>

<style scoped>

</style>

<i18n>
    {
        "pt-BR": {
            "vehicles_register_new": "Cadastrar novo veículo"
        },
        "en-US": {
            "vehicles_register_new": "Register new vehicle"
        }
    }

</i18n>
