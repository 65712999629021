<template>
    <div id="google-maps-fields"></div>
</template>

<script>

import gmapsInit from '../../../utils/gmaps';
import {mapGetters} from 'vuex';

import * as jsts from 'jsts';

import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [jstsWithHolesToGoogleMaps, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        let mapType = 'hybrid';

        if (this.isUserDemo) {
            mapType = 'satellite'
        }

        let vm = this;
        try {
            const google = await gmapsInit();
            vm.google_maps_reference = google;
            vm.infoWindow = new google.maps.InfoWindow();
            this.map = new google.maps.Map(document.getElementById('google-maps-fields'), {
                // https://developers.google.com/maps/documentation/javascript/tutorial#MapOptions
                minZoom: 4, maxZoom: 20,
                zoom: 4,
                center: vm.positionCenter,
                pan: true,
                mapTypeId: mapType,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true
            });

            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingControl: false,
                polygonOptions: {
                    fillColor: 'white',
                    strokeColor: 'white'
                },
                polylineOptions: {
                    fillColor: 'white',
                    strokeColor: 'white'
                }
            });

            google.maps.event.addListener(vm.drawingManager, 'overlaycomplete', function (event) {
                let newShape;
                if (vm.draw_mode === 'polyline') {
                    if (event.overlay.getPath().getLength() == 1) {
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Deve ter, no mínimo, dois pontos distintos',
                            life: 5000
                        });
                        event.overlay.setMap(null)
                        return;
                    }
                    newShape = vm.createPolygonFromPolyline(event);
                    newShape.setMap(vm.map);
                    event.overlay.setMap(null);
                } else {
                    newShape = event.overlay;
                    newShape.type = event.type;
                }

                google.maps.event.addListener(newShape.getPath(), 'set_at', function () {
                    newShape.area = vm.getShapeArea(newShape);
                    vm.$emit('newFieldDrawn', newShape);
                });

                google.maps.event.addListener(newShape.getPath(), 'insert_at', function () {
                    newShape.area = vm.getShapeArea(newShape);
                    vm.$emit('newFieldDrawn', newShape);
                });

                newShape.area = vm.getShapeArea(newShape);

                // Disable drawingManager
                vm.drawingManager.setDrawingMode(null);
                newShape.setEditable(true);

                vm.newShape = newShape;

                vm.$emit('newFieldDrawn', newShape);
            })

            this.drawingManager.setMap(this.map);

        } catch (error) {
            console.error(error);
        }

    },
    data() {
        return {
            map: null,
            google_maps_reference: {},
            fieldsList: [],
            newShape: null
        }
    },
    props: {
        //posição no mapa, ex: {lat: -14.916356, lng: -53.516697}
        positionCenter: {
            required: true,
        },
        fields_to_initialize: {
            type: Array,
            required: true
        },
        selected_field: {
            type: Object,
        },
        draw_field: {
            type: Boolean
        },
        edit_field: {
            type: Boolean
        },
        field_to_edit: {
            type: Object,
        },
        draw_mode: {
            type: String,
        },
        color_polygon: {
            type: String,
        }
    },

    methods: {
        createPolygonFromPolyline(event) {
            let pathToBuffer = [];
            let colorFormat = this.color_polygon === null ? '#FFFFFF' : `#${this.color_polygon}`;
            for (let i = 0; i < event.overlay.getPath().getLength(); i++) {
                pathToBuffer.push(
                    [event.overlay.getPath().getAt(i).lng(),event.overlay.getPath().getAt(i).lat()]
                );
            }
            const POLYGON_WIDTH_IN_METERS = 4/11112, // Roughly 40m
                geoInput = {
                    type: "LineString",
                    coordinates: pathToBuffer
                };
            let geoReader = new jsts.io.GeoJSONReader(),
                geoWriter = new jsts.io.GeoJSONWriter();
            let geometry = geoReader.read(geoInput).buffer(POLYGON_WIDTH_IN_METERS, 2, 2);
            let polygon = geoWriter.write(geometry);
            const SIMPLIFY_DISTANCE_TOLERANCE = 1/11112; // Roughly 10m
            polygon = jsts.simplify.TopologyPreservingSimplifier.simplify(geometry, SIMPLIFY_DISTANCE_TOLERANCE);
            return new this.google_maps_reference.maps.Polygon({
                paths: this.jstsWithoutHolesToGoogleMaps(polygon, this.google_maps_reference.maps),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0.35,
                editable: false,
                strokeColor: colorFormat,
                fillColor: colorFormat
            });
        },
        getCoordinatesFromShape(shape) {
            let paths = shape.getPaths().getArray();

            if (paths.length === 0) {
                return;
            }

            let pathArray = [];
            let points = paths[0].getArray();
            let firstPoint = false;

            for (let point of points) {
                if (firstPoint === false) {
                    firstPoint = point;
                }

                pathArray.push([point.lng().toFixed(6), point.lat().toFixed(6)])
            }

            pathArray.push([firstPoint.lng().toFixed(6), firstPoint.lat().toFixed(6)]);

            return pathArray;
        },
        getShapeArea(shape) {
            return (this.google_maps_reference.maps.geometry.spherical.computeArea(shape.getPaths().getArray()[0]) / 10000).toFixed(3);
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        getCoordinatesCenter(coordinates) {
            if (!coordinates || coordinates.length === 0) {
                return null;
            }

            let northernmostCoordinates = -90;
            let southernmostCoordinates = 90;
            let easternmostCoordinates = -180;
            let westernmostCoordinates = 180;

            coordinates.forEach(coordinate => {
                if (coordinate.lat() > northernmostCoordinates) {
                    northernmostCoordinates = coordinate.lat();
                }
                if (coordinate.lat() < southernmostCoordinates) {
                    southernmostCoordinates = coordinate.lat();
                }
                if (coordinate.lng() > easternmostCoordinates) {
                    easternmostCoordinates = coordinate.lng();
                }
                if (coordinate.lng() < westernmostCoordinates) {
                    westernmostCoordinates = coordinate.lng();
                }
            })

            let center = {
                lat: (northernmostCoordinates + southernmostCoordinates) / 2,
                lng: (easternmostCoordinates + westernmostCoordinates) / 2
            };

            return center;
        },
        clearSelection() {
            this.fieldsList.forEach(field => {
                field.shape.setOptions({
                    fillColor: '#ff6060',
                    strokeColor: '#ff6060',
                });
            });
        },
        getPolygonCenter(shape) {
            return this.getCoordinatesCenter(shape.getPath());
        },
        setSelection(shape, center, color) {
            let colorFormat = color.startsWith("#") ? color : `#${color}`;
            this.clearSelection();
            shape.setOptions({
                fillColor: colorFormat,
                strokeColor: colorFormat,
            });

            this.map.setCenter({lat: center.lat, lng: center.lng})
            if (this.map.getZoom() < 14) {
                this.map.setZoom(14)
            }
        },
        polygonClicked(field) {
            if(this.edit_field){
                return;
            }
            this.setSelection(field.shape, this.getPolygonCenter(field.shape), field.color);
            this.$emit('changeSelectedField', field);
        },
        addSavedFields(fields) {
            this.fieldsList = [];
            let colorFormat;
            fields.forEach(field => {
                let vm = this;
                colorFormat = field.color.startsWith("#") ? field.color : `#${field.color}`;
                let reader = new jsts.io.WKTReader();
                let geometry = reader.read(field.coordinates);

                let newPolygon = new vm.google_maps_reference.maps.Polygon({
                    paths: this.jstsWithHolesToGoogleMaps(geometry, vm.google_maps_reference.maps),
                    strokeColor: colorFormat,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: colorFormat,
                    fillOpacity: 0.35,
                    editable: false,
                    zIndex: 1/geometry.getArea()
                });

                field.shape = newPolygon;
                field.shape.area = (this.google_maps_reference.maps.geometry.spherical.computeArea(
                    this.jstsWithoutHolesToGoogleMaps(geometry,
                        this.google_maps_reference.maps)[0]) / 10000).toFixed(4);

                this.fieldsList.push(field)

                vm.google_maps_reference.maps.event.addListener(newPolygon, 'click', function () {
                    vm.polygonClicked(field);
                });

                newPolygon.setMap(vm.map);

            })
        },
        setSelectionFromList(val) {
            this.fieldsList.forEach(field => {
                if (field.id === val.id) {
                    this.setSelection(field.shape, this.getPolygonCenter(field.shape), val.color);
                }
            })
        },
        clearAllFields() {

            if (this.newShape) {
                this.newShape.setMap(null);
            }

            this.fieldsList.forEach(field => {
                field.shape.setMap(null);
            })

            this.fieldsList = [];
        },
        discardCurrentShape(clean) {
            if (this.newShape) {
                this.newShape.setMap(null);
            }
            if(clean){
                this.newShape = null;
            }
        }
    },
    computed: {
        ...mapGetters([
            'isUserDemo'
        ])
    },
    watch: {
        color_polygon: function (val) {
            if (val) {
                const newColor = '#' + val;

                const polygonOptions = {
                    fillColor: newColor,
                    strokeColor: newColor,
                };

                const polylineOptions = {
                    fillColor: newColor,
                    strokeColor: newColor,
                };

                this.drawingManager.setOptions({
                    polygonOptions,
                    polylineOptions,
                });

                if (this.newShape !== null) {
                    this.discardCurrentShape(false);
                    this.newShape.setOptions({
                        fillColor: newColor,
                        strokeColor: newColor,
                    });
                    this.newShape.setMap(this.map);
                }
            }
        },
        positionCenter: {
            handler: function (val) {
                if (val) {
                    this.map.setCenter({lat: val.lat, lng: val.lng,})
                    if (this.map.getZoom() < 10) {
                        this.map.setZoom(16)
                    }
                }
            },
            deep: true
        },
        fields_to_initialize: {
            handler: function (val) {
                this.clearAllFields();
                if (val) {
                    this.addSavedFields(val);
                }
            }
        },
        selected_field: {
            handler: function (val) {
                this.clearSelection();
                if (val) {
                    this.setSelectionFromList(val)
                }
            }
        },
        draw_field: {
            handler: function (val) {
                if (!val) {
                    this.drawingManager.setDrawingMode(null);
                    return;
                }

                if (this.draw_mode === 'polygon') {
                    this.drawingManager.setDrawingMode(this.google_maps_reference.maps.drawing.OverlayType.POLYGON);
                } else {
                    this.drawingManager.setDrawingMode(this.google_maps_reference.maps.drawing.OverlayType.POLYLINE);
                }
            }
        },
        edit_field: {
            handler: function (val) {
                if (val) {
                    this.fieldsList.forEach(field => {
                        field.shape.setOptions({
                            clickable: false
                        });
                    })
                    return;
                }
                this.drawingManager.setDrawingMode(null);
            }
        },
        field_to_edit: {
            handler: function (val) {
                let vm = this;
                if (val) {
                    this.fieldsList.forEach(field => {
                        if (field.id === val.id) {

                            field.shape.setEditable(true)

                            field.shape.setOptions({
                                clickable: false,
                                fillColor: '#' + val.color,
                                strokeColor: '#' + val.color,
                            });

                            vm.google_maps_reference.maps.event.addListener(field.shape.getPath(), 'set_at', function () {
                                field.shape.area = vm.getShapeArea(field.shape);
                                vm.$emit('newFieldDrawn', field.shape);
                            });

                            vm.google_maps_reference.maps.event.addListener(field.shape.getPath(), 'insert_at', function () {
                                field.shape.area = vm.getShapeArea(field.shape);
                                vm.$emit('newFieldDrawn', field.shape);
                            });

                            vm.newShape = field.shape;
                        }
                    })
                }
            }
        },
    },
}
</script>

<style scoped>

#google-maps-fields {
    height: 100%;
}

</style>
