<template>
    <div class="card-chart">
        <div class="header">
            <span style="font-size: large; color: #000;">{{ $t('AppWaterWithdrawalTypeCard.Caráter captação') }}</span>
            <dropdown v-model="selectedType" :options="types" optionLabel="name" @change="updateCatchmentSelection"/>
        </div>
        <div>
            <app-water-withdrawal-type-chart :data="dataSelected" :showLabel="showLabel">
            </app-water-withdrawal-type-chart>
        </div>
    </div>
</template>

<script>
import AppWaterWithdrawalTypeChart from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeChart";
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        AppWaterWithdrawalTypeChart, Dropdown
    },
    props: {
        typeProcess: {
            type: Array
        },
        typeState: {
            type: Array
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    beforeMount() {
        this.selectedType = this.types[0]
    },
    data() {
        return {
            selectedType: null,
            types: [
                {name: 'Processos'},
                {name: 'Estados'},
            ],
            data: {
                processos: {
                    labels: [],
                    regular: [],
                    desvio: [],
                    nao_autorizados: [],
                    protocolizados: []
                },
                estados: {
                    labels: [],
                    regular: [],
                    desvio: [],
                    nao_autorizados: [],
                    protocolizados: []
                }
            },
        }
    },
    watch: {
        typeProcess: {
            deep: true,
            handler(data) {
                this.setupGraphSeries(this.data.processos, data)
            }
        },
        typeState: {
            deep: true,
            handler(data) {
                this.setupGraphSeries(this.data.estados, data)
            }
        }
    },
    methods: {
        setupGraphSeries(series, data) {
            this.cleanData(series)
            data.forEach(item => {
                if (item.type) {
                    series.labels.push(item.type.name.replace(' ', '\n'))
                } else {
                    series.labels.push(item.name.replace(' ', '\n'))
                }
                series.regular.push(item.regular)
                series.desvio.push(item.deviation)
                series.nao_autorizados.push(item.no_permission)
                series.protocolizados.push(item.protocol)
            })
        },
        cleanData(series) {
            series.labels = []
            series.regular = []
            series.desvio = []
            series.nao_autorizados = []
            series.protocolizados = []
        },
        updateCatchmentSelection() {
            this.$store.dispatch('updateCatchmentType', this.selectedType);
        }
    },
    computed: {
        dataSelected: function () {
            if (this.$store.getters.getCatchmentType) {
                if (this.$store.getters.getCatchmentType.name === "Processos") {
                    return this.data.processos
                }
                return this.data.estados
            } else if (this.selectedType.name === "Processos") {
                return this.data.processos
            }
            return this.data.estados
        }
    }
}
</script>

<style scoped>
.card-chart {
    margin: 1.3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 35rem;
    padding: 2rem;
}

.header {
    padding-inline: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
