import axios from '../axios/axios-auth'

export default class ChecklistService {

    getChecklistData(checklistId){
        return axios.get(`/appConfig/v1/checklists/${checklistId}`).then(response => response.data);
    }

    getChecklistsByOrg(organizationId){ //Pegar todos os checklists pertencentes a organização
        return axios.get(`/appConfig/v1/organizations/${organizationId}/checklists`).then(response => response.data)
    }

    getVehiclesChecklists(organizationId){
        return axios.get(`/api/v3/organizations/${organizationId}/vehicles`).then(response => response.data)
    }

    deleteChecklist(checklistId) {
        return axios.delete(`/appConfig/v1/checklists/${checklistId}`).then(response => response.data)
    }

    saveChecklist(organizationId, data) {
        return axios.post(`/appConfig/v1/organizations/${organizationId}/checklists`, data).then(response => response.data)
    }

    sendChecklistToVehicles(checklistId, data){
        return axios.post(`/appConfig/v1/checklists/${checklistId}/send`, data).then(response => response.data)
    }

    sendEmptyChecklistToVehicles(organizationId, data){
        return axios.post(`/appConfig/v1/organizations/${organizationId}/sendEmptyChecklist`, data).then(response => response.data)
    }

    saveEditedChecklist(checklistId, data) {
        return axios.patch(`/appConfig/v1/checklists/${checklistId}`, data).then(response => response.data)
    }

    getChecklistAnswersList(data){
        return axios.post(`/appConfig/v1/checklists/answers-list`, data).then(response => response.data)
    }

    getChecklistAnswers(answer_id){
        return axios.get(`/appConfig/v1/open/checklists/answers/${answer_id}`).then(response => response.data)
    }

    getChecklistsAnswersByVehicle(organizationId, vehicleId) {
        return axios.get(`/appConfig/v1/organizations/${organizationId}/checklists/answers-list`).then(response => response.data)
    }
}
