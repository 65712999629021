// @formatter:off
import i18n from "../../../i18n";
export const CHARTS_TYPES_DATA = () => {
    return [
        {key: 'events_timeline', value: 'events_timeline', name: i18n.t('AppOptions_real.Linha do Tempo de Apontamentos'), unit: ''},
        {key: 'events_summary', value: 'events_summary', name: i18n.t('AppOptions_real.Resumo de Apontamentos'), unit: ''},
        {key: 'atividade', value: 'atividade', name: i18n.t('AppOptions_real.Atividade (Análise)'), unit: ''},
        {key: 'gasto_combustivel', value: 'gasto_combustivel', name: i18n.t('AppOptions_real.Combustível (Análise)'), unit: ''},
        {key: 'hour_meter', value: 'hour_meter', name: i18n.t('AppOptions_real.Horímetro'), unit: ''},
        {key: 'altitude', value: 'altitude', name: i18n.t('AppOptions_real.Altitude'), unit: 'm'},
        {key: 'engine_instantaneous_fuel_economy', value: 'engine_instantaneous_fuel_economy_series', name: i18n.t('AppOptions_real.Consumo (L/h)'), unit: 'L/h'},
        {key: 'selected_gear', value: 'selected_gear', name: i18n.t('AppOptions_real.Marcha'), unit: ''},
        {key: 'number_of_satellites', value: 'number_of_satellites', name: i18n.t('AppOptions_real.Nº de satélites'), unit: ''},
        {key: 'vehicle_slip_percentage', value: 'vehicle_slip_percentage', name: i18n.t('AppOptions_real.Patinagem (%)'), unit: '%'},
        {key: 'positioning_precision', value: 'positioning_precision', name: i18n.t('AppOptions_real.Precisão (m)'), unit: 'm'},
        {key: 'vehicle_rpm', value: 'vehicle_rpm', name: i18n.t('AppOptions_real.Rotação (rpm)'), unit: 'rpm'},
        {key: 'gsm_signal', value: 'gsm_signal', name: i18n.t('AppOptions_real.Sinal GSM (dbm)'), unit: 'dbm'},
        {key: 'gsm_band', value: 'gsm_band', name: i18n.t('AppOptions_real.Banda GSM'), unit: ''},
        {key: 'temperature', value: 'temperature', name: i18n.t('AppOptions_real.Temperatura (°C)'), unit: '°C'},
        {key: 'actual_engine_percent_torque', value: 'actual_engine_percent_torque', name: i18n.t('AppOptions_real.Torque (%)'), unit: '%'},
        {key: 'gps_vehicle_speed', value: 'gps_vehicle_speed', name: i18n.t('AppOptions_real.Velocidade (km/h)'), unit: 'km/h'},
        {key: 'engine_oil_level', value: 'engine_oil_level', name: i18n.t('AppOptions_real.Nível de Óleo (%)'), unit: '%'},
        {key: 'fuel_level', value: 'fuel_level', name: i18n.t('AppOptions_real.Nível do Tanque (%)'), unit: '%'},

    ];
}

export const  VEHICLES_STATUSES_LIST = () => { return [
    {name: i18n.t('AppOptions_real.Conectado'), code: "WORKING"},
    {name: i18n.t('AppOptions_real.Sem conexão'), code: "DISCONNECTED"},
    {name: i18n.t('AppOptions_real.Sem conexão por mais de 24h'), code: "DISCONNECTED_24"},
];
}

export const OPTIONS_SELECT_WORK_TYPE = () => { return [
    {key:'TOTAL', value: i18n.t('AppOptions_real.Total')},
    {key:'TRABALHANDO', value: i18n.t('AppOptions_real.Trabalhando')},
    {key:'OCIOSO', value: i18n.t('AppOptions_real.Ocioso')},
    {key:'MANOBRANDO', value: i18n.t('AppOptions_real.Manobrando')},
    {key:'DESLOCANDO', value: i18n.t('AppOptions_real.Deslocando')}
];}

export const MAPS_OVERLAY_OPEN_WEATHER_TYPES = Object.freeze({
    PRECIPITATION: "precipitation",
    CLOUDS: "clouds_new",
    TEMPERATURE: "temp_new"
});

export const MAPS_OVERLAY_WEATHER_TYPES = Object.freeze({
    PRECIPITATION: "precipitation",
    CLOUDS: "clouds",
    TEMPERATURE: "temperature",
    REMOVE:'remove'
});

export const MAPS_OVERLAY_WORK_TYPES = Object.freeze({
    TRACK: "track",
    APPLICATION: "application",
    SPEED: "speed",
    HUMIDITY: "humidity"
});

export const MAPS_DEFAULT_CONFIGS = Object.freeze({
    ZOOM:4,
});



// @formatter:on
