<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="!show_canva"
            :paginate-elements-by-height="1100"
            :filename="'relatorio_atividade_' + Date.now()"
            :pdf-quality="1"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf">
            <section slot="pdf-content" style="color: black">
                <section class="pdf-item">
                    <div class="kt-aside__brand kt-grid__item">
                        <div>
                            <img alt="Logo" style="height: 80px;width: 150px" :src="headerImage"/>
                        </div>
                    </div>
                    <div style="padding-left: 50px; padding-right: 50px;padding-top: 20px;padding-bottom: 20px;">
                        <b>{{ $t('AppRealTimeReport.Máquinas selecionadas') }}: </b>
                        <p v-for="vehicle in selected_vehicles" style="display: inline;margin-right: 5px">
                            {{ vehicle.display_id }},
                        </p>
                        <br/>
                        <b>{{ $t('AppRealTimeReport.Informações coletadas entre') }}:</b> {{ dateHourFormat(report_dates.start_date / 1000) }} a
                        {{ dateHourFormat(report_dates.end_date / 1000) }} <br/>
                        <b>{{ $t('AppRealTimeReport.Gerado em') }}:</b> {{ dateHourFormat(Date.now() / 1000) }} <br/>
                    </div>
                    <div style="padding-left: 50px; padding-right: 50px;">
                        <app-real-time-report-cards :cards_data="this.cards_data" :cards_data_loading="false"
                                                    :first_part="true" :vehicle_amount="this.selected_vehicles.length"
                                                    :selected_dates="this.report_dates"/>
                    </div>
                </section>
                <div class="html2pdf__page-break"/>
                <section class="pdf-item" style="padding-left: 50px; padding-right: 50px;padding-top: 30px">
                    <app-real-time-report-cards :cards_data="this.cards_data" :cards_data_loading="false"
                                                :first_part="false"/>
                </section>
                <section class="pdf-item" style="padding-left: 50px; padding-right: 50px;padding-top: 70px;">
                    <div style="text-align: center;font-weight: bold;padding-bottom: 10px">
                        {{ $t('AppRealTimeReport.Trajeto Percorrido') }}
                    </div>
                    <slot>
                        <div id="google-maps-report"></div>
                    </slot>
                </section>
                <div class="html2pdf__page-break"/>
                <section class="pdf-item" style="padding-left: 50px; padding-right: 50px;margin-top: 30px">
                    <div class="row">
                        <div class="col-6" style="text-align: center;font-weight: bold;">
                            {{ $t('AppRealTimeReport.Atividade') }} {{ displayValueInTitle(this.cards_data.total_time, 'h') }}
                            <app-real-time-report-chart-pie
                                style="height: 350px"
                                class="chart-pie"
                                :chart-data="historical_pie_charts_data.atividade_series"/>
                        </div>
                        <div class="col-6" style="text-align: center;font-weight: bold;">
                            Combustível {{ displayValueInTitle(this.cards_data._1009, 'L') }}
                            <app-real-time-report-chart-pie
                                style="height: 350px"
                                class="chart-pie"
                                :chart-data="historical_pie_charts_data.gasto_combustivel_series"/>
                        </div>
                    </div>
<!--                    <app-vehicles-data-table :report_clustered="events_clustered"-->
<!--                                             :report="events"-->
<!--                                             style="margin-top: 50px;"/>-->
                    <div  style="margin-top: 50px;" v-if="this.events_clustered && this.events_clustered.length > 0">
                        <div style="text-align: center;font-weight: bold;padding-bottom: 10px">
                            {{ $t('AppRealTimeReport.Resumo dos Apontamentos') }}
                        </div>
                        <table style="width:100%">
                            <tr>
                                <th>{{ $t('AppRealTimeReport.Descrição') }}</th>
                                <th>{{ $t('AppRealTimeReport.Duração') }}</th>
                            </tr>
                            <tr v-for="event in this.events_clustered">
                                <td>{{showOption(event)}}</td>
                                <td>{{getTimeFromHoursDurationFormatMixin(event.total_time)}}</td>
                            </tr>
                        </table>
                    </div>
                </section>
                <section v-if="this.cards_data && this.cards_data.refuel_liters && this.cards_data.refuel_liters.length > 0"
                         class="pdf-item" style="padding-left: 50px; padding-right: 50px;margin-top: 30px">
                    <div style="text-align: center;font-weight: bold;padding-bottom: 10px">
                        {{ $t('AppRealTimeReport.Abastecimento') }}
                    </div>
                    <table style="width:100%">
                        <tr>
                            <th>{{ $t('AppRealTimeReport.Data') }}</th>
                            <th>{{ $t('AppRealTimeReport.Litros') }}</th>
                        </tr>
                        <tr v-for="event_refuel in this.cards_data.refuel_liters">
                            <td>{{dateHourFormat(event_refuel.event_start_time)}}</td>
                            <td>{{event_refuel.refuel_liters}}</td>
                        </tr>
                    </table>
                </section>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'

import AppVehiclesDataTable from "@/components/views/real-time/AppVehiclesDataTable";
import AppRealTimeReportCards from "@/components/views/real-time/report/AppRealTimeReportCards";
import AppRealTimeReportChartPie from "@/components/views/real-time/report/AppRealTimeReportChartPie";

import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import dateHourFormat from "@/mixins/DateMixin";

export default {
    mixins: [getTimeFromSecondsDurationFormatMixin, dateHourFormat],
    data() {
        return {
            headerImage: require("../../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_HEADER + ".png"),
        }
    },
    props: {
        selected_vehicles: {
            type: Array
        },
        events: {
            type: Array
        },
        events_clustered: {
            type: Array
        },
        cards_data: {
            type: Object
        },
        report_dates: {
            type: Object
        },
        historical_pie_charts_data: {
            type: Object
        }
    },
    components: {
        VueHtml2pdf, AppRealTimeReportCards, AppRealTimeReportChartPie, AppVehiclesDataTable
    },
    computed:{
        show_canva() {
            return window.screen.width <= 1024;
        }
    },
    methods: {
        showOption(data) {
            return data.reason_description;
        },
        generateReport: function () {
            this.$refs.html2Pdf.generatePdf();
        },
        hasDownloaded(event) {
            this.$emit('reportHasBeenDownloaded');
        },
        displayValueInTitle(val, symbol) {
            if (val) {
                return "(" + val.toFixed(2) + " " + symbol + ")";
            }
            return "";
        },
        displayInfo(val) {
            if (val) {
                return val;
            }
            return "N/D";
        }
    },
}
</script>

<style scoped>
table, th, td {
    border:1px solid black;
}

</style>
