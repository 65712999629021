<template>
    <div v-click-outside="closeOptions">
        <div>
            <img
                src="@/assets/images/anomalias/maps-control.png"
                alt="" @click="showControls = !showControls" class="btn-control">
        </div>
        <div style="padding-top: 40px">
            <transition name="fade">
                <div id="controls" v-show="showControls">
                    <div style="display:flex; align-items:center;">
                        <InputSwitch v-model="quality"/>
                        <div @click="qualityChanged" class="mouse-pointer">{{ $t('ANOMALIAS_LAYERS_LIST.Qualidade') }}</div>
                    </div>
                    <div style="display:flex;align-items:center;margin-top: 10px">
                        <InputSwitch v-model="heatMap"/>
                        <div @click="heatMapChanged" class="mouse-pointer">{{ $t('ANOMALIAS_LAYERS_LIST.Mapa de Calor') }}</div>
                    </div>
                    <div style="display:flex;align-items:center;margin-top: 10px">
                        <InputSwitch v-model="stretches"/>
                        <div @click="stretchesChanged" class="mouse-pointer">{{ $t('ANOMALIAS_LAYERS_LIST.Trechos') }}</div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import InputSwitch from 'primevue/inputswitch';
import {ANOMALIAS_LAYERS_LIST} from './AppAnomaliasOptions'


export default {
    data() {
        return {
            showControls: false,
            quality: false,
            heatMap: true,
            stretches: true,
        }
    },

    methods: {
        closeOptions() {
            this.showControls = false;
        },
        qualityChanged() {
            this.quality = !this.quality;
        },
        heatMapChanged() {
            this.heatMap = !this.heatMap;
        },
        stretchesChanged() {
            this.stretches = !this.stretches;
        },
        handleChange() {
            let temp = [];

            if (this.quality) {
                temp.push(ANOMALIAS_LAYERS_LIST()[0]);
            }
            if (this.heatMap) {
                temp.push(ANOMALIAS_LAYERS_LIST()[1]);
            }
            if (this.stretches) {
                temp.push(ANOMALIAS_LAYERS_LIST()[2]);
            }

            this.$emit('layerChanged', temp);
        }
    },
    watch: {
        quality: function (val) {
            this.handleChange();
        },
        heatMap: function (val) {
            this.handleChange();
        },
        stretches: function (val) {
            this.handleChange();
        },
    },
    components: {
        InputSwitch
    }
}
</script>

<style scoped lang="scss">

.mouse-pointer {
    cursor: pointer;
    margin-left: 10px;
}

.header {
    font-weight: bold;
    padding-left: 10px;
}

.line {
    padding: 5px;
}

.item {
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 90px;
}

#controls {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 6px;
    outline: 2px solid #cbcbcb;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.btn-control {
    float: right;
    cursor: pointer;
    background-color: #f2f2f2;
    padding: 5px;
    border-radius: 50px;
    outline: 2px solid #cbcbcb;
    width: 40px;
}

.menu-item-on {
    border: 2px solid #0f9af0;
}


</style>
