import axios from "../axios/axios-auth";

export default class FieldsService {
    /**
     * V3
     **/
    isAuthorized(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/fields/isAuthorized`).then(response => response.data)
    }

    getFieldsSummaryV3(orgId, params) {
        return axios.get(`/api/v3/organizations/${orgId}/fields/summary`, {params: params}).then(response => response.data)
    }

    getFieldsV3(orgId, params) {
        return axios.get(`/api/v3/organizations/${orgId}/fields`, {params: params}).then(response => response.data)
    }

    getNumberOfFieldsV3(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/fields/number`).then(response => response.data)
    }

    createNewFieldV3(orgId, postObject) {
        return axios.post(`/api/v3/organizations/${orgId}/fields`, postObject).then(response => response.data)
    }

    updateFieldV3(fieldId, postObject) {
        return axios.patch(`/api/v3/fields/${fieldId}`, postObject).then(response => response.data)
    }

    deleteFieldV3(fieldId) {
        return axios.delete(`/api/v3/fields/${fieldId}`).then(response => response.data)
    }

    postShapefileV3(orgId, formData) {
        return axios.post(`/api/v3/organizations/${orgId}/fields/shapefile`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => response.data)
    }


}
