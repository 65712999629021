import axios from '../axios/axios-auth'

export default class WaterWithdrawalService {
    getStates(orgId) {
        return axios.get(`/waterService/v1/organizations/${orgId}/filter`).then(response => response.data)
    }

    getAllWaterWithdrawals(orgId, start, end) {
        return axios.get(`/waterService/v1/organizations/${orgId}/withdrawals/general`,
            {params: {startDate: start, endDate: end}}).then(response => response.data)
    }

    getWaterWithdrawalsByState(orgId, stateId, start, end) {
        return axios.get(`/waterService/v1/organizations/${orgId}/withdrawals/state`,
            {params: {state: stateId, startDate: start, endDate: end}}).then(response => response.data)
    }

    getWaterWithdrawalsByUnity(orgId, pointId, start, end) {
        return axios.get(`/waterService/v1/organizations/${orgId}/withdrawals/point`,
            {params: {pointId: pointId, startDate: start, endDate: end}}).then(response => response.data)
    }

    getWaterPoints(orgId) {
        return axios.get(`waterService/v1/organizations/${orgId}/points`).then(response => response.data)
    }

    getWaterWithdrawalsCSV(orgId, start, end) {
        return axios.get(`/waterService/v1/organizations/${orgId}/csv`,
            {params: {startDate: start, endDate: end}}).then(response => response.data)
    }

    deleteWaterWithdrawal(orgId, withdrawalId){
        return axios.delete(`/waterService/v1/organizations/${orgId}/delete`,
            {params: {id: withdrawalId}}).then(response => response.data)
    }

    updateWaterWithdrawal(orgId, request){
        return axios.patch(`waterService/v1/organizations/${orgId}/edit`, request)
            .then(response => response.data)
    }
}
