<template>
    <div>
        <v-chart class="chart" ref="chartInstance" :option="chartOption" :autoresize="true" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, MarkAreaComponent, DataZoomComponent } from 'echarts/components';
import { VisualMapComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import VChart from "vue-echarts";

use([
    CanvasRenderer,
    UniversalTransition,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
    ToolboxComponent,
    VisualMapComponent,
    MarkAreaComponent
]);

export default {
    components: {
        VChart
    },
    props: {
        historical_charts_data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        chartOption() {
            return {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (args) {
                        let value = args[0].value[1] != null ? args[0].value[1].toLocaleString('pt-BR') : '-'
                        return `${args[0].axisValueLabel}<br><b>${value} h</b><br>`;
                    }
                },
                grid: [
                    {
                        top: '11%',
                        bottom: '17%',
                        left: '90',
                        right: '30'
                    }
                ],
                title: {
                    top: '13',
                    left: 'center',
                    text: this.$t('AppWorkingModesLineChart.Horímetro X Tempo'),
                    textStyle: {
                        fontSize: '14',
                        color: '#696969'
                    }
                },
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    type: 'value',
                    min: 'dataMin',
                    axisLabel: {
                        formatter: function (val) {
                            return val.toLocaleString('pt-BR') + ' h'
                        }
                    },
                    axisPointer: {
                        snap: true
                    }
                },
                dataZoom: [
                    {
                        type: 'inside',
                        start: 0,
                        end: 100
                    },
                    {
                        start: 0,
                        end: 100
                    }
                ],
                series: this.historical_charts_data
            };
        }
    },
    watch: {
        historical_charts_data: function(val) {
            this.setupSeries(val)
        }
    },
    methods: {
        setupSeries(chartData) {
            this.$set(this.chartOption, 'series', chartData);
        }
    },
};
</script>
