import { render, staticRenderFns } from "./AppFieldsDetails.vue?vue&type=template&id=810d811a&scoped=true&"
import script from "./AppFieldsDetails.vue?vue&type=script&lang=js&"
export * from "./AppFieldsDetails.vue?vue&type=script&lang=js&"
import style0 from "./AppFieldsDetails.vue?vue&type=style&index=0&id=810d811a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810d811a",
  null
  
)

export default component.exports