import i18n from "../../../i18n";

export const tooltipTexts = Object.freeze({
    'fixedCosts': i18n.t('AppCostsTooltipTextsEnum.Os custos fixos são aqueles que devem ser debitados, independentemente de a máquina ser ou não utilizada'),
    'variableCosts': i18n.t('AppCostsTooltipTextsEnum.Os custos variáveis são aqueles que dependem da quantidade de uso que se faz da máquina e são constituídos por: combustíveis, manutenção e salário do operador'),
    'totalCosts': i18n.t('AppCostsTooltipTextsEnum.Contituídos por: depreciação, juros, seguro, combustíveis, manutenção e salário do operador'),
    'potentialSavings': i18n.t('AppCostsTooltipTextsEnum.Economia potencial dos custos totais'),
    'dieselPrice': i18n.t('AppCostsTooltipTextsEnum.Preço do diesel na época em que foi comprado'),
    'laborPrice':i18n.t('AppCostsTooltipTextsEnum.Preço da mão de obra (incluindo encargos) por hora'),
    'interest': i18n.t('AppCostsTooltipTextsEnum.Taxa de juros anual atribuída ao financiamento da máquina (ex: 8,5%, 4%)'),
    'maintenancePrice': i18n.t('AppCostsTooltipTextsEnum.Preço da manutenção por hora'),
    'deprecationTotal': i18n.t('AppCostsTooltipTextsEnum.Total gasto com depreciação de todos os veículos no período selecionado'),
    'insuranceTotal': i18n.t('AppCostsTooltipTextsEnum.Total gasto com seguro de todos os veículos no período selecionado'),
    'interestTotal': i18n.t('AppCostsTooltipTextsEnum.Total gasto com juros de todos os veículos no período selecionado'),
    'operation': i18n.t('AppCostsTooltipTextsEnum.Tipo de operação'),
    'timePercentage': i18n.t('AppCostsTooltipTextsEnum.Percentual do tempo para cada tipo de operação'),
    'timeTotal':i18n.t('AppCostsTooltipTextsEnum.Tempo absoluto para cada tipo de operação'),
    'laborCost':i18n.t('AppCostsTooltipTextsEnum.Custo de mão-de-obra vezes tempo de operação'),
    'maintenanceCost': i18n.t('AppCostsTooltipTextsEnum.Custo de manutenção vezes tempo de operação'),
    'fuelCost': i18n.t('AppCostsTooltipTextsEnum.Preço do diesel vezes litros gastos para cada operação'),
    'loss': i18n.t('AppCostsTooltipTextsEnum.Soma do preço de mão-de-obra, preço da manutenção e preço do combustível para cada operação'),
    'newVehiclePrice': i18n.t('AppCostsTooltipTextsEnum.Valor que aquisição (Valor compra do produto novo ou usado)'),
    'salvage': i18n.t('AppCostsTooltipTextsEnum.Valor de sucata ou de revenda (pode variar de 10% a 60% do Valor inicial, dependendo da máquina)'),
    'lifespan': i18n.t('AppCostsTooltipTextsEnum.Vida útil em horas (número de horas de uso)'),
    'insurance': i18n.t('AppCostsTooltipTextsEnum.Taxa de seguro (depende da região e da máquina, varia entre 0,5% a 2%)'),
    'maneuverSavings': i18n.t('AppCostsTooltipTextsEnum.Porcentagem dos gastos variáveis em modo Manobrando que poderia ter sido economizada'),
    'travelSavings': i18n.t('AppCostsTooltipTextsEnum.Porcentagem dos gastos variáveis em modo Deslocando que poderia ter sido economizada'),
    'idleSavings': i18n.t('AppCostsTooltipTextsEnum.Porcentagem dos gastos variáveis em modo Ocioso que poderia ter sido economizada'),
    'sameConfiguration': i18n.t('AppCostsTooltipTextsEnum.Aplicar mesmas configurações de custos e economia potencial para máquinas também selecionadas'),
    'others': i18n.t('AppCostsTooltipTextsEnum.Representam os custos fixos, que são aqueles que devem ser debitados, independentemente de a máquina ser ou não utilizada'),
})
