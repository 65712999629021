import axios from '../axios/axios-auth'

export default class TaskOptionsService {

    getAllOptions(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/taskOptions`).then(response => response.data)
    }

    saveNewOption(orgId, name) {
        return axios.post(`/api/v3/organizations/${orgId}/taskOptions`, null,{ params: {name: name} }).then(response => response.data)
    }
}
