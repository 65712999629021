export const STOMP_SOCKET_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};

// ref https://stomp-js.github.io/api-docs/latest/classes/Client.html#state
export const ACTIVATION_STATE = {
    ACTIVE: 0,
    DEACTIVATING: 1,
    INACTIVE: 2,
};