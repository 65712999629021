<template>
    <div id="kt_user_profile_aside" class="kt-grid__item kt-app__toggle kt-app__aside" style="opacity: 1;">
        <div class="kt-portlet">
            <div class="kt-portlet__head  kt-portlet__head--noborder">
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit-y">
                <div class="kt-widget kt-widget--user-profile-1">
                    <div class="kt-widget__head">
                        <div class="kt-widget__media">
                        </div>
                        <div class="kt-widget__content">
                            <div class="kt-widget__section">
                                <a class="kt-widget__username" href=""> {{ user.name }} </a>
                                <span class="kt-widget__subtitle">
                                                    </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <div class="kt-widget__content">
                            <div class="kt-widget__info">
                                <span class="kt-widget__label">{{ $t('email') }}</span>
                                <a class="kt-widget__data">{{ user.username }}</a>
                            </div>
                            <div class="kt-widget__info">
                                <span class="kt-widget__label">{{ $t('phone') }}</span>
                                <a class="kt-widget__data"></a>
                            </div>
                        </div>
                        <div class="kt-widget__items">
                            <router-link class="kt-widget__item kt-widget__item" :to="{name:'profile-account-information'}">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                        <i class="kt-menu__link-icon glyphicons glyphicons-user"></i>
                                                     </span>
                                                     <span class="kt-widget__desc">
                                                            {{ $t('personal_information') }}
                                                     </span>
                                                 </span>
                            </router-link>
                            <router-link class="kt-widget__item" :to="{name:'profile-change-password'}">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                          <i class="kt-menu__link-icon glyphicons glyphicons-lock"></i>
                                                     </span>
                                                     <span class="kt-widget__desc"> {{ $t('change_password') }} </span>
                                                 </span>
                            </router-link>
                            <a class="kt-widget__item " href="#" data-toggle="kt-tooltip" title="" data-placement="right" data-original-title="Coming soon...">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                        <i class="kt-menu__link-icon glyphicons glyphicons-credit-card"></i>
                                                     </span>
                                                     <span class="kt-widget__desc" href="#">
                                                            {{ $t('saved_credit_cards') }}
                                                     </span>
                                                 </span>
                            </a>
                            <a class="kt-widget__item" href="#" data-toggle="kt-tooltip" title="" data-placement="right" data-original-title="Coming soon...">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                            <i class="kt-menu__link-icon glyphicons glyphicons-invoice"></i>
                                                     </span>
                                                     <span class="kt-widget__desc"> {{ $t('statements') }} </span>
                                                 </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.user = this.$store.getters.user;
        },
        data() {
            return {
                user: {
                    name: '',
                    email: ''
                }
            }
        },
        components: {},
        methods: {
            validateForm() {
                console.log('Click validateForm')
            },
        }
    }
</script>

<style scoped>
    a {
        color: #74788d;
    }

    a:hover {
        color: #2c77f4;
    }
</style>

<i18n>
    {
        "pt-BR": {
            "fist_name": "Nome",
            "last_name": "Sobrenome",
            "company_name": "Nome da Empresa",
            "contact_phone": "Telefone",
            "email_address": "Email",
            "company_site": "Site da empresa",
            "email": "Email",
            "phone": "Telefone",
            "location": "Localização",
            "profile_overview": "Visão Geral",
            "personal_information": "Informações Pessoais",
            "account_information": "Informações da Conta",
            "change_password": "Alterar Senha",
            "email_settings": "Alterar Email",
            "saved_credit_cards": "Cartões de credito",
            "tax_information": "Informações legais",
            "new": "Novo",
            "statements": "Extratos",
            "update_personal_information": "Atualize suas informações pessoais",
            "customer": "Informações pessoais:",
            "contact": "Informações de contato",
            "submit": "Salvar",
            "cancel": "Cancelar",
            "chat": "Chat",
            "follow": "Seguir"
        },
        "en-US": {
            "fist_name": "Fist Name",
            "last_name": "LastName",
            "company_name": "Company Name",
            "contact_phone": "Contact Phone",
            "email_address": "Email Address",
            "company_site": "Company Site",
            "email": "Email",
            "phone": "Phone",
            "location": "Location",
            "profile_overview": "Profile Overview",
            "personal_information": "Personal Information",
            "account_information": "Account Information",
            "change_password": "Change Password",
            "email_settings": "Email Settings",
            "saved_credit_cards": "Saved Credit Cards",
            "tax_nformation": "Tax Information",
            "new": "new",
            "statements": "Statements",
            "update_personal_information": "update your personal informaiton",
            "customer": "Personal Info:",
            "contact": "Contact Info:",
            "submit": "Submit",
            "cancel": "Cancel",
            "chat": "Chat",
            "follow": "Follow"
        }
    }

</i18n>
