<template>
    <div id="google-maps"></div>
</template>

<script>

import gmapsInit from '../../../utils/gmaps';
import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [jstsWithHolesToGoogleMaps],
    async mounted() {
        try {
            let vm = this;
            const google = await gmapsInit();
            vm.google = google;
            this.map = new google.maps.Map(document.getElementById("google-maps-review"), {
                minZoom: 4, maxZoom: 20,
                zoom: 4,
                center: {lat: -25.407688, lng: -49.253990},
                mapTypeId: 'hybrid',
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true,
            });

            const roadWorkerImage = require('@/assets/images/anomalias/road_worker.png');
            let icon = {
                url: roadWorkerImage,
                scaledSize: new this.google.maps.Size(40, 40),
                anchor: new this.google.maps.Point(20, 20)
            }

            vm.fix_point_markers.forEach(point => {
                let markerPosition = {lat: point.lat, lng: point.lng};
                const marker = new vm.google.maps.Marker({
                    id: vm.fixPointMarkersIndex,
                    position: markerPosition,
                    map: vm.map,
                    icon: icon
                });
            })
        } catch (error) {
            console.error(error);
        }
    },

    data() {
        return {
            map: null,
            google: {},
            polygons: [],
            selectedColor: '#fbff00'
        }
    },
    props: {
        center_location: {
            required: true
        },
        polygons_data: {
            type: Array,
        },
        fix_point_markers: {
            type: Array,
            required: true
        }
    },
    methods: {

        centerMap() {
            this.map.setCenter({lat: this.center_location.lat, lng: this.center_location.lng});
        },

        deleteDrawnPolygons() {
            this.polygons.forEach(polygon => {
                polygon.setMap(null);
            })
        }
    },
    watch: {
        polygons_data: {
            deep: true,
            handler(val) {
                let vm = this;
                this.deleteDrawnPolygons();

                this.polygons = [];
                val.forEach(polygon => {
                    let newPolygon = new this.google.maps.Polygon({
                        fillColor: polygon.color,
                        fillOpacity: polygon.opacity,
                        strokeColor: polygon.color,
                        strokeOpacity: 1,
                        strokeWeight: 6,
                        map: this.map,
                        paths: vm.jstsWithHolesToGoogleMaps(polygon.geom, this.google.maps),
                        indexID: polygon.id,
                        zIndex: 1
                    });
                    this.polygons.push(newPolygon);
                })
                this.map.setZoom(9);
                this.centerMap();
            }
        },
    },
}
</script>

<style scoped>
#google-maps {
    height: 100%;
}
</style>
