<template>
    <v-chart class="chart" :option="chartOption" autoresize/>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";

use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    components: {
        VChart
    },
    data() {
        return {
            chartOption: null,
            tooltipSuffix: 'm³',
        }
    },
    computed: {
        catchmentSelected: function () {
            return this.$store.getters.getCatchmentType
        }
    },
    props: {
        data: {
            type: Object
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        data: {
            deep: true,
            handler(val) {
                this.setupChart();
                this.setupSeries();
            }
        },
        catchmentSelected: {
            deep: true,
            handler(val) {
                this.setupChart();
                this.setupSeries();
            }
        }
    },
    methods: {
        setupChart() {
            this.chartOption = {
                fontFamily: "'Unica One', sans-serif",
                tooltip: {
                    trigger: "item",
                    formatter: function (args) {
                        return `${args.name} <br> ${args.marker} ${args.seriesName}: <b>${args.value} m³</b><br>`;
                    }
                },
                legend: {
                    icon: 'circle'
                },
                series: [],
                xAxis: {
                    type: 'category',
                    data: this.data.labels,
                    axisLabel: {
                        interval: 0,
                        overflow: 'break'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} m³'
                    },
                },
            }
        },
        setupSeries() {
            let vm = this;
            this.chartOption.series = [
                {
                    name: 'Regular',
                    type: 'bar',
                    stack: 'total',
                    color: '#43912d',
                    data: this.data.regular,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    label: {
                        show: this.showLabel,
                        position: 'inside',
                        color: '#fff',
                        formatter: function (params) {
                            return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                        }
                    }
                },
                {
                    name: 'Desvio',
                    type: 'bar',
                    stack: 'total',
                    color: '#0ca1cb',
                    data: this.data.desvio,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    label: {
                        show: this.showLabel,
                        position: 'inside',
                        color: '#fff',
                        formatter: function (params) {
                            return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                        }
                    }
                },
                {
                    name: 'Pontos não autorizados',
                    type: 'bar',
                    stack: 'total',
                    color: '#b60909',
                    data: this.data.nao_autorizados,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    label: {
                        show: this.showLabel,
                        position: 'inside',
                        color: '#fff',
                        formatter: function (params) {
                            return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                        }
                    }
                },
                {
                    name: 'Pontos protocolizados',
                    type: 'bar',
                    stack: 'total',
                    color: '#888888',
                    data: this.data.protocolizados,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    },
                    label: {
                        show: this.showLabel,
                        position: 'inside',
                        color: '#fff',
                        formatter: function (params) {
                            return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                        }
                    }
                },
            ]

        }
    }
}
</script>

<style scoped>
.chart {
    height: 400px;
}
</style>
