<template>
    <iframe ref="grafana" v-show="src"  :src="src" width="100%" height="100%" frameborder="0" id="frame"></iframe>
</template>

<script>

import moment from "moment";
import {mapGetters} from "vuex";

export default {
    mounted() {
        //NECESSÁRIO PARA DESABILITAR USO DE TECLAS NO DASHBOARD
        // this.$refs.grafana.contentWindow.addEventListener('keydown', function (event) {
        //     console.log('fgndjsfbiusdjbfiusdhbfbuisduifbds')
        //     // if (event.keyCode === 27) {
        //     //     event.preventDefault()
        //     //     event.stopPropagation()
        //     //     return false
        //     // }
        // }, true)

        // window.addEventListener("blur", function (e) {
        //     setTimeout(function () {
        //         window.focus();
        //     }, 0);
        // });
    },
    data() {
        return {
            src: null
        }
    },
    methods: {
        preventNumericInput() {
        },
        validateDataPreRequest(val) {
            let startDate = moment.tz(val.start_date, 'America/Sao_Paulo').utc().valueOf();
            let endDate = moment.tz(val.end_date, 'America/Sao_Paulo').utc().valueOf();

            let src = "https://dashboard.iotag.com.br/d/yBc5x90nk3/iotag-dev-alex?orgId=" + val.org.dashboard_org_id + "&from=" + startDate + "&to=" + endDate;
            src += "&var-veiculos=" + val.vehicles.identification_number;
            src += "&auth_token=" + this.user.access_token;
            src += "&theme=light";
            this.src = src;
        },
    },
    computed: {
        ...mapGetters([
            'user'
        ])
    }
}


</script>


<style>

.css-1peyh2t {
    display: none !important;
}
</style>
