<template>
    <div @click="hover = !hover" v-bind:style="{'show': hover}" class="kt-header__topbar-item kt-header__topbar-item--user">
        <div :class="classPosition" data-toggle="dropdown" data-offset="0px,0px" aria-expanded="false">
            <div class="kt-header__topbar-user">
                <span class="kt-hidden kt-header__topbar-welcome kt-hidden-mobile">{{ $t('hi') }},</span>
                <span class="kt-hidden kt-header__topbar-username kt-hidden-mobile">{{ user.name }}</span>
                <span class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bolder">
                    {{ user.name.substring(0,1) }}
                </span>
            </div>
        </div>
        <div v-bind:class="{'show': hover}" @mouseleave="hover = false"
             class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
             x-placement="top-end" :style="style"
             style="position: absolute; will-change: transform; top: 0; left: 0;">
            <!--begin: Head -->
            <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" id="header">
                <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
                    <div class="kt-user-card__avatar">
                        <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                            {{ user.name.substring(0,1) }}
                        </span>
                    </div>
                    <div class="kt-user-card__name">
                        {{ user.name }}
                    </div>
                </div>
            </div>
            <!--end: Head -->
            <!--begin: Navigation -->
            <div class="kt-notification">
                <router-link :to="{name:'profile-account-information'}"
                             class="kt-notification__item hover">
                    <div class="kt-notification__item-icon">
                        <i class="glyphicons glyphicons-calendar kt-font-success"/>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            {{ $t('my_profile') }}
                        </div>
                        <div class="kt-notification__item-time">
                            {{ $t('my_profile_description') }}
                        </div>
                    </div>
                </router-link>
                <!--                <a href="#"-->
                <!--                   class="kt-notification__item">-->
                <!--                    <div class="kt-notification__item-icon">-->
                <!--                        <i class="glyphicons glyphicons-charts kt-font-warning"></i>-->
                <!--                    </div>-->
                <!--                    <div class="kt-notification__item-details">-->
                <!--                        <div class="kt-notification__item-title kt-font-bold">-->
                <!--                            {{ $t('billing') }}-->
                <!--                        </div>-->
                <!--                        <div class="kt-notification__item-time">-->
                <!--                            {{ $t('billing_description') }}-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </a>-->
                <div class="kt-notification__custom kt-space-between">
                    <a @click.prevent='logout' href="#" class="btn btn-label btn-sm btn-bold">{{ $t('sign_out') }}
                    </a>
                </div>
            </div>
            <!--end: Navigation -->
        </div>
    </div>

</template>

<script>
    export default {
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();

        },
        data() {
            return {
                hover: false,
                window: {
                    width: 0,
                },
                // locale: 'pt-BR',
                user: this.getUser()
            };
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
            },
            logout() {
                console.log('logout')
                this.$store.dispatch('logout');
            },
            getUser() {
                return this.$store.getters.user;
            },
            getLocale() {
                return this.$store.getters.user.language;
            }
        },
        computed: {
            style() {
                if(this.window.width < 400){
                    return {transform: 'translate3d( 55px, 50px, 0px)'}
                }
                if(this.window.width > 400 && this.window.width < 450){
                    return {transform: 'translate3d( 100px, 50px, 0px)'}
                }
                if(this.window.width < 800){
                    return {transform: 'translate3d( 453px, 50px, 0px)'}
                }
                if(this.window.width < 1000){
                    return {transform: 'translate3d( 410px, 50px, 0px)'}
                }
                else {
                    return {transform: 'translate3d(' + (this.window.width - 540) + 'px, 79px, 0px)'}
                }
            },
            classPosition(){
                if (this.window.width < 1000) {
                    return '.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user'
                }
                else{
                    return 'kt-header__topbar-wrapper'
                }

            }
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style scoped>

    .hover {
        cursor: pointer;
    }

    .kt-user-card {
        padding: 1rem 1rem;
        background-color: transparent;
    }

    #header {
        background: #726F70
    }
</style>

<i18n>
    {
        "pt-BR": {
            "my_profile": "Meu perfil",
            "my_profile_description": "Configurações da conta",
            "billing": "Cobrança",
            "billing_description": "Cobranças e notas",
            "sign_out": "Sair",
            "hi": "Olá"
        },
        "en-US": {
            "my_profile": "My profile",
            "my_profile_description": "Account settings",
            "billing": "Billing",
            "billing_description": "billing & statements",
            "sign_out": "Sign Out",
            "hi": "Hi"
        }
    }
</i18n>
