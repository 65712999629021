import axios from '../axios/axios-auth'

export default class AlertsService {

    getDetails(id) {
        return axios.get('/api/v1/alerts/' + id).then(response => response.data)
    }

    getVehicleDetails(vid) {
        return axios.get('/api/v1/alerts/vehicle/' + vid).then(response => response.data)
    }

    getAlertChildren(val) {
        return axios.post('/api/v1/alerts/children', val).then(response => response.data);
    }

    findAlertsByVehicleAndPage(vehicleId, page, params) {
        return axios.get(`/api/v1/alerts/vehicle/${vehicleId}/pages/${page}`, {params: params}).then(response => response.data);
    }

    saveFrentesSelectedPushNotification(val) {
        return axios.post(`/api/v1/alerts/frentes`, val).then(response => response.data);
    }

    findAllByIdentificationNumberAndBetweenV3(val) {
        return axios.post('/api/v3/vehicles/alerts', val, {timeout: 20000}).then(response => response.data);
    }

    updateAlertV3(alertId, val) {
        return axios.patch(`/api/v3/alerts/${alertId}`, val).then(response => response.data)
    }

    getListFailures(){
        return axios.get(`/api/v3/failuresExports`).then(response => response.data)
    }

    newReport(params){
        return axios.post(`/api/v3/failuresExports`, params).then(response => response.data);
    }

    downloadCsv(id){
        return axios.get(`/api/v3/failuresExports/${id}`).then(response => response.data)
    }

    deleteCsv(id){
        return axios.delete(`/api/v3/failuresExports/${id}`).then(response => response.data)
    }
}
