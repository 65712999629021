<template>
    <div class="pt-2 pl-2 pr-2"
         style="background-color: #e8e8e8;height: calc(100vh - 81px);position: relative;overflow: auto">
        <div style="width: 100%;height: 1.5rem">
            <span class="pi pi-times-circle" @click="closeFieldInfo"
                  style="font-size: 1.8rem;color: #393939;float: right;cursor: pointer;"/>
        </div>
        <div class="title" style="font-size: 20px; color: black; font-weight: 800;">
            {{ selected_field.name }}
        </div>
        <div class="mt-4 mr-2 ml-2 field-details">
            <div class="legend">
                {{ $t('AppFieldsDetails.informações do local') }}
            </div>
            <div class="row mt-1 pl-2 pb-2">
                <div class="col-4">
                    <div style="font-size: 15px; color: black; font-weight: 800">
                        <div>{{ $t('AppFieldsDetails.Cor') }}</div>
                        <div style="width: 25px; height: 25px; border-radius: 3px;" :style="{'background-color': '#' + selected_field.color}"></div>
                    </div>
                </div>
                <div class="col-4">
                    <div style="font-size: 15px; color: black; font-weight: 800">
                        {{ selected_field.area }}
                    </div>
                    <div style="font-size: 13px; color: #989898; font-weight: 800">
                        ha
                    </div>
                </div>
                <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                    <div style="font-size: 15px; color: black; font-weight: 800">
                        {{ selected_field.attribute }}
                    </div>
                    <div v-if="selected_field.attribute"
                         style="font-size: 13px; color: #989898; font-weight: 800">
                        {{ $t('AppFieldsDetails.Atributo') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 ml-2 mr-2">
            <Button :label="$t('AppFieldsDetails.Editar')" class="p-button-outlined p-button-secondary"
                    style="background-color: white" :style="{'width': width_edit}" icon="pi pi-pencil" @click="editFieldClicked"/>
            <Button :label="$t('AppFieldsDetails.Deletar')" class="p-button-outlined p-button-secondary"
                    style="background-color: white" :style="{'width': width_edit}" icon="pi pi-trash" @click="deleteFieldClicked"/>
        </div>
        <div style="display: flex;justify-content: center;align-items: center; margin-bottom: 55px;" class="p-0 mt-md-4">
            <canvas id="canvas" width=150 height=120 ref="myCanvas" class="mt-2"/>
        </div>

        <Dialog :visible.sync="showEditFieldDialog" :style="{width: width_dialog}" :header="$t('AppFieldsDetails.Editar')" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span>{{$t('AppFieldsDetails.Deseja editar o local?')}}</span>
            </div>
            <template #footer>
                <Button :label="$t('AppFieldsDetails.Não')" icon="pi pi-times" class="p-button-text" @click="showEditFieldDialog = false"/>
                <Button :label="$t('AppFieldsDetails.Sim')" icon="pi pi-check" class="p-button-text" @click="editField"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="showDeleteFieldDialog" :style="{width: width_dialog}"  :header="$t('AppFieldsDetails.Deletar')" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span>{{$t('AppFieldsDetails.Tem certeza que deseja deletar o local?')}}</span>
            </div>
            <template #footer>
                <Button :label="$t('AppFieldsDetails.Não')" icon="pi pi-times" class="p-button-text" @click="showDeleteFieldDialog = false"/>
                <Button :label="$t('AppFieldsDetails.Sim')" icon="pi pi-check" class="p-button-text" @click="deleteField"/>
            </template>
        </Dialog>

    </div>
</template>

<script>

import AppButton from "../../common/AppButton";
import dateFormat from "@/mixins/DateMixin";

import Button from 'primevue/button';
import Dialog from "primevue/dialog"
import Fieldset from 'primevue/fieldset';
import TabMenu from 'primevue/tabmenu';
import ProgressSpinner from 'primevue/progressspinner';
import ColorPicker from 'primevue/colorpicker';

import * as jsts from 'jsts';


export default {
    mixins: [dateFormat],
    mounted() {
        this.changeSelectedField(this.selected_field);
        this.width_edit = this.isScreenMobile ? '100%' : '50%';
        this.width_dialog = this.isScreenMobile ? '85vw' : '30vw';
    },
    data() {
        return {
            color: 'FFFFFF',
            width_dialog: '',
            width_edit: '',
            showEditFieldDialog: false,
            showDeleteFieldDialog: false,
        }
    },
    props: {
        selected_field: {
            type: Object
        },
    },
    watch: {
        selected_field: function (val) {
            if (val) {
                this.changeSelectedField(val)
            }
        },
        color: function(val){
            if (val) {
                this.$emit('colorPolygon', val);
            }
        },
    },
    components: {
        AppButton,
        Button,
        Dialog,
        Fieldset,
        TabMenu,
        ProgressSpinner,
        ColorPicker
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    methods: {
        editFieldClicked() {
            this.showEditFieldDialog = true;
        },
        editField() {
            this.showEditFieldDialog = false;
            this.$emit('editSelectedField');
        },
        deleteFieldClicked() {
            this.showDeleteFieldDialog = true;
        },
        deleteField() {
            this.showDeleteFieldDialog = false;
            this.$emit('deleteSelectedField');
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async changeSelectedField(val) {
            await this.sleep(20);
            this.drawPolygon(val);
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        drawPolygon(val) {
            let canvas = document.getElementById('canvas');
            let ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            let polygon =  this.buildPolygonFromCoordinates(val.coordinates)
            let myPoints = []

            polygon._shell.getCoordinates().forEach(coord => {
                myPoints.push([parseFloat(coord.x), parseFloat(coord.y)]);
            })

            let minX, minY, maxX, maxY;
            myPoints.forEach((p, i) => {
                if (i === 0) {
                    minX = maxX = p[0];
                    minY = maxY = p[1];
                } else {
                    minX = Math.min(p[0], minX);
                    minY = Math.min(p[1], minY);
                    maxX = Math.max(p[0], maxX);
                    maxY = Math.max(p[1], maxY);
                }
            });

            const mapWidth = maxX - minX;
            const mapHeight = maxY - minY;
            const mapCenterX = (maxX + minX) / 2;
            const mapCenterY = (maxY + minY) / 2;


            const scale = Math.min((canvas.width - 5) / mapWidth, (canvas.height - 5) / mapHeight);

            ctx.setTransform(1, 0, 0, -1, -1, canvas.height);

            ctx.beginPath();
            myPoints.forEach(p => {
                ctx.lineTo(
                    (p[0] - mapCenterX) * scale + canvas.width / 2,
                    (p[1] - mapCenterY) * scale + canvas.height / 2
                );
            });
            ctx.stroke();

        },
        closeFieldInfo() {
            this.$emit('closeFieldInfo');
        }
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.legend {
    width: 134px;
    margin-top: -10px;
    margin-left: 5px;
    padding-right: 2px;
    background-color: #e8e8e8;
    color: #989898;
    overflow-x: auto;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.field-details {
    border: 1px solid #989898;
    border-radius: 5px;
}

.p-fieldset p {
    line-height: 1.5;
    margin: 0;
}
</style>
