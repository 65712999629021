<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">

                <Dropdown v-model="organizationSelected" :options="orgsList"
                          optionLabel="display_name" :placeholder="$t('AppConfigurationList.Selecione a organização')"
                          class="auto-center" @change="handleOrganizationChange($event.value)"
                          :filter="true" :dataKey="'id'"
                          style="width: 250px">
                    <template #option="slotProps" style="width: 500px">
                        <div v-if="loadingOrgs">{{ $t('AppConfigurationList.Carregando, aguarde') }}</div>
                        <div style="padding-right: 20px" v-else-if="slotProps.option.display_name === 'ND'">
                            -
                        </div>
                        <div style="padding-right: 20px" v-else>
                            {{ slotProps.option.display_name }}
                        </div>
                    </template>
                </Dropdown>

                <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                        </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="primary" :class="{'margin-25-right': !is_col_12, 'width-button': is_col_12}"
                                    @handleClick="newConfigurationList()">
                            <i class="glyphicons glyphicons-plus"></i><span
                            v-if="!is_col_12">{{ $t('AppConfigurationList.Nova Lista') }}</span>
                        </app-button>
                        <app-button type="secondary" @handleClick="back()" :class="{'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-arrow-left"></i><span v-if="!is_col_12">{{ $t('AppConfigurationList.Voltar') }}</span>
                        </app-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!organizationSelected" class="config-body">
            <h3 style="text-align: center; margin-top: 15px;">{{
                    $t('AppConfigurationList.Selecione uma organização')
                }}</h3>
        </div>
        <div v-else class="config-body">
            <div v-if="!configurationList" class="config-body">
                <h3 style="text-align: center; margin-top: 15px;">
                    {{ $t('AppConfigurationList.Nenhuma lista de configuração disponível para a organização') }}.</h3>
            </div>
            <div v-else class="config-body-table">
                <div style="width: 100%;">
                    <h3 style="margin-bottom: 2rem">
                        {{ $t('AppConfigurationList.Lista de Configurações para Dispositivos') }}</h3>
                    <DataTable :value="configurationList" :rowHover="true"
                               :sortOrder="-1" sortField="created_date" responsiveLayout="scroll"
                               :loading="configurationListLoading" class="p-datatable-striped">
                        <template #loading>
                            {{ $t('AppConfigurationList.Carregando lista de configurações, por favor aguarde') }}
                        </template>
                        <Column field="created_date" :header=" $t('AppConfigurationList.Data de Criação') " headerClass="headerClass"
                                bodyClass="bodyClass">
                            <template #body="slotProps">
                                <h5>{{ dateFormat(slotProps.data.created_date) }}</h5>
                            </template>
                        </Column>
                        <Column field="title" :header=" $t('AppConfigurationList.Título')" headerClass="headerClass"
                                bodyClass="bodyClass">
                            <template #body="slotProps">
                                <h5>{{ slotProps.data.title }}</h5>
                            </template>
                        </Column>
                        <Column field="description"
                                :header="$t('AppConfigurationList.Descrição')"
                                headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <h5>{{ slotProps.data.description }}</h5>
                            </template>
                        </Column>
                        <Column :header="$t('AppConfigurationList.Ações')" headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <div>
                                    <Button icon="pi pi-upload" class="p-button-rounded upload-btn"
                                            style="margin-right: 1rem"
                                            @click="showSendListDialog(slotProps.data)"/>
                                    <Button icon="pi pi-pencil" class="p-button-rounded edit-btn"
                                            @click="goToEdit(slotProps.data.id)"
                                            style="margin-right: 1rem"/>
                                    <Button :disabled="disableButton" icon="pi pi-times"
                                            class="p-button-rounded delete-btn"
                                            @click="openDeleteDialog(slotProps.data)"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <!--- Dialogo de exclusão de lista -->
        <Dialog :header="$t('AppConfigurationList.Deletar Lista de Configuração')" :visible="deleteDialog" :modal="true"
                @update:visible='closeDeleteDialog()'>
            <div class="dialog-body">
                <h5 style="margin-bottom: 1rem">{{ $t('AppConfigurationList.Deseja deletar a lista')  }}:</h5>
                <div style="display: flex; justify-content:center; min-width: 300px; max-width: 600px">
                    <h3>{{ deleteListSelected.title }}: {{ deleteListSelected.description }}</h3>
                </div>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationList.Cancelar')" icon="pi pi-times" class="p-button-text"
                        @click='closeDeleteDialog()'/>
                <Button :disabled="disableButton" :label="$t('AppConfigurationList.Deletar')" icon="pi pi-check"
                        @click="deleteConfigurationList()"/>
            </template>
        </Dialog>

        <!--- Dialogo de enviar lista de configuração para veículo --->
        <div>
            <app-configuration-dialog :show-dialog="listDialog" @hideDialog="hideSendListDialog"
                                      @handleSendConfigListToVehicles="verifyVehiclesBeforeSend"
                                      :loading-table="loadingSendDialog"
                                      :config-list-title="configurationListTitleSelected"
                                      :vehicles-config-list="vehiclesConfigurationList"/>
        </div>


        <!--- Dialogo de lista de configurações sobrescrita -->
        <Dialog :visible="listHasVehicleWithListFlag" :modal="true" :closable="false">
            <div v-if="!loadingSendDialog" class="d-flex flex-column align-items-center" style="width: 25vw">
                <h3>{{ $t('AppConfigurationList.Atenção') }}!</h3>
                {{
                    $t('AppConfigurationList.Dentre os veículos escolhidos há dispositivos que já possuem uma lista de configurações, deseja continuar')
                }}
                <p><b>{{ $t('AppConfigurationList.Obs') }}.</b>
                </p>
            </div>
            <div v-else class="d-flex flex-column align-items-center" style="width: 25vw">
                <ProgressSpinner/>
                <p>{{ $t('AppConfigurationList.Enviando') }} ...</p>
            </div>
            <template #footer>
                <Button :label="$t('AppConfigurationList.Cancelar')" icon="pi pi-times" class="p-button-text" :disabled="disableButton"
                        @click='closeWarningDialog()'/>
                <Button :disabled="disableButton" :label="$t('AppConfigurationList.Confirmar')" icon="pi pi-check"
                        @click="sendConfigListToVehicles(configurationListToSend)"/>
            </template>
        </Dialog>


    </div>
</template>

<script>
import {router} from '../../../router';
import AppButton from "../../common/AppButton";
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import Button from "primevue/button";
import dateFormat from "@/mixins/DateMixin";
import OrganizationsService from "../../../services/OrganizationsService";
import ConfigurationListService from "../../../services/ConfigurationListService";
import ProgressSpinner from 'primevue/progressspinner';
import AppConfigurationDialog from "@/components/views/configuration-list/AppConfigurationDialog";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";

export default {
    beforeMount() {
        this.organizationsService = new OrganizationsService();
        this.configurationListService = new ConfigurationListService();
        this.getOrganizations();
    },
    mixins: [dateFormat, orderCustomFieldByNaturalOrder],
    mounted() {
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            disableButton: false,
            //organização
            organizationsService: null,
            organizationSelected: null,
            orgsList: null,
            loadingOrgs: true,
            //configuration list
            configurationListService: null,
            configurationList: null,
            configurationListLoading: true,
            //delete
            deleteDialog: false,
            deleteListSelected: {},
            //send config
            listDialog: false,
            loadingSendDialog: false,
            vehiclesConfigurationList: null,
            configurationListIdSelected: null,
            configurationListTitleSelected: null,
            configurationListToSend: null,
            listHasVehicleWithListFlag: false,
        }
    },
    methods: {
        back() {
            router.push('/settings');
        },
        newConfigurationList() {
            if (this.organizationSelected) {
                router.push({name: 'new-configuration-list', query: {org_id: this.organizationSelected.id}});
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Erro'),
                    detail: this.$t('AppConfigurationList.Por favor, selecione uma organização'),
                    life: 5000
                });
            }
        },
        //Carrega todas as organizaçãoes que o usuário tem acesso no ínicio da sessão = beforeMount()
        getOrganizations() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('APPCONFIGS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppConfigurationList.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.organizationSelected = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];

            if (this.organizationSelected) {
                this.handleOrganizationChange(this.organizationSelected);
            }
        },
        handleOrganizationChange(organization) {
            this.configurationListService.getConfigurationListByOrg(organization.id)
                .then((response) => {
                    if (response.length === 0) {
                        this.configurationList = null;
                    } else {
                        this.configurationList = response;
                    }
                    this.getVehiclesConfigListByOrg(organization);
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppConfigurationList.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                this.configurationList = null;
            }).finally(() => {
                this.configurationListLoading = false;
            })

            this.saveChosenOptionsToLocalStorage();
        },
        saveChosenOptionsToLocalStorage() {
            if (!this.storedChosenOptions) {
                this.storedChosenOptions = {}
            }

            this.storedChosenOptions['clients'] = [this.organizationSelected];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        openDeleteDialog(list) {
            this.deleteDialog = true;
            this.deleteListSelected = list;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.disableButton = false;
            this.deleteListSelected = {};
        },
        closeWarningDialog() {
            this.listHasVehicleWithListFlag = false;
            this.disableButton = false;
        },
        deleteConfigurationList() {
            this.disableButton = true;
            this.configurationListService.deleteConfigurationList(this.deleteListSelected.id)
                .then((response) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppConfigurationList.Sucesso'),
                        detail: this.$t('AppConfigurationList.Lista deletada'),
                        life: 5000
                    });
                }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Erro'),
                    detail: this.$t('AppConfigurationList.Não foi possível deletar a lista'),
                    life: 5000
                });
            }).finally(() => {
                this.closeDeleteDialog();
                this.handleOrganizationChange(this.organizationSelected);
            })
        },
        goToEdit(idList) {
            router.push({
                name: 'edit-configuration-list',
                query: {org_id: this.organizationSelected.id, list_id: idList}
            });
        },
        getVehiclesConfigListByOrg(organization) {
            this.loadingSendDialog = true;
            this.configurationListService.getVehicleConfigurationByOrg(organization.id)
                .then((response) => {
                    this.vehiclesConfigurationList = response;
                }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Erro'),
                    detail: this.$t('AppConfigurationList.Não foi possível recuperar lista de veículos'),
                    life: 5000
                })
            }).finally(() => {
                this.loadingSendDialog = false;
            });
        },
        showSendListDialog(configList) {
            this.configurationListTitleSelected = configList.title;
            this.configurationListIdSelected = configList.id;
            this.listDialog = true;
        },
        hideSendListDialog() {
            this.listDialog = false;
            this.configurationListTitleSelected = null;
            this.configurationListIdSelected = null;
        },
        verifyVehiclesBeforeSend(data) {
            this.configurationListToSend = data;
            data.vehicles.forEach(vehicle => {
                if (vehicle.status) {
                    this.listHasVehicleWithListFlag = true;
                }
            })
            if (!this.listHasVehicleWithListFlag) {
                this.sendConfigListToVehicles(this.configurationListIdSelected, data);
            }
        },
        sendConfigListToVehicles(configListId, data) {
            this.loadingSendDialog = true;
            this.disableButton = true;
            this.configurationListService.sendConfigurationListToVehicle(configListId, data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppConfigurationList.Sucesso'),
                        detail: this.$t('AppConfigurationList.Lista de configurações enviada aos veículo(s) selecionado(s)'),
                        life: 5000
                    });
                    this.hideSendListDialog();
                    this.closeWarningDialog();
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppConfigurationList.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationList.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.loadingSendDialog = false;
                this.getVehiclesConfigListByOrg(this.organizationSelected);
            })
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    components: {
        AppButton,
        Dropdown,
        DataTable,
        Dialog,
        Column,
        Button,
        OrganizationsService,
        ConfigurationListService,
        AppConfigurationDialog,
        ProgressSpinner
    },
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

.config-body-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.config-body {
    width: 50%;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0rem 2rem 1rem 2rem;
    border-radius: 5px;
    margin: 2rem auto;
}

.upload-btn {
    border: none;
    background-color: #3bbeb4;
}

.upload-btn:enabled:hover {
    background-color: #216963;
}

.delete-btn {
    border: none;
    background-color: #E51111;
}

.delete-btn:enabled:hover {
    background-color: #AD1111;
}

.width-button {
    max-width: 55px;
    margin: 5px;
}

@media(max-width: 1024px) {
    .config-body {
        width: 95%;
    }
}
</style>
