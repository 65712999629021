import axios from '../axios/axios-auth'

export default class AnomaliasService {

    getAnomaliasByOrgV3(orgId, data) {
        return axios.post(`/api/v3/organizations/${orgId}/anomalias`, data).then(response => response.data)
    }

    getDatesWithDataV3(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/anomalias/dates`).then(response => response.data)
    }


}
