<template>
    <input class="form-control" :placeholder="placeholder" :type="type" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :disabled="disabled" :maxlength="maxLength">
</template>

<script>
    export default {
        props: {
            value: {
                type: [Number, String]
            },
            placeholder: String,
            type: {
                type: String,
                default: 'text'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            maxLength: {
                type: Number,
                default: 200
            }
        }
    }
</script>

<style scoped>

</style>
