import axios from '../axios/axios-auth'

export default class UserOrgInvitesService {

    findInvitesByPage(params) {
        return axios.get('/api/v3/invites', {params: params}).then(response => response.data);
    }

    acceptInvite(inviteId) {
        return axios.post(`/api/v3/invites/${inviteId}/accept`).then(response => response.data);
    }

    denyInvite(inviteId) {
        return axios.post(`/api/v3/invites/${inviteId}/deny`).then(response => response.data);
    }

}
