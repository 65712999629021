import axios from '../axios/axios-auth'

export default class StopReasonsService {

    getStopReasonsByOrganization(orgId){
        return axios.get(`/appConfig/v1/organizations/${orgId}/stopReasons`).then(response => response.data)
    }

    addStopReasons(orgId, data){
        return axios.post(`/appConfig/v1/organizations/${orgId}/stopReasons`, data).then(response => response.data)
    }

    deleteStopReasons(stopCodeId) {
        return axios.delete(`/appConfig/v1/stopReasons/${stopCodeId}`).then(response => response.data)
    }

}
