import axios from "../axios/axios-auth";

export default class FieldWorksService {

    getApplicationRateWorks(fieldId, taskId, params) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks/${taskId}/works/application`, {params: params}).then(response => response.data)
    }

    getHumidityWorks(fieldId, taskId, params) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks/${taskId}/works/humidity`, {params: params}).then(response => response.data)
    }

    getSpeedWorks(fieldId, taskId, params) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks/${taskId}/works/speed`, {params: params}).then(response => response.data)
    }

    getOverlap(fieldId, taskId, params) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks/${taskId}/works/overlap`, {params: params}).then(response => response.data)
    }

    getFieldWorkTypes(fieldId, taskId) {
        return axios.get(`/api/v3/fields/${fieldId}/tasks/${taskId}/works`).then(response => response.data)
    }

    getFieldWorksDates(fieldId) {
        return axios.get(`/api/v3/fields/${fieldId}/works/dates`).then(response => response.data)
    }
}
