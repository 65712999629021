<template>
    <vue-highcharts :options="chart_options_activity" ref="child"/>
</template>

<script>
    import {Chart} from 'highcharts-vue';
    import Highcharts from "highcharts";
    import theme from "../../../assets/styles/charts/default";
    import i18n from "@/i18n";
    import HighchartsNoData from 'highcharts/modules/no-data-to-display';
    HighchartsNoData(Highcharts);

    export default {
        props: {
            chart_data: {
                type: Array,
                required: true
            },
            reflow_chart: {
                type: Boolean
            },
            chart_code: {
                type: String,
                required: true
            },
            chart_title: {
                type: String,
                default: null
            },
            is_analysis: {
                type: Boolean,
                default: false
            },
            is_pdf: {
                type: Boolean,
                default: false
            }
        },
        beforeCreate() {
            Highcharts.setOptions(theme);
        },
        created() {
            this.setupChart();

        },
        data() {
            return {
                chart_options_activity: {},
                showDate: false,
                old_chart_code: null,
                height: 0
            }
        },
        methods: {
            setupChart() {
                let viewModel = this;
                this.chart_options_activity = {
                    lang: {
                        viewFullscreen: i18n.t('AppLineChart.Tela Cheia'),
                        downloadPNG: i18n.t('AppLineChart.Download PNG'),
                        printChart: i18n.t('AppLineChart.Imprimir'),
                        exitFullscreen: i18n.t('AppLineChart.Sair da Tela Cheia')
                    },
                    exporting: {
                        shared: true,
                        useHTML: true,
                        filename: 'gráfico_estatística',
                        menuItemDefinitions: {},
                        buttons: {
                            contextButton: {
                                menuItems: ['viewFullscreen', 'downloadPNG', 'printChart'],
                                theme:{ fill: "white", stroke: "none", padding: 5 },
                                symbolFill: "#666666",
                                symbolStroke: "#666666",
                            },
                        }
                    },
                    chart: {
                        spacingTop: this.is_pdf ? 10 : this.is_analysis ? 0 : 30,
                        spacingBottom: this.is_analysis ? 0 : 30,
                        height: 450,
                        style: {
                            fontFamily: 'robotoregular'
                        },
                        type: 'pie'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: viewModel.chart_title
                    },
                    tooltip: {
                        outside: true,
                        useHTML: true,
                        formatter: function () {
                            if(this.point.description && this.point.rawValue){
                                return this.point.description + ': ' + this.point.y + '%' + ' | ' +
                                    this.point.rawValue + ' ' + this.point.rawSymbol;
                            }else if (this.point.description) {
                                return this.point.description + ':' + this.point.y + '%';
                            } else if (this.point.rawValue) {
                                return this.point.name + ': ' + this.point.y + '%' + ' | ' +
                                    this.point.rawValue + ' ' + this.point.rawSymbol;
                            }
                            return this.point.name + ': ' + this.point.y + '%';
                        }
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                                formatter: function () {
                                    return this.point.name + ': <br>' + this.point.y + '%';
                                }
                            },
                            showInLegend: true,
                            point: {
                                events: {
                                    click: function (event){
                                        let chart_code = viewModel.chart_code;
                                        let chart = this.series.chart;
                                        let find_div = document.getElementById(chart_code + "-" + event.point.id);
                                        if(find_div){
                                            document.getElementById(chart_code + "-" + event.point.id).remove();
                                        } else {
                                            let new_div = viewModel.createDiv(event);
                                            chart.container.appendChild(new_div);
                                        }
                                    }
                                }
                            }
                        }
                    },
                    series: [{
                        colorByPoint: true,
                        data: viewModel.chart_data,
                    }],
                };
            },
            createDiv(event){
                let cloneDiv;
                cloneDiv = document.createElement('div');
                cloneDiv.id = `${this.chart_code}-${event.point.id}`;
                cloneDiv.style.cssText = `top:${event.chartY - 52}px; left:${event.chartX - 68}px; padding:0.8rem; border-style:solid; border-width:0.5px; \n
                border-color:${event.point.color}; border-radius:5px; background:rgb(255,255,255,0.85); position:absolute; fontSize:12px; color:#333333`;
                cloneDiv.innerHTML = `${event.point.name}: ${parseFloat(event.point.percentage).toFixed(2)}% | ${event.point.rawValue} ${event.point.rawSymbol}`;
                return cloneDiv;
            },
            cleanFixedTooltips(){
                let tooltips_to_remove = document.querySelectorAll(`[id^="${this.old_chart_code}-highcharts-"]`);
                tooltips_to_remove.forEach((tooltip) => {
                    tooltip.remove();
                })
            },
            redimensionar(val){
                this.height = val;
                this.chart_options_activity.chart.height = val - 50;
                let chart = this.$refs.child.chart;
                chart.reflow();
            }
        },
        components: {VueHighcharts: Chart},
        watch: {
            chart_data: {
                deep: true,
                handler() {
                    if (this.chart_data === undefined) {
                        return
                    }
                    this.cleanFixedTooltips();
                    this.setupChart();
                    this.old_chart_code = this.chart_code;
                    if(this.is_analysis){
                        this.redimensionar(this.height);
                    }
                    let chart = this.$refs.child.chart;
                    chart.reflow();
                }
            },
            reflow_chart: function (val) {
                //https://api.highcharts.com/class-reference/Highcharts.Chart#reflow
                let chart = this.$refs.child.chart;
                chart.reflow();
            }
        }
    }
</script>

<style>
    .highcharts-tooltip-container {
        z-index: 9999 !important;
    }


</style>
